import { logoutWithoutRedirectToMainPage, USER_SESSION_CACHE_NAME } from 'actions/auth';
import { cacheManager } from 'utils/cache-manager';
import { PRIVATE_CACHE_FLAG } from 'consts';
import store from 'store';

import { API_BASE_URL } from './apiBaseUrl';
import { createError } from './createError';
import { getHeaders } from './getHeaders';

function refresh() {
    const userSession = cacheManager.getItem(USER_SESSION_CACHE_NAME);

    if (userSession) {
        store.dispatch(logoutWithoutRedirectToMainPage());
    } else {
        cacheManager.removeByFlag(PRIVATE_CACHE_FLAG);
        localStorage.clear();
    }
}

async function retryFetch(url, params = {}, times = 2) {
    const headers = await getHeaders(params.headers);

    const response = await fetch(`${API_BASE_URL}/${url}`, { ...params, headers });

    if (response.status === 401 && times) {
        refresh();

        return retryFetch(url, params, times - 1);
    }

    return response;
}

export async function fetcherWithRetry(url, params = {}) {
    const response = await retryFetch(url, params);

    if (response.ok) {
        return response.json();
    }

    return createError(response);
}
