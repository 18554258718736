import { combineReducers } from '@reduxjs/toolkit';
import {
    SET_CHECKIN_LOCATOR,
    SET_CHECKIN_LASTNAME,
    SET_CHECKIN_FLIGHT,
    SET_IS_MODAL_OPEN,
    SET_IS_BOARDING_PASS_MODAL_OPEN,
    SET_IS_FLIGHT_NOT_FOUND,
    SET_ONLY_OFFLINE_REGISTRATION,
    SET_IS_BOOKING_NOT_FOUND,
    SET_ORDER_DATA,
    FETCH_ORDERS_CHECKIN_FAILURE,
    FETCH_ORDERS_CHECKIN_REQUEST,
    FETCH_ORDERS_CHECKIN_SUCCESS,
    FETCH_USER_ORDERS_CHECKIN_FAILURE,
    FETCH_USER_ORDERS_CHECKIN_REQUEST,
    FETCH_USER_ORDERS_CHECKIN_SUCCESS,
    START_FETCHING,
    FINISH_FETCHING,
    SET_IS_FAILURE,
    START_TRANSITION_TO_REGISTRATION,
} from 'actions/checkin';

const isFetching = (state = false, action) => {
    switch (action.type) {
        case FETCH_ORDERS_CHECKIN_REQUEST:
        case FETCH_USER_ORDERS_CHECKIN_REQUEST:
        case START_FETCHING:
        case START_TRANSITION_TO_REGISTRATION:
            return true;

        case FETCH_ORDERS_CHECKIN_FAILURE:
        case FETCH_ORDERS_CHECKIN_SUCCESS:
        case FETCH_USER_ORDERS_CHECKIN_FAILURE:
        case FETCH_USER_ORDERS_CHECKIN_SUCCESS:
        case FINISH_FETCHING:
            return false;

        default:
            return state;
    }
};

const isFailure = (state = false, action) => {
    switch (action.type) {
        case SET_IS_FAILURE:
            return action.payload;

        case FETCH_ORDERS_CHECKIN_FAILURE:
        case FETCH_USER_ORDERS_CHECKIN_FAILURE:
            return true;

        case FETCH_ORDERS_CHECKIN_SUCCESS:
        case FETCH_USER_ORDERS_CHECKIN_SUCCESS:
            return false;

        default:
            return state;
    }
};

const INITIAL_STATE = {
    locator: '',
    lastName: '',
    flight: '',
};

const searchForm = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_CHECKIN_LOCATOR:
            return { ...state, locator: payload };

        case SET_CHECKIN_LASTNAME:
            return { ...state, lastName: payload };

        case SET_CHECKIN_FLIGHT:
            return { ...state, flight: payload };

        default:
            return state;
    }
};

const isModalOpen = (state = false, action) => {
    switch (action.type) {
        case SET_IS_MODAL_OPEN:
            return action.payload;

        default:
            return state;
    }
};

const isBoardingPassModalOpen = (state = false, action) => {
    switch (action.type) {
        case SET_IS_BOARDING_PASS_MODAL_OPEN:
            return action.payload;

        default:
            return state;
    }
};

const isFlightNotFound = (state = false, action) => {
    switch (action.type) {
        case SET_IS_FLIGHT_NOT_FOUND:
            return action.payload;

        default:
            return state;
    }
};

const onlyOfflineRegistrationMessage = (state = false, action) => {
    switch (action.type) {
        case SET_ONLY_OFFLINE_REGISTRATION:
            return action.payload;

        default:
            return state;
    }
};

const isBookingNotFound = (state = false, action) => {
    switch (action.type) {
        case SET_IS_BOOKING_NOT_FOUND:
            return action.payload;

        default:
            return state;
    }
};

const order = (state = null, action) => {
    switch (action.type) {
        case SET_ORDER_DATA:
            return action.payload;

        default:
            return state;
    }
};

const openedOrders = (state = [], action) => {
    switch (action.type) {
        case FETCH_ORDERS_CHECKIN_SUCCESS:
        case FETCH_USER_ORDERS_CHECKIN_SUCCESS:
            return action.payload;

        case FETCH_ORDERS_CHECKIN_FAILURE:
        case FETCH_USER_ORDERS_CHECKIN_FAILURE:
            return [];

        default:
            return state;
    }
};

export default combineReducers({
    isFetching,
    searchForm,
    isModalOpen,
    isBoardingPassModalOpen,
    isBookingNotFound,
    isFlightNotFound,
    onlyOfflineRegistrationMessage,
    isFailure,
    order,
    openedOrders,
});
