import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router'; // eslint-disable-line no-unused-vars
import { Button } from '@public-projects/utair-ui-kit';
import get from 'lodash/get';
import { parseError } from 'utils/fetch';
import { connect, useDispatch } from 'react-redux';
import { initAuth } from 'actions/auth';
import { getUserData } from 'actions/user';

import { getDialogIsVisibleState, isSocialRegistration as getIsSocialRegistration } from './social/selectors';
import SocialAuth from './Social';
import { authLogin, getAuthConfirmError } from './queries';
import styles from './Account.module.scss';
import InputSelector from '../common/InputSelector';

function LogIn({ isSocialRegistration, setIsLoading, isDialogVisible, onNext = () => {} }) {
    const dispatch = useDispatch();
    const [login, setLogin] = useState('');
    const [checkType, setCheckType] = useState('phone');
    const [doLogin, setDoLogin] = useState();
    const [error, setError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setDoLogin(true);
    };

    const handleErrorLogin = (value) => {
        parseError(value).catch((data) => {
            const errorCode = get(data, 'data.meta.error_code');
            setDoLogin(false);
            setIsLoading(false);
            setError(getAuthConfirmError(errorCode));
        });
    };

    const onSuccessSNLogin = () => {
        dispatch(initAuth());
        setIsLoading(false);

        if (window.location.pathname === '/status/about') {
            dispatch(getUserData(true));

            return;
        }

        const path = isSocialRegistration ? 'user' : 'bonuses';

        window.location.href = `/${path}`;
    };

    useEffect(() => {
        if (!doLogin) return;
        setIsLoading(true);
        authLogin(login)
            .then((data) => {
                onNext({ __step: 'LogInConfirm', data: { ...data, login, checkType } });
                setIsLoading(false);
            })
            .catch(handleErrorLogin);
    }, [doLogin]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <h3 className={styles.title}>{isDialogVisible ? 'Регистрация' : 'Вход в личный кабинет'}</h3>
            {!isDialogVisible && (
                <InputSelector
                    value={login}
                    setValue={setLogin}
                    error={error}
                    setError={setError}
                    checkType={checkType}
                    setCheckType={setCheckType}
                    buttonName="Войти"
                />
            )}
            <div className="!mt18">
                <SocialAuth setIsLoading={setIsLoading} onSuccess={onSuccessSNLogin} />
            </div>
            {!isDialogVisible && (
                <Button
                    className={styles.buttonWithSocial}
                    fullWidth
                    variant="text"
                    onClick={() => onNext({ __step: 'SignUp' })}
                >
                    Зарегистрироваться
                </Button>
            )}
        </form>
    );
}

const mapStateToProps = (state) => ({
    isDialogVisible: getDialogIsVisibleState(state),
    isSocialRegistration: getIsSocialRegistration(state),
});

export default connect(mapStateToProps)(LogIn);
