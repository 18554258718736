import { Button } from '@public-projects/utair-ui-kit';
import cn from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './Passenger.module.scss';

interface Props {
    firstName?: string;
    lastName?: string;
    note?: string;
    Action?: ReactNode;
    isSelected?: boolean;
    price?: string;
    formattedOldPrice: string | null;
    onAdd?: () => void;
}

export function Passenger({
    firstName = '',
    lastName = '',
    note = '',
    Action = null,
    isSelected = false,
    price = '',
    formattedOldPrice = '',
    onAdd = () => {},
}: Props) {
    return (
        <div className={styles.passenger} data-testid="checkIn-ServiceItemPassenger">
            <div>
                <div className={styles.name}>
                    {firstName} {lastName}
                </div>
                {note && <div className={styles.subText}>{note}</div>}
            </div>
            <div className={styles.counterWrapper}>
                <div className={cn(styles.prices)}>
                    {!!formattedOldPrice && (
                        <span className={cn(styles.oldPrice, isSelected && styles.selectedOldPrice)}>
                            {formattedOldPrice}
                        </span>
                    )}
                    <span
                        className={cn(
                            styles.price,
                            !!formattedOldPrice && styles.activeDiscount,
                            isSelected && styles.selectedPrice
                        )}
                    >
                        {price}
                    </span>
                </div>
                {isSelected ? (
                    Action
                ) : (
                    <Button
                        className={styles.button}
                        size="small"
                        data-testid="checkIn-ServiceItemPassenger-addButton"
                        onClick={onAdd}
                    >
                        Добавить
                    </Button>
                )}
            </div>
        </div>
    );
}
