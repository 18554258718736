import {CAPTCHA_ACTION, CAPTCHA_KEY, CAPTCHA_FAIL_TOKEN} from 'consts';

const captchaManager = {
    getCaptchaToken() {
        return new Promise((resolve) => {
            const timer = setTimeout(() => {
                resolve(CAPTCHA_FAIL_TOKEN);
            }, 10000);

            try {
                grecaptcha.ready(() => {
                    grecaptcha.execute(CAPTCHA_KEY, {action: CAPTCHA_ACTION}).then((captchaToken) => {
                        clearTimeout(timer);
                        resolve(captchaToken);
                    });
                });
            } catch (error) {
                clearTimeout(timer);
                resolve(CAPTCHA_FAIL_TOKEN);
            }
        });
    }
};

export default captchaManager;
