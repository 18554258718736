exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InputSelector-module__wrapper--2onov{margin:24px 0 16px}", ""]);

// exports
exports.locals = {
	"wrapper": "InputSelector-module__wrapper--2onov"
};