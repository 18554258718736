export const footer = {
    phone_label: 'Платно',
    phone_label_ru: 'Бесплатно для участников Status',
    appstore: 'Доступно в App Store',
    googleplay: 'Доступно на Google Play',
    links: {
        view: 'Обзор',
        status: 'Программа Статус',
        orders: 'Мои полёты',
        private_data: 'Личные данные'
    },
    rights: '© 2017 ПАО «Авиакомпания „ЮТэйр“». Все права защищены'
};
