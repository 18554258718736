import React from 'react';
import cn from 'classnames';

import styles from './Row.module.scss';
import { PassengerInfo } from '../PassengerInfo';

export function TabContentRow({ className, segment, passenger, Action }) {
    return (
        <div className={cn(styles.root, className)}>
            <PassengerInfo className={styles.passenger} passenger={passenger} />
            <span className={styles.date}>{segment.date}</span>
            <span className={styles.duration}>{segment.duration}</span>
            <span className={styles.gate}>{segment.gate}</span>
            <span className={styles.seat}>{passenger.seat}</span>
            <div className={styles.action}>{Action}</div>
        </div>
    );
}
