import React from 'react';
import i18next from 'i18next';

export const ConfirmAttemptsExceededError = () => (
    <div>
        {i18next.t('sign.error.confirm_attempts_exceeded_text')}&nbsp;
        <a href="https://www.utair.ru/information/contacts/" target="_blank" rel="noreferrer">
            {i18next.t('sign.error.confirm_attempts_exceeded_link')}
        </a>
    </div>
);
