exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Card-module__root--2tIbH{display:flex;background:#f6f8fc;border-radius:6px}.Card-module__content--14Qkw{display:flex;flex-direction:column;flex:3 0;margin:20px 20px 20px 0}.Card-module__actions--2uCi2{display:flex;align-items:center;justify-content:space-between;margin-top:auto}.Card-module__title--2Z-DN{position:relative;padding-right:25px;color:#111820;font-weight:400;font-size:16px;line-height:24px}.Card-module__image--1xiVX{margin:6px 12px 6px 6px;width:146px;height:144px}.Card-module__icon--PJRQu{position:absolute;top:0;right:0;height:22px;width:22px}.Card-module__subTitle--WhPln{color:rgba(94,110,135,.8);font-weight:400;font-size:14px;line-height:20px}.Card-module__alert--2XnBZ{color:#008c5f;font-weight:400;font-size:14px;line-height:20px}.Card-module__price--3LQXp{color:#003594;font-weight:400;font-size:14px;line-height:20px}.Card-module__cancelButton--3Sdzr{padding:0}", ""]);

// exports
exports.locals = {
	"root": "Card-module__root--2tIbH",
	"content": "Card-module__content--14Qkw",
	"actions": "Card-module__actions--2uCi2",
	"title": "Card-module__title--2Z-DN",
	"image": "Card-module__image--1xiVX",
	"icon": "Card-module__icon--PJRQu",
	"subTitle": "Card-module__subTitle--WhPln",
	"alert": "Card-module__alert--2XnBZ",
	"price": "Card-module__price--3LQXp",
	"cancelButton": "Card-module__cancelButton--3Sdzr"
};