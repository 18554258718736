/* eslint-disable */
import { FETCH_SOCIAL_NETWORKS } from 'actions/social.networks';

const INITIAL_DATA = {
    isFetching: false,
    items: [],
    error: ''
};

const socials = (state = INITIAL_DATA, {type, payload, error}) => {
    switch (type) {
        case FETCH_SOCIAL_NETWORKS:
            if (error) {
                return {...state, items: [], isFetching: false, error: payload.message};
            } else if (payload) {
                return {...state, items: payload, isFetching: false, error: ''};
            }
            return {...state, items: [], isFetching: true, error: ''};

        default:
            return state;
    }
};

export default socials;
