/* eslint-disable */
import _ from 'lodash/fp';

export const evolve = _.curry(function evolve(transformations, o) {
    const result = {};

    // eslint-disable-next-line
    for (let key in o) {
        const transformation = transformations[key];
        const type = typeof transformation;

        if (type === 'function') {
            result[key] = transformation(o[key]);
        } else if (transformation && type === 'object') {
            result[key] = evolve(transformation, o[key]);
        } else {
            result[key] = o[key];
        }
    }
    return result;
});

export const when = _.curry((pred, whenTrueFn, x) => (pred(x) ? whenTrueFn(x) : x));
