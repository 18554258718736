// страница нужна из-за https://jira.utair.ru/browse/UTFRNT-2238
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Preloader } from '../../components/common';
import './Authorize.scss';

export const START_LINK_AUTH = 'START_LINK_AUTH';

export default function Authorize({ location: { query } }) {
    const dispatch = useDispatch();

    useEffect(() => {
        // eslint-disable-next-line camelcase
        const { email, attempt_id, code, redirect_url } = query;

        dispatch({
            type: START_LINK_AUTH,
            payload: {
                email,
                code,
                attemptId: attempt_id,
                redirectUrl: redirect_url,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return (
        <div className="AuthorizePage">
            <Preloader />
        </div>
    );
}
