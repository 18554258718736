import { getToken } from './getToken';

export async function getHeaders(headers: HeadersInit): Promise<Headers> {
    const hdrs = new Headers(headers);
    const token = await getToken();

    hdrs.append('Content-Type', 'application/json');
    hdrs.append('Authorization', `Bearer ${token}`);

    return hdrs;
}
