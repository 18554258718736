import { createSelector } from '@reduxjs/toolkit';

const getBalanceState = (state) => state.bonuses;

export const getBalance = createSelector(
    [getBalanceState],
    ({
        cardLevel,
        flightsCountCurrentYear,
        deposits,
        milesCount,
        familyMilesCount,
        rubleNeeded,
        qualifyingRuble,
        nextLevel,
        nextLevelName,
        levelName,
        levelColor,
        levelExpire,
        levelBenefits,
        privileges,
    }) => ({
        cardLevel,
        flightsCountCurrentYear,
        deposits,
        milesCount,
        familyMilesCount,
        rubleNeeded,
        qualifyingRuble,
        nextLevel,
        nextLevelName,
        levelName,
        levelColor,
        levelExpire,
        levelBenefits,
        privileges,
    })
);

export const getIsUnregistered = createSelector([getBalanceState], ({ isUnregistered }) => isUnregistered);

export const getBonusesBalance = createSelector([getBalanceState], ({ bonusesBalance }) => bonusesBalance);

export const getIsBonusesBalanceError = createSelector([getBonusesBalance], ({ error }) => !!error);

export const getBonusesContractBalances = createSelector([getBalanceState], ({ contractBalances }) => contractBalances);

export const selectMilesCount = (state) => state.bonuses.milesCount;
export const selectFamilyMilesCount = (state) => state.bonuses.familyMilesCount;
