/* eslint-disable max-len */
// eslint-disable-next-line camelcase
export const seat_map = {
    direction_back: 'Обратно',
    direction_there: 'Туда',
    choose_a_seat_for: 'Выберите место для',
    seats_selected: 'Места выбраны',
    collapse: 'Свернуть',
    rules_of_seats_plus: 'Правила и ограничения к перевозке на Местах+',
    save_selection: 'Сохранить выбор',
    seat_plus_info:
        'Места+ возле аварийных выходов — для тех, кто способен помочь бортпроводникам при экстренной эвакуации.',
    seat_plus_rules: 'Поэтому по правилам безопасности места+ не могут оформить пассажиры',
    seat_plus_rules_item_1: 'С ограниченными физическими возможностями.',
    seat_plus_rules_item_2: 'Беременные женщины.',
    seat_plus_rules_item_3: 'С животными в салоне.',
    seat_plus_rules_item_4: 'В возрасте до 12 лет.',
    seat_plus_rules_item_5: 'С младенцами.',
    seat_restrictions_info: 'Utair также просит не выбирать кресла у аварийных люков',
    seat_restrictions_info_item_1: 'Пассажиров от 12 до 18 лет.',
    seat_restrictions_info_item_2:
        'Пассажиров, не владеющих ни одним из языков, на которых ведется аварийное оповещение.',
    seat_discount_for_elite_levels:
        'Скидки на выбор места и места+ для элитных уровней применяются при формировании итоговой суммы к оплате банковской картой.',
    seat_standard: 'Стандартное место',
    seat_standard_info: 'Обычное место. Выбирайте место+ для дополнительного комфорта',
    reset_selected_places: 'Сбросить выбранные места',
    total: 'Итого',
    seats_selection_not_available: 'Выбор мест недоступен'
};
