import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';
import { startSavePrevScreenName } from 'checkin/shared/lib/savePrevScreenName';
import { CheckInLayout } from 'checkin/widgets/main-layout';
import { ServicesProvider } from 'checkin/widgets/services/layout';
import DynamicImport from 'components/common/dynamic.import';

const CheckInApp = (props) => <DynamicImport load={() => import('checkin/app')} {...props} />;
const Search = (props) => <DynamicImport load={() => import('./search')} {...props} />;
const Passengers = (props) => <DynamicImport load={() => import('./passengers')} {...props} />;
const Seats = (props) => <DynamicImport load={() => import('./seats')} {...props} />;
const Baggage = (props) => <DynamicImport load={() => import('./baggage')} {...props} />;
const Meal = (props) => <DynamicImport load={() => import('./meal')} {...props} />;
const AllServices = (props) => <DynamicImport load={() => import('./all-services')} {...props} />;
const Payment = (props) => <DynamicImport load={() => import('./payment')} {...props} />;
const Summary = (props) => <DynamicImport load={() => import('./summary')} {...props} />;
const Failure = (props) => <DynamicImport load={() => import('./summary/ui/failure')} {...props} />;
const Tickets = (props) => <DynamicImport load={() => import('./tickets')} {...props} />;

export const checkinRoutes = () => {
    startSavePrevScreenName();

    return (
        <Route component={CheckInApp}>
            <Route path="check-in-new">
                <IndexRoute component={Search} />
                <Route component={CheckInLayout}>
                    <Route path="passengers/:cartId" component={Passengers} />
                    <Route path="services" component={ServicesProvider}>
                        <IndexRedirect to="/404" />
                        <Route path="seats/:cartId" component={Seats} />
                        <Route path="baggage/:cartId" component={Baggage} />
                        <Route path="meal/:cartId" component={Meal} />
                        <Route path=":cartId" component={AllServices} />
                    </Route>
                    <Route path="payment/:cartId" component={Payment} />
                    <Route path="summary/:cartId" component={Summary} />
                </Route>
                <Route path="failure/:cartId" component={Failure} />
            </Route>
            <Route path="tickets" component={Tickets} />
        </Route>
    );
};
