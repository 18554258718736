export const TYPE_OF_APPLICATION = {
    SAVE: 'save',
    REMOVE: 'remove',
    CLOSE: 'close',
};

export const SERVICE_TYPE = {
    MEAL: 'meal',
    BAGGAGE: 'baggage',
};
