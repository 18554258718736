const ACTIONS_PREFIX = 'actions/online-panel/';

export const FETCH_ONLINE_PANEL_REQUEST = `${ACTIONS_PREFIX}FETCH_ONLINE_PANEL_REQUEST`;
export const FETCH_ONLINE_PANEL_SUCCESS = `${ACTIONS_PREFIX}FETCH_ONLINE_PANEL_SUCCESS`;
export const FETCH_ONLINE_PANEL_FAILURE = `${ACTIONS_PREFIX}FETCH_ONLINE_PANEL_FAILURE`;
export const FETCH_ONLINE_PANEL_CITIES_REQUEST = `${ACTIONS_PREFIX}FETCH_CITIES_REQUEST`;
export const FETCH_ONLINE_PANEL_CITIES_SUCCESS = `${ACTIONS_PREFIX}FETCH_CITIES_SUCCESS`;
export const FETCH_ONLINE_PANEL_CITIES_FAILURE = `${ACTIONS_PREFIX}FETCH_CITIES_FAILURE`;

export const fetchOnlinePanel = (payload) => ({
    type: FETCH_ONLINE_PANEL_REQUEST,
    payload,
});

export const fetchOnlinePanelCities = (payload) => ({
    type: FETCH_ONLINE_PANEL_CITIES_REQUEST,
    payload,
});
