import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowIcon } from 'checkin/shared/assets/icons/arrow-next.svg';

import styles from './ScrollableTabs.module.scss';

export default function ScrollableTabs({ children }) {
    const containerRef = useRef();
    const [isAtLeftEdge, setIsAtLeftEdge] = useState(true);
    const [isAtRightEdge, setIsAtRightEdge] = useState(true);

    const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        setIsAtLeftEdge(scrollLeft === 0);
        setIsAtRightEdge(scrollWidth - clientWidth - scrollLeft <= 1);
    };

    useEffect(() => {
        handleScroll();
    }, [children.length]);

    const scrollLeft = () => {
        containerRef.current.scrollTo({
            left: 0,
            behavior: 'smooth',
        });
    };

    const scrollRight = () => {
        const { scrollWidth, clientWidth } = containerRef.current;

        containerRef.current.scrollTo({
            left: scrollWidth - clientWidth,
            behavior: 'smooth',
        });
    };

    return (
        <div className={styles.root}>
            {!isAtLeftEdge && <ArrowIcon className={styles.leftArrow} onClick={scrollLeft} />}
            <div ref={containerRef} className={styles.content} onScroll={handleScroll}>
                {children}
            </div>
            {!isAtRightEdge && <ArrowIcon className={styles.rightArrow} onClick={scrollRight} />}
        </div>
    );
}
