/* eslint-disable */
import { takeLatest, all, call, put } from 'redux-saga/effects';
import {SUBMIT_REREGISTER, fetchBalance} from 'actions/bonuses';
import {PROFILE_CACHE_KEYNAME, getUserData} from 'actions/user';
import {utairHttpManager as request} from '../../managers/utair';
import {API_URLS} from '../../consts';
import {cacheManager} from '../../utils/cache-manager';

const submitReregister = () => request.post(API_URLS.AUTH.REREGISTER, null, true);

const submitReregisterSaga = function* () {
    try {
        yield call(submitReregister);
        cacheManager.removeItem(PROFILE_CACHE_KEYNAME);
        cacheManager.removeItem('balance');
        yield put(getUserData());
        yield put(fetchBalance());
    } catch (error) {
        console.log(error);
    }
};

const blockedUsersSaga = function* () {
    yield all([takeLatest(SUBMIT_REREGISTER, submitReregisterSaga)]);
};

export default blockedUsersSaga;
