/* eslint-disable */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import i18next from 'i18next';
import moment from 'moment';

import {
    checkCartPayment,
    clearBasket,
    ENTRANCE_TO_ORDER_SERVICES,
    fetchSeatMap,
    fetchSelectedOrderFSA,
    getAvailableServices,
    getOrderByUUID,
    prefillBasket,
    setDelayedPayment,
    setFetchOrderError,
    setPersonalSaleUTM,
    setSeatMap,
} from 'actions/orders';
import { addMessage } from 'actions/sysmessages';
import { DATE_MASK, ISO_DATE_MASK } from 'consts';
import metrics from 'managers/metrics';
import { getIsAuthUser } from 'selectors/auth';
import { serializeError } from 'utils/serialize-error';

const getSeatMap = (params) => fetchSeatMap(params).catch(() => ({ result: null }));

function* fetchSelectedOrderSaga(uuid) {
    try {
        yield put(fetchSelectedOrderFSA({ order: null, services: null }));
        yield put(clearBasket());

        const { objects } = yield call(getOrderByUUID, uuid);

        const order = objects[0];
        order.services = order.services || [];
        const firstPassenger = order.passengers[0];
        const params = {
            rloc: order.rloc.split('/')[0],
            last_name: firstPassenger.last_name,
        };

        const isAuthUser = yield select(getIsAuthUser);

        const [{ result: services }, { result: seatmap }] = yield all([
            call(getAvailableServices, params, isAuthUser),
            call(getSeatMap, params),
        ]);

        const mapPassengerAgeBySegment = services.segments.reduce(
            (res, segment) => ({
                ...res,
                [segment.id]: services.passengers.reduce(
                    (res, passenger) => ({
                        ...res,
                        [passenger.id]: moment(segment.departure_date_local, ISO_DATE_MASK).diff(
                            moment(passenger.birthdate, DATE_MASK),
                            'years'
                        ),
                    }),
                    {}
                ),
            }),
            {}
        );

        yield put(fetchSelectedOrderFSA({ order, services: { ...services, mapPassengerAgeBySegment } }));
        yield put(setSeatMap(seatmap));
    } catch (error) {
        yield put(setFetchOrderError(serializeError(error)));
    }
}

function* entranceToOrderServicesSaga({ payload }) {
    const { uuid, query } = payload;
    yield fetchSelectedOrderSaga(uuid);
    yield put(setDelayedPayment(query.delayed_payment === 'true'));

    if ('utm_source' in query) {
        const { utm_source, utm_medium, utm_campaign } = query;
        yield put(setPersonalSaleUTM({ utm_source, utm_medium, utm_campaign }));
    } else {
        yield put(setPersonalSaleUTM(null));
    }

    yield put(prefillBasket(query));

    if (query.payment_success) {
        if (!Number(query.payment_success)) {
            const eventType = 'error';
            const errorMessage = i18next.t('orders.payment.failed');
            yield put(addMessage(errorMessage, eventType));

            metrics.pushEvent(eventType, {
                type: 'modal',
                text: errorMessage,
                detail: 'method: entranceToOrderServicesSaga, payment status = 0',
            });
        } else if (query.cartId) {
            yield put(checkCartPayment(query.cartId));
        }
    }
}

export default function* enterOrderServicesWatcher() {
    yield takeLatest(ENTRANCE_TO_ORDER_SERVICES, entranceToOrderServicesSaga);
}
