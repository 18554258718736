import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Notifications } from '@public-projects/utair-ui-kit';
import cn from 'classnames';
import i18next from 'i18next';
import { API_URLS } from 'consts';
import Messages from 'components/common/sysmessages';
import CheckinModal from 'components/CheckinModal';
import CookiesAccept from 'components/cookies-accept';
import { QratorErrorModal } from 'components/common/QratorErrorModal';
import { getHasOrders as getHasCheckInOrders } from 'selectors/checkin';
import { utairHttpManager as request } from 'managers/utair';
import {
    getIsIndexPage as getIsBlueTheme,
    getIsCheckIn,
    getIsOrderManage,
    getIsRoot,
    getIsHiddenFooter,
    getIsOnlinePanel,
} from 'utils/paths';
import { ticketsModel } from 'checkin/entities/tickets';
import { getChatWidgetContext, getIsChatWidgetOpen } from 'selectors/chat-widget';
import { closeChatWidget } from 'actions/chat-widget';

import { useLocale } from '../../locales';
import Header from './header';
import NavMenu from './NavMenu';
import Footer from './footer';

const chatWidgetScriptId = 'chat';

const widgetAttributeTypes = {
    variant: 'data-variant',
    open: 'data-open',
    context: 'data-context',
};

function Layout({
    children,
    router: {
        location: { pathname },
    },
}) {
    const dispatch = useDispatch();

    const hasManageOrders = useSelector((state) => state.orderManage.orders.length);
    const hasCheckInOrders = useSelector(getHasCheckInOrders);
    const isChatWidgetOpen = useSelector(getIsChatWidgetOpen);
    const chatWidgetContext = useSelector(getChatWidgetContext);

    const isBlueTheme = getIsBlueTheme(pathname);
    const isCheckInPage = getIsCheckIn(pathname) && !hasCheckInOrders;
    const isOrderManagePage = getIsOrderManage(pathname) && !hasManageOrders;
    const isRootPage = getIsRoot(pathname);
    const isHiddenFooter = getIsHiddenFooter(pathname);
    const isOnlinePanel = getIsOnlinePanel(pathname);

    const { language: lang } = useLocale();

    const chatWidgetScriptRef = useRef();

    useEffect(() => {
        const widgetCdn = 'https://ut-messenger.utair.ru/cdn';

        const styleLink = document.createElement('link');
        styleLink.href = `${widgetCdn}/bundle.min.css`;
        styleLink.rel = 'stylesheet';

        const script = document.createElement('script');
        script.src = `${widgetCdn}/bundle.min.js`;
        script.id = chatWidgetScriptId;
        script.setAttribute(widgetAttributeTypes.variant, 'controlled');
        script.setAttribute(widgetAttributeTypes.open, 'false');
        script.setAttribute(widgetAttributeTypes.context, '');

        document.body.appendChild(styleLink);
        document.body.appendChild(script);

        chatWidgetScriptRef.current = script;
    }, []);

    const setChatWidgetAttribute = (name, value) => {
        const { current: widgetScript } = chatWidgetScriptRef;

        if (widgetScript) {
            widgetScript.setAttribute(name, value);
        }
    };

    useEffect(() => {
        const { current: widgetScript } = chatWidgetScriptRef;

        if (widgetScript && widgetScript.getAttribute(widgetAttributeTypes.open) !== isChatWidgetOpen) {
            setChatWidgetAttribute(widgetAttributeTypes.open, isChatWidgetOpen.toString());
        }
    }, [isChatWidgetOpen]);

    useEffect(() => {
        const { current: widgetScript } = chatWidgetScriptRef;

        const preparedContext = chatWidgetContext ? JSON.stringify(chatWidgetContext) : '';

        if (widgetScript && widgetScript.getAttribute(widgetAttributeTypes.context) !== preparedContext) {
            setChatWidgetAttribute(widgetAttributeTypes.context, preparedContext);
        }
    }, [chatWidgetContext]);

    useEffect(() => {
        const { current: widgetScript } = chatWidgetScriptRef;

        const mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach(({ type, attributeName }) => {
                if (widgetScript && type === 'attributes' && attributeName === widgetAttributeTypes.open) {
                    const open = widgetScript.getAttribute(attributeName);

                    if (open === 'false') {
                        dispatch(closeChatWidget());
                    }
                }
            });
        });

        if (widgetScript) {
            mutationObserver.observe(widgetScript, {
                attributes: true,
            });
        }

        return () => {
            if (widgetScript) {
                mutationObserver.disconnect();
            }
        };
    }, [chatWidgetScriptRef, dispatch]);

    return (
        <div className={cn('common', { isBlueTheme, isCheckInPage, isOrderManagePage, isRootPage })}>
            <Notifications
                loadUrl={API_URLS.NOTIFICATIONS.GET}
                isBlueTheme={isBlueTheme}
                pathname={pathname}
                ofLabel={i18next.t('notifications.of')}
                moreLabel={i18next.t('notifications.more')}
                request={request}
                lang={lang}
            />
            <Helmet>
                <title>{i18next.t('common.page_title')}</title>
            </Helmet>
            <CookiesAccept />
            <Messages />
            <QratorErrorModal />
            <Header pathname={pathname} isOnlinePanel={isOnlinePanel} />
            <NavMenu />
            {children}
            {!isHiddenFooter && <Footer />}
            <CheckinModal />
            <div id="chat-widget" />
        </div>
    );
}

export default ticketsModel.withContext(Layout);
