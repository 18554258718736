/* eslint camelcase: [0, {properties: "never"}] */

export const nav_menu_links = {
    buy: 'Покупка авиабилета',
    buy_mobile: 'Покупка',
    check_in: 'Регистрация на рейс',
    standby: 'Подсадка',
    tickets_mobile: 'Посадочные',
    tickets: 'Посадочные талоны',
    order_manage: 'Управление билетом',
    user: 'Профиль',
    bonuses: 'Мои мили и уровни',
    bonuses_short: 'Мои мили',
    utair_status: 'Utair Status',
    helicopters: 'Вертолеты',
};
