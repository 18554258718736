exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScrollableTabs-module__root--33MGx{position:relative}.ScrollableTabs-module__rightArrow--1bJeJ,.ScrollableTabs-module__leftArrow--2oC6O{position:absolute;height:100%;width:24px;padding:0 16px;background-color:#fff;color:#003594;cursor:pointer}.ScrollableTabs-module__leftArrow--2oC6O{transform:rotate(-180deg);border-bottom-right-radius:6px}.ScrollableTabs-module__rightArrow--1bJeJ{right:0;top:0;border-top-right-radius:6px}.ScrollableTabs-module__content--2Wt-L{overflow-x:scroll;white-space:nowrap;-ms-overflow-style:none;scrollbar-width:none}.ScrollableTabs-module__content--2Wt-L::-webkit-scrollbar{display:none}", ""]);

// exports
exports.locals = {
	"root": "ScrollableTabs-module__root--33MGx",
	"rightArrow": "ScrollableTabs-module__rightArrow--1bJeJ",
	"leftArrow": "ScrollableTabs-module__leftArrow--2oC6O",
	"content": "ScrollableTabs-module__content--2Wt-L"
};