import { extractError } from 'schedule/shared/api';
import { useAsync } from 'schedule/shared/lib';

import { getAvailableFlightDatesApi } from '../api/getAvailableFlightDatesApi';

export function useGetAvailableDates(departureCityCode: Nullable<string>, arrivalCityCode: Nullable<string>) {
    const { data, isLoading, error } = useAsync(
        () =>
            departureCityCode && arrivalCityCode
                ? getAvailableFlightDatesApi({
                      departureCityCode,
                      arrivalCityCode,
                  })
                : Promise.resolve([]),
        [departureCityCode, arrivalCityCode]
    );

    return {
        data: data || null,
        isLoading,
        error: error ? extractError(error) : null,
    };
}
