exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RadioSelector-module__radioSelector--1F62U{display:flex;justify-content:space-between;margin-top:34px}.RadioSelector-module__radioSelector--1F62U>*+*{margin-left:15px}.RadioSelector-module__radioSelector--1F62U label{display:flex;align-items:center;cursor:pointer;font-size:12px;line-height:16px}.RadioSelector-module__radioSelector--1F62U span{margin-right:10px}", ""]);

// exports
exports.locals = {
	"radioSelector": "RadioSelector-module__radioSelector--1F62U"
};