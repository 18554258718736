import { useEffect, useState } from 'react';

export function useAsync<T>(asyncFunction: () => Promise<T>, deps: unknown[]) {
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        asyncFunction()
            .then(setData)
            .catch(setError)
            .finally(() => setIsLoading(false));
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps

    return { data, error, isLoading };
}
