import React from 'react';
import cn from 'classnames';

import styles from './Row.module.scss';
import { PassengerInfo } from '../PassengerInfo';

export function ModalRow({ className, passenger, Action }) {
    return (
        <div className={cn(styles.root, className)}>
            <div className={styles.action}>{Action}</div>
            <PassengerInfo passenger={passenger} />
        </div>
    );
}
