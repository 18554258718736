import i18next from 'i18next';
import { EMAIL_REGEXP, LOYALTY_CARD } from 'consts';
import { isForeignPhone } from 'utils/phone';

function email(value, isRequired = false) {
    if (!value && value === '') {
        return i18next.t('validator.errors.login_incorrect');
    }

    if (value && value !== '') {
        if (!EMAIL_REGEXP.test(value)) {
            return i18next.t('validator.errors.email_format');
        }
    } else if (isRequired) {
        return i18next.t('validator.errors.email_required');
    }

    return true;
}

function validateForeignPhone(value) {
    if (value.length < 5 || value.length > 15) {
        return i18next.t('validator.errors.phone_foreign_format');
    }

    return true;
}

function validateRuPhone(value) {
    if (value.length !== 12) {
        return i18next.t('validator.errors.phone_format');
    }

    if (![3, 4, 5, 6, 7, 8, 9].includes(Number(value[2]))) {
        return i18next.t('validator.errors.phone_operator_code');
    }

    return true;
}

function phone(value, isRequired = false) {
    const phoneReg = /[0-9]/g;
    const phoneMatch = value ? value.match(phoneReg) : null;

    if (phoneMatch === null) {
        return isRequired ? i18next.t('validator.errors.phone_required') : true;
    }

    const incorrectPhoneSymbols = /[^\d\s+()-]/g;

    if (value.match(incorrectPhoneSymbols)) {
        return isForeignPhone(value)
            ? i18next.t('validator.errors.phone_foreign_format')
            : i18next.t('validator.errors.phone_format');
    }

    return isForeignPhone(value) ? validateForeignPhone(value) : validateRuPhone(value);
}

function cartStatus(value, isRequired = false) {
    const card = value.replace(/\s/g, '');
    if (!isRequired && !card) return true;

    return LOYALTY_CARD.test(card) || i18next.t('validator.errors.status_card_incorrect');
}

export default {
    phone,
    email,
    cartStatus,
};
