import React from 'react';
import { Link } from 'react-router';
import i18next from 'i18next';
import { ReactComponent as LogoUG } from 'icons/logo/logo-u.svg';

const SVG_STYLE = { width: '44px', height: '40px' };

export default function Logo({ indexRoute = '', isMediaPhone = false }) {
    if (isMediaPhone) {
        return (
            <a href={indexRoute} className="header-logo" title={i18next.t('header.to_home')}>
                <LogoUG style={SVG_STYLE} />
            </a>
        );
    }

    return (
        <Link to={indexRoute} className="header-logo" title={i18next.t('header.to_home')}>
            <LogoUG style={SVG_STYLE} />
        </Link>
    );
}
