import {
    SET_SOCIALAUTH_ERROR,
    RESET_SOCIALAUTH,
    SET_DIALOG_STATE,
    SET_SOCIAL_REGISTRATION,
    SET_VISIBLE_SOCIAL,
    SET_SOCIAL_DATA,
    SET_SOCIAL_AUTH,
    RESET_SOCIAL_DATA,
} from './actions';

const INITIAL_STATE = {
    eventBinded: false,
    error: null,
    isVisibleDisalog: false,
    isSocialRegistration: false,
    isVisibleSocial: true,
};

const socialAuth = (
    state = INITIAL_STATE,
    { type, error, isVisible, isSocialRegistration, isVisibleSocial, socialData, isSocialAuth }
) => {
    switch (type) {
        case SET_SOCIALAUTH_ERROR: {
            return {
                ...state,
                error,
            };
        }

        case RESET_SOCIALAUTH: {
            return {
                ...INITIAL_STATE,
                eventBinded: state.eventBinded,
                isSocialRegistration: state.isSocialRegistration,
            };
        }

        case SET_DIALOG_STATE: {
            return {
                ...state,
                isVisibleDisalog: isVisible,
            };
        }

        case SET_SOCIAL_REGISTRATION: {
            return {
                ...state,
                isSocialRegistration,
            };
        }

        case SET_VISIBLE_SOCIAL: {
            return {
                ...state,
                isVisibleSocial,
            };
        }

        case RESET_SOCIAL_DATA: {
            return {
                ...state,
                merging_social_data: null,
                social_data: null,
            };
        }

        case SET_SOCIAL_DATA: {
            return {
                ...state,
                merging_social_data: state.merging_social_data
                    ? state.merging_social_data
                    : state.social_data && state.social_data,
                social_data: {
                    ...state.social_data,
                    ...socialData,
                },
            };
        }

        case SET_SOCIAL_AUTH: {
            return {
                ...state,
                social_auth: isSocialAuth,
            };
        }

        default:
            return state;
    }
};

export default socialAuth;
