import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from '@public-projects/utair-ui-kit';
import { setIsOpenBoardingPassModal, fetchBoardingPass, setIsFailure } from 'actions/checkin';
import { getCheckedInPassengers } from 'selectors/checkin';
import { ReactComponent as IconDownload } from 'icons/interface/download.svg';

import styles from './BoardingPassModal.module.scss';

function downloadURI(url) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.download = url.match(/[^/\\&?]+\.\w{3,4}(?=([?&].*$|$))/g);
    link.href = url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function BoardingPassModal({ isOpen, rloc, flightNumber }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const passengers = useSelector(getCheckedInPassengers(rloc, flightNumber));

    const handleClose = () => {
        dispatch(setIsOpenBoardingPassModal(false));
    };

    const handleDownloadClick = (astraPassengerId) => {
        setIsLoading(true);
        dispatch(fetchBoardingPass(astraPassengerId))
            .then((response) => {
                const { download_link: link } = response;
                downloadURI(link);
            })
            .catch(() => {
                dispatch(setIsFailure(true));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Modal isOpen={isOpen} isLoading={isLoading} onClose={handleClose}>
            <Modal.Title centered>Скачать посадочные</Modal.Title>
            <Modal.Content>
                {passengers.map((passenger) => (
                    <div key={passenger.astraPassengerId} className={styles.boardingPassRow}>
                        <div>
                            {passenger.lastName} {passenger.firstName}
                        </div>
                        <Button
                            variant="text"
                            className={styles.downloadButton}
                            endIcon={<IconDownload className={styles.icon} />}
                            onClick={() => handleDownloadClick(passenger.astraPassengerId)}
                        />
                    </div>
                ))}
            </Modal.Content>
        </Modal>
    );
}

BoardingPassModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    rloc: PropTypes.string.isRequired,
    flightNumber: PropTypes.string.isRequired,
};
