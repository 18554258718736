exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Calendar-module__calendarDropdown--UQN2N{opacity:0;visibility:hidden;transform:translateY(0.5em);transition:transform .3s,opacity .3s,visibility .3s}[aria-hidden=false].Calendar-module__calendarDropdown--UQN2N{opacity:1;visibility:visible;transform:translateY(0)}.Calendar-module__calendar--pOYuF{box-sizing:border-box;display:inline-block;vertical-align:top;height:64px;width:100%;background-color:#fff;border-top:4px solid transparent;border-bottom:4px solid transparent;border-radius:4px}.Calendar-module__calendar--pOYuF.Calendar-module__focused--97c7q{border-bottom:4px solid rgba(0,53,148,.3)}.Calendar-module__calendarInput--2VHKk{position:relative;z-index:1;box-sizing:border-box;width:100%;height:100%;padding:16px 20px;color:#111820;font-size:16px;font-style:normal;font-weight:400;line-height:24px;letter-spacing:.1px;border-width:0;background-color:transparent}.Calendar-module__calendarInput--2VHKk:focus{outline:0}.Calendar-module__calendarInput--2VHKk:not(:focus){text-overflow:ellipsis}.Calendar-module__calendarInput--2VHKk::placeholder{opacity:1;color:rgba(94,110,135,.8)}.Calendar-module__calendarDropdown--UQN2N{box-sizing:border-box;position:absolute;z-index:100;left:auto;right:0;margin-top:12px;padding:32px 16px;width:auto;background-color:#fff;background-clip:padding-box;border-radius:4px}@media screen and (max-width: 599px){.Calendar-module__calendarDropdown--UQN2N{display:flex;justify-content:center;width:100%}}.Calendar-module__calendarDropdown--UQN2N .Calendar-module__CalendarMonth--2yUNu .Calendar-module__CalendarMonth_caption--1mCjR{color:#111820}.Calendar-module__calendarDropdown--UQN2N .Calendar-module__backdrop--srkQG{position:fixed;inset:0}.Calendar-module__navBtn--3e5E4{width:48px;height:48px;padding:12px}", ""]);

// exports
exports.locals = {
	"calendarDropdown": "Calendar-module__calendarDropdown--UQN2N",
	"calendar": "Calendar-module__calendar--pOYuF",
	"focused": "Calendar-module__focused--97c7q",
	"calendarInput": "Calendar-module__calendarInput--2VHKk",
	"CalendarMonth": "Calendar-module__CalendarMonth--2yUNu",
	"CalendarMonth_caption": "Calendar-module__CalendarMonth_caption--1mCjR",
	"backdrop": "Calendar-module__backdrop--srkQG",
	"navBtn": "Calendar-module__navBtn--3e5E4"
};