/* eslint-disable max-len */
export const profile = {
    tab: {
        personal: 'Профиль',
        companions: 'Попутчики',
        documents: 'Документы',
    },
    add_card: {
        title: 'Добавление карты',
        description1: 'На указанной кредитной карте мы временно заблокируем 1 руб',
        description2: 'для подтверждения активности платежного счета.',
        number: 'Номер банковской карты',
        expiry: 'Срок действия',
        cvc: 'CVV/CVC код',
        holder: 'Имя и фамлия владельца',
        button: 'Добавить',
        go_to_exposure: 'Перейти к подтверждению',
        remove_title: 'Удаление карты',
        remove_text: 'Вы уверены, что хотите удалить карту оплаты?',
        remove_success: 'Карта успешно удалена',
        error_binding_window: 'Ошибка открытия окна оплаты. Проверьте не заблокированы ли у Вас всплывающие окна',
        error_binding_card: 'Ошибка привязки карты. Попробуйте позднее',
    },
    edit: 'Редактировать профиль',
    exit: 'Выйти из профиля',
    download_personal_data: 'Скачать персональные данные',
    status: 'Карта СТАТУС',
    name: {
        placeholder: 'Фамилия имя отчество',
        label: 'Ваше имя',
    },
    birthday: {
        placeholder: 'ДД.ММ.ГГГГ',
        label: 'Дата рождения',
    },
    phone: {
        label: 'Номер телефона',
        disable_login: 'Запретить вход по номеру телефона',
        login_disabled: 'Вход по номеру телефона отключен',
    },
    address: {
        placeholder: 'Введите адрес в свободной форме',
        label: 'Почтовый адрес',
    },
    documents: {
        type: 'Тип документа',
        number: 'Номер документа',
        add: 'Добавить документ',
        add_more: 'Добавить еще документ',
    },
    confirm: {
        accept_rules: {
            title: 'Регистрируясь, я принимаю условия',
            link: 'использования персональных данных',
        },
        email: {
            header_1: 'Пожалуйста, проверьте',
            header_2: 'свою почту',
            text_1: 'В течение минуты вам на почту',
            text_2: 'придет письмо с подтверждением',
        },
        phone: {
            header_1: 'Проверочный код',
            header_2: 'отправлен',
            text_1: 'В течение минуты на номер',
            text_2: 'придет СМС с кодом подтверждения',
        },
        resend: 'Отправить еще раз',
    },
    messages: {
        data_saved: 'Данные сохранены',
        save_error: 'Ошибка изменения данных',
        image_saved: 'Изображение успешно сохранено',
        incorrect_image: 'Формат изображения должен быть jpg и размер не больше 600x600',
        image_save_error: 'Ошибка обновления аватара! Попробуйте позднее',
        profile_delete_success: 'Ваш профиль и все персональные данные были удалены',
        profile_delete_error: 'Ваши данные не были удалены. Повторите попытку позднее',
        can_not_disable_phone: 'Нельзя отключить авторизацию по телефону без подтвержденного E-mail',
    },
    socialRegistrationModal: {
        title: 'Редактирование данных',
        text: 'Убедитесь, что фамилия, имя и дата рождения в профиле соответствуют паспорту или иному документу, используемому при полётах.\n\nЭти данные понадобятся при начислении миль и покупке авиабилетов',
    },
    confirmInitials: 'Пожалуйста проверьте соответствие полей Ф.И.О',
    sessions: {
        header: 'Активные сессии',
        current: 'Текущая',
        old: 'Очень давно',
        tableHeaders: {
            lastVisit: 'Последний вход',
            client: 'Клиент',
            os: 'Операционная система',
            browser: 'Браузер',
        },
        clients: {
            website_client: 'Веб-сайт',
            code: 'Мобильное приложение',
            opening_client: 'Банк Открытие',
        },
        lockDestroyWarning: 'Вы не можете удалить текущую сессию',
        showAll: 'Показать все',
        hideAll: 'Скрыть все',
        removeAll: 'Завершить все',
        messages: {
            successRemoveOne: 'Сессия удалена',
            successRemoveFew: 'Сессии удалены',
        },
        errors: {
            40401: 'Сессия не найдена',
            40045: 'Вы не можете удалить текущую сессию',
            requestError: 'Ошибка запроса сессий',
            removeError: 'Ошибка удаления',
        },
    },
};
