exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Layout-module__root--3DzhQ{background-color:#ebeef3}.Layout-module__title--3iJ8_{margin:0 auto;padding:42px 0 0;width:1088px;font-weight:400;font-size:24px;line-height:32px}.Layout-module__container--2rgxM{display:flex;margin:0 auto;width:1088px}.Layout-module__content--3kx-e{flex:0 1 76.3422818792%;padding-right:16px}.Layout-module__sidebar--1QKT-{flex:0 0 23.6577181208%}", ""]);

// exports
exports.locals = {
	"root": "Layout-module__root--3DzhQ",
	"title": "Layout-module__title--3iJ8_",
	"container": "Layout-module__container--2rgxM",
	"content": "Layout-module__content--3kx-e",
	"sidebar": "Layout-module__sidebar--1QKT-"
};