/* eslint-disable */
import { API_URLS, PRIVATE_CACHE_FLAG } from 'consts';
import { createFSA } from 'utils';
import { formatPrice } from 'utils/formatter';
import { utairHttpManager as request } from 'managers/utair';
import { cacheManager } from 'utils/cache-manager';
import { normalizeTransactions } from 'utils/normalize';
import { getUserData, PROFILE_CACHE_KEYNAME } from 'actions/user';
import {
    getUserStatusCard,
    getUserEmail,
    getUserPhone,
    getUserName,
    getUserLastName,
    getUserMiddleName,
} from 'selectors/user';

export const FETCH_BONUSES_TRANSACTIONS = 'FETCH_BONUSES_TRANSACTIONS';
export const FETCH_AIRPORTS = 'FETCH_AIRPORTS';
export const FETCH_BONUSES_BALANCE = 'FETCH_BONUSES_BALANCE';
export const RESET_BONUSES = 'RESET_BONUSES';

export const BALANCE_CACHE_SETTINGS = {
    name: 'balance',
    flag: PRIVATE_CACHE_FLAG,
    lifeTime: 60000,
};

const TRANSACTIONS_CACHE_SETTINGS = {
    name: 'bonuses_transactions',
    flag: PRIVATE_CACHE_FLAG,
    lifeTime: 60000,
};

const fetchTransactionsFSA = createFSA(FETCH_BONUSES_TRANSACTIONS);
const fetchBalanceFSA = createFSA(FETCH_BONUSES_BALANCE);

export const fetchBonusesTransactions = () => (dispatch) => {
    dispatch({ type: FETCH_BONUSES_TRANSACTIONS });

    return request
        .get(API_URLS.BONUSES.TRANSACTIONS, null, true, TRANSACTIONS_CACHE_SETTINGS)
        .then((res) => dispatch(fetchTransactionsFSA(normalizeTransactions(res?.data?.items || []))))
        .catch((e) => dispatch(fetchTransactionsFSA(new Error(e))));
};

export const createRetroClaim = (params) => () => request.post(API_URLS.BONUSES.CREATE_RETRO_CLAIM, params, true);

const mapDeposit = ({ balance, deposit_id: depositId }) => {
    return {
        balance,
        balanceString: formatPrice(balance, 'RUR', false, false),
        depositId,
    };
};

export const fetchBalance =
    (silent = false) =>
    (dispatch, getState) => {
        if (!silent) {
            dispatch(fetchBalanceFSA());
        }

        return request
            .get(API_URLS.BONUSES.BALANCE, null, true, BALANCE_CACHE_SETTINGS)
            .then((data) => {
                if (getState().bonuses.isUnregistered) {
                    cacheManager.removeItem(TRANSACTIONS_CACHE_SETTINGS.name);
                    dispatch(fetchBonusesTransactions());
                }

                const {
                    household: familyMilesCount,
                    redemption: milesCount,
                    level: cardLevel,
                    levelExpire,
                    rubleNeeded,
                    qualifyingRuble,
                    flights: flightsCountCurrentYear,
                    nextLevel,
                    nextLevelName,
                    levelName,
                    levelColor,
                    levelBenefits,
                    privileges,
                    deposits,
                    finance_avia: financeAvia,
                } = data;

                /**
                 * Это нужно на случай если закешировались данные у пользователя со старыми значениями
                 */
                const preparedDeposits = Array.isArray(deposits) ? deposits.map(mapDeposit) : [];
                const contractBalances = Array.isArray(financeAvia) ? financeAvia : [];

                return dispatch(
                    fetchBalanceFSA({
                        isUnregistered: false,
                        deposits: preparedDeposits,
                        contractBalances,
                        milesCount,
                        familyMilesCount,
                        cardLevel,
                        levelExpire,
                        rubleNeeded,
                        qualifyingRuble,
                        flightsCountCurrentYear,
                        nextLevel,
                        nextLevelName,
                        levelColor,
                        levelName,
                        levelBenefits,
                        privileges,
                    })
                );
            })
            .catch((error) => {
                const { error_code: errorCode } = error.data?.meta || {};

                return dispatch(fetchBalanceFSA(new Error(errorCode)));
            });
    };

export const getLinkId = () => () => request.get(API_URLS.REFERRALS.GET_LINK_ID, null, true);

export const invite = (email, linkId) => () =>
    request.post(
        API_URLS.REFERRALS.INVITE,
        {
            email,
            register_link: window.location.origin + '/sign?linkId=' + linkId,
        },
        true
    );

export const resetBonuses = () => (dispatch) => dispatch({ type: RESET_BONUSES });

export const findUser = (login) => () => request.get(API_URLS.BONUSES.FIND_USER, { login }, true);

export const transferToFund = (amount) => () => request.post(API_URLS.BONUSES.TRANSFER_TO_FUND, { amount }, true);

export const joinCards = (cardNo) => (dispatch, getState) => {
    const state = getState();
    const status = getUserStatusCard(state);
    const email = getUserEmail(state);
    const phone = getUserPhone(state);
    const name = getUserName(state);
    const lastName = getUserLastName(state);
    const middleName = getUserMiddleName(state);
    const data = {
        customfield_10326: lastName,
        customfield_10325: name,
        customfield_10327: middleName,
        customfield_10241: email,
        customfield_10406: phone,
        customfield_10417: status.cardNo,
        customfield_10418: cardNo,
    };

    return request.post(API_URLS.SUPPORT.FORM(10201), data);
};

export const applyPromo = (code) => (dispatch) =>
    request.post(API_URLS.BONUSES.APPLY_PROMO, { code }, true).then((data) => {
        cacheManager.removeItem(BALANCE_CACHE_SETTINGS.name);
        cacheManager.removeItem(TRANSACTIONS_CACHE_SETTINGS.name);
        cacheManager.removeItem(PROFILE_CACHE_KEYNAME);
        dispatch(getUserData(true));
        return data;
    });

// Blocked Users Action Types

export const SUBMIT_REREGISTER = 'utair/bonuses/SUBMIT_REREGISTER';

// Blocked Users Action Creators

export const submitReregister = () => ({ type: SUBMIT_REREGISTER });
