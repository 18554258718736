import React from 'react';
import cn from 'classnames';

import styles from './AdaptiveLayout.module.scss';

function AdaptiveLayout({ wrapperRef, children, className = '' }) {
    return (
        <div ref={wrapperRef} className={cn(styles.root, className)}>
            {children}
        </div>
    );
}

const getComponentByType = (type) =>
    function Component({ wrapperRef, children, className }) {
        return (
            children && (
                <div ref={wrapperRef} className={cn(styles[type], className)}>
                    {children}
                </div>
            )
        );
    };

AdaptiveLayout.Stepper = getComponentByType('stepper');

AdaptiveLayout.Footer = getComponentByType('footer');

AdaptiveLayout.Popups = getComponentByType('popups');

AdaptiveLayout.Main = getComponentByType('main');

export default AdaptiveLayout;
