import { fetcher } from 'checkin/shared/api';
import { toCamelCase } from 'checkin/shared/lib/toCamelCase';

interface Services {
    [key: string]: number | string;
}
interface AsmCartApi {
    rloc: string;
    lastName: string;
    services: Services[];
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmContent: string;
}

export async function createAsmCartApi({
    rloc,
    lastName,
    services,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
}: AsmCartApi) {
    const params = {
        services,
        rloc: rloc.replace(/\/.*/, ''),
        last_name: lastName,
        utm_source: utmSource || undefined,
        utm_medium: utmMedium || undefined,
        utm_campaign: utmCampaign || undefined,
        utm_content: utmContent || undefined,
    };

    const rawResponse = await fetcher(
        'asm/api/v1/services/cart/',
        {
            method: 'POST',
            body: JSON.stringify(params),
        },
        true
    );
    const response = toCamelCase(await rawResponse.json());

    return { ...response, lastModified: rawResponse.headers.get('last-modified') };
}
