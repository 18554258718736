// import metrics from 'managers/metrics';
import { utairHttpManager as request } from 'managers/utair';
import { cacheManager } from 'utils/cache-manager';
import { getRockstatUid } from 'utils/rockstat-metrics';
import { API_URLS } from 'consts';
import { USER_SESSION_CACHE_NAME } from 'actions/auth';

export const getLink = (endpoint, type = 'website') =>
    new Promise((resolve) => {
        const userSession = cacheManager.getItem(USER_SESSION_CACHE_NAME);
        const userToken = userSession !== null ? userSession.accessToken : '';
        // TODO поправить получение uid в getUid https://jira.utair.ru/browse/UTFRNT-1799
        // const uid = metrics.getUid();

        const options = {
            type,
            endpoint,
            userToken,
            uid: getRockstatUid(), // '1' - ticket, '7' - search
        };

        return request
            .put(API_URLS.REDIRECT.LINK, options)
            .then((response) => {
                resolve(response.endpoint);
            })
            .catch(() => {
                // TODO: нужно залогировать ошибку получения эндпоинта
                resolve(endpoint);
            });
    });
