/* eslint-disable max-len */
/* eslint camelcase: [0, {properties: "never"}] */

export const status_business = {
    company_fields: {
        title: 'Организация',
        name: 'Название организации',
        name_placeholder: 'ООО "Ромашка"',
        inn: 'ИНН',
        ogrn: 'ОГРН/ОГРНИП/НЗА',
        kpp: 'КПП',
        address: 'Адрес',
        address_placeholder: 'г. Москва, ул. Пресненская Набережная, д. 1, оф. 123'
    },
    errors: {
        name_is_required: 'Название компании не может быть менее 5-и символов',
        inn_length: 'ИНН не может быть менее 10-и символов',
        inn_incorrect: 'ИНН заполнен некорректно',
        ogrn_length: 'ОГРН/ОГРНИП/НЗА не может быть менее 11-и символов',
        ogrn_incorrect: 'ОГРН/ОГРНИП/НЗА заполнен некорректно',
        kpp_length: 'КПП не может быть менее 9-и символов',
        kpp_incorrect: 'КПП заполнен некорректно',
        address_is_required: 'Адрес обязателен для заполнения',
        create: 'Ошибка создания компании. Попробуйте позднее',
        more_than_one_company: 'Вы не можете состоять более чем в одной компании',
        exist: 'Эта компания уже зарегистрирована',
        save: 'Ошибка обновления компании',
        fetch: 'Ошибка запроса компании',
        join_error: 'Данное приглашение более не действительно',
        join_link_incorrect: 'Проверьте правильность ссылки приглашения',
        employee_not_found: 'Сотрудник не найден. Обновите страницу',
        company_is_blocked: 'Данная компания зблокирована администрацией',
        incomplete_registration: 'Профиль заполнен не полностью',
        not_registered: 'Не зарегистрирован в Utair.ru',
        already_employee: 'Вы уже являетесь сотрудником компании',
        max_admins: 'В компании может быть максимум 3 администратора',
        create_error: 'Ошибка создания компании. Попробуйте позднее'
    },
    page_title: 'Utair Business',
    page_description: 'Программа лояльности для бизнес-партнёров',
    invite_message: 'Для работы с компанией необходимо авторизоваться на сайте',
    join_wait_message: 'Ожидание подтверждения',
    success: {
        title: 'Вы успешно обновили информацию о компании',
        text:
            'Теперь вы будете копить корпоративные мили в размере 5% от суммы покупок\nВы сможете тратить их на билеты и дополнительные услуги авиакомпании Utair',
        button: 'Перейти к покупкам'
    },
    buttons: {
        expel: 'Исключить сотрудника'
    },
    labels: {
        admin: 'Администратор',
        current_admin: 'Вы администратор',
        can_spend_miles: 'Может тратить мили',
        current_can_spend_miles: 'Вы можете тратить мили',
        invited_employee: 'Сотрудник приглашён'
    },
    info: {
        title: 'Получайте мили на счет компании за деловые поездки с Utair',
        description: 'И получайте дополнительные бонусные мили за билеты на корпоративный счет',
        block1: {
            caption: 'Как это работает',
            text1_title: 'для сотрудника',
            text1: 'Сотрудник получает от 3% до 7% стоимости билета на свой личный счет Utair Status',
            text2_title: 'для компании',
            text2:
                '5% от фактической стоимости билета зачисляется на счет компании в Utair Business при покупке билета авторизованным сотрудником на десктопном сайте utair.ru'
        },
        block2: {
            caption: 'Как завести корпоративный аккаунт',
            text1:
                'Укажите название организации, ИНН и ОГРН/ОГРНИП/НЗА — этого достаточно для создания личного кабинета и накопления миль за покупки.',
            text2: 'Отправьте копию ОГРН/ОГРНИП/НЗА на\u00A0',
            text3: ' для проверки авиакомпанией. После этого можно будет тратить накопленные мили'
        },
        block3: {
            caption: 'Как добавить сотрудников',
            text:
                'Администратор аккаунта Utair Business добавляет сотрудников. Чем больше сотрудников — тем быстрее копятся мили.\n\nНажмите «Пригласить сотрудника» и введите его почту.'
        },
        block4: {
            caption: 'Как получить корпоративные мили',
            text:
                'Авторизуйтесь на десктопном сайте utair.ru под своей учетной записью\nВыберите и оплатите билет\nМили будут начислены на счет Utair Business\n'
        },
        block5: {
            caption: 'Когда будут начислены мили',
            text: 'Мили будут начислены на счет компании после совершения фактического полёта. \n'
        },
        block6: {
            caption: 'Как тратить мили',
            item1: 'Авторизуйтесь под корпоративной учетной записью на сайте utair.ru',
            item2: 'Выберите рейсы и введите данные о пассажирах',
            item3: 'Выберите пункт «использовать мили компании» на этапе выбора средств оплаты'
        }
    },

    confirmation: {
        warning: {
            instruction_part_1:
                ' Воспользоваться милями компании можно будет только после предоставления подтверждающих документов (копия ОГРН/ОГРНИП/НЗА) на\u00A0',
            instruction_part_2: 'и их проверки авиакомпанией'
        }
    }
};
