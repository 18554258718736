import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import i18next from 'i18next';
import { getIsIndexPage } from 'utils/paths';
import { ReactComponent as Logotype } from 'icons/logo/logotype.svg';
import { getFeedbackUrl } from 'consts';
import { fetchBalance } from 'actions/bonuses';
import { getIsBonusesBalanceError } from 'selectors/bonuses';
import { getIsAuthUser } from 'selectors/auth';
import { getUserData } from 'selectors/user';
import { /* languages, routePrefixEn, routePrefixRu */ useLocale } from 'locales/index';
import { ReactComponent as MenuIcon } from 'icons/interface/rounded-menu.svg';

import Logo from './Logo';
import Menu from './menu';
import LoginLink from './LoginLink';
import ProfileLink from './ProfileLink';
import styles from './Header.module.scss';

export default function Header({ pathname /* isOnlinePanel */ }) {
    const dispatch = useDispatch();

    const [showMenu, setShowMenu] = useState();

    const media = useSelector((state) => state.media);

    const isAuthUser = useSelector(getIsAuthUser);

    const userData = useSelector(getUserData);

    const isBonusesBalanceError = useSelector(getIsBonusesBalanceError);

    const { language: lng, routePrefix } = useLocale();

    const isMobile = media.phone;

    useEffect(() => {
        if (isAuthUser) {
            dispatch(fetchBalance());
        }
    }, [isAuthUser]);

    const handleMenuClick = (event) => {
        event.preventDefault();
        setShowMenu(true);
    };

    const isIndexRoute = getIsIndexPage(pathname);

    const shouldRedirectToFlutterApp =
        media.phone && (pathname.includes('/order-manage') || pathname.includes('/orders/services/'));

    const showProfileLink = isMobile || lng !== 'en';

    return (
        <header className={classNames('header', { '-text-white': isIndexRoute })}>
            <div className="container">
                <div className="header-body">
                    <Logo indexRoute={shouldRedirectToFlutterApp ? '/m/' : routePrefix} isMediaPhone={isMobile} />

                    <div className="header-links">
                        {/* {!isMobile && !isOnlinePanel && (
                            <a
                                href={lng === languages.ru ? routePrefixEn : routePrefixRu}
                                className="header-links-language"
                                aria-label="Сменить язык"
                            >
                                {lng === languages.ru ? 'EN' : 'RU'}
                            </a>
                        )} */}
                        <a href={getFeedbackUrl()} className="phonablet-hide">
                            {i18next.t('header.menu.feedback')}
                        </a>
                        {isMobile && (
                            <MenuIcon
                                className={classNames(
                                    styles.mainMenuIcon,
                                    isIndexRoute ? '' : styles.mainMenuIconFilled
                                )}
                                onClick={handleMenuClick}
                            />
                        )}
                        {!isMobile && (
                            <a href="#header-menu" onClick={handleMenuClick}>
                                <span className="header-menuLink inlineBlock-middle" aria-hidden="true">
                                    <i />
                                    <i />
                                    <i />
                                </span>
                                <span className="phonablet-hide">{i18next.t('common.menu')}</span>
                            </a>
                        )}
                        {showProfileLink &&
                            (isAuthUser ? (
                                <ProfileLink
                                    isMobile={isMobile}
                                    isIndexRoute={isIndexRoute}
                                    isBonusesBalanceError={isBonusesBalanceError}
                                />
                            ) : (
                                <LoginLink isIndexRoute={isIndexRoute} />
                            ))}
                        <Link to="/" className="header-logoUtair phonablet-hide" title={i18next.t('header.to_home')}>
                            <Logotype width="6.75em" height="2.5em" />
                        </Link>
                    </div>
                </div>

                <Menu
                    baseUrl="https://www.utair.ru"
                    isAuth={isAuthUser}
                    handleClose={() => setShowMenu(false)}
                    indexRoute={routePrefix}
                    isEmployee={userData.IsEmployee}
                    isOpen={showMenu}
                    media={media}
                />
            </div>
        </header>
    );
}
