import React, { useState } from 'react';
import { Input, Button } from '@public-projects/utair-ui-kit';

import RadioSelector from './RadioSelector';
import PhoneInput from './PhoneInput';
import validators from './validators';
import styles from './InputSelector.module.scss';

export default function InputSelector({
    value = '',
    setValue = () => {},
    error = '',
    setError = () => {},
    checkType = 'phone',
    setCheckType = () => {},
    buttonName = '',
}) {
    const [isButtonDisabled, setIsButtonDisable] = useState(true);

    const handleInput = (event) => {
        const inputValue = event.target.value.trim();

        if (checkType === 'cartStatus' && !/^\d+$/.test(Number(inputValue))) {
            return;
        }

        setValue(inputValue);
        const validValue = validators[checkType](inputValue);

        if (validValue === true && inputValue) {
            setIsButtonDisable(false);
        } else {
            setIsButtonDisable(true);
            setError('');
        }
    };

    const handlePhoneInputChange = (inputValue) => {
        if (inputValue === undefined) {
            setValue('');
            setIsButtonDisable(true);

            return;
        }

        setValue(inputValue);
        const validValue = validators[checkType](inputValue);

        if (validValue === true) {
            setIsButtonDisable(false);
        } else {
            setIsButtonDisable(true);
            setError('');
        }
    };

    const handleSelectorChange = (selectorValue) => {
        setIsButtonDisable(true);
        setValue('');
        setError('');
        setCheckType(selectorValue);
    };

    const handleBlur = (event) => {
        const inputValue = event.target.value.replaceAll(' ', '');

        if (!inputValue || inputValue === '+7') {
            setIsButtonDisable(true);
            setError('');

            return;
        }

        const validValue = validators[checkType](inputValue);

        if (validValue === true) {
            setValue(inputValue);
            setError('');
        } else {
            setError(validValue);
        }
    };

    return (
        <>
            <RadioSelector
                items={{
                    phone: 'Телефон',
                    email: 'Email',
                    cartStatus: 'Карта Status',
                }}
                selected={checkType}
                onChange={handleSelectorChange}
            />
            <div className={styles.wrapper}>
                {checkType === 'phone' ? (
                    <PhoneInput
                        value={value}
                        onChange={handlePhoneInputChange}
                        onBlur={handleBlur}
                        message={error}
                        isError={!!error}
                    />
                ) : (
                    <Input
                        isError={!!error}
                        onBlur={handleBlur}
                        onInput={handleInput}
                        message={error}
                        value={value}
                        autoFocus
                        data-testid="InputSelector-input"
                    />
                )}
            </div>
            <Button type="submit" fullWidth size="large" disabled={isButtonDisabled} data-testid="InputSelector-button">
                {buttonName}
            </Button>
        </>
    );
}
