/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-else-return */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { every, map, includes, orderBy, get, head, isEmpty, sortBy } from 'lodash';
import store from 'store';
import { logError } from 'actions/error';
import { PASSENGER_API_KEYS } from 'constants/passengerTypes';
import { DEFAULT_TICKET_CLASS, TICKET_CLASS_TYPES } from 'constants/ticketClassTypes';
import { SERVICE_TYPES_API_KEYS } from 'constants/serviceTypes';
import {
    ADDITIONAL_SERVICE_API_KEYS,
    ADDITIONAL_SERVICE_TYPES,
    MEAL_API_KEYS,
    MEAL_TYPES,
} from 'constants/additionalServiceTypes';
import {
    ONE_WAY,
    TWO_WAYS,
    DIRECT_TO,
    DIRECT_BACK,
    IS_INSURANCE_ENABLED,
    QUARANTINE_START_TIME_STAMP,
    QUARANTINE_END_TIME_STAMP,
} from 'consts';
import { SEAT_CONFIGURATIONS, SEAT_TYPES } from 'utils/seatmap';

const { BAGGAGE, MEAL, INSURANCE, SEAT } = ADDITIONAL_SERVICE_TYPES;

const toTimestamp = (value, isUtc = false) =>
    isUtc ? moment.utc(value, 'DD.MM.YYYY hh:mm:ss').valueOf() : moment(value, 'DD.MM.YYYY hh:mm:ss').valueOf();

export const getHistorySort = createSelector([(state) => state.orders.history.sort], (historySort) => historySort);

export const getFetching = createSelector([(state) => state.orders.isFetching], (isFetching) => isFetching);

export const getActualTotal = createSelector([(state) => state.orders.actual.total], (total) => total);

export const getActualDataCount = createSelector([(state) => state.orders.actual.data.length], (total) => total);

export const getHistoryTotal = createSelector([(state) => state.orders.history.total], (total) => total);

export const getHistoryDataCount = createSelector([(state) => state.orders.history.data.length], (total) => total);

/**
 * get ticket class and included options from order, segments or offers
 * @param {Object} order
 * @param {Array} segments
 * @param {Array} [offers]
 * @return {{ticketClass: Object, options: Array}} ticketClassInfo
 */
const getTicketClassInfo = (order, segments, offers) => {
    let options;
    let ticketClass;
    let ticketClassKey;

    if (every(segments, { class: 'B' })) {
        ticketClass = TICKET_CLASS_TYPES.BUSINESS;
        ticketClassKey = 'BUSINESS';
    }

    if (offers) {
        const segmentsIds = map(segments, (segment) => segment.segment_id);
        offers = offers.filter((offer) => includes(segmentsIds, offer.segment_id));

        let ticketClassOffer;

        map(offers, (offer) => {
            let newTicketClassKey;

            if (offer.marketing_fare_code2) {
                const ticketClassCode = offer.marketing_fare_code2;
                newTicketClassKey = Object.keys(TICKET_CLASS_TYPES).find(
                    (key) => TICKET_CLASS_TYPES[key].code === ticketClassCode
                );
            } else {
                const codePrefRegexp = /^\w+(RT|OW|SA)SUB(\/\S+)?$/;

                if (codePrefRegexp.test(offer.fare_code)) {
                    newTicketClassKey = TICKET_CLASS_TYPES.PREFERENTIAL;
                } else {
                    const codeRegexp = /^\w(\w+)(RT|OW|SA)(\/\S+)?$/;
                    const searched = offer.fare_code && offer.fare_code.match(codeRegexp);

                    if (searched && searched[1]) {
                        const ticketClassCode = searched[1];

                        newTicketClassKey = Object.keys(TICKET_CLASS_TYPES).find(
                            (key) => TICKET_CLASS_TYPES[key].shortCode === ticketClassCode
                        );
                    }
                }
            }

            if (newTicketClassKey) {
                if (
                    !ticketClassKey ||
                    TICKET_CLASS_TYPES[newTicketClassKey].priority > TICKET_CLASS_TYPES[ticketClassKey].priority
                ) {
                    ticketClassKey = newTicketClassKey;
                    ticketClass = TICKET_CLASS_TYPES[newTicketClassKey];
                    ticketClassOffer = offer;
                }
            }
        });

        if (ticketClassOffer && ticketClassOffer.fare_services) {
            const disabledOptionValues = ['&amp;times;', '&times;'];
            const payOptionValue = 'RUB';

            options = [];

            ticketClassOffer.fare_services.forEach((service) => {
                if (!disabledOptionValues.includes(service.value) && SERVICE_TYPES_API_KEYS[service.code]) {
                    let optionValue = service.value;

                    if (typeof optionValue === 'string') {
                        optionValue =
                            optionValue === payOptionValue
                                ? ''
                                : optionValue.replace(/&#215;/gi, '×').replace(/&#10003;/gi, '✓');
                    }

                    let optionName = service.name;

                    if (typeof optionName === 'string') {
                        optionName = optionName.replace(/&times;/gi, '×').replace(/&amp;times;/gi, '×');
                    }

                    options.push({
                        service: SERVICE_TYPES_API_KEYS[service.code],
                        value: optionValue,
                        name: optionName,
                    });
                }
            });
        }
    }

    ticketClass = ticketClass || DEFAULT_TICKET_CLASS;
    options = options || ticketClass.defaultOptions;

    return {
        ticketClass,
        options,
    };
};

/**
 * filter segments by direction and add info about stops
 * @param {Array} segments
 * @param {string} direction - 'TO' or 'BACK'
 * @return {Array} directionSegments
 */
const getSegmentsInfoByDirection = (segments, direction) => {
    const directionSegments = segments.filter((segment) => segment.direction === direction);

    directionSegments.forEach((segment, index) => {
        const nextSegment = directionSegments[index + 1];

        if (nextSegment) {
            segment.stopTime =
                (toTimestamp(nextSegment.departure_utc, true) - toTimestamp(segment.arrival_utc, true)) / 1000;
        }
    });

    return orderBy(directionSegments, 'departure_unixtime', 'asc');
};

/**
 * get info about direction
 * @param {Object} order
 * @param {Array} segments - airsegments list
 * @param {Array} offers - offers list
 * @return {?DirectionInfo}
 */
const getDirectionInfo = (order, segments, offers, direction) => {
    if (direction === DIRECT_BACK && order.owrt !== TWO_WAYS) {
        return null;
    }

    const directionSegments = getSegmentsInfoByDirection(segments, direction);

    if (!directionSegments || directionSegments.length === 0) {
        return null;
    }

    const departureSegment = directionSegments[0];
    const arrivalSegment = directionSegments[directionSegments.length - 1];
    const ticketClassInfo = getTicketClassInfo(order, directionSegments, offers);
    const isBoughtInQuarantineTime =
        order.booking_timestamp > QUARANTINE_START_TIME_STAMP && order.booking_timestamp < QUARANTINE_END_TIME_STAMP;
    const isReturnable = getTicketClassIsReturnable(ticketClassInfo.ticketClass) || isBoughtInQuarantineTime;

    return {
        arrivalCity: arrivalSegment.arrival_city_full_name,
        arrivalAirport: arrivalSegment.arrival_airport_code,
        arrivalDate: arrivalSegment.arrival_date,
        arrivalTime: arrivalSegment.arrival_time,
        departureCity: departureSegment.departure_city_full_name,
        departureAirport: departureSegment.departure_airport_code,
        departureDate: departureSegment.departure_date,
        departureTime: departureSegment.departure_time,
        segments: directionSegments,
        isReturnable,
        ...ticketClassInfo,
    };
};

const RETURNABLE_TICKETS_CLASSES = [
    TICKET_CLASS_TYPES.FLEX,
    TICKET_CLASS_TYPES.BUSINESS,
    TICKET_CLASS_TYPES.COMFORT,
    TICKET_CLASS_TYPES.PREMIUM_NEW,
    TICKET_CLASS_TYPES.BUSINESS_NEW,
];
/**
 * get flag - is ticket can be return
 * @param {object} ticketClass
 * @return {boolean}
 */
const getTicketClassIsReturnable = (ticketClass) => RETURNABLE_TICKETS_CLASSES.includes(ticketClass);

/**
 * get flag - is ticket purchased for bonuses,
 * check by first segment and first ticket
 * @param {Array} segments
 * @param {Array} tickets
 * return {boolean}
 */
const getIsByBonuses = (segments, tickets) =>
    includes(['W', 'E', 'Z'], get(segments, '[0].rbd', null)) && get(tickets, '[0].fare', null) === 0;

/**
 * get flag - is ticket without time of flight,
 * check by first segment, often for open tariff
 * @param {Array} segments
 * return {boolean}
 */
function getIsWithoutTime(segments) {
    const firstSegment = head(segments);

    if (isEmpty(firstSegment)) {
        return true;
    }

    const departureUTC = firstSegment.departure_utc;
    const departureDate = firstSegment.departure_date;
    const departureTime = firstSegment.departure_time;

    if (!every([departureUTC, departureDate, departureTime])) {
        return true;
    }

    if (firstSegment.standby === 'N') {
        return false;
    }

    const departureMoment = moment.utc(`${departureDate} ${departureTime}`, 'DD.MM.YYYY HH:mm:ss');
    const departureUTCMoment = moment.utc(departureUTC, 'DD.MM.YYYY HH:mm:ss');

    const departureUTCDelta = departureUTCMoment.diff(departureMoment, 'hours');

    // Day before departure, 16:00 in local time
    const openTimeMoment = moment(departureUTCMoment);
    openTimeMoment
        .add(departureUTCDelta, 'hours')
        .subtract(1, 'days')
        .hours(16 + departureUTCDelta)
        .minutes(0)
        .seconds(0);

    if (moment.utc().isBefore(openTimeMoment)) {
        return true;
    }

    return false;
}

/**
 * - normalize segments as list and sort segments by departure time
 * - normalize tickets as list
 * - normalize passengers as list
 * - normalize services as list
 * - normalize ticket class info
 * - get info for directions "TO"/"BACK" from segments
 * - get info about passengers types
 * - check owrt and segments "BACK", save owrt as 'OW' if segments "BACK" are missing
 */
export const normalizeOrder = (order) => {
    try {
        const coupons = order.coupons || [];
        const segments = order.segments
            .map((segment) => ({
                ...segment,
                isAccomplished:
                    !!coupons.find(
                        (coupon) =>
                            coupon.seg_num && coupon.seg_num.toString() === segment.segment_id && coupon.status === 'F'
                    ) || toTimestamp(segment.arrival_utc, true) < Date.now(),
                isDeparted: toTimestamp(segment.departure_utc, true) < Date.now(),
            }))
            .sort((curr, next) =>
                toTimestamp(curr.departure_utc, true) > toTimestamp(next.departure_utc, true) ? 1 : -1
            );

        if (order.owrt === TWO_WAYS && !segments.some((segment) => segment.direction === DIRECT_BACK)) {
            order.owrt = ONE_WAY;
        }

        const isWithoutTime = getIsWithoutTime(segments);
        const passengersCount = order.passengers.counts || order.passengers.count;
        const passengers = order.passengers;
        const services = order.services;
        const activeTickets = order.tickets_active || [];
        const returnedTickets = order.tickets_returned || [];
        const offers = order.offers;
        const isWaitingReturn = order.waiting_for_refund;

        const activePassengersIds = activeTickets.map((ticket) => ticket.psgid);
        passengers.forEach((passenger) => {
            passenger.services = {
                [DIRECT_TO]: [],
                [DIRECT_BACK]: [],
            };
            passenger.hasActiveTicket = activePassengersIds.includes(passenger.passenger_id);
        });

        services.forEach((service) => {
            const serviceKey = ADDITIONAL_SERVICE_API_KEYS[service.type2];
            const passenger = passengers.find((item) => item.passenger_id === service.passenger_id);

            if (passenger && serviceKey) {
                if (service.segment_id === 'ALL') {
                    passenger.services[DIRECT_TO].push(serviceKey);
                    passenger.services[DIRECT_BACK].push(serviceKey);
                } else {
                    const segment = segments.find((item) => item.segment_id === service.segment_id);

                    if (segment) {
                        passenger.services[segment.direction].push(serviceKey);
                    }
                }
            }
        });

        const directionInfoTo = getDirectionInfo(order, segments, offers, DIRECT_TO);
        const directionInfoBack = getDirectionInfo(order, segments, offers, DIRECT_BACK);

        const adultsCount = passengers.filter((passenger) => passenger.pass_type === PASSENGER_API_KEYS.ADULT).length;
        const childrenCount = passengers.filter((passenger) => passenger.pass_type === PASSENGER_API_KEYS.CHILD).length;
        const infantsCount = passengers.filter((passenger) => passenger.pass_type === PASSENGER_API_KEYS.INFANT).length;

        const ticketClassInfo = getTicketClassInfo(order, segments, offers);

        const isReturned = returnedTickets.length && !activeTickets.length;
        const byBonuses = getIsByBonuses(segments, activeTickets);

        return {
            ...order,
            ...ticketClassInfo,
            passengersCount,
            segments,
            passengers,
            returnedTickets,
            adultsCount,
            childrenCount,
            infantsCount,
            directionInfoTo,
            directionInfoBack,
            isWithoutTime,
            isWaitingReturn,
            isReturned,
            byBonuses,
        };
    } catch (error) {
        console.error(error);
        store.dispatch(logError(error));

        return null;
    }
};

export const normalizeSelectedServices = (servicesData, guidList = []) => {
    const services = {};
    const { services: rawServices = [] } = servicesData;

    rawServices.forEach((rawService) => {
        const {
            applicability,
            description,
            dimensions,
            guid,
            image_url,
            category,
            name,
            service_type,
            old_price: oldPrice = 0,
            order = 0,
            // eslint-disable-next-line camelcase
            max_count: maxCount = service_type === 'baggage' || service_type === 'pet' ? 1 : 10,
        } = rawService;
        const serviceTypeKey = ADDITIONAL_SERVICE_API_KEYS[service_type];

        if (!serviceTypeKey) {
            return;
        }

        const serviceType = ADDITIONAL_SERVICE_TYPES[serviceTypeKey];
        const serviceKey = serviceType.value;

        const mealTypeKey = MEAL_API_KEYS[category];
        const mealType = MEAL_TYPES[mealTypeKey];

        if (!services[serviceKey]) {
            services[serviceKey] = {
                serviceType,
                price: Infinity,
                items: [],
            };
        }

        applicability.forEach(({ passenger_id: passengerId, segment_id: segmentId, price, ...rest }) => {
            if (serviceType === MEAL && !mealType) {
                return;
            }

            if (services[serviceKey].price > price) {
                if (serviceType !== SEAT) {
                    services[serviceKey].price = price;
                }

                if (serviceType === SEAT && guidList.includes(guid)) {
                    services[serviceKey].price = price;
                }
            }

            services[serviceKey].items.push({
                name,
                category,
                description,
                dimensions,
                guid,
                maxCount,
                image_url,
                serviceType,
                oldPrice,
                passengerId,
                segmentId,
                mealType,
                price,
                order,
                ...rest,
            });
        });

        services[serviceKey].items = sortBy(services[serviceKey].items, (item) => item.order);
    });

    return services;
};

/**
 * modify section of basket for meal and baggage type
 * @param {BasketItem[]} basketSection - existing section values
 * @param {BasketItem} value - new value
 * @param {boolean} [isRemoving]
 * @return {BasketItem[]} - modified basket section
 */
export const updateBasket = (basketSection, value, isRemoving) => {
    let foundService;
    let data;

    if (isRemoving) {
        data = basketSection.filter(
            (item) =>
                item.passengerId !== value.passengerId || item.segmentId !== value.segmentId || item.guid !== value.guid
        );
    } else {
        foundService = basketSection.find(
            (item) =>
                item.passengerId === value.passengerId && item.segmentId === value.segmentId && item.guid === value.guid
        );

        if (foundService) {
            data = basketSection.map((item) => (item === foundService ? value : item));
        } else {
            data = basketSection.concat(value);
        }
    }

    return data;
};

/**
 * find item of basket by {service} and {value}
 * and modify section of basket
 * @param {Basket} basket
 * @param {Object} service
 * @param {BasketItem} value
 * @param {boolean} [isRemoving]
 * @return {Array | boolean} - modified basket section
 */
export const updateBasketSection = (basket, service, value, isRemoving) => {
    const basketSection = basket.services[service.value];

    if (service === INSURANCE) {
        return !isRemoving;
    }

    return updateBasket(basketSection, value, isRemoving);
};

/**
 * calculate cost of basket section
 * @param {BasketItem[]} basket
 * @param {Object} service
 * @param {*[]} passengers
 * @return {number}
 */
export const getBasketSectionCost = (basket, service, passengers) => {
    if (Array.isArray(basket) && basket.length) {
        return basket.reduce((fullPrice, basketItem) => {
            let item;

            if (service.serviceType.value === ADDITIONAL_SERVICE_TYPES.SEAT.value) {
                item = service.items.find(
                    (serviceItem) =>
                        serviceItem.dynamic_key === basketItem.dynamicKey &&
                        serviceItem.segmentId === basketItem.segmentId &&
                        serviceItem.passengerId === basketItem.passengerId &&
                        serviceItem.guid === basketItem.guid
                );
            } else {
                item = service.items.find(
                    (serviceItem) =>
                        serviceItem.guid === basketItem.guid &&
                        serviceItem.segmentId === basketItem.segmentId &&
                        serviceItem.passengerId === basketItem.passengerId
                );
            }

            const itemPrice = item ? item.price : 0;

            return fullPrice + itemPrice * basketItem.count;
        }, 0);

        // for insurance
    } else if (basket === true) {
        return service.price * passengers.length;
    }

    return 0;
};

export const getSelectedOrder = createSelector([(state) => state.orders.selectedOrder.order], (orderData) =>
    orderData ? normalizeOrder(orderData) : null
);

/*
 * https://jira.utair.ru/browse/UTFRNT-334
 * сегменты из getorderservices - нужны для доп. услуг, отличаются от сегментов из order
 */
export const getAlternativeSegments = createSelector([(state) => state.orders.selectedOrder.services], (services) =>
    services
        ? services.segments.map((segment) => ({
              ...segment,
              isDeparted: moment().isAfter(moment.utc(segment.departure_date_utc)),
          }))
        : []
);

const prepareDirectionShortInfo = (segments, direction) => {
    const segmentsByDirection = segments.filter((segment) => segment.direction === direction);

    if (segmentsByDirection.length < 1) {
        return null;
    }

    const departureSegment = segmentsByDirection[0];
    const arrivalSegment = segmentsByDirection[segmentsByDirection.length - 1];

    return {
        departureCity: departureSegment.departure_city_full_name,
        arrivalCity: arrivalSegment.arrival_city_full_name,
        hasDepartedSegments: segmentsByDirection.some((segment) => segment.isDeparted),
    };
};

export const getDirectionsShortInfo = createSelector([getAlternativeSegments], (segments) => ({
    directionInfoTo: prepareDirectionShortInfo(segments, DIRECT_TO),
    directionInfoBack: prepareDirectionShortInfo(segments, DIRECT_BACK),
}));

/*
 * https://jira.utair.ru/browse/UTFRNT-334
 * пассажиры из getorderservices - нужны для доп. услуг, отличаются от пассажиров из order
 */
export const getAlternativePassengers = createSelector([(state) => state.orders.selectedOrder.services], (services) =>
    services ? services.passengers : []
);

export const getPassengersSelectedOrder = createSelector([(state) => state.orders.selectedOrder], (order) => {
    if (isEmpty(order) || !order.services) {
        return [];
    }

    return order.services.passengers;
});

/*
 * https://jira.utair.ru/browse/UTFRNT-1935
 * возраст пассажиров, актуальный на дату вылета, нужен для определения доступных мест (на карте мест)
 */
export const getMapPassengerAgeBySegment = createSelector([(state) => state.orders.selectedOrder], (order) => {
    if (isEmpty(order) || !order.services) {
        return {};
    }

    return order.services.mapPassengerAgeBySegment;
});

export const getRawSeatMap = (state) => state.orders.selectedOrder.seatmap;

export const getSeatMapGuidList = createSelector(getRawSeatMap, (seatMap) => {
    const guidList = [];
    const segments = get(seatMap, 'segments', []);

    !!segments &&
        segments.forEach((segment) => {
            const cabins = get(segment, 'cabins', []);

            return (
                !!cabins &&
                cabins.forEach((cabin) =>
                    cabin.rows.forEach(({ cells }) =>
                        cells.forEach(({ guid }) => {
                            if (guid && !guidList.includes(guid)) {
                                guidList.push(guid);
                            }
                        })
                    )
                )
            );
        });

    return guidList;
});

export const getSelectedOrderServices = createSelector(
    [(state) => state.orders.selectedOrder.services, getSeatMapGuidList],
    (services, guidList) => (services ? normalizeSelectedServices(services, guidList) : {})
);

export const getAllowedPaymentMethods = (state) =>
    get(state, 'orders.selectedOrder.services.meta.allowed_payment_methods', {});

export const getSelectedOrderBasket = createSelector(
    [(state) => state.orders.selectedOrder.basket],
    (basket) => basket || {}
);

/**
 * calculate cost of basket
 */
export const getSelectedOrderBasketCost = createSelector(
    [getSelectedOrderBasket, getSelectedOrderServices, getPassengersSelectedOrder],
    (basket, services, passengers) =>
        Object.keys(basket.services)
            .map((sectionKey) => getBasketSectionCost(basket.services[sectionKey], services[sectionKey], passengers))
            .reduce((fullCost, sectionCost) => fullCost + sectionCost, 0)
);

const mapInsuranceBasket = (hasInsurance) => {
    if (!IS_INSURANCE_ENABLED) {
        return [];
    }

    const insuranceItem = {
        type: ADDITIONAL_SERVICE_TYPES.INSURANCE.value,
    };

    return hasInsurance ? [insuranceItem] : [];
};

const prepareServiceToUrl = (services, type) =>
    services.map(({ passengerId, segmentId, guid, count, freetext, dynamicKey, text }) => {
        const preparedServices = { passengerId, segmentId, guid, count, type, dynamicKey, text };

        if (type === ADDITIONAL_SERVICE_TYPES.SEAT.value) {
            preparedServices.freetext = freetext;
        }

        return preparedServices;
    });

export const getBasketUrlString = createSelector([getSelectedOrderBasket], (basket) => {
    const { BAGGAGE, MEAL, SEAT } = ADDITIONAL_SERVICE_TYPES;
    const { baggage, meal, seat, insurance } = basket.services;

    return `json=${JSON.stringify({
        services: [
            ...prepareServiceToUrl(baggage, BAGGAGE.value),
            ...prepareServiceToUrl(meal, MEAL.value),
            ...prepareServiceToUrl(seat, SEAT.value),
            ...mapInsuranceBasket(insurance),
        ],
    })}`;
});

export const getSessionToken = createSelector(
    [(state) => state.orders.selectedOrder.sessionToken],
    (sessionToken) => sessionToken
);

/**
 * passengers for available services
 * have IDs as {ADT|CHD|INF}{number}, example: 'ADT1', 'CHD2'
 */
export const getPassengers = createSelector([(state) => state.orders.services.availableServices], (servicesData) => {
    if (!servicesData) {
        return [];
    }

    return Array.isArray(servicesData.passengers.passenger)
        ? servicesData.passengers.passenger
        : [servicesData.passengers.passenger];
});

/**
 * Check if there are any children in the selected order
 * @return {boolean}
 */
export const getHasChildren = createSelector([getPassengersSelectedOrder], (passengers) =>
    passengers.some((passenger) => passenger.passenger_id.includes('CHD'))
);

/**
 * Check segment_ids of Open ticket class (syn. tariff, offer), if any
 * @return {Array}
 */
export const getIsTicketClassOpen = createSelector([getSelectedOrder], (order) => {
    const openTicketClass = [];

    if (order && order.offers) {
        order.offers.forEach((offer) => {
            const ticketClass = /SA/;

            if (ticketClass.test(offer.fare_code)) {
                openTicketClass.push(offer.segment_id);
            }
        });
    }

    return openTicketClass;
});

/**
 * prepare basket for server
 * @param {Object} basket
 * @param {Object} order - normalized order
 * @param {Object} availableServices
 */
export const prepareBasketWtiteData = (basket, order, availableServices) => {
    const data = [];

    basket[BAGGAGE.value].forEach((basketItem) => {
        const itemsByDirection = order.segments
            .filter((segment) => segment.direction === basketItem.direction)
            .map((segment) => ({
                id: basketItem.id,
                segment_id: segment.segment_id,
                passenger_id: basketItem.passengerId,
                count: basketItem.count,
            }));

        data.push(...itemsByDirection);
    });

    basket[MEAL.value].forEach((basketItem) => {
        data.push({
            id: basketItem.id,
            segment_id: basketItem.segmentId,
            passenger_id: basketItem.passengerId,
            count: basketItem.count,
        });
    });

    if (basket[INSURANCE.value]) {
        order.passengers.forEach((passenger) => {
            data.push({
                id: availableServices[INSURANCE.value].id,
                segment_id: 'ALL',
                passenger_id: passenger.passenger_id,
                count: 1,
            });
        });
    }

    return data;
};

const formatCellsInRow = (row, index) => ({
    items: [],
    key: index,
    rowNumber: row.number,
    isExtraLegRoom: row.cells.some(
        (cell) => cell.configurations && cell.configurations.includes(SEAT_CONFIGURATIONS.LEGROOM)
    ),
});

const prepareRow = (row, availableSeats, segmentId) => ({
    number: row.row_number,
    cells: row.cells.map((rawCell) => {
        const availability = [];
        const prices = [];

        availableSeats.forEach((service) => {
            if (
                rawCell.dynamic_key === service.dynamic_key &&
                rawCell.guid === service.guid &&
                service.segmentId === segmentId
            ) {
                availability.push({
                    occupied: rawCell.occupied,
                    restrictions: rawCell.restrictions,
                    passengerId: service.passengerId,
                });
                prices.push({
                    oldPrice: (service.old_price && service.old_price / 100) || 0,
                    passengerId: service.passengerId,
                    price: service.price / 100,
                });
            }
        });

        const { seat_category_id, seat_class, seat_letter, seat_number, seat_properties, seat_type, ...rest } = rawCell;

        return {
            ...rest,
            categoryId: seat_category_id,
            class: seat_class,
            letter: seat_letter,
            label: seat_number,
            configurations: seat_properties,
            type: seat_type,
            availability,
            prices,
        };
    }),
});

const formatSeatRows = (rows, availableSeats, segmentId) => {
    const rawRows = rows.map((row) => prepareRow(row, availableSeats, segmentId));
    const maxPossibleRowCount = 2;
    const formattedRows = [];
    const formattedRowsCount = rawRows.reduce((count, row) => {
        const aisleInRowCount = row.cells.filter((cell) => cell.type === SEAT_TYPES.EMPTY_SPACE).length;

        return Math.min(aisleInRowCount + 1, count);
    }, maxPossibleRowCount + 1);

    for (let i = 0; i < formattedRowsCount; i += 1) {
        formattedRows.push({
            key: i,
            cells: rawRows.map(formatCellsInRow),
        });
    }

    rawRows.forEach((row, rowIndex) => {
        let targetFormattedRowNumber = 0;

        [...row.cells].reverse().forEach((item, cellIndex, reversedCells) => {
            const rawCellIndex = row.cells.length - 1 - cellIndex;
            const isEmpty = item.type === SEAT_TYPES.EMPTY_SPACE;
            const isAisle =
                isEmpty &&
                rawRows
                    .filter((rawRow) => rawRow.cells.length === reversedCells.length)
                    .every((rawRow) => rawRow.cells[rawCellIndex].type === SEAT_TYPES.EMPTY_SPACE);

            if (isAisle) {
                targetFormattedRowNumber += 1;
            } else {
                const targetFormattedCell = formattedRows[targetFormattedRowNumber].cells[rowIndex];
                let itemLabel = item.label;
                let itemLetter = item.letter;

                if (isEmpty) {
                    const seatCell = rawRows
                        .filter((rawRow) => rawRow.cells.length === reversedCells.length)
                        .map((rawRow) => rawRow.cells[rawCellIndex])
                        .find((rawCell) => !!rawCell.letter);

                    /** letter нужен, когда для "empty_space" нужно вывести букву, а label для key в render */
                    itemLabel = `${row.number}${seatCell.letter}`;
                    itemLetter = seatCell.letter;
                }

                targetFormattedCell.items.push({
                    ...item,
                    label: itemLabel,
                    letter: itemLetter,
                    catalogItem: {
                        name: item.label,
                    },
                });
            }
        });
    });

    return formattedRows;
};

const formatSeatMap = (rawData, availableSeats) => {
    const formattedMap = {};
    const segments = get(rawData, 'segments', []);
    const copySegments = segments.map((obj) => ({ ...obj }));
    formattedMap.segments = copySegments
        .filter((segment) => !!segment.cabins && get(segment, 'meta.source') === 'sirena')
        .map((segment) => {
            const rawRows = [];

            segment.cabins.forEach((cabin) => rawRows.push(...cabin.rows));
            segment.segment_id = segment.segment_internal_id;

            const result = { schema: null };

            try {
                result.schema = {
                    rows: formatSeatRows(rawRows, availableSeats.items, segment.segment_id),
                };

                result.segmentId = segment.segment_id;
            } catch (error) {
                result.schema = null;
                result.error = error;
            }

            return result;
        });

    return formattedMap;
};

export const getFormattedSeatMap = createSelector([getRawSeatMap, getSelectedOrderServices], (seatMapData, services) =>
    formatSeatMap(seatMapData, services.seat)
);

export const getCategoriesById = createSelector(getRawSeatMap, (seatMapData) => {
    const categories = get(seatMapData, 'legend', []);
    const categoriesById = {};

    if (categories) {
        categories.forEach((category) => {
            categoriesById[category.id] = category;
        });
    }

    return categoriesById;
});
