import Url from 'url';
import { SOCIAL_AUTH } from 'constants/socialAuth';

export const yandex = {
    NETWORK: 'yandex',

    auth(setIsLoading) {
        const queryParams = Url.format({
            query: {
                client_id: process.env.YANDEX_APP_ID,
                redirect_uri: SOCIAL_AUTH.REDIRECT_URL,
                scope: 'login:birthday login:email login:info login:avatar login:default_phone',
                response_type: 'token',
            },
        });
        const winHeight = Math.trunc(window.screen.height * 0.7);
        const newWin = window.open(
            SOCIAL_AUTH.AUTH.YANDEX + queryParams,
            'Yandex ID',
            `width=665, height=${winHeight}}`
        );

        if (setIsLoading) {
            let timer;

            const checkWinClosed = function checkWinClosed() {
                if (newWin.closed) {
                    setIsLoading(false);

                    if (timer) {
                        clearInterval(timer);
                    }
                }
            };

            timer = setInterval(checkWinClosed, 1000);
        }
    },
};
