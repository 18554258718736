import {
    FETCH_ONLINE_PANEL_FAILURE,
    FETCH_ONLINE_PANEL_REQUEST,
    FETCH_ONLINE_PANEL_SUCCESS,
    FETCH_ONLINE_PANEL_CITIES_REQUEST,
    FETCH_ONLINE_PANEL_CITIES_SUCCESS,
    FETCH_ONLINE_PANEL_CITIES_FAILURE,
} from '../actions/online-panel';

const INITIAL_STATE = {
    data: {},
    cities: [],
    isFetching: false,
};

export default function onlinePanelReducer(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case FETCH_ONLINE_PANEL_REQUEST:
            return {
                ...state,
                data: {},
                isFetching: true,
            };
        case FETCH_ONLINE_PANEL_SUCCESS:
            return {
                ...state,
                data: payload,
                isFetching: false,
            };
        case FETCH_ONLINE_PANEL_FAILURE:
            return {
                ...state,
                data: {},
                isFetching: false,
            };
        case FETCH_ONLINE_PANEL_CITIES_REQUEST:
            return {
                ...state,
                cities: [],
            };
        case FETCH_ONLINE_PANEL_CITIES_SUCCESS:
            return {
                ...state,
                cities: payload,
            };
        case FETCH_ONLINE_PANEL_CITIES_FAILURE:
            return {
                ...state,
                cities: [],
            };
        default:
            return state;
    }
}
