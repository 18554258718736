exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Row-module__root--3EjIw{display:flex;background-color:#f6f8fc;border-radius:6px;padding:16px 20px}.Row-module__root--3EjIw:not(:first-child){margin-top:8px}.Row-module__passenger--2QCFM{width:34.5%}.Row-module__date--3Lxjm,.Row-module__duration--kB7ow{width:16%}.Row-module__gate--2McRS{width:9%}.Row-module__seat--QR19c{width:8%}.Row-module__action--1DalY{width:16.5%}", ""]);

// exports
exports.locals = {
	"root": "Row-module__root--3EjIw",
	"passenger": "Row-module__passenger--2QCFM",
	"date": "Row-module__date--3Lxjm",
	"duration": "Row-module__duration--kB7ow",
	"gate": "Row-module__gate--2McRS",
	"seat": "Row-module__seat--QR19c",
	"action": "Row-module__action--1DalY"
};