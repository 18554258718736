import store from 'store';
import metrics from 'managers/metrics';
import { qratorActions } from 'components/common/QratorErrorModal';

export const forbiddenErrorHandler = (error, url) => {
    const sendQratorBlockLog = (responseBody) => {
        if (responseBody.includes('Access to resource was blocked')) {
            const supportIdRegex = /ID:\s[\w-]+./gm;
            const stringWithSupportId = responseBody.match(supportIdRegex)[0];
            const supportId = stringWithSupportId.substring(4, stringWithSupportId.length - 1);

            metrics.pushEvent('error', {
                type: 'qrator',
                url,
                support_id: supportId,
            });
            store.dispatch(qratorActions.setQratorSupportId(supportId));
        }
    };

    if (error.data instanceof Object) {
        if ('text' in error.data) {
            error.data.text().then(sendQratorBlockLog);
        }
    } else {
        sendQratorBlockLog(error.data);
    }
};
