/* eslint-disable */
import _ from 'lodash';

import {FETCH_MEMBERS, SET_CAN_REDEEM} from 'actions/family';
import {RESET_BONUSES} from 'actions/bonuses';
import {createFSAReducer} from 'utils';

const INITIAL_STATE = {
    data: [],
    error: null,
    isFetching: false
};

const members = (state, {type, payload}) => {
    switch (type) {
        case SET_CAN_REDEEM: {
            const ix = _.findIndex(state.data, (member) => member.status.cardNo === payload.cardNo);

            const newData = [
                ..._.slice(state.data, 0, ix),
                {...state.data[ix], ...{can_redeem: !state.data[ix].can_redeem}},
                ..._.slice(state.data, ix + 1)
            ];

            return {...state, ...{data: newData}};
        }
        case RESET_BONUSES:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default createFSAReducer(FETCH_MEMBERS, INITIAL_STATE, members);
