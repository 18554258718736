import { getDayAndMonthAndYear, getHoursAndMinutes } from 'checkin/shared/lib/dates';
import { getFormattedPassportNumber } from 'checkin/shared/lib/docs';

export function mapDataToModel(tickets) {
    if (!(tickets && tickets.length)) return [];

    return tickets.map((ticket) => ({
        ...ticket,
        segments: ticket.segments.map((segment) => ({
            ...segment,
            date: getDayAndMonthAndYear(segment.departureTime),
            flight: `${segment.ak} ${segment.flightNumber}`,
            direction: `${segment.departureCityName} ${segment.departureAirportCode} – ${segment.arrivalCityName} ${segment.arrivalAirportCode}`,
            duration: `${getHoursAndMinutes(segment.departureTime)} — ${getHoursAndMinutes(segment.arrivalTime)}`,
            passengers: segment.passengers.map((passenger) => ({
                ...passenger,
                isChecked: false,
                name: `${passenger.lastName} ${passenger.firstName}`,
                documentNumber: getFormattedPassportNumber(passenger.document.type, passenger.document.number),
            })),
        })),
    }));
}
