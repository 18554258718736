exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Link-module__root--1xLQJ{background:none;border:none;font-size:12px;line-height:16px;padding:0}", ""]);

// exports
exports.locals = {
	"root": "Link-module__root--1xLQJ"
};