import React, { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { get, isEqual } from 'lodash';
import { METRICS_EVENT_NAMES, METRICS_PARAM_VALUES } from 'constants/metrics';
import pushMetricsEvent from 'actions/metrics';
import { getAvailableBenefits, getCanUserBuyStandby } from 'selectors/user';
import { usePrevious } from 'utils/hooks';

import { useLocale } from '../../../locales';
import { cacheManager } from '../../../utils/cache-manager';
import { DATE_MASK, ISO_DATE_MASK } from '../../../consts';
import {
    SEARCH_CACHE_NAME,
    setFromCity,
    setDestCity,
    setCitiesFrom,
    setCitiesTo,
    setCitiesPopular,
    setCitiesError,
    SET_IS_TWO_WAY,
    setPassengers,
    setDefaultCitiesTo,
    swapCities,
    setFromDate,
    setBackDate,
    setAvailableFromDates,
    setAvailableBackDates,
    setTicketType,
    toggleUnaccompaniedMinor,
    setIsTwoWay,
} from './actions';
import reducer, { INITIAL_DATA } from './reducer';
import { BENEFIT_TICKET_TYPES_SET, DEFAULT_TICKET_TYPE } from './const';
import { queryCities, queryNearestCity, queryAvailableDates } from './queries';
import { prepareCity, prepareCitiesList } from './utils';
import SearchWidget from './SearchWidget';

const getSearchCache = (isUserCanBuyStandby) => {
    const cachedSearchData = cacheManager.getItem(SEARCH_CACHE_NAME) || {};

    return isUserCanBuyStandby || !BENEFIT_TICKET_TYPES_SET.has(cachedSearchData?.ticketType) ? cachedSearchData : {};
};

const getPreparedDates = (cache, query, savedFromDate, savedBackDate) => {
    const today = moment.now();

    const fromDate = moment(get(query, 'dates[0]', cache.fromDate), DATE_MASK);
    const backDate = moment(get(query, 'dates[1]', cache.backDate), DATE_MASK);

    return {
        fromDate: fromDate.isValid() && fromDate.isAfter(today) ? fromDate : savedFromDate,
        backDate:
            fromDate.isValid() && fromDate.isAfter(today) && backDate.isValid() && backDate.isAfter(today)
                ? backDate
                : savedBackDate,
    };
};

function SearchForm({ location: { query }, media, isUserCanBuyStandby, availableBenefits }) {
    const { language: lang } = useLocale();
    const prevIsUserCanBuyStandby = usePrevious(isUserCanBuyStandby);
    const prevLang = usePrevious(lang);

    const [state, dispatch] = useReducer(reducer, INITIAL_DATA, (initState) => {
        const {
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_term: utmTerm,
            utm_content: utmContent,
        } = query;
        const cache = getSearchCache(isUserCanBuyStandby);

        const { fromDate, backDate } = getPreparedDates(cache, query, initState.fromDate, initState.backDate);

        return {
            ...initState,
            utmSource,
            utmMedium,
            utmCampaign,
            utmTerm,
            utmContent,
            isCheckedUnaccompaniedMinor: cache?.isCheckedUnaccompaniedMinor,
            ticketType: cache.ticketType || null,
            isTwoWay: !!cache.isTwoWay,
            passengers: cache.passengers || initState.passengers,
            fromDate,
            backDate,
        };
    });

    useEffect(() => {
        if (isUserCanBuyStandby !== prevIsUserCanBuyStandby) {
            const { isCheckedUnaccompaniedMinor, ticketType, isTwoWay, passengers, fromDate, backDate } = state;
            const cache = getSearchCache(isUserCanBuyStandby);

            const {
                isCheckedUnaccompaniedMinor: cashedIsCheckedUnaccompaniedMinor,
                ticketType: cashedTicketType,
                isTwoWay: cashedIsTwoWay,
                passengers: cashedPassengers,
            } = cache;

            const { fromDate: cashedFromDate, backDate: cashedBackDate } = getPreparedDates(
                cache,
                query,
                fromDate,
                backDate
            );

            if (cashedIsCheckedUnaccompaniedMinor !== isCheckedUnaccompaniedMinor) {
                dispatch(toggleUnaccompaniedMinor(cashedIsCheckedUnaccompaniedMinor));
            }

            if (cashedTicketType !== ticketType) {
                dispatch(setTicketType(cashedTicketType || DEFAULT_TICKET_TYPE));
            }

            if (cashedIsTwoWay !== isTwoWay) {
                dispatch(setIsTwoWay(!!cashedIsTwoWay));
            }

            if (!isEqual(passengers, cashedPassengers)) {
                dispatch(setPassengers(cashedPassengers || INITIAL_DATA.passengers));
            }

            if (cashedFromDate !== fromDate) {
                dispatch(setFromDate(cashedFromDate));
            }

            if (cashedBackDate !== backDate) {
                dispatch(setBackDate(cashedBackDate));
            }
        }
    }, [isUserCanBuyStandby, prevIsUserCanBuyStandby, state]);

    useEffect(() => {
        if (lang !== prevLang || isUserCanBuyStandby !== prevIsUserCanBuyStandby) {
            queryNearestCity().then((nearestCity) => {
                const { fromCity, destCity, isTwoWay, fromDate, backDate, passengers } = state;
                const cachedSearchParams = getSearchCache(isUserCanBuyStandby);
                const cachedFromCity = cachedSearchParams ? cachedSearchParams.fromCity : null;
                const cachedDestCity = cachedSearchParams ? cachedSearchParams.destCity : null;
                const secondaryFromCity = cachedFromCity || nearestCity;
                let fillMethodFrom = METRICS_PARAM_VALUES.FILL_METHOD.NO_FILL;
                let fillMethodTo = METRICS_PARAM_VALUES.FILL_METHOD.NO_FILL;

                if (!fromCity && secondaryFromCity) {
                    dispatch(setFromCity(secondaryFromCity));
                    fillMethodFrom =
                        cachedFromCity !== null
                            ? METRICS_PARAM_VALUES.FILL_METHOD.CACHE
                            : METRICS_PARAM_VALUES.FILL_METHOD.GEOLOCATION;
                }

                const widgetFromCity = fromCity || secondaryFromCity;
                const isSameCities = widgetFromCity && cachedDestCity && widgetFromCity.code === cachedDestCity.code;

                if (!destCity && cachedDestCity && !isSameCities) {
                    dispatch(setDestCity(cachedDestCity));
                    fillMethodTo = METRICS_PARAM_VALUES.FILL_METHOD.CACHE;
                }

                const nowFromCity = prepareCity(state.fromCity, lang);
                const nowDestCity = prepareCity(state.destCity, lang);
                pushMetricsEvent(METRICS_EVENT_NAMES.SEARCH_PARAM_PRECHOSEN, {
                    from: get(nowFromCity, 'name', ''),
                    to: get(nowDestCity, 'name', ''),
                    dep_date: moment(fromDate).format(ISO_DATE_MASK),
                    back_date: isTwoWay ? moment(backDate).format(ISO_DATE_MASK) : '',
                    adt_count: passengers.adultsCount,
                    chd_count: passengers.childrenCount,
                    inf_count: passengers.infantsCount,
                    fill_method_from: fillMethodFrom,
                    fill_method_to: fillMethodTo,
                });
            });
        }
    }, [prevIsUserCanBuyStandby, isUserCanBuyStandby, lang, prevLang, state]);

    useEffect(() => {
        queryCities(null, true)
            .then((response) => dispatch(setCitiesPopular(response)))
            .catch(setCitiesError);
    }, []);

    useEffect(() => {
        const { from, to } = query;
        Promise.all([from && queryCities(from), to && queryCities(to)]).then(([citiesFrom, citiesBack]) => {
            if (citiesFrom && citiesFrom.length) {
                dispatch(setFromCity(citiesFrom[0]));
            }

            if (citiesBack && citiesBack.length) {
                dispatch(setDestCity(citiesBack[0]));
            }
        });
    }, [query]);

    useEffect(() => {
        const { fromCity, destCity, isTwoWay } = state;

        if (!!fromCity && !!destCity) {
            queryAvailableDates(fromCity.code, destCity.code)
                .then((response) => dispatch(setAvailableFromDates(response)))
                // eslint-disable-next-line no-console
                .catch(console.error);

            if (isTwoWay) {
                queryAvailableDates(destCity.code, fromCity.code)
                    .then((response) => dispatch(setAvailableBackDates(response)))
                    // eslint-disable-next-line no-console
                    .catch(console.error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.fromCity, state.destCity, state.isTwoWay]);

    useEffect(() => {
        const { fromCity, destCity } = state;

        if (!fromCity) {
            dispatch(setDefaultCitiesTo([]));

            return;
        }

        dispatch(setCitiesFrom([]));

        if (!destCity) {
            queryCities(null, false, fromCity.code)
                .then((response) => dispatch(setDefaultCitiesTo(response)))
                .catch((err) => dispatch(setCitiesError(err)));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.fromCity]);

    useEffect(() => {
        if (state.destCity) {
            dispatch(setCitiesTo([]));
        }
    }, [state.destCity]);

    useEffect(() => {
        const { isTwoWay, fromDate, backDate, availableFromDates, isAvailableFromDatesLoaded } = state;
        const fromDateISO = moment(fromDate).format(ISO_DATE_MASK);
        const backDateISO = moment(backDate).format(ISO_DATE_MASK);

        if (fromDate && !availableFromDates.includes(fromDateISO) && isAvailableFromDatesLoaded) {
            dispatch(setFromDate(null));
            dispatch(setBackDate(null));

            return;
        }

        if (!isTwoWay && backDate && !availableFromDates.includes(backDateISO) && isAvailableFromDatesLoaded) {
            dispatch(setBackDate(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.availableFromDates]);

    return (
        <SearchWidget
            swapCities={() => dispatch(swapCities())}
            fromCity={prepareCity(state.fromCity, lang)}
            setFromCity={(value) => dispatch(setFromCity(value))}
            citiesFrom={prepareCitiesList(state.citiesFrom, lang)}
            fetchCitiesFrom={(value) => {
                queryCities(value, false, null, 'fromCity')
                    .then((response) => dispatch(setCitiesFrom(response)))
                    .catch((err) => dispatch(setCitiesError(err)));
            }}
            destCity={prepareCity(state.destCity, lang)}
            setDestCity={(value) => dispatch(setDestCity(value))}
            citiesTo={prepareCitiesList(state.citiesTo, lang)}
            fetchCitiesTo={(value) => {
                const forCity = value && get(state, 'state.fromCity.code', null);
                queryCities(value, false, forCity, 'toCity')
                    .then((response) => {
                        dispatch(setCitiesTo(response));
                    })
                    .catch((err) => dispatch(setCitiesError(err)));
            }}
            citiesPopular={prepareCitiesList(state.citiesPopular, lang)}
            defaultCitiesTo={prepareCitiesList(state.defaultCitiesTo, lang)}
            citiesError={state.citiesError || false}
            fromDate={state.fromDate}
            setFromDate={(value) => dispatch(setFromDate(value))}
            availableFromDates={state.availableFromDates}
            backDate={state.backDate}
            setBackDate={(value) => dispatch(setBackDate(value))}
            availableBackDates={state.availableBackDates}
            isAvailableFromDatesLoaded={state.isAvailableFromDatesLoaded}
            isAvailableBackDatesLoaded={state.isAvailableBackDatesLoaded}
            isTwoWay={state.isTwoWay}
            toggleIsTwoWay={() =>
                dispatch({
                    type: SET_IS_TWO_WAY,
                    payload: !state.isTwoWay,
                })
            }
            passengers={state.passengers}
            setPassengers={(value) => {
                dispatch(setPassengers(value));
            }}
            utmCampaign={state.utmCampaign}
            utmSource={state.utmSource}
            utmMedium={state.utmMedium}
            utmTerm={state.utmTerm}
            utmContent={state.utmContent}
            lng={lang}
            media={media}
            isUserCanBuyStandby={isUserCanBuyStandby}
            ticketType={state.ticketType}
            setTicketType={(value) => dispatch(setTicketType(value))}
            isCheckedUnaccompaniedMinor={state.isCheckedUnaccompaniedMinor}
            onToggleUnaccompaniedMinor={() => dispatch(toggleUnaccompaniedMinor(!state.isCheckedUnaccompaniedMinor))}
            pushMetricsEvent={pushMetricsEvent}
            availableBenefits={availableBenefits}
        />
    );
}

const mapStateToProps = (state) => ({
    media: state.media,
    isUserCanBuyStandby: getCanUserBuyStandby(state),
    availableBenefits: getAvailableBenefits(state),
});

const mapDispatchToProps = {
    pushMetricsEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchForm));
