import { fetcher } from 'schedule/shared/api';
import type { CompanyRoutesByCountry } from 'schedule/shared/model';

import { mapRoutesByCountry } from '../lib';
import type { CompanyRoutesByCountryResponseDto } from './types';

type CompanyRoutesParams = {
    isDirect?: boolean;
    departureCityCode?: string;
    arrivalCityCode?: string;
};

export async function getCompanyRoutesByCountryApi({
    isDirect = false,
    departureCityCode = '',
    arrivalCityCode = '',
}: CompanyRoutesParams): Promise<CompanyRoutesByCountry | null> {
    if (!departureCityCode) return null;

    const searchParams = new URLSearchParams({
        departureCityCode,
    });

    if (isDirect) searchParams.set('isDirect', String(Number(isDirect)));
    if (arrivalCityCode) searchParams.set('arrivalCityCode', arrivalCityCode);

    const qs = searchParams.toString();

    const response = await fetcher<CompanyRoutesByCountryResponseDto>(
        `/schedule/api/v1/companyRoutes/byCountry?${qs}`,
        {
            method: 'GET',
        }
    );

    return mapRoutesByCountry(response);
}
