/* eslint-disable max-len */
export const booking = {
    fromCity: 'Откуда',
    destCity: 'Куда',
    passengers: 'Пассажиры',
    steps: {
        direction_ow: 'В одном\n направлении',
        direction_rt: 'Туда\n и обратно',
        passengers: 'Пассажиры',
        selected_seats: 'Выбранные\n места',
        services: 'Услуги',
        payment: 'Оплата',
        full_price: 'Общая сумма',
        continue: 'Далее',
    },
    dates: 'Дата перелета',
    best_offer: 'Лучшее предложение',
    no_stop: 'Без остановки',
    on_way: 'в пути',
    local_time: 'Местное время',
    info_cost: 'Стоимость тарифов указана на всех пассажиров',
    info_time: 'Указано местное время отправления/прибытия',
    'flight-info': 'Общая стоимость перевозки за всех пасажиров включает таксы и сборы.',
    direct_flights: 'Прямые рейсы',
    no_direct_flights: 'Полёты с пересадкой',
    unknown_time: '-- ч -- мин',
    to: 'Туда',
    back: 'Обратно',
    passenger_interval: '(1){1 пассажир};(2-4){ {{count}} пассажира};(0,5-inf){ {{count}} пассажиров};',
    adult_interval: '(1){ {{count}} взрослый};(0,2-inf){ {{count}} взрослых};',
    adults: 'Взрослые',
    employees: 'Сотрудник',
    relative: 'Родственник',
    child_interval:
        '(0){без детей до 12 лет};(1){ {{count}} ребенок до 12 лет};(2-4){ {{count}} ребенка до 12 лет};(5-inf){ {{count}} детей до 12 лет}',
    children: 'Дети до 12 лет',
    unaccompaniedMinorCheckbox: 'Ребенок летит один',
    childrenUnaccompaniedMinor: 'Дети от 5 до 11 лет',
    teenagerUnaccompaniedMinor: 'Дети от 12 до 16 лет',
    infant_interval:
        '(0){без детей до 2 лет};(1){ {{count}} ребенок до 2 лет};(2-4){ {{count}} ребенка до 2 лет};(5-inf){ {{count}} детей до 2 лет}',
    infants: 'Дети до 2 лет',
    infants_note: 'Без места',
    offers: {
        miles_bonus: 'вернем милями',
        seats_left: 'Осталось всего',
        chosen_offer: 'Выбранный тариф',
        services_title: 'В тариф входит:',
        change_return: 'Можно изменить или\u00A0вернуть билет',
        no_change_return: 'Нельзя изменить или\u00A0вернуть билет',
        change: 'Можно изменить билет',
        return: 'Можно вернуть билет',
        no_change: 'Нельзя изменить',
        no_return: 'Нельзя вернуть',
        segment_start: 'Взлёт',
        segment_end: 'Посадка',
        plane_type: 'Тип ВС',
        company: 'А/К',
        waiting: 'Ожидание между рейсами',
    },
};
