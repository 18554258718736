/* eslint-disable max-len */
/* eslint camelcase: [0, {properties: "never"}] */

export const check_in = {
    document: 'Номер документа, заказа или билета',
    lastname: 'Фамилия как в билете',
    flight: 'Рейс',
    order_lookup: 'Найти заказ',
    for_checkin_close: 'до закрытия регистрации',
    booking_number: 'Номер бронирования',
    checkin_close: 'Регистрация закрыта',
    get_a_boarding_passes: 'Получить посадочные',
    checkin_disabled_message: 'Регистрация только<br />в аэропорту',
    do_checkin: 'Зарегистрироваться',
    modal_title: 'Пересядьте в евробизнес и летите с комфортом на вашем рейсе',
    move_to_eurobusiness: 'Пересесть в евробизнес',
    continue_registration: 'Продолжить регистрацию',
    meta: {
        title: 'Бесплатная онлайн регистрация на рейс Ютэйр на официальном сайте',
        description:
            'Зарегистрируйтесь на рейс авиакомпании Utair на официальном сайте ' +
            'и выберите лучшие места. Оформите дополнительный багаж дешевле, чем ' +
            'в аэропорту. Сохраните посадочный талон - и не стойте в очередях на регистрацию.',
        keywords:
            'онлайн-регистрация,онлайн регистрация на рейс,посадочный талон,выбор места, ' +
            'комфортное место,место у окна,оформить багаж,utair,ютейр,ютэйр,utr,ак ютейр,' +
            'ютеир,ютэир',
    },
    end_to_end_registration:
        'Сквозная регистрация.\nМаршрут следования, где рейсы разделены пунктирной линией, является сквозным, регистрация производится сразу на все участки маршрута.\nНа таких перелетах вы сможете получить свой багаж по прилету в конечном пункт сквозного маршрута.',
    non_end_to_end_registration:
        'Не сквозная регистрация.\nМаршрут следования, где рейсы разделены жирной линией, является не сквозным, можно пройти регистрацию как на все рейсы сразу, так и по отдельности.\nНа таких перелетах, вам необходимо будет получить свой багаж по прибытию в пункт пересадки, заново пройти процедуру досмотра.',
    check_in: 'Идет регистрация',
    check_in_completed: 'Регистрация завершена',
    transfer: 'Пересадка',
    booking: 'Бронирование',
    go_to_passengers: 'Перейти к пассажирам',
    passengers: 'Выбор рейса и пассажиров',
    seats: 'Места в салоне',
    baggage: 'Багаж',
    meal: 'Еда',
    services: 'Услуги',
    payment: 'Оплата',
    summary: 'Посадочные талоны',
    go_to_boarding: 'К посадочным',
    checkin: 'Продолжить',
    passengers_title: 'Выбор рейсов и пассажиров',
    web_registration_forbidden_pet: 'Регистрация с животными в салоне доступна только в аэропорту',
};
