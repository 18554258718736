export function extractError(error) {
    const { status } = error;

    if (status) {
        const e = error.info?.errors || [];
        const firstError = e[0] || { code: null };
        e.shift();

        return {
            status,
            meta: error.info?.meta,
            ...firstError,
            type: firstError.meta?.errors?.[0]?.type || null,
            otherErrors: e,
        };
    }

    // eslint-disable-next-line no-console
    console.error(error);

    return {
        type: error.name,
        detail: error.stack,
    };
}
