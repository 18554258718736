import React from 'react';
import cn from 'classnames';

import styles from './PassengerInfo.module.scss';

export function PassengerInfo({ className, passenger }) {
    return (
        <div className={cn(styles.root, className)}>
            <span className={styles.name}>{passenger.name}</span>
            <div className={styles.document}>
                Номер документа <div className={styles.dot} /> {passenger.documentNumber}
            </div>
        </div>
    );
}
