/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { UNKNOWN, errors, mobileAppFlag } from './consts';
import type { Brand, Order, QueryParams } from './types';

const initialState: Order = {
    rloc: '',
    flightCities: '',
    flightNumber: '',
    lastName: '',
    ticket: '',
    email: '',
    orderId: '',
    departureTime: 0,
    departureDate: '',
    hasBusinessClass: false,
    segmentBrand: UNKNOWN,

    isLoading: false,
    isError: false,

    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmContent: '',

    paymentUrl: '',
    backToSearchUrl: '',
    backUrl: '',
    failure: false,

    isMainTimeUp: false,
    isExtraTimeUp: false,
    isRegistrationTimeUp: false,
    isAvailableOnlySleepingRow: false,

    isAfterRegistration: false,
    isFail: false,

    showPaymentError: false,
    error: null,
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        initializeData(state, { payload }: PayloadAction<QueryParams>) {
            return { ...state, ...payload };
        },
        setIsMainTimeUp(state, { payload }: PayloadAction<boolean>) {
            state.isMainTimeUp = payload;
        },
        setIsExtraTimeUp(state, { payload }: PayloadAction<boolean>) {
            state.isExtraTimeUp = payload;
        },
        setIsRegistrationTimeUp(state, { payload }: PayloadAction<boolean>) {
            state.isRegistrationTimeUp = payload;
        },
        setIsLoading(state, { payload }: PayloadAction<boolean>) {
            state.isLoading = payload;
        },
        setHasBusinessClass(state, { payload }: PayloadAction<boolean>) {
            state.hasBusinessClass = payload;
        },
        setIsError(state, { payload }: PayloadAction<boolean>) {
            state.isError = payload;
        },
        setPaymentUrl(state, { payload }: PayloadAction<string>) {
            state.paymentUrl = payload;
        },
        setShowPaymentError(state, { payload }: PayloadAction<boolean>) {
            state.showPaymentError = payload;
        },
        setShowProcessError(state) {
            state.error = errors.processError;
        },
        setShowNoFreeSeatsError(state) {
            state.error = errors.noFreeSeatsInBusinessClass;
        },
        setShowUpgradeError(state) {
            state.error = errors.businessClassUpgradeError;
        },
        clearError(state) {
            state.error = null;
        },
        setSegmentBrand(state, { payload }: PayloadAction<Brand>) {
            state.segmentBrand = payload;
        },
    },
});

export const selectors = {
    hasRequiredParams(state: RootState) {
        return (
            state.upgrade.order.rloc !== '' &&
            state.upgrade.order.lastName !== '' &&
            state.upgrade.order.flightNumber !== ''
        );
    },
    isMainTimeUp(state: RootState) {
        return state.upgrade.order.isMainTimeUp;
    },
    isTimeUp(state: RootState) {
        return state.upgrade.order.isExtraTimeUp && selectors.isMainTimeUp(state);
    },
    departureDate(state: RootState) {
        return state.upgrade.order.departureDate || moment.unix(state.upgrade.order.departureTime).format('DD.MM.YYYY');
    },
    departureTime(state: RootState) {
        return state.upgrade.order.departureTime;
    },
    hasBusinessClass(state: RootState) {
        return state.upgrade.order.hasBusinessClass;
    },
    isLoading(state: RootState) {
        return state.upgrade.order.isLoading;
    },
    isAvailableOnlySleepingRow(state: RootState) {
        return state.upgrade.order.isAvailableOnlySleepingRow;
    },
    backUrl(state: RootState) {
        return state.upgrade.order.backUrl;
    },
    isFromMobileApp(state: RootState) {
        return state.upgrade.order.backUrl === mobileAppFlag;
    },
    lastName(state: RootState) {
        return state.upgrade.order.lastName;
    },
    ticket(state: RootState) {
        return state.upgrade.order.ticket;
    },
    flightNumber(state: RootState) {
        return state.upgrade.order.flightNumber;
    },
    isAfterRegistration(state: RootState) {
        return state.upgrade.order.isAfterRegistration;
    },
    isFail(state: RootState) {
        return state.upgrade.order.isFail;
    },
    backToSearchUrl(state: RootState) {
        return state.upgrade.order.backToSearchUrl;
    },
    email(state: RootState) {
        return state.upgrade.order.email;
    },
    isError(state: RootState) {
        return state.upgrade.order.isError;
    },
    showPaymentError(state: RootState) {
        return state.upgrade.order.showPaymentError;
    },
    error(state: RootState) {
        return state.upgrade.order.error;
    },
    isRegistrationTimeUp(state: RootState) {
        return state.upgrade.order.isRegistrationTimeUp;
    },
    flightCities(state: RootState) {
        return state.upgrade.order.flightCities;
    },
    segmentBrand(state: RootState) {
        return state.upgrade.order.segmentBrand;
    },
};

export const { name, reducer, actions } = orderSlice;
