import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { ECOMMERCE_EVENTS, SEGMENTS_DIRECTIONS } from 'constants/ecommerce';
import { ADDITIONAL_SERVICE_TYPES } from 'constants/additionalServiceTypes';
import { getSelectedOrderServices, getCategoriesById, getAlternativeSegments } from 'selectors/orders';
import googleTagsManager from 'utils/google-tags-manager';

export const UPDATE_ECOMMERCE_BASKET = 'UPDATE_ECOMMERCE_BASKET';

export const updateEcommerceBasket = (payload) => ({
    type: UPDATE_ECOMMERCE_BASKET,
    payload,
});

export const getSelectedEcommerceBasket = createSelector(
    (state) => state.orders.selectedOrder.ecommerceBasket,
    (basket) => basket || []
);

const convertPrice = (price) => {
    if (!price) return 0;

    return parseFloat((price / 100).toFixed(2));
};

const getServiceVariant = (serviceItem, segments) => {
    if (serviceItem.segmentId === 'ALL') {
        const firstSegment = segments[0];

        return `${firstSegment.company_operating || ''}${firstSegment.flight || ''}`;
    }

    if (SEGMENTS_DIRECTIONS[serviceItem.segmentId]) {
        return segments
            .filter((seg) => seg.direction === SEGMENTS_DIRECTIONS[serviceItem.segmentId])
            .map((seg) => `${seg.company_operating || ''}${seg.flight || ''}`)
            .join(',');
    }

    const segment = segments.find((el) => el.id === serviceItem.segmentId);

    return `${segment.company_operating || ''}${segment.flight || ''}`;
};

const getServicePrice = (serviceItem, service, serviceType) => {
    if (serviceType === ADDITIONAL_SERVICE_TYPES.SEAT.value) {
        return serviceItem.prices[0].price;
    }

    return convertPrice(service.price);
};

const getServiceName = (serviceItem, service, categories, serviceType) => {
    if (serviceType === ADDITIONAL_SERVICE_TYPES.SEAT.value) {
        return categories[serviceItem.categoryId].name;
    }

    return service.name;
};

const normalizeServices = (state, rawItems, serviceType) => {
    const availableServices = getSelectedOrderServices(state);
    const categories = getCategoriesById(state);
    const segments = getAlternativeSegments(state);
    const serviceDictionary = _.get(availableServices, `${serviceType}.items`, null);

    return rawItems.map((serviceItem) => {
        const service = serviceDictionary.find((x) => x.guid === serviceItem.guid);

        return {
            item_id: serviceItem.guid,
            item_name: getServiceName(serviceItem, service, categories, serviceType),
            price: getServicePrice(serviceItem, service, serviceType),
            item_brand: '',
            item_category: `Service/${serviceType}/${serviceItem.guid}`,
            index: 1,
            quantity: serviceItem.count || 1,
            item_variant: getServiceVariant(serviceItem, segments),
        };
    });
};

const createUniqSeatId = (seat) => seat.guid + seat.segmentId + (seat.freetext || seat.label);

const createUniqId = (bag) => bag.guid + bag.segmentId + bag.passengerId;

const createUniqEcommerceId = (el) => el.item_name + el.price + el.quantity;

const filterServices = (oldBasket, newBasket, serviceType) =>
    newBasket.reduce((acc, newItem) => {
        const item = { ...newItem };
        let res = !oldBasket.some((oldItem) => createUniqId(oldItem) === createUniqId(newItem));

        if (serviceType === ADDITIONAL_SERVICE_TYPES.SEAT.value) {
            res = !oldBasket.some((oldItem) => createUniqSeatId(oldItem) === createUniqSeatId(newItem));
        }

        if (!res) {
            const foundItem = oldBasket.find((oldItem) => createUniqId(oldItem) === createUniqId(newItem));

            if (foundItem && newItem.count > foundItem.count) {
                item.count = newItem.count - foundItem.count;
                res = true;
            }
        }

        if (res) {
            acc.push(item);
        }

        return acc;
    }, []);

const getAddedServiceData = (state, { oldBasket, newBasket, serviceType }) => {
    const addedItems = filterServices(oldBasket, newBasket, serviceType);

    return {
        products: normalizeServices(state, addedItems, serviceType),
    };
};

const getRemovedServiceData = (state, { oldBasket, newBasket, serviceType, isRemoveAll }) => {
    let removedItems = oldBasket;

    if (!isRemoveAll) {
        removedItems = filterServices(newBasket, oldBasket, serviceType);
    }

    return {
        products: normalizeServices(state, removedItems, serviceType),
    };
};

const checkEcommerceBasket = (state, products, toRemove) => {
    const basket = getSelectedEcommerceBasket(state);

    if (toRemove) {
        const updatedBasket = [...basket];

        products.forEach((product) => {
            const idx = updatedBasket.findIndex((el) => createUniqEcommerceId(el) === createUniqEcommerceId(product));

            if (idx > -1) {
                updatedBasket.splice(idx, 1);
            }
        });

        return updatedBasket;
    }

    return [...basket, ...products];
};

export const getPurchaseData = (state) => {
    const { rloc } = state.orders.selectedOrder.order;
    const products = getSelectedEcommerceBasket(state);
    const revenue = products.reduce((acc, { price, quantity }) => acc + price * quantity, 0);

    return {
        actionField: {
            id: rloc,
            goal_id: 296171212, // https://confluence.utair.ru/pages/viewpage.action?pageId=213124507
            revenue,
            coupon: '',
        },
        products,
    };
};

export const getEcommercePurchase = createSelector(
    (state) => state,
    (state) => getPurchaseData(state)
);

export const sendEcommerceEvent = (event, clientData) => (dispatch, getState) => {
    if (!event) {
        return;
    }

    let data = {};
    const state = getState();
    const oldBasket = _.get(clientData, 'oldBasket', null);
    const newBasket = _.get(clientData, 'newBasket', null);
    const serviceType = _.get(clientData, 'serviceType', null);
    const isRemoveAll = _.get(clientData, 'isRemoveAll', false);
    const ecommerceData = _.get(clientData, 'ecommerceData', false);

    const eventObject = {
        ecommerce: {
            currencyCode: 'RUB',
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': event.action,
        'gtm-ee-event-non-interaction': event.nonInteraction,
    };

    switch (event.id) {
        case ECOMMERCE_EVENTS.ADD_SERVICE.id:
            data = getAddedServiceData(state, { oldBasket, newBasket, serviceType });
            eventObject.ecommerce.add = data;

            if (data.products?.length) {
                const updatedBasket = checkEcommerceBasket(state, data.products);
                dispatch(updateEcommerceBasket(updatedBasket));
            }

            break;
        case ECOMMERCE_EVENTS.REMOVE_SERVICE.id:
            data = getRemovedServiceData(state, { oldBasket, newBasket, serviceType, isRemoveAll });
            eventObject.ecommerce.remove = data;

            if (data.products?.length) {
                const updatedBasket = checkEcommerceBasket(state, data.products, true);
                dispatch(updateEcommerceBasket(updatedBasket));
            }

            break;
        case ECOMMERCE_EVENTS.PAYMENT_SUCCESSFUL.id:
            eventObject.event = 'gtm-ee-event_transaction_asm';
            data = ecommerceData;
            eventObject.ecommerce.purchase = data;
            dispatch(updateEcommerceBasket([]));
            break;
        default:
            break;
    }

    if (_.isEmpty(data) || !data?.products?.length) {
        return;
    }

    googleTagsManager.push(eventObject);
};
