/* eslint-disable max-len */
export const about = {
    title: 'О программе Utair Status',
    title_descr: 'Набирайте мили, получайте награду в виде авиабилета, повышения класса обслуживания и других наград.',
    description: {
        program:
            'Программа Status создана специально для поощрения наших постоянных пассажиров. Став её участником, вы получаете возможность набирать мили за полёты и тратить их на наградные билеты, а также на другие награды нашей Программы. Оформить награду за бонусные мили программы Status Вы можете в агентствах.',
        miles:
            'Вы можете набирать дополнительные мили, останавливаясь в гостиницах, пользуясь услугами по прокату автомобилей, расплачиваясь банковской картой за покупки.',
        flights:
            'Совершая перелеты на регулярных рейсах Utair, вы получаете мили на ваш счет участника в зависимости от дальности полёта и класса обслуживания. При накоплении определенной суммы миль вы можете получить награду в виде авиабилета, повышения класса обслуживания и других наград.',
        elite:
            'Каждый полёт с авиакомпанией Utair приближает Вас к элитному уровню – BRONZE, SILVER или GOLD. Особый статус участника элитного уровня программы позволит вам воспользоваться дополнительными привилегиями и услугами программы.'
    },
    link_registration: 'Станьте участником',
    link_difference_levels: 'Чем отличаются уровни?',
    link_rules: 'Полные правила участия в PDF'
};
