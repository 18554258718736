/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Select component
 * @param {object} attributes - Object of select element props. Will be directly added to select element
 * @param {array} options - Array of select option, ex: [{value: option_name, name: option_name}]
 * @param {string} defaultValue - Add option with empty value if parameter is not empty
 * @param {string} className - Set html class to select wrapper
 * @param {string} label - Set select label
 * @param {bool} hiddenLabel - Add `is-hidden` class to the label, if parameter exist and select value is empty
 * @param {string} value - Select value
 * @param {string} error - Error text
 * @param {string} caption - Underline text
 * @param {func} onChange - Change handler function
 */
export const Select = ({
    attributes,
    label,
    error,
    options,
    defaultValue,
    className,
    hiddenLabel,
    caption,
    value,
    onChange,
    disabled
}) => (
    <div className={classNames('inputCover', {'is-error': !!error})}>
        {label && (
            <label className={classNames({'is-hidden': hiddenLabel && !value && !error})} htmlFor={attributes.id || ''}>
                {label} {error && <span className="text-regular">{error}</span>}
            </label>
        )}
        <div className={classNames('select', className, {'is-empty': !value}, {'is-error': error})}>
            <select {...attributes} onChange={onChange} value={value} disabled={disabled} className="select-select">
                {defaultValue && <option value="">{defaultValue}</option>}
                {options.map((item, key) => (
                    <option key={key} value={item.value}>
                        {item.name}
                    </option>
                ))}
            </select>
        </div>
        {caption && <span className="inputCover-caption">{caption}</span>}
    </div>
);

Select.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    attributes: PropTypes.object,
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.array,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    hiddenLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string
};

Select.defaultProps = {
    attributes: {},
    label: '',
    options: [],
    defaultValue: '',
    className: '',
    hiddenLabel: false,
    disabled: false,
    value: ''
};
