export default [
    {
        name: 'Информация',
        url: '/information/',
        selected: false,
        children: [
            {
                name: 'Правила перелета на международных рейсах',
                url: 'https://www.utair.ru/support/12',
                selected: false,
            },
            { name: 'Дополнительные рейсы', url: '/information/extra-flights/', selected: false },
            { name: 'Специальные предложения', url: 'https://media.utair.ru/promo', selected: false },
            { name: 'Билет и документы', url: '/information/tickets-and-documents/', selected: false },
            { name: 'Правила перевозки ', url: '/information/transport-regulations/', selected: false },
            { name: 'Помощь пассажирам с ограничениями жизнедеятельности', url: '/support/7', selected: false },
            { name: 'Табло рейсов', url: '/information/onlinepanel', selected: false },
            { name: 'Расписание', url: '/information/schedule', selected: false },
            { name: 'Тарифы и условия', url: 'https://www.utair.ru/support/1/tarify_i_usloviya', selected: false },
            {
                name: 'Задержка и отмена рейса',
                url: 'https://www.utair.ru/support/1/obmen_ili_vozvrat/chto_delat_esli_avai_kompaniya_izmenila_vremya_vileta_ili_otmenila_reis',
                selected: false,
            },
            { name: 'Контакты', url: '/information/contacts/', selected: false },
            { name: 'Противодействие коррупции', url: '/information/fighting-corruption/', selected: false },
        ],
    },
    {
        name: 'Услуги',
        url: '/service/',
        selected: false,
        children: [
            { name: 'Программа «Ветеран»', url: 'https://www.utair.ru/support/6/programma_veteran', selected: false },
            { name: 'Багаж, еда и другие услуги', url: '/service/services2019/', selected: false },
            {
                name: 'Справки о перелете',
                url: 'https://www.utair.ru/support/6/kak_poluchit_spravki_podtverzhdenie_pereleta_ego_stoimost_i_drugie_podtverzhdayushie_dokumenty',
                selected: false,
            },
            {
                name: 'Трансфер',
                url: 'https://www.utair.ru/support/4/obshie_pravila_perevozki_bagazha/transfer_bagazha',
                selected: false,
            },
        ],
    },
    {
        name: 'Для бизнеса',
        url: '/business/',
        selected: false,
        children: [
            { name: 'Групповые перевозки', url: '/business/gruppovye-perevozki/', selected: false },
            { name: 'Корпоративным клиентам', url: '/business/korporativnym-klientam-utair/', selected: false },
            { name: 'Блок мест на рейсах Utair', url: '/business/blok-mest-na-reysakh-utair/', selected: false },
            { name: 'Чартерные перевозки', url: 'https://charter.utair.ru/', selected: false },
        ],
    },
    {
        name: 'В аэропорту',
        url: '/airport/',
        selected: false,
        children: [
            { name: 'Бизнес-залы', url: 'https://www.utair.ru/support/11/biznes_zaly', selected: false },
            { name: 'Транспортная безопасность', url: '/airport/security/', selected: false },
        ],
    },
    {
        name: 'На борту',
        url: '/on-board/',
        selected: false,
        children: [{ name: 'Классы обслуживания', url: '/on-board/business/', selected: false }],
    },
    {
        name: 'О компании',
        url: '/about/',
        selected: false,
        children: [
            { name: 'Новости', url: '/about/news/', selected: false },
            { name: 'Флот Utair', url: '/about/aircrafts/', selected: false },
            { name: 'Вертолетные услуги', url: 'https://heli.utair.ru/', selected: false },
            { name: 'Корпоративная информация', url: '/about/corporate/', selected: false },
            { name: 'Вакансии', url: '/about/jobs/', selected: false },
            { name: 'Пресс-служба Utair', url: '/about/press-office/', selected: false },
            { name: 'Авиакомпании-партнёры', url: '/about/airlines-partners/', selected: false },
            { name: 'Политики', url: '/about/politics/', selected: false },
            { name: 'Сообщение сотрудника', url: '/about/feedbackstaff/', selected: false },
        ],
    },
    { name: 'Программа лояльности Status', url: 'https://media.utair.ru/status', selected: false },
    { name: 'Куда летает Utair?', url: 'https://media.utair.ru/?from=menu', selected: false },
    { name: 'Мерч Utair', url: 'https://merch.utair.ru/', selected: false },
];
