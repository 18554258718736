import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { USER_CATEGORIES } from 'consts';
import { dateToUTC } from 'utils/formatter';

const getUserState = (state) => state.user;

export const getUserData = createSelector(getUserState, (state) => state.userData);

export const getConfirmInitials = createSelector(getUserState, (state) => state.confirmInitials);

export const getBonusSteps = createSelector(getUserData, (state) => state.bonusSteps);

export const getUserName = createSelector(
    getUserData,
    (userData) => userData.initials.original && userData.initials.original.name
);

export const getUserLastName = createSelector(
    getUserData,
    (userData) => userData.initials.original && userData.initials.original.surname
);

export const getUserMiddleName = createSelector(
    getUserData,
    (userData) => userData.initials.original && userData.initials.original.secondName
);

export const getUserBirthday = createSelector(getUserData, (userData) => userData.birthday);

export const getUserAvatar = createSelector(getUserData, (userData) => userData.avatar);

export const getUserStatusCard = createSelector(getUserData, (userData) => userData.status);

export const getUserCategories = createSelector(getUserData, (userData) => userData.categories);

export const getUserId = createSelector(getUserData, (userData) => userData.status && userData.status.id);

export const getInitState = createSelector(getUserState, (user) => user.isInit);

export const getUserMiles = createSelector(getUserData, (userData) => userData.miles);

export const getBlockedStatus = createSelector(getUserData, (userData) => userData.block);

export const getUserEmail = createSelector(getUserData, (userData) => userData.email);

export const getUserPhone = createSelector(getUserData, (userData) => userData.phone);

export const getAvailableBenefits = createSelector(getUserState, (state) => state.availableBenefits);

/**
 * Получение списка сессий из state
 * @type {Reselect.Selector<any, any>}
 */
export const getUserSessions = createSelector(
    (state) => state.user.sessions.list,
    (list) =>
        list.map((i) => ({
            ...i,
            date: i.date ? moment.utc(i.date) : null,
        }))
);

/**
 * Получение состояния запроса сессий
 * @type {Reselect.Selector<any, any>}
 */
export const getUserSessionsFetchState = createSelector(
    (state) => state.user.sessions.isFetching,
    (isFetching) => isFetching
);

export const getCanUserBuyStandby = createSelector(getUserCategories, (categories) => {
    const standbyCategoriesSet = new Set(USER_CATEGORIES.STANDBY);

    const standbyCategories = categories ? categories.filter(({ type }) => standbyCategoriesSet.has(type)) : [];

    if (!standbyCategories.length) {
        return false;
    }

    const now = moment().utc();

    return _.some(
        standbyCategories,
        ({ start, expire }) => now.isAfter(dateToUTC(start)) && now.isBefore(dateToUTC(expire))
    );
});
