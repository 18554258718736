import React, { useEffect, useMemo } from 'react';
import { browserHistory } from 'react-router';
import { PreloaderFullscreen } from '@public-projects/utair-ui-kit';
import { servicesModel, getServicesError, SERVICES_ERROR_CODES } from 'checkin/entities/services';
import { ErrorModal } from 'checkin/shared/ui/ErrorModal';
import { PATHS } from 'checkin/shared/consts';
import { sendServicePageErrorEvent } from 'checkin/widgets/services/layout/lib';

export function Boundaries() {
    const { isLoading, error } = servicesModel.useQuery();
    const { setError } = servicesModel.useAction();

    const { pathname } = browserHistory.getCurrentLocation() || {};

    const errorText = useMemo(() => (error ? getServicesError(error?.code) : ''), [error]);

    useEffect(() => {
        if (error) {
            sendServicePageErrorEvent(pathname, { error, errorText });
        }
    }, [error, errorText, pathname]);

    const handleCloseModal = () => {
        if (Object.values(SERVICES_ERROR_CODES).includes(error?.code)) {
            browserHistory.push(PATHS.SEARCH);
        } else {
            setError(null);
        }
    };

    return (
        <>
            {isLoading && <PreloaderFullscreen />}
            {!!error && <ErrorModal text={errorText} onClose={handleCloseModal} />}
        </>
    );
}
