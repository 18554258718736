const VALIDATION_ERRORS = {
    REQUIRED: 'required',
    INVALID_RANGE: 'invalid_range',
    INVALID_RANGE_MIN: 'invalid_range_min',
    INVALID_RANGE_MAX: 'invalid_range_max',
    INVALID_FORMAT: 'invalid_format',
    INVALID_FOREIGN_FORMAT: 'invalid_foreign_format',
    WRONG_LANG: 'wrong_lang',
};

export default VALIDATION_ERRORS;
