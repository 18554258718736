function invariant(value: boolean, message?: string): asserts value;
function invariant<T>(value: T | null | undefined, message?: string): asserts value is T;

function invariant(value: unknown, message?: string) {
    if (value === false || value === null || typeof value === 'undefined') {
        throw new Error(message);
    }
}

function warning(cond: unknown, message: string) {
    if (!cond) {
        // eslint-disable-next-line no-console
        if (typeof console !== 'undefined') console.warn(message);

        try {
            throw new Error(message);
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }
}

export function generatePath<Path extends string>(
    originalPath: Path,
    params: Record<string, string | null> = {},
    qsParams: Partial<Record<string, string | null | undefined>> = {}
): string {
    let path: string = originalPath;

    if (path.endsWith('*') && path !== '*' && !path.endsWith('/*')) {
        warning(
            false,
            `Route path "${path}" will be treated as if it were ` +
                `"${path.replace(/\*$/, '/*')}" because the \`*\` character must ` +
                `always follow a \`/\` in the pattern. To get rid of this warning, ` +
                `please change the route path to "${path.replace(/\*$/, '/*')}".`
        );
        path = path.replace(/\*$/, '/*') as Path;
    }

    const prefix = path.startsWith('/') ? '/' : '';

    const stringify = (p: unknown) => {
        if (p == null) return '';

        if (typeof p === 'string') return p;

        return String(p);
    };

    const segments = path
        .split(/\/+/)
        .map((segment, index, array) => {
            const isLastSegment = index === array.length - 1;

            if (isLastSegment && segment === '*') {
                const star = '*';

                return stringify(params[star]);
            }

            const keyMatch = segment.match(/^:([\w-]+)(\??)$/);

            if (keyMatch) {
                const [, key, optional] = keyMatch;
                const param = params[key];
                invariant(optional === '?' || param != null, `Missing ":${key}" param`);

                return stringify(param);
            }

            return segment.replace(/\?$/g, '');
        })
        .filter((segment) => !!segment);

    const formattedPath = prefix + segments.join('/');

    const searchParams = new URLSearchParams();

    Object.entries(qsParams).forEach(([name, val]) => {
        if (val) {
            searchParams.append(name, val);
        }
    });

    return `${formattedPath}${searchParams.toString() ? `/?${searchParams.toString()}` : ''}`;
}
