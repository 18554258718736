/* eslint-disable func-names */
import { takeLatest, select, put, call } from 'redux-saga/effects';
import { ADDITIONAL_SERVICE_TYPES } from 'constants/additionalServiceTypes';
import { PREFILL_BASKET, setBasketSection, setFetching } from 'actions/orders';
import { getSelectedOrderServices } from 'selectors/orders';
import { parseJson } from 'utils/formatter';
import { IS_INSURANCE_ENABLED } from 'consts';

import { getCartWorker } from './payment-order-saga';

const prefillServiceSaga = function* (availableServices, selectedServices, type) {
    const availableItems = availableServices[type] ? availableServices[type].items : [];
    const selectedItems = selectedServices.filter((item) => item.type === type);
    const service = selectedItems.filter((selected) =>
        availableItems.find(
            (available) =>
                selected.guid === available.guid &&
                selected.passengerId === available.passengerId &&
                selected.segmentId === available.segmentId &&
                (selected.dynamicKey ? selected.dynamicKey === available.dynamic_key : true)
        )
    );

    if (service.length > 0) {
        yield put(setBasketSection(type, service));
    }
};

const prefillInsuranceSaga = function* (availableServices, selectedServices) {
    if (!IS_INSURANCE_ENABLED) {
        return;
    }

    const hasInsurance = selectedServices.some((item) => item.type === ADDITIONAL_SERVICE_TYPES.INSURANCE.value);

    if (hasInsurance) {
        yield put(setBasketSection('insurance', true));
    }
};

const prefillBasketSaga = function* ({ payload }) {
    yield put(setFetching(true));
    const json = parseJson(payload.json);

    if (!json || !Array.isArray(json.services)) {
        yield put(setFetching(false));

        return;
    }

    const availableServices = yield select(getSelectedOrderServices);
    const selectedServices = json.services;
    yield call(prefillServiceSaga, availableServices, selectedServices, ADDITIONAL_SERVICE_TYPES.BAGGAGE.value);
    yield call(prefillServiceSaga, availableServices, selectedServices, ADDITIONAL_SERVICE_TYPES.MEAL.value);
    yield call(prefillServiceSaga, availableServices, selectedServices, ADDITIONAL_SERVICE_TYPES.SEAT.value);
    yield call(prefillServiceSaga, availableServices, selectedServices, ADDITIONAL_SERVICE_TYPES.INSURANCE.value);
    yield call(prefillInsuranceSaga, availableServices, selectedServices);

    if (!payload.cartId) {
        yield call(getCartWorker);
    }

    yield put(setFetching(false));
};

const prefillBasketWatcherSaga = function* () {
    yield takeLatest(PREFILL_BASKET, prefillBasketSaga);
};

export default prefillBasketWatcherSaga;
