import i18next from 'i18next';
import Url from 'url';
import fetch, { parseError } from 'utils/fetch';
import { getClientAccessToken, getUserAccessToken } from 'actions/auth';

import { ERROR_TYPES } from '../constants/errorTypes';
import { forbiddenErrorHandler } from './forbidden-error-handler';

function request({ method, url, data, ifUnmodifiedSince, isUserAuth }) {
    const headers = new Headers();
    let body = null;
    let responseHeaders = null;
    const getTokenAction = isUserAuth ? getUserAccessToken : getClientAccessToken;

    return getTokenAction()
        .then((token) => {
            headers.append('Authorization', `Bearer ${token}`);
            headers.append('Accept-Language', i18next.language);

            if (['POST', 'PUT'].includes(method) && data) {
                headers.append('Content-type', 'application/json');
                headers.append('If-Unmodified-Since', ifUnmodifiedSince);
                body = JSON.stringify(data);
            }

            return fetch(url, { headers, method, body });
        })
        .then((response) => {
            responseHeaders = response.headers;

            return response.json();
        })
        .then((result) => ({
            responseHeaders,
            result,
        }))
        .catch(parseError)
        .catch((error) => {
            if (error.code === ERROR_TYPES.FORBIDDEN) {
                forbiddenErrorHandler(error, url);
            }

            throw error;
        });
}

export const additionalPurchaseHttpManager = {
    get(url, params, isUserAuth) {
        const fullUrl = url + Url.format({ query: params });

        return request({ method: 'GET', url: fullUrl, isUserAuth });
    },

    post(url, data, ifUnmodifiedSince, isUserAuth) {
        return request({ method: 'POST', url, data, ifUnmodifiedSince, isUserAuth });
    },

    put(url, data, ifUnmodifiedSince) {
        return request({ method: 'PUT', url, data, ifUnmodifiedSince });
    },
};
