export function toCamelCase(value: object | object[]): object | object {
    if (!(value && typeof value === 'object')) return value;

    let result;

    if (value instanceof Array) {
        result = value.map(toCamelCase);
    } else {
        result = Object.fromEntries(
            Object.entries(value).map(([key, val]) => {
                const newKey = key.replace(/(_\w)/g, (item) => item[1].toUpperCase());

                return [newKey, toCamelCase(val)];
            })
        );
    }

    return result;
}
