exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NotificationMessage-module__root--32t6m{position:relative;display:flex;justify-content:center;color:#fff;line-height:1.5;text-align:center;background-color:#0f56d7}.NotificationMessage-module__info--3zV4e{background-color:#39aeff}.NotificationMessage-module__error--177oS{background-color:#ff5056}.NotificationMessage-module__success--TK-0C{background-color:#20ce8e}.NotificationMessage-module__text--dgpx0{max-width:1296px;padding:24px 48px}.NotificationMessage-module__button--3W3vO{padding:0;color:inherit;font:inherit;background:none;border:none;border-radius:0;outline:inherit;cursor:pointer;position:absolute;top:50%;right:24px;padding:5px;color:#0f56d7;background-color:#ebeef3;border-radius:50%;transform:translateY(-50%)}.NotificationMessage-module__info--3zV4e .NotificationMessage-module__button--3W3vO{color:#39aeff}.NotificationMessage-module__error--177oS .NotificationMessage-module__button--3W3vO{color:#ff5056}.NotificationMessage-module__success--TK-0C .NotificationMessage-module__button--3W3vO{color:#20ce8e}.NotificationMessage-module__icon--B3WrT{display:block;width:14px;height:14px;margin:auto}", ""]);

// exports
exports.locals = {
	"root": "NotificationMessage-module__root--32t6m",
	"info": "NotificationMessage-module__info--3zV4e",
	"error": "NotificationMessage-module__error--177oS",
	"success": "NotificationMessage-module__success--TK-0C",
	"text": "NotificationMessage-module__text--dgpx0",
	"button": "NotificationMessage-module__button--3W3vO",
	"icon": "NotificationMessage-module__icon--B3WrT"
};