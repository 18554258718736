/* eslint-disable */
import { utairHttpManager as manager } from 'managers/utair';
import {API_URLS} from 'consts';
import {call, takeLatest, all} from 'redux-saga/effects';
import {CONFIRM_CREDENTIALS} from 'actions/user';

const confirmRequest = (type) => manager.post(API_URLS.PROFILE.GET_CONFIRM(type), null, true);

const confirmCredentialsSaga = function* ({payload: type}) {
    yield call(confirmRequest, type);
};

const confirmCredentialsWatcherSaga = function* () {
    yield all([takeLatest(CONFIRM_CREDENTIALS, confirmCredentialsSaga)]);
};

export default confirmCredentialsWatcherSaga;
