/* eslint-disable max-len */
// eslint-disable-next-line camelcase
export const check_in = {
    document: 'Document number, booking reference or ticket number',
    lastname: 'Last name',
    flight: 'Flight №',
    order_lookup: 'Find my flight',
    for_checkin_close: 'for checkin close',
    booking_number: 'Booking number',
    checkin_close: 'Check-in closed',
    get_a_boarding_passes: 'Get a boarding passes',
    checkin_disabled_message: 'You can only check in<br/>at the airport',
    do_checkin: 'Check-in',
    modal_title: 'Transfer to Eurobusiness and fly comfortably on your flight',
    move_to_eurobusiness: 'Move to Eurobusiness',
    continue_registration: 'Continue registration',
    meta: {
        title: 'Check in online for flights by Utair',
        description:
            'Зарегистрируйтесь на рейс авиакомпании Utair на официальном сайте ' +
            'и выберите лучшие места. Оформите дополнительный багаж дешевле, чем ' +
            'в аэропорту. Сохраните посадочный талон - и не стойте в очередях на регистрацию.',
        keywords:
            'онлайн-регистрация,онлайн регистрация на рейс,посадочный талон,выбор места, ' +
            'комфортное место,место у окна,оформить багаж,utair,ютейр,ютэйр,utr,ак ютейр,' +
            'ютеир,ютэир',
    },
    web_registration_forbidden_pet: 'Check-in with pets in the cabin is only available at the airport',
};
