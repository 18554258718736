/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import InputElement from 'react-input-mask';
import classNames from 'classnames';

/*
 **
 ** @param {function} onClearClick - обработчик клика по кнопке очистить
 ** @param {function} addStringButton - добавляет текстовую кнопку в поле
 ** @param {function} onAddStringButtonClick - обработчик клика по текстовой кнопке
 **
 */

export const Input = ({
    id,
    label,
    value,
    name,
    className,
    placeholder,
    disabled,
    type,
    Container = 'div',
    maxLength,
    minLength,
    onBlur,
    onChange,
    onKeyPress,
    onFocus,
    onKeyDown,
    error,
    addStringButton = null,
    onAddStringButtonClick,
    mask,
    maskChar = null,
    hiddenLabel,
    caption,
    Ref,
    showClearButton,
    autoComplete = null,
}) => (
    <Container className={classNames(className, { 'is-error': error })}>
        <label htmlFor={id} className={classNames({ 'is-hidden': hiddenLabel && !value && !error })}>
            {label}{' '}
            {error && (
                <span className="text-regular" data-testid="common-input-compnent-error">
                    {error}
                </span>
            )}
        </label>
        <InputElement
            className="input"
            ref={Ref}
            type={type}
            value={value}
            id={id}
            name={name}
            maxLength={maxLength}
            minLength={minLength}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            mask={mask}
            maskChar={maskChar}
        />
        {value && showClearButton && !disabled && (
            <button
                className="clear-button"
                onClick={(event) => {
                    event.preventDefault();
                    onChange({
                        target: {
                            value: '',
                        },
                    });
                }}
            >
                ×
            </button>
        )}
        {addStringButton && (
            <a
                href="#!"
                className="input-link"
                onClick={(e) => {
                    e.preventDefault();
                    onAddStringButtonClick(e);
                }}
            >
                {addStringButton}
            </a>
        )}
        {caption && <span className="inputCover-caption">{caption}</span>}
    </Container>
);

Input.propTypes = {
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    value: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    maskChar: PropTypes.string,
    caption: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onAddStringButtonClick: PropTypes.func,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    disabled: PropTypes.bool,
    showClearButton: PropTypes.bool,
};

Input.defaultProps = {
    id: '',
    label: '',
    value: '',
    name: '',
    className: 'inputCover',
    placeholder: '',
    type: 'text',
    maskChar: '',
    caption: '',
    onBlur: () => {},
    onChange: () => {},
    onKeyPress: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    onAddStringButtonClick: () => {},
    maxLength: null,
    minLength: null,
    disabled: false,
    showClearButton: false,
};
