export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

let counter = 0;

/**
 * Add message to output queue
 * @param {string} text - message text
 * @param {string} type - type of the messgae, ex: 'success', 'error', etc.
 */
export const addMessage = (text, type = '') => (dispatch) => {
    const id = ++counter;

    dispatch({
        type: ADD_MESSAGE,
        message: {
            id,
            type,
            text
        }
    });

    setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        dispatch(closeMessage(id));
    }, 10000);
};

/**
 * Set close state of the message
 * @param {number} id - message id
 */
export const closeMessage = (id) => (dispatch) => {
    dispatch({
        type: CLOSE_MESSAGE,
        id
    });
};

/**
 * Remove message from queue
 * @param {number} id - message id
 */
export const removeMessage = (id) => (dispatch) => {
    dispatch({
        type: REMOVE_MESSAGE,
        id
    });
};
