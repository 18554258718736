import React from 'react';
import { browserHistory } from 'react-router';
import { t } from 'i18next';
import cn from 'classnames';
import { PATHS } from 'checkin/shared/consts';

import styles from './Stepper.module.scss';

const getPositionInList = (stepIndex, index) => {
    if (index === stepIndex) {
        return 'active';
    }

    if (index < stepIndex) {
        return 'before';
    }

    return 'after';
};

export function Stepper({ step, steps, cartId }) {
    const list = Object.values(steps);
    const stepIndex = list.findIndex((item) => item === step);

    const handleSelect = (path) => {
        browserHistory.push(`${path}/${cartId}`);
    };

    return (
        <ul className={cn(styles.root, styles.list)}>
            {list.map((stepName, i) => {
                const positionInList = getPositionInList(stepIndex, i);
                const positionKey = Object.keys(steps).find((key) => steps[key] === stepName);

                return positionInList === 'before' ? (
                    <a
                        role="link"
                        tabIndex="0"
                        onClick={() => handleSelect(PATHS[positionKey])}
                        onKeyPress={() => handleSelect(PATHS[positionKey])}
                        key={`${stepIndex + i}`}
                        className={cn(styles.listItem, styles[positionInList])}
                    >
                        {t(`check_in.${stepName}`)}
                    </a>
                ) : (
                    <li key={`${stepIndex + i}`} className={cn(styles.listItem, styles[positionInList])}>
                        {t(`check_in.${stepName}`)}
                    </li>
                );
            })}
        </ul>
    );
}
