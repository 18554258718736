// страница нужна для ре-авторизации из других приложений(например мобвеба) и перехода на страницу /bonuses
// в queryString ожидается access_token, refresh_token и expires_in

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useBonusesLink } from 'utils/hooks';
import { setSessionData, initAuth, logoutWithoutRedirectToMainPage } from 'actions/auth';

import { Preloader } from '../components/common';

export default function AuthHandler({ location: { query }, router }) {
    const isAuthUser = useSelector((state) => state.auth.isAuthUser);

    const dispatch = useDispatch();

    const bonusesLink = useBonusesLink();

    useEffect(() => {
        if (isAuthUser) {
            dispatch(logoutWithoutRedirectToMainPage());
        }

        setSessionData(query);
        dispatch(initAuth());
        router.replace(bonusesLink);
    }, []);

    return <Preloader />;
}
