export function getDurationFromSeconds(duration: number) {
    if (!duration) {
        return [0, 0];
    }

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);

    return [hours, minutes];
}
