import React from 'react';
import { IndexRoute, Route } from 'react-router';
import DynamicImport from 'components/common/dynamic.import';
import { entranceToOrderServices } from 'actions/orders';

const OrderServices = (props) => <DynamicImport load={() => import('./order-services')} {...props} />;
const CryptogramPayment = () => <DynamicImport load={() => import('./cryptogram-payment')} />;
const PaymentProcessing = () => <DynamicImport load={() => import('./processing')} />;
const PaymentSuccess = () => <DynamicImport load={() => import('./payment-success')} />;
const PaymentFailure = () => <DynamicImport load={() => import('./payment-failure')} />;
const OrderSearch = () => <DynamicImport load={() => import('./order-search')} />;

const onEnterOrderServices = ({ dispatch }, nextState) => {
    const { uuid } = nextState.params;

    dispatch(entranceToOrderServices(uuid, nextState.location.query));
};

export const orderServicesRoutes = (store) => (
    <Route path="orders/services">
        <IndexRoute component={OrderSearch} />
        <Route path=":uuid" component={OrderServices} onEnter={(...args) => onEnterOrderServices(store, ...args)} />
        <Route path=":uuid/payment/:cartId" component={CryptogramPayment} />
        <Route path=":uuid/processing/:cartId" component={PaymentProcessing} />
        <Route path=":uuid/success/:cartId" component={PaymentSuccess} />
        <Route path=":uuid/failure/:cartId" component={PaymentFailure} />
    </Route>
);
