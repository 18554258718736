import React, { useEffect } from 'react';
import { Link } from 'react-router';
import Modal from 'components/common/modal-new';
import { URLS } from 'consts';
import metrics from 'managers/metrics';
import { useBonusesLink } from 'utils/hooks';

const ErrorModal = ({ isOpen }) => {
    const bonusesLink = useBonusesLink();

    useEffect(() => {
        if (isOpen) {
            metrics.pushEvent('error', {
                type: 'modal',
                text: 'Эта страница временно недоступна. Вы можете вернуться к поиску авиабилетов.',
            });
        }
    }, [isOpen]);

    return (
        isOpen && (
            <Modal isOpen hideClose>
                <p className="h4 !mb40">Эта страница временно недоступна. Вы можете вернуться к поиску авиабилетов.</p>

                <a href={URLS.BOOKING_FALLBACK} className="Button is-small inlineBlock mr-20">
                    Найти билет
                </a>
                <Link to={bonusesLink} className="Button is-small inlineBlock mr-20">
                    В личный кабинет
                </Link>
            </Modal>
        )
    );
};

export default ErrorModal;
