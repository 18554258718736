exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Stepper-module__root--ufkdY{margin:0 auto;width:1088px}.Stepper-module__list--33p9C{display:flex}.Stepper-module__listItem--3TYNA{list-style-type:none;font-size:12px;line-height:16px;padding:0 26px 4px}.Stepper-module__listItem--3TYNA:first-child{padding-left:0}.Stepper-module__listItem--3TYNA:last-child{padding-right:0}.Stepper-module__before--3nWgB{color:#111820}.Stepper-module__active--1BFt5,.Stepper-module__before--3nWgB{border-bottom:2px solid #b4c8e7}.Stepper-module__after--3lf8Y{color:#c1c5cc}.Stepper-module__active--1BFt5{color:#003594}", ""]);

// exports
exports.locals = {
	"root": "Stepper-module__root--ufkdY",
	"list": "Stepper-module__list--33p9C",
	"listItem": "Stepper-module__listItem--3TYNA",
	"before": "Stepper-module__before--3nWgB",
	"active": "Stepper-module__active--1BFt5",
	"after": "Stepper-module__after--3lf8Y"
};