/* eslint-disable max-len */
export const common = {
    page_title: 'Купить авиабилеты онлайн в авиакомпании «ЮТэйр» - Напрямую дешевле!',
    unknown_error: 'Извините, возникла неожиданная проблема. Мы уже работаем над этим.',
    more: 'Подробнее',
    details: 'Детали',
    from: 'от',
    tariff: 'Тариф',
    hour_short: 'ч',
    min_short: 'мин',
    kg: 'кг',
    direct_to: 'Туда',
    direct_back: 'Обратно',
    economical: 'эконом',
    birthday: 'Дата рождения',
    additional_services_title: 'Дополнительные услуги',
    additional_pay: 'Доплата',
    back: 'Назад',
    passengers_interval: '(0){ };(1){1 пассажир};(2-4){ {{count}} пассажира};(5-inf){ {{count}} пассажиров};',
    adults_interval: '(0){ };(1){1 взрослый};(2-inf){ {{count}} взрослых};',
    children_interval: '(0){ };(1){1 ребенок};(2-inf){ {{count}} детей};',
    infants_interval: '(0){ };(1){1 ребенок до двух лет};(2-inf){ {{count}} детей до двух лет};',
    flight_interval: '(1){1 полёт};(2-4){ {{count}} полёта};(0,5-inf){ {{count}} полётов};',
    flight_units_interval: '(1){полёт};(2-4){полёта};(0,5-inf){полётов};',
    flights: 'Полётов за\n{{year}} год',
    stop_interval: '(0){прямой рейс};(1){1 пересадка};(2-4){ {{count}} пересадки};(0,5-inf){ {{count}} пересадок};',
    transfer: 'Пересадка',
    transfers: 'Пересадки',
    no_transfers: 'Без пересадок',
    flight: 'Рейс',
    ow: 'В одну сторону',
    rt: 'Туда и обратно',
    for_refund: 'за возврат',
    ruble_interval: '(1){рубль};(2-4){рубля};(0,5-inf){рублей};',
    mile_interval: '(1){миля};(2-4){мили};(0,5-inf){миль};',
    mile_units_interval: '(1){миля};(2-4){мили};(0,5-inf){миль};',
    private_mile_units_interval: '(1){личная миля};(2-4){личные мили};(0,5-inf){личных миль};',
    private_mile: 'Баланс миль',
    family_mile_units_interval: '(1){семейная миля};(2-4){семейные мили};(0,5-inf){семейных миль};',
    family_mile: 'Семейные мили',
    step: 'Шаг {{number}} из {{count}}',
    departure_date: 'Дата вылета',
    flight_number: 'Номер рейса',
    ticket_number: 'Номер билета',
    date_placeholder: 'ДД.ММ.ГГГГ',
    send_request: 'Отправить запрос',
    operation_error: 'Некорректные данные',
    contacts_error: 'Для покупки миль необходимо заполнить профиль',
    operation_success: 'Ваша заявка успешно отправлена',
    menu: 'Меню',
    profile: 'Профиль',
    logout: 'Выход',
    close: 'Закрыть',
    close_menu: 'Закрыть меню',
    search: 'Поиск',
    start_search: 'Начать поиск',
    continue: 'Продолжить',
    something_went_wrong_please_try_again: 'Что-то пошло не так. Пожалуйста, попытайтесь еще раз.',
    actions: {
        save: 'Сохранить',
        add: 'Добавить',
        change: 'Изменить',
        edit: 'Редактировать',
        override: 'Отменить',
        remove: 'Удалить',
        find: 'Найти',
        send: 'Отправить',
        pay: 'Оплатить',
        confirm: 'Подтвердить',
        send_code: 'Получить код',
        sign_up: 'Зарегистрироваться',
        or_sign_up: 'или зарегистрироваться',
        or_sign_in: 'или войти',
        sign_in: 'Войти',
        apply: 'Применить',
        invite: 'Пригласить',
    },
    reload: 'Обновить страницу',
    status: {
        pending: 'В ожидании',
        accepted: 'Принято',
        declined: 'Отклонено',
    },
    documents: {
        ps: 'Паспорт РФ',
        psp: 'Заграничный паспорт РФ',
        za: 'Загранпаспорт любой страны, кроме РФ',
        dp: 'Дипломатический паспорт',
        sp: 'Служебный паспорт',
        np: 'Национальный паспорт',
        pm: 'Паспорт моряка',
        vv: 'Вид на жительство',
        udl: 'Удостоверение личности офицера, прапорщика, мичмана',
        sr: 'Свидетельство о рождении',
        ud: 'Удостоверение депутата',
        vul: 'Временное удостоверение осужденного',
        cvv: 'Свидетельство на возвращение в страну СНГ',
        vb: 'Военный билет',
        spo: 'Справка об освобождении',
        spu: 'Временное удостоверение личности',
    },
    ticket_classes: {
        businessNew: 'Бизнес',
        premiumNew: 'Премиум',
        optimumNew: 'Оптимум',
        minimumNew: 'Минимум',
        business: 'Бизнес',
        comfort: 'Комфорт',
        flex: 'Гибкий',
        standard: 'Стандарт',
        standby: 'Подсадка',
        light: 'Лайт',
        preferential: 'Льготный',
    },
    services: {
        cabin_bag: 'Багаж в салон (55x40x20см)',
        baggage: 'Багаж (в багажный отсек)',
        bag_sport: 'Спортинвентарь',
        miles: 'Начисление миль',
        tkt_changes: 'Обмен билета',
        tkt_return: 'Возврат билета',
        vip_lodge: 'Доступ в бизнес-залы',
        prior_register: 'Приоритетная регистрация',
        bag_inf: 'Багаж младенца (55x40x20см)',
        food: 'Питание на борту',
    },
    services_short: {
        cabin_bag: 'Ручная кладь',
        baggage: 'Багаж',
        bag_sport: 'Спортинвентарь',
        miles: 'Начисление миль',
        tkt_changes: 'Обмен билета',
        tkt_return: 'Возврат билета',
        vip_lodge: 'Бизнес-зал',
        prior_register: 'Приоритетная регистрация',
        bag_inf: 'Багаж младенца',
        food: 'Питание',
    },
    additional_services: {
        baggage: 'Багаж сверх нормы',
        insurance: 'Страховка',
        meal: 'Заказ еды в полет',
        seat: 'Выбор места в салоне',
        business_room: 'Доступ в бизнес-зал',
        sets: 'Место комфорт',
        combo_insurance: 'Комбо-страховка',
    },
    header: {
        created: 'Создано',
        user: 'Пользователь',
        status: 'Состояние',
        initials: 'ФИО',
        balance: 'Баланс (миль)',
        joined: 'Присоединился',
        can_redeem: 'Право на списание миль',
        operations: 'Операции',
    },
    contacts: {
        phone: 'Номер телефона',
        email: 'Электронная почта',
    },
    gender: {
        label: 'Пол',
        f: 'Женский',
        m: 'Мужской',
    },
    field: {
        name: 'Имя',
        surname: 'Фамилия',
        secondName: 'Отчество',
        number: 'Номер документа',
        expireDate: 'Дата истечения',
        country: 'Страна',
    },
    error: {
        field_error: 'ошибка заполнения поля',
        latin_letters: 'может содержать только латинские буквы',
        number_10: 'должен состоять из 10-и цифр',
        number_9: 'должен состоять из 9-и цифр',
        number_3_15: 'должен содержать от 3-х до 15-и цифр',
        number_pm_format: 'укажите серию и номер в формате ББ 1234567',
        number_sr_format: 'должен состоять из римской цифры, двух русских букв и шести цифр. Пример: IVЯЮ123456.',
        passport_expire: 'укажите корректно дату истечения паспорта',
    },
};
