exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OnlyOfflineRegistrationModal-module__modalContentText--24lEb{opacity:50%}", ""]);

// exports
exports.locals = {
	"modalContentText": "OnlyOfflineRegistrationModal-module__modalContentText--24lEb"
};