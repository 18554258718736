import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import Root from 'Root';
import routes from 'routes';
import { initAuth } from 'actions/auth';

// eslint-disable-next-line no-shadow
const onRootEnter = (store) => {
    store.dispatch(initAuth());
};

export const App = () => (
    <Provider store={store}>
        <Root routes={routes} onEnter={onRootEnter(store)} />
    </Provider>
);
