import {t} from 'i18next';

export const getSeatCategoryName = (seat, categoriesById = {}) => {
    const seatCategory = seat.categoryId && categoriesById[seat.categoryId];

    return seatCategory ? seatCategory.name : t('seat_map.seat_standard');
};

export const getSeatCategoryDescription = (seat, categoriesById = {}) => {
    const seatCategory = seat.categoryId && categoriesById[seat.categoryId];

    return seatCategory ? seatCategory.description : t('seat_map.seat_standard_info');
};

export const SEAT_CONFIGURATIONS = {
    LEGROOM: 'legroom',
    COMFORT: 'comfort',
    AISLE: 'aisle',
    WINDOW: 'window',
    STANDARD: 'standard',
    LAST_OFFER: 'last_offer',
    BUSINESS: 'business',
    STATUS: 'status',
    NEAR_BULKHEAD: 'near_the_bulkhead'
};

export const SEAT_TYPES = {
    EMPTY_SPACE: 'empty_space',
    SEAT: 'seat'
};

export const SEAT_RESTRICTION = {
    NOT_FOR_CHILD: 'not_for_child',
    NOT_FOR_INFANT: 'not_for_infant'
};
