/* eslint-disable max-len */
export const booking = {
    fromCity: 'From',
    destCity: 'To',
    passengers: 'Passengers',
    steps: {
        direction_ow: 'One way\n',
        direction_rt: 'Return ticket\n',
        passengers: 'Пассажиры',
        selected_seats: 'Selected seats\n',
        services: 'Services',
        payment: 'payment',
        full_price: 'Order total',
        continue: 'Proceed',
    },
    dates: 'Flight date',
    to: 'To',
    back: 'Back',
    passenger_interval: '(1){1 passenger};(2-4){ {{count}} passengers};(0,5-inf){ {{count}} passengers};',
    adult_interval: '(1){ {{count}} adult};(0,2-inf){ {{count}} adults};',
    adults: 'Adults',
    child_interval: '(0){no children};(1){ {{count}} child};(2-4){ {{count}} children};(5-inf){ {{count}} children}',
    children: 'Children',
    unaccompaniedMinorCheckbox: 'Child will fly alone',
    childrenUnaccompaniedMinor: 'Children from 5 to 11 y.o.',
    teenagerUnaccompaniedMinor: 'Children from 12 to 16 y.o.',
    infant_interval: '(0){infants};(1){ {{count}} infant};(2-4){ {{count}} infants};(5-inf){ {{count}} infants}',
    infants: 'Infants',
    infants_note: 'lap seat',
};
