/* eslint-disable max-len */
export const common = {
    page_title: 'Utair tickets on the official site - They are cheaper!',
    unknown_error: 'Sorry, unexpected error occurred. We are working on it.',
    more: 'More',
    details: 'details',
    from: 'from',
    tariff: 'Tariff',
    hour_short: 'h',
    min_short: 'min',
    kg: 'kg',
    direct_to: 'To',
    direct_back: 'Back',
    price: 'Price',
    economical: 'economy',
    birthday: 'Birth date',
    additional_services_title: 'Services',
    additional_pay: 'Доплата',
    back: 'back',
    passengers_interval: '(0){ };(1){1 passenger};(2-4){ {{count}} passengers};(5-inf){ {{count}} passengers};',
    adults_interval: '(0){ };(1){1 adult};(2-inf){ {{count}} adults};',
    children_interval: '(0){ };(1){1 child};(2-inf){ {{count}} children};',
    infants_interval: '(0){ };(1){1 infant < 2 years old};(2-inf){ {{count}} infant < 2 years old};',
    flight_interval: '(1){1 flight};(2-4){ {{count}} flights};(0,5-inf){ {{count}} flights};',
    flight_units_interval: '(1){flight};(2-4){flights};(0,5-inf){flights};',
    flights: 'Полётов',
    stop_interval:
        '(0){direct flight};(1){1 connection};(2-4){ {{count}} connections};(0,5-inf){ {{count}} connections};',
    transfer: 'Transfer',
    transfers: 'Transfers',
    no_transfers: 'Direct flight',
    flight: 'Flight',
    ow: 'One way',
    rt: 'Return ticket',
    for_refund: 'for refund',
    ruble_interval: '(1){рубль};(2-4){рубля};(0,5-inf){рублей};',
    mile_interval: '(1){миля};(2-4){мили};(0,5-inf){миль};',
    mile_units_interval: '(1){миля};(2-4){мили};(0,5-inf){миль};',
    private_mile_units_interval: '(1){личная миля};(2-4){личные мили};(0,5-inf){личных миль};',
    private_mile: 'Баланс миль',
    family_mile_units_interval: '(1){семейная миля};(2-4){семейные мили};(0,5-inf){семейных миль};',
    family_mile: 'Семейный баланс миль',
    step: 'Шаг {{number}} из {{count}}',
    departure_date: 'Дата вылета',
    flight_number: 'Flight number',
    ticket_number: 'Ticket number',
    date_placeholder: 'DD.MM.YYYY',
    send_request: 'Send',
    operation_error: 'Некорректные данные',
    contacts_error: 'Для покупки миль необходимо заполнить профиль',
    operation_success: 'Ваша заявка успешно отправлена',
    menu: 'Menu',
    profile: 'Profile',
    logout: 'Sign out',
    close: 'Close',
    close_menu: 'Close menu',
    search: 'Search',
    start_search: 'Search',
    continue: 'Continue',
    something_went_wrong_please_try_again: 'Something went wrong. Please try again.',
    actions: {
        save: 'Save',
        add: 'Add',
        change: 'Change',
        edit: 'Edit',
        override: 'Cancel',
        remove: 'Delete',
        find: 'Find',
        send: 'Send',
        pay: 'Pay',
        confirm: 'Confirm',
        send_code: 'Request code',
        sign_up: 'Create an account',
        or_sign_up: 'or create an account',
        or_sign_in: 'or sign in',
        sign_in: 'Sign in',
        apply: 'Apply',
    },
    status: {
        pending: 'Pending',
        accepted: 'Accepted',
        declined: 'Rejected',
    },
    documents: {
        ps: 'Паспорт РФ',
        psp: 'Заграничный паспорт РФ',
        dp: 'Дипломатический паспорт',
        sp: 'Служебный паспорт',
        np: 'Национальный паспорт',
        pm: 'Паспорт моряка',
        vv: 'Вид на жительство в РФ',
        udl: 'Удостоверение личности',
        sr: 'Свидетельство о рождении',
        ud: 'Удостоверение депутата',
        vul: 'Временное удостоверение личности',
        cvv: 'Свидетельство на возвращение в страну СНГ',
        vb: 'Военный билет',
        spu: 'Справка об утере паспорта',
        za: 'Заграничный паспорт иностранного государства',
    },
    ticket_classes: {
        business: 'Бизнес',
        comfort: 'Комфорт',
        flex: 'Гибкий',
        standard: 'Стандарт',
        light: 'Лайт',
        preferential: 'Льготный',
    },
    services: {
        cabin_bag: 'Багаж в салон (55x40x20см)',
        baggage: 'Багаж (в багажный отсек)',
        bag_sport: 'Спортинвентарь',
        miles: 'Начисление миль',
        tkt_changes: 'Обмен билета',
        tkt_return: 'Возврат билета',
        vip_lodge: 'Доступ в бизнес-залы',
        prior_register: 'Приоритетная регистрация',
        bag_inf: 'Багаж младенца (55x40x20см)',
        food: 'Питание на борту',
    },
    services_short: {
        cabin_bag: 'Ручная кладь',
        baggage: 'Багаж',
        bag_sport: 'Спортинвентарь',
        miles: 'Начисление миль',
        tkt_changes: 'Обмен билета',
        tkt_return: 'Возврат билета',
        vip_lodge: 'Бизнес-зал',
        prior_register: 'Приоритетная регистрация',
        bag_inf: 'Багаж младенца',
        food: 'Питание',
    },
    additional_services: {
        baggage: 'Экстра багаж',
        insurance: 'Страховка',
        meal: 'Food&Drink',
        business_room: 'Доступ в бизнес-зал',
        sets: 'Место комфорт',
        combo_insurance: 'Комбо-страховка',
    },
    header: {
        created: 'Создано',
        user: 'Пользователь',
        status: 'Состояние',
        initials: 'ФИО',
        balance: 'Баланс (миль)',
        joined: 'Присоединился',
        can_redeem: 'Право на списание миль',
        operations: 'Операции',
    },
    contacts: {
        phone: 'Phone',
        email: 'Email',
    },
    gender: {
        label: 'Пол',
        f: 'Женский',
        m: 'Мужской',
    },
    field: {
        name: 'Имя',
        surname: 'Фамилия',
        secondName: 'Отчество',
        number: 'Номер документа',
        expireDate: 'Дата истечения',
        country: 'Страна',
    },
    error: {
        field_error: 'ошибка заполнения поля',
        latin_letters: 'может содержать только латинские буквы',
        number_10: 'должен состоять из 10-и цифр',
        number_9: 'должен состоять из 9-и цифр',
        number_3_15: 'должен содержать от 3-х до 15-и цифр',
        number_pm_format: 'укажите серию и номер в формате ББ 1234567',
        number_sr_format: 'должен состоять из римской цифры, двух русских букв и шести цифр. Пример: IVЯЮ123456.',
        passport_expire: 'укажите корректно дату истечения паспорта',
    },
};
