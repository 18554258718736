import { API_BASE_URL } from './apiBaseUrl';
import { createError } from './createError';
import { getHeaders } from './getHeaders';

export async function fetcher<T>(url: string, params?: RequestInit): Promise<T> {
    const headers = await getHeaders(params?.headers || {});

    const apiUrl = new URL(url, API_BASE_URL).toString();

    const response = await fetch(`${apiUrl}`, { ...params, headers });

    if (response.ok) {
        return response.json();
    }

    throw createError(response);
}
