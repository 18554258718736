/* eslint-disable */
import React from 'react';
import {connect} from 'react-redux';
import {removeMessage, closeMessage} from 'actions/sysmessages';

import Message from './message';

class Messages extends React.Component {
    render() {
        const {messages, media} = this.props;

        return (
            <div className="sysmessages">
                {messages.map((item) => (
                    <Message
                        key={item.id}
                        {...item}
                        removeHandler={this.props.remove}
                        swipeEnabled={!media.desktop}
                        onClose={() => {
                            this.props.close(item.id);
                        }}
                    />
                ))}
            </div>
        );
    }
}

export default connect(
    (state) => ({
        messages: state.sysmessages,
        media: state.media
    }),
    (dispatch) => ({
        remove: (id) => dispatch(removeMessage(id)),
        close: (id) => dispatch(closeMessage(id))
    })
)(Messages);
