/* eslint-disable */
import React from 'react';
// import { useSelector } from 'react-redux';
// import i18next from 'i18next';
//
// import badgeAppstoreUrl from 'icons/platforms/badge-appstore.svg';
// import badgeAppstoreEngUrl from 'icons/platforms/badge-appstore-eng.svg';
// import badgeGoogleplayUrl from 'icons/platforms/badge-googleplay.svg';
// import badgeGoogleplayEngUrl from 'icons/platforms/badge-googleplay-eng.svg';

const Footer = () => {
    // const lng = useSelector((state) => state.global.lang);
    // const lngPath = lng === 'ru' ? 'ru/' : '';

    return (
        <footer className="container footerMain">
            <div>
                {/*<a href={`https://itunes.apple.com/${lngPath}app/utejr/id747831899`}>*/}
                {/*    <img*/}
                {/*        src={lng === 'ru' ? badgeAppstoreUrl : badgeAppstoreEngUrl}*/}
                {/*        alt={i18next.t('footer.appstore')}*/}
                {/*        style={{width: '8.25em', height: '2.81em'}}*/}
                {/*    />*/}
                {/*</a>{' '}*/}
                {/*<a href={`https://play.google.com/store/apps/details?id=ru.utair.android&hl=${lng}`}>*/}
                {/*    <img*/}
                {/*        src={lng === 'ru' ? badgeGoogleplayUrl : badgeGoogleplayEngUrl}*/}
                {/*        alt={i18next.t('footer.googleplay')}*/}
                {/*        style={{width: '8.25em', height: '2.81em'}}*/}
                {/*    />*/}
                {/*</a>*/}
            </div>
        </footer>
    );
};

export default Footer;
