import { utairHttpManager as request } from 'managers/utair';
import Url from 'url';

import { API_BASE_URL } from '../../../consts';

// https://b.utair.ru/api/docs/cities/GET_cities/
export function queryCities(q, isPopular, forCity, requestId = '') {
    const params = {
        q: q || undefined,
        popular: !!isPopular || undefined,
        for_city: forCity || undefined,
        limit: 7,
    };

    const queryString = Url.format({ query: params });

    return request
        .get(`${API_BASE_URL}/cities/api/v3/cities${queryString}`, null, false, null, false, requestId)
        .then((citiesResponse) => citiesResponse.data);
}

// https://b.utair.ru/api/docs/cities/GET_cities_nearest/
export function queryNearestCity() {
    return request.get(`${API_BASE_URL}/cities/api/v3/cities/nearest`, null, false)
    .then((cityResponse) => cityResponse.data)
    .catch(() => null);
}

// https://b.utair.ru/api/docs/booking-ext-flights/GET_available_flight_dates/
export function queryAvailableDates(fromCityCode, destCityCode) {
    return request
        .get(
            `${API_BASE_URL}/api/v1/flights/available-flight-dates/?departure=${fromCityCode}&arrival=${destCityCode}`,
            null,
            false
        )
        .then((response) => response.dates || []);
}
