import { SET_COUNTRIES } from 'actions/countries';

const INITIAL_STATE = {
    list: [],
    byCode2: {},
};

const mapCountry = ({ name_cyrillic: nameCyrillic, ...rest }) => ({ ...rest, nameCyrillic });

export default function countriesReducer(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case SET_COUNTRIES:
            return {
                ...state,
                list: payload.map(mapCountry),
                byCode2: payload.reduce(
                    (res, country) => ({
                        ...res,
                        [country.code2]: mapCountry(country),
                    }),
                    {}
                ),
            };
        default:
            return state;
    }
}
