exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SearchForm-module__form--1LR-1{display:flex;justify-content:center;align-items:flex-start;flex-wrap:wrap;gap:8px}.SearchForm-module__block--3GcBo{position:relative}.SearchForm-module__btn--_PbRs{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;text-wrap:nowrap;gap:16px;margin-top:8px}.SearchForm-module__route--1Ktb-{flex:1}.SearchForm-module__datepicker--2lD5S{flex:0 30%}.SearchForm-module__submit--2wpFI{height:64px}@media screen and (max-width: 959px){.SearchForm-module__route--1Ktb-{flex:1 1 100%}.SearchForm-module__datepicker--2lD5S{flex:1 1 70%}}@media screen and (max-width: 599px){.SearchForm-module__form--1LR-1{flex-direction:column;flex-wrap:nowrap}.SearchForm-module__datepicker--2lD5S,.SearchForm-module__route--1Ktb-{width:100%}}", ""]);

// exports
exports.locals = {
	"form": "SearchForm-module__form--1LR-1",
	"block": "SearchForm-module__block--3GcBo",
	"btn": "SearchForm-module__btn--_PbRs",
	"route": "SearchForm-module__route--1Ktb-",
	"datepicker": "SearchForm-module__datepicker--2lD5S",
	"submit": "SearchForm-module__submit--2wpFI"
};