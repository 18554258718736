export const ECOMMERCE_EVENTS = {
    ADD_SERVICE: {
        id: 'addService',
        action: 'Adding a Product to a Shopping Cart',
        nonInteraction: 'False',
    },
    REMOVE_SERVICE: {
        id: 'removeService',
        action: 'Removing a Product from a Shopping Cart',
        nonInteraction: 'False',
    },
    PAYMENT_SUCCESSFUL: {
        id: 'paymentSuccessful',
        action: 'Purchase',
        nonInteraction: 'False',
    },
};

export const SEGMENTS_DIRECTIONS = {
    TO: 'TO',
    BACK: 'BACK',
};

export const ECOMMERCE_CACHE_NAME = 'ecommerceData';

export const ECOMMERCE_CACHE_LIFETIME = 900000;
