import { PLAUSIBLE_PARAM_NAME, getPlausibleUid } from 'checkin/shared/lib/plausible';

import { getToken } from './getToken';

export async function getHeaders(headers) {
    const token = await getToken();
    const plausible = getPlausibleUid();

    return {
        'Content-type': 'application/json',
        Platform: 'desktop',
        Authorization: `bearer ${token}`,
        [PLAUSIBLE_PARAM_NAME]: plausible,
        ...headers,
    };
}
