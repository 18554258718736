// eslint-disable
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import cn from 'classnames';
import { Button } from '@public-projects/utair-ui-kit';
import { ReactComponent as Plane } from 'icons/interface/plane.svg';
import { ReactComponent as MapMarker } from 'icons/interface/map-pin.svg';

import { ReactComponent as Attention } from './attention-icon.svg';
import styles from './OrderItem.module.scss';

export function OrderSegment({
    ak,
    flightNumber,
    arrivalCity,
    departureCity,
    departureDate,
    departureTime,
    timeToCheckinCloseString,
    canDowloadBoardingPass,
    allPassengersChecked,
    isCheckInOpen,
    isDisableCheckin,
    passengerLastName,
    onCheckinClick,
    onBoardingPassClick,
}) {
    return (
        <div className={cn('row', styles.item)}>
            <div className={cn('cell', styles.flight)}>
                <div className={styles.subtitle}>{t('check_in.flight')}</div>
                <div className={styles.value}>
                    {ak}
                    {flightNumber}
                </div>
            </div>
            <div className={cn('cell', styles.direction)}>
                <div className={cn('nowrap', styles.departureCity)}>
                    <Plane className={cn(styles.icon, styles.plane)} />
                    {departureCity}
                    <div className={styles.route} />
                </div>
                <div className={cn('nowrap', styles.departureCity)}>
                    <MapMarker className={cn(styles.icon, styles.map)} />
                    {arrivalCity}
                </div>
            </div>
            <div className={cn('cell', styles.date)}>
                <div className={cn('nowrap', styles.subtitle)}>{departureDate}</div>
                <div className={cn('nowrap', styles.value)}>{departureTime}</div>
            </div>
            <div className={cn('cell', styles.status, { [styles.closed]: !isCheckInOpen })}>
                {isCheckInOpen && timeToCheckinCloseString ? (
                    <>
                        <div className="nowrap">{timeToCheckinCloseString}</div>
                        <div className="nowrap">{t('check_in.for_checkin_close')}</div>
                    </>
                ) : (
                    <div className="nowrap">{t('check_in.checkin_close')}</div>
                )}
            </div>
            <div className={cn('cell', styles.actions)}>
                {canDowloadBoardingPass && (
                    <Button
                        variant="secondary"
                        size="large"
                        fullWidth
                        onClick={() => onBoardingPassClick(flightNumber)}
                    >
                        {t('check_in.get_a_boarding_passes')}
                    </Button>
                )}
                {isCheckInOpen && isDisableCheckin && (
                    <div className={cn(styles.checkInDisadled)}>
                        <Attention className={cn(styles.icon)} />
                        <div dangerouslySetInnerHTML={{ __html: t('check_in.checkin_disabled_message') }} />
                    </div>
                )}
                {isCheckInOpen && !isDisableCheckin && (
                    <Button
                        variant={allPassengersChecked ? 'secondary' : 'primary'}
                        size="large"
                        fullWidth
                        onClick={() => onCheckinClick(flightNumber, passengerLastName)}
                    >
                        {allPassengersChecked ? t('check_in.get_a_boarding_passes') : t('check_in.do_checkin')}
                    </Button>
                )}
            </div>
        </div>
    );
}

OrderSegment.propTypes = {
    ak: PropTypes.string.isRequired,
    flightNumber: PropTypes.string.isRequired,
    arrivalCity: PropTypes.string.isRequired,
    departureCity: PropTypes.string.isRequired,
    departureDate: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    timeToCheckinCloseString: PropTypes.string.isRequired,
    canDowloadBoardingPass: PropTypes.bool.isRequired,
    allPassengersChecked: PropTypes.bool,
    isCheckInOpen: PropTypes.bool.isRequired,
    isDisableCheckin: PropTypes.bool.isRequired,
    passengerLastName: PropTypes.string.isRequired,
    onCheckinClick: PropTypes.func.isRequired,
    onBoardingPassClick: PropTypes.func.isRequired,
};

OrderSegment.defaultProps = {
    allPassengersChecked: false,
};
