import React from 'react';
import { t } from 'i18next';
import { ReactComponent as Success } from 'icons/interface/success.svg';
import { ReactComponent as AttentionWhite } from 'icons/interface/attention-white.svg';

import styles from './PromoBlock.module.scss';

export default function PromoBlock() {
    return (
        <div className={styles.PromoBlock}>
            <div className={styles.Content}>
                <h1 className={styles.Header}>{t('main_page.checkin_promo.header')}</h1>
                <ul className={styles.List}>
                    {t('main_page.checkin_promo.promos', { returnObjects: true }).map((promo) => {
                        const [head, text] = promo.split('&');

                        return (
                            <React.Fragment key={head}>
                                <Success width={24} height={24} />
                                <li className={styles.ListItem}>
                                    <h2 className={styles.ListItemHeader}>{head}</h2>
                                    <p className={styles.ListItemDescription}>{text}</p>
                                </li>
                            </React.Fragment>
                        );
                    })}
                </ul>
                <div className={styles.Divider} />
                <div className={styles.Attention}>
                    <AttentionWhite width={22} height={22} className={styles.AttentionIcon} />
                    <div>
                        <h2 className={styles.AttentionTitle}>{t('main_page.checkin_promo.attention')}</h2>
                        <a
                            href="https://utair.ru/support/5"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.AttentionLink}
                        >
                            {t('main_page.checkin_promo.attentionLink')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
