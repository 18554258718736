import cn from 'classnames';
import React from 'react';

import styles from './Backdrop.module.scss';

interface Props {
    isOpen: boolean;
    disableOverlayClick: boolean;
    onClick: () => void;
}

export function Backdrop({ isOpen = true, disableOverlayClick, onClick = () => {} }: Props) {
    return (
        <div
            className={cn(styles.root, isOpen && styles.isOpen)}
            onClick={disableOverlayClick ? undefined : onClick}
            onKeyPress={disableOverlayClick ? undefined : onClick}
        />
    );
}
