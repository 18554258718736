import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@public-projects/utair-ui-kit';

import { resetQratorSupportId } from './actions';

export const QratorErrorModal = () => {
    const dispatch = useDispatch();
    const qratorSupportId = useSelector((state) => state.qratorError.supportId);

    const handleClose = () => {
        dispatch(resetQratorSupportId());
    };

    return (
        !!qratorSupportId && (
            <Modal isOpen={!!qratorSupportId} onClose={handleClose}>
                <Modal.Title>Ваш запрос заблокирован.</Modal.Title>

                <Modal.Content>
                    <Modal.Description>Код ошибки: {qratorSupportId}</Modal.Description>

                    <Modal.Description>
                        Пожалуйста, попробуйте еще раз или напишите{' '}
                        <a
                            href="https://www.utair.ru/support/11/vyhodit_oshibka_na_sajte_chto_delat"
                            target="_blank"
                            rel="noreferrer"
                        >
                            в чат службы поддержки Utair
                        </a>
                        .
                    </Modal.Description>

                    <Modal.Description>Сохраните код ошибки, чтобы сообщить оператору.</Modal.Description>

                    <Modal.Buttons row>
                        <Button variant="primary" onClick={() => window.location.reload()}>
                            Попробовать ещё раз
                        </Button>
                    </Modal.Buttons>
                </Modal.Content>
            </Modal>
        )
    );
};
