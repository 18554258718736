exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Image-module__root--2Wbpt{width:100%;height:100%;pointer-events:none;user-select:none;object-fit:cover;border-radius:4px}", ""]);

// exports
exports.locals = {
	"root": "Image-module__root--2Wbpt"
};