var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PromoBlock-module__PromoBlock--3SW6W{display:flex;width:107%;padding-bottom:49px;background-image:url(" + escape(require("./bg.svg")) + ");background-repeat:no-repeat;background-position:bottom right}.PromoBlock-module__Header--2cSW3{font-size:32px;line-height:40px;margin-bottom:29px;color:#fff;white-space:pre-line}.PromoBlock-module__List--Avu-U{line-height:24px;font-size:16px;color:#fff;margin-top:-8px;list-style:none;padding:0}.PromoBlock-module__ListItem--1ur5B{margin-bottom:16px;padding-left:29px}.PromoBlock-module__ListItemHeader--12Gc9{line-height:24px;font-size:16px;color:#fff;margin-top:-24px}.PromoBlock-module__ListItemDescription--2pmxt{color:rgba(255,255,255,.5)}.PromoBlock-module__Divider--2fTQz{height:1px;background:rgba(255,255,255,.3);margin:40px 0 16px}.PromoBlock-module__Attention--2poOg{display:flex}.PromoBlock-module__AttentionIcon--3BVWw{margin-right:7px}.PromoBlock-module__AttentionTitle--3GqA1,.PromoBlock-module__AttentionLink--2OpxJ{font-size:16px;line-height:1.5;color:#fff}.PromoBlock-module__AttentionLink--2OpxJ{text-decoration:underline}.PromoBlock-module__AttentionLink--2OpxJ:hover,.PromoBlock-module__AttentionLink--2OpxJ:active{color:#fff}@media(min-width: 768px){.PromoBlock-module__Content--HNcUR{width:57%}}@media(max-width: 768px){.PromoBlock-module__PromoBlock--3SW6W{width:100%;margin-bottom:30px;padding-bottom:65%;background-position:bottom;background-size:80%}}", ""]);

// exports
exports.locals = {
	"PromoBlock": "PromoBlock-module__PromoBlock--3SW6W",
	"Header": "PromoBlock-module__Header--2cSW3",
	"List": "PromoBlock-module__List--Avu-U",
	"ListItem": "PromoBlock-module__ListItem--1ur5B",
	"ListItemHeader": "PromoBlock-module__ListItemHeader--12Gc9",
	"ListItemDescription": "PromoBlock-module__ListItemDescription--2pmxt",
	"Divider": "PromoBlock-module__Divider--2fTQz",
	"Attention": "PromoBlock-module__Attention--2poOg",
	"AttentionIcon": "PromoBlock-module__AttentionIcon--3BVWw",
	"AttentionTitle": "PromoBlock-module__AttentionTitle--3GqA1",
	"AttentionLink": "PromoBlock-module__AttentionLink--2OpxJ",
	"Content": "PromoBlock-module__Content--HNcUR"
};