exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Account-module__form--1lLPC input{outline:none;margin-top:0 !important}.Account-module__wrapper--1dhPa{margin:24px 0 16px}.Account-module__codeInput--3vNp2{margin:16px 0}.Account-module__button--31Qqz{margin-top:32px;line-height:20px}.Account-module__title--YdLmE{font-size:24px;line-height:32px;text-align:center}.Account-module__subtitle--2UuFs{margin-top:10px;text-align:center;color:rgba(17,24,32,.5)}", ""]);

// exports
exports.locals = {
	"form": "Account-module__form--1lLPC",
	"wrapper": "Account-module__wrapper--1dhPa",
	"codeInput": "Account-module__codeInput--3vNp2",
	"button": "Account-module__button--31Qqz",
	"title": "Account-module__title--YdLmE",
	"subtitle": "Account-module__subtitle--2UuFs"
};