/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
// @ts-ignore lib without types
import { mediaQueryTracker } from 'redux-mediaquery';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from 'reducers';

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
});

store.dispatch(
    mediaQueryTracker({
        desktop: 'screen and (min-width: 960px)',
        tablet: 'screen and (min-width: 768px) and (max-width: 959px)',
        phablet: 'screen and (min-width: 480px) and (max-width: 767px)',
        phone: 'screen and (max-width: 479px)',
        phonablet: 'screen and (max-width: 767px)',
    })
);

sagaMiddleware.run(rootSaga);

export default store;
