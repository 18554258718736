/* eslint-disable */
import _ from 'lodash';
import 'url-search-params-polyfill';
import { serializeError } from 'utils/serialize-error';

export const createFSA = (type) => (payload) => {
    if (payload === undefined) {
        return { type };
    } else if (payload instanceof Error) {
        return { type, error: true, errorCode: payload.message, payload: serializeError(payload) };
    }

    return { type, payload };
};

const INITIAL_STATE = {
    data: [],
    error: null,
    isFetching: false,
};
export const createFSAReducer =
    (fsaType, initialState = INITIAL_STATE, additionalHandler) =>
    (state = initialState, { type, payload, error }) => {
        if (type === fsaType) {
            if (!payload) {
                return { ...state, ...{ isFetching: true, error: null } };
            }
            if (error) {
                return { ...state, ...{ isFetching: false, error: payload.message } };
            }

            return { ...state, ...{ data: payload, error: null, isFetching: false } };
        }

        if (_.isFunction(additionalHandler)) {
            return additionalHandler(state, { type, payload, error }) || state;
        }

        return state;
    };

export const createFormData = (data) => {
    const formData = new FormData();
    _.forEach(data, (value, key) => formData.append(key, value));

    return formData;
};

export const createFormURLEncoded = (data) => {
    const result = [];

    Object.getOwnPropertyNames(data).forEach((name) => result.push(`${name}=${encodeURIComponent(data[name])}`));

    return result.join('&');
};

export const getDirectionFromItem = (item) =>
    (item && item.directions && item.directions.GetOptimalFaresDirection) || {};

export const getFlightsFromItem = (item) => {
    const directions = getDirectionFromItem(item);
    return (directions && directions.flights && directions.flights.GetOptimalFaresFlight) || [];
};

export const parseQueryString = (query) => {
    const vars = query.split('&');
    const queryString = {};
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (typeof queryString[pair[0]] === 'undefined') {
            queryString[pair[0]] = decodeURIComponent(pair[1]);
        } else if (typeof queryString[pair[0]] === 'string') {
            queryString[pair[0]] = [queryString[pair[0]], decodeURIComponent(pair[1])];
        } else {
            queryString[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return queryString;
};

export const getCountForPlural = (value) => {
    const absoluteValue = Math.abs(value);

    return absoluteValue % 100 <= 20 ? absoluteValue % 100 : absoluteValue % 10;
};

export * from './paths';
export * from './curried-helper-funcs';
export * from './formatter';
