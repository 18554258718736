import React, { useCallback } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Input } from '@public-projects/utair-ui-kit';
import { useTextFieldWithError } from 'utils/input';
import { setIsBookingNotFound, checkinFlight } from 'actions/checkin';
import { getSearchFormData } from 'selectors/checkin';

export function BookingNotFoundModal() {
    const dispatch = useDispatch();
    const { locator, lastName } = useSelector(getSearchFormData);
    const [flight, flightError, createFlightHandlers, validateFlight] = useTextFieldWithError(
        'flightNumber',
        Cookies.get('checkinform.flight'),
        '',
        null,
        (value) => value.replace(' ', '').replace('_', '').toUpperCase()
    );

    const handleClose = () => {
        dispatch(setIsBookingNotFound(false));
    };

    const handleFindClick = useCallback(() => {
        if (validateFlight() !== '') {
            return;
        }

        dispatch(checkinFlight(locator, lastName, flight));
        dispatch(setIsBookingNotFound(false));
    }, [dispatch, flight, lastName, locator, validateFlight]);

    return (
        <Modal size="small" onClose={handleClose}>
            <Modal.Title centered className="!mb0">
                Бронирование не&nbsp;найдено
            </Modal.Title>
            <Modal.Description centered className="!mt8">
                Введите номер рейса
            </Modal.Description>
            <Modal.Content centered>
                <Input
                    value={flight}
                    message={flightError}
                    isError={flightError !== ''}
                    mask={['U', 'T', ' ', ...Array(4).fill(/\d/)]}
                    guide={false}
                    showMask
                    {...createFlightHandlers()}
                />
            </Modal.Content>
            <Modal.Buttons className="!mt16">
                <Button size="large" onClick={handleFindClick}>
                    Найти
                </Button>
            </Modal.Buttons>
        </Modal>
    );
}
