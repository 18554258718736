import { ReactComponent as Vkontakte } from 'icons/social/vkontakte.svg';
import { ReactComponent as Mailru } from 'icons/social/mailru.svg';
import { ReactComponent as Facebook } from 'icons/social/facebook.svg';
import { ReactComponent as Telegram } from 'icons/social/telegram.svg';
import { ReactComponent as Google } from 'icons/social/googleplus.svg';
import { ReactComponent as Yandex } from 'icons/social/yandex.svg';

export const SOCIAL_AUTH = {
    REDIRECT_URL: `${window.location.protocol}//${window.location.hostname}/success`,
    AUTH: {
        VK: 'https://oauth.vk.com/authorize',
        FB: 'https://www.facebook.com/v2.9/dialog/oauth',
        MAILRU: 'https://connect.mail.ru/oauth/authorize',
        OK: 'https://connect.ok.ru/oauth/authorize',
        GOOGLE: 'https://accounts.google.com/o/oauth2/v2/auth',
        YANDEX: 'https://oauth.yandex.ru/authorize',
    },
};

export const SOCIAL_NETWORKS = {
    vk: {
        title: 'Vkontakte',
        icon: Vkontakte,
        className: '-vk',
    },
    mailru: {
        title: 'Mail.ru',
        icon: Mailru,
        className: '-mailru',
    },
    fb: {
        title: 'Facebook',
        icon: Facebook,
        className: '-fb',
    },
    telegram: {
        title: 'Telegram',
        icon: Telegram,
        className: '-telegram',
    },
    google: {
        title: 'Google',
        icon: Google,
        className: '-google',
    },
    yandex: {
        title: 'Yandex ID',
        icon: Yandex,
        className: '-yandex',
    },
};
