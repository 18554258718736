export const UTAIR_BENEFITS = {
    UTAIR_STANDBY: 'Подсадка Utair',
    UTAIR_VACATION: 'В отпуск',
};

export const BENEFIT_TICKET_TYPES_SET = new Set(['UTAIR_STANDBY', 'UTAIR_VACATION']);

export const DEFAULT_TICKET_TYPE = 'DEFAULT';

export const ALL_TICKETS = {
    [DEFAULT_TICKET_TYPE]: 'Обычный билет',
    ...UTAIR_BENEFITS,
};

export const AIRPORT_TYPES = {
    AIRPLANE: 'airplane',
    HELICOPTER: 'helicopter',
};

export const AIRPORT_TYPES_VALUES = Object.values(AIRPORT_TYPES);
