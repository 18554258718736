exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Segment-module__root--3XelZ{padding-bottom:1px;background-color:#f6f8fc;border-radius:6px}.Segment-module__root--3XelZ+.Segment-module__root--3XelZ{margin-top:12px}.Segment-module__header--3T05u{display:flex;align-items:center;padding:8px 16px;border-radius:6px 6px 0px 0px;background-color:rgba(0,53,148,.1)}.Segment-module__headerSubText--23VGs{font-weight:500;font-size:16px;line-height:24px;color:#111820}.Segment-module__plane--27grS{height:20px;width:20px;margin-right:12px;color:rgba(0,53,148,.5)}.Segment-module__rotate--1Mdcd{transform:rotate(180deg)}", ""]);

// exports
exports.locals = {
	"root": "Segment-module__root--3XelZ",
	"header": "Segment-module__header--3T05u",
	"headerSubText": "Segment-module__headerSubText--23VGs",
	"plane": "Segment-module__plane--27grS",
	"rotate": "Segment-module__rotate--1Mdcd"
};