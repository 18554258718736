import cn from 'classnames';
import React from 'react';

import styles from './Tab.module.scss';

interface Props {
    className: string;
    label: string;
    value: string;
    active: boolean;
}

export function Tab({ className, label, value, active }: Props) {
    return (
        <a className={cn(styles.root, active && styles.active, className)} href={`#${value}`}>
            {label}
        </a>
    );
}
