exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Header-module__mainMenuIcon--1dubI{width:24px;height:24px}.Header-module__mainMenuIconFilled--7IvRb>path{fill:#003594;fill-opacity:1}.Header-module__mobileProfileLink--1YCOp{margin-right:0;order:0 !important;padding-bottom:0}.Header-module__mobileProfileLinkFilled--1Ifu9>rect{fill:#003594;fill-opacity:1}.Header-module__userAvatarWrapper--3s-rC{position:relative}.Header-module__userAvatarWrapper--3s-rC>svg,.Header-module__userAvatarWrapper--3s-rC>img{width:36px;height:36px}.Header-module__userAvatarWrapper--3s-rC>img{border-radius:50%}.Header-module__balanceErrorIndicator--1kfd8{position:absolute;background-color:#ff6e0a;border-radius:50%;width:6px;height:6px;left:26px;top:0;border:2px solid #003594}.Header-module__balanceErrorIndicatorLight--2vzB3{border:2px solid #fff}.Header-module__standbyHeaderBody--2sbGO{min-height:32px;display:flex;justify-content:space-between;align-items:center;font-size:16px;color:#fff}.Header-module__goBackIconButton--3mgM8{color:rgba(255,255,255,.5);width:24px;height:24px}.Header-module__goBackIconButton--3mgM8:hover{color:rgba(255,255,255,.6);cursor:pointer}", ""]);

// exports
exports.locals = {
	"mainMenuIcon": "Header-module__mainMenuIcon--1dubI",
	"mainMenuIconFilled": "Header-module__mainMenuIconFilled--7IvRb",
	"mobileProfileLink": "Header-module__mobileProfileLink--1YCOp",
	"mobileProfileLinkFilled": "Header-module__mobileProfileLinkFilled--1Ifu9",
	"userAvatarWrapper": "Header-module__userAvatarWrapper--3s-rC",
	"balanceErrorIndicator": "Header-module__balanceErrorIndicator--1kfd8",
	"balanceErrorIndicatorLight": "Header-module__balanceErrorIndicatorLight--2vzB3",
	"standbyHeaderBody": "Header-module__standbyHeaderBody--2sbGO",
	"goBackIconButton": "Header-module__goBackIconButton--3mgM8"
};