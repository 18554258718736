import { combineReducers } from '@reduxjs/toolkit';

import members from './members';
import in_requests from './in_requests'; // eslint-disable-line camelcase
import modals from './modals';

export default combineReducers({
    members,
    in_requests,
    modals,
});
