/* eslint-disable */
import React from 'react';
import {validator} from 'components/common';

const defaultValueTransformer = (value) => value;

export const useTextFieldWithError = (
    fieldType,
    initialState = '',
    initialError = '',
    customValidator,
    valueTransformer = defaultValueTransformer
) => {
    const [value, setValue] = React.useState(initialState);
    const [error, setError] = React.useState(initialError);

    const wrappedValidator = React.useCallback(
        (value) => {
            if (customValidator) {
                return customValidator(value);
            }

            switch (fieldType) {
                case 'birthday':
                    return validator.birthday(value, true);
                case 'locator':
                    return validator.locator(value, true);
                case 'flightNumber':
                    return validator.flightNumber(value, true);
                case 'email':
                    return validator.email(value, true);
                case 'phone':
                    return validator.phone(value, true);
                case 'firstName':
                case 'lastName':
                default:
                    return validator.text(value, fieldType, true);
            }
        },
        [value]
    );

    const validate = React.useCallback(() => {
        const test = wrappedValidator(value);
        const errorMessage = typeof test === 'string' ? test : '';

        setError(errorMessage);

        return errorMessage;
    }, [value]);

    const createEventHandlers = React.useCallback(
        () => ({
            onChange: (event) => {
                setValue(valueTransformer(event.target.value));
                setError('');
            },
            onFocus: () => {
                setError('');
            },
            onBlur: validate
        }),
        [value]
    );

    return [value, error, createEventHandlers, validate, setValue, setError];
};
