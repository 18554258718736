/* eslint-disable */
import { FETCH_IN_REQUESTS } from 'actions/family';
import {RESET_BONUSES} from 'actions/bonuses';

import {createFSAReducer} from 'utils';

const INITIAL_STATE = {
    data: [],
    error: null,
    isFetching: false
};

const inRequests = (state, {type, payload}) => {
    switch (type) {
        case RESET_BONUSES:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default createFSAReducer(FETCH_IN_REQUESTS, INITIAL_STATE, inRequests);
