import React from 'react';

class CookiesAccept extends React.Component {
    static cookieValue = 'cookies_accepted=true';

    static cookieExpiresInDays = 10 * 365; // 10 years

    static get cookieExpire() {
        const date = new Date();
        date.setTime(date.getTime() + CookiesAccept.cookieExpiresInDays * 24 * 60 * 60 * 1000);

        return `expires=${date.toUTCString()}`;
    }

    state = {
        isShow: document.cookie.indexOf(CookiesAccept.cookieValue) < 0,
    };

    acceptCookies = () => {
        document.cookie = `${CookiesAccept.cookieValue}; ${CookiesAccept.cookieExpire}; path=/`;
        this.setState({ isShow: false });
    };

    render() {
        const { isShow } = this.state;

        if (!isShow) {
            return null;
        }

        // data-nosnippet https://jira.utair.ru/browse/UTFRNT-2138
        // https://developers.google.com/search/docs/advanced/robots/robots_meta_tag?hl=ru#data-nosnippet-attr
        return (
            <noindex>
                <div className="CookiesAccept" data-nosnippet>
                    <div className="CookiesAccept-Body">
                        <div className="CookiesAccept-Text">
                            Utair использует cookies, данные об ip-адресе и геолокации, чтобы повысить качество сервиса.
                            Пользуясь сайтом, вы даете согласие на использование этих данных.
                        </div>

                        <button className="CookiesAccept-Button" onClick={this.acceptCookies}>
                            Понятно
                        </button>

                        <button className="CookiesAccept-CloseButton" onClick={this.acceptCookies}>
                            &times;
                        </button>
                    </div>
                </div>
            </noindex>
        );
    }
}

export default CookiesAccept;
