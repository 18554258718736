export const PASSENGERS_TYPES = {
    ADULT: 'ADULT',
    CHILD: 'CHILD',
    INFANT: 'INFANT',
};

export const RU_COUNTRY_CODE = 'RU';

export const COUNTRY_CODES_WITH_RU_PASSPORT = ['RU', 'AM', 'BY', 'KZ', 'KG'];
export const COUNTRY_CODES_WITH_RU_DOCS = ['RU', 'BY', 'KZ', 'KG'];
