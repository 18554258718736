/* eslint-disable */
import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';

import DynamicImport from 'components/common/dynamic.import';

const LandingServices = (props) => <DynamicImport load={() => import('./services')} {...props} />;

const LandingServicesSuccess = (props) => <DynamicImport load={() => import('./services/success')} {...props} />;

const LandingServicesFail = (props) => <DynamicImport load={() => import('./services/fail')} {...props} />;

export const landingsRoutes = () => (
    <Route path="lp">
        <IndexRedirect to="/404" />
        <Route path="services">
            <IndexRoute component={LandingServices} />
            <Route path="success" component={LandingServicesSuccess} />
            <Route path="fail" component={LandingServicesFail} />
        </Route>
    </Route>
);
