import { FETCH_BONUSES_TRANSACTIONS, FETCH_AIRPORTS, FETCH_BONUSES_BALANCE, RESET_BONUSES } from 'actions/bonuses';

const UNREGISTERED_ERROR_CODE = '40009';

const INITIAL_DATA = {
    transactions: {
        isFetching: false,
        items: [],
        error: '',
    },
    airports: [],
    transferPackages: {
        isFetching: false,
        items: [],
        error: '',
    },
    bonusesBalance: {
        isFetching: false,
        error: '',
    },
    cardLevel: null,
    flightsCountCurrentYear: 0,
    deposits: [],
    contractBalances: [],
    mileToNextLevel: 0,
    nextLevel: null,
    qualifying: 0,
    rubleNeeded: 0,
    qualifyingRuble: 0,
    milesCount: 0,
    familyMilesCount: 0,
    levelExpire: null,
    isUnregistered: false,
};

const orders = (state = INITIAL_DATA, { type, payload, error, errorCode }) => {
    switch (type) {
        case FETCH_BONUSES_TRANSACTIONS: {
            if (error) {
                return { ...state, transactions: { items: [], isFetching: false, error: payload.message } };
            }

            if (payload) {
                return { ...state, transactions: { items: payload, isFetching: false, error: '' } };
            }

            return { ...state, transactions: { items: [], isFetching: true, error: '' } };
        }

        case FETCH_AIRPORTS:
            return { ...state, airports: payload };

        case FETCH_BONUSES_BALANCE: {
            if (error) {
                const unregisteredData = errorCode === UNREGISTERED_ERROR_CODE ? { isUnregistered: true } : {};

                return { ...state, ...unregisteredData, bonusesBalance: { isFetching: false, error } };
            }

            if (!payload) {
                return { ...state, bonusesBalance: { isFetching: true, error: '' } };
            }

            return {
                ...state,
                ...payload,
                bonusesBalance: { isFetching: false, error: '' },
            };
        }

        case RESET_BONUSES:
            return INITIAL_DATA;

        default:
            return state;
    }
};

export default orders;
