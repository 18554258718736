/* eslint-disable camelcase */
import cn from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import { ReactComponent as Plane } from './icons/plane-sharp.svg';
import styles from './Segment.module.scss';

interface Props {
    direction: string;
    departure: string;
    arrival: string;
    children: ReactNode;
}

export function Segment({ direction, departure, arrival, children }: Props) {
    return (
        <div className={styles.root} data-testid="checkIn-ServiceItemSegment">
            <div className={styles.header}>
                <Plane className={cn(styles.plane, direction.toLowerCase() === 'back' && styles.rotate)} />
                <div className={styles.headerSubText}>
                    {departure} — {arrival}
                </div>
            </div>
            {children}
        </div>
    );
}
