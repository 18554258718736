exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Social-module__socialHeader--3PzLr{width:100%;text-align:center;border-bottom:1px solid #dfe3eb;line-height:0em;margin:10px 0 20px}.Social-module__socialHeader--3PzLr span{font-size:12px;color:#111820;background:#fff;padding:0 10px}.Social-module__socialButton--ntF6c{display:flex;width:100%;padding:11px 0;font-size:16px;background-color:#060606;color:#fff;border-radius:8px;border:0}.Social-module__socialButton--ntF6c:hover{color:#fff}.Social-module__socialButton--ntF6c:active,.Social-module__socialButton--ntF6c:focus{color:#7f7d7d;outline:none}.Social-module__socialButton--ntF6c svg{padding:0 43px 0 16px;margin:0}.Social-module__buttonWithSocial--3IApu{margin-top:32px;padding:0 39px;line-height:20px}.Social-module__socailAgreementWrapper--Tq3Ss{margin-top:16px}", ""]);

// exports
exports.locals = {
	"socialHeader": "Social-module__socialHeader--3PzLr",
	"socialButton": "Social-module__socialButton--ntF6c",
	"buttonWithSocial": "Social-module__buttonWithSocial--3IApu",
	"socailAgreementWrapper": "Social-module__socailAgreementWrapper--Tq3Ss"
};