/* eslint camelcase: [0, {properties: "never"}] */

export const main_page = {
    title: 'Путешествуем зимой!',
    more_details: '',
    link_title: 'Куда летает Utair',
    checkin_promo: {
        title: 'Бесплатная онлайн регистрация на рейс ЮТэйр на официальном сайте',
        header: 'Зарегистрируйтесь на рейс бесплатно и выберите лучшие места!',
        promos: [
            'Выберите комфортное место заранее&Онлайн-регистрация начинается ' +
                'раньше, чем в аэропорту - за 36 часов до вылета. Выберите комфортное ' +
                'место, пока оно еще доступно.',
            'Оформите дополнительный багаж по лучшей цене&В аэропорту багаж будет стоить дороже.',
            'Сэкономьте время в аэропорту&Распечатайте посадочный и проходите сразу на ' +
                'предполетный досмотр, если путешествуете без багажа.',
        ],
        attention: 'Рекомендуем приезжать в аэропорт заранее',
        attentionLink: 'Узнать больше о регистрации и посадке',
    },
    meta: {
        description:
            'Бронируйте авиабилеты на официальном сайте Utair.ru. Билеты без комиссии ' +
            'и без переплаты. Получите возврат милями до 7%. Регистрируйтесь ' +
            'на рейс бесплатно.',
    },
};
