import React from 'react';

type InternationalIcon1x1Props = JSX.IntrinsicElements['svg'] & {
    title: string;
};

export function InternationalIcon1x1({ title, ...rest }: InternationalIcon1x1Props) {
    return (
        <svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <title>{title}</title>
            <g
                className="PhoneInputInternationalIconGlobe"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
            >
                <path d="M8.45,13A21.44,21.44,0,1,1,37.08,41.56" />
                <path d="M19.36,35.47a36.9,36.9,0,0,1-2.28-13.24C17.08,10.39,21.88.85,27.8.85s10.72,9.54,10.72,21.38c0,6.48-1.44,12.28-3.71,16.21" />
                <path d="M17.41,33.4A39,39,0,0,1,27.8,32.06c6.62,0,12.55,1.5,16.48,3.86" />
                <path d="M44.29,8.53c-3.93,2.37-9.86,3.88-16.49,3.88S15.25,10.9,11.31,8.54" />
                <line x1="27.8" y1="0.85" x2="27.8" y2="34.61" />
                <line x1="15.2" y1="22.23" x2="49.15" y2="22.23" />
            </g>
            <path
                className="PhoneInputInternationalIconPhone"
                stroke="transparent"
                fill="currentColor"
                d="M9.42,26.64c2.22-2.22,4.15-3.59.22-8.49S3.08,17,.93,19.17c-2.49,2.48-.13,11.74,9,20.89s18.41,11.5,20.89,9c2.15-2.15,5.91-4.77,1-8.71s-6.27-2-8.49.22c-1.55,1.55-5.48-1.69-8.86-5.08S7.87,28.19,9.42,26.64Z"
            />
        </svg>
    );
}
