import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { checkinFlight } from 'actions/checkin';

import { OrderSegment } from './OrderSegment';
import styles from './OrderItem.module.scss';

export function OrderItem({ rloc, segments, onShowBoardingPassesModal }) {
    const dispatch = useDispatch();
    const handleCheckinClick = useCallback(
        (flightNumber, lastName) => {
            dispatch(checkinFlight(rloc, lastName, flightNumber));
        },
        [dispatch, rloc]
    );

    const handleBoardingPassClick = useCallback(
        (flightNumber) => {
            onShowBoardingPassesModal(rloc, flightNumber);
        },
        [onShowBoardingPassesModal, rloc]
    );

    return (
        <div className={styles.container} key={rloc}>
            <div className={styles.bookingNumber}>
                {t('check_in.booking_number')} {rloc.substring(0, 6)}
            </div>
            <div className={cn('table-grid', styles.segments)}>
                {segments.map((segment) => (
                    <OrderSegment
                        key={`${segment.flightNumber}-${segment.departureDate}-${segment.departureTime}`}
                        {...segment}
                        onCheckinClick={handleCheckinClick}
                        onBoardingPassClick={handleBoardingPassClick}
                    />
                ))}
            </div>
        </div>
    );
}

OrderItem.propTypes = {
    rloc: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    segments: PropTypes.arrayOf(PropTypes.object).isRequired,
    onShowBoardingPassesModal: PropTypes.func.isRequired,
};
