exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Loader-module__root--2ifIC{position:absolute;top:0;left:0;display:flex;align-items:center;justify-content:center;z-index:100;width:100%;height:100%;background-color:rgba(255,255,255,.5);border-radius:6px}", ""]);

// exports
exports.locals = {
	"root": "Loader-module__root--2ifIC"
};