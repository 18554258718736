import React, { useCallback, useEffect, useState } from 'react';

import { extractError } from 'checkin/shared/api';
import { CURRENCIES } from 'consts';
import { formatPrice } from 'utils/formatter';

import { TYPE_OF_APPLICATION } from '../../consts';
import {
    changeSelectedServices,
    getHasChangeInBasket,
    getIsSelected,
    getTotalPrice,
    mapServicesForRemove,
} from '../../lib';
import { useAction } from '../../model';
import type { Applicability, CheckinService, Passenger as PassengerType } from '../../types';
import { Card } from '../Card';
import { Passenger } from '../Passenger';
import { Segment } from '../Segment';
import { ServiceItemModal } from '../ServiceItemModal';

const note = 'Куплено ранее';

interface Props {
    id: string;
    image: string;
    name?: string;
    dimensions?: string;
    description?: string;
    segments?: ServiceItemSegment[];
    applicability?: Applicability[];
    checkinServices?: CheckinService[];
    hasBookedServices?: boolean;
    minPrice?: number | string;
    Action: ({ selected, onChange, segmentId, passengerId }: ActionProps) => React.JSX.Element;
    applyServices?: (
        selected: CheckinService[],
        checkinServices: CheckinService[],
        typeOfApplication: string
    ) => Promise<void> | void;
    showDetails: boolean;
    setShowDetails: (value: boolean) => void;
    isDisabledImgInModal?: boolean;
}

export interface ServiceItemSegment {
    segmentId: number;
    departureCityName?: string | null;
    arrivalCityName?: string | null;
    departureAirportCode: string;
    arrivalAirportCode: string;
    segmentIdAsm: string;
    direction?: string | null;
    passengers: ServiceItemPassenger[];
}

export interface ServiceItemPassenger extends PassengerType {
    price: string;
    bookedQuantity: number;
    formattedOldPrice: string | null;
}

interface ActionProps {
    selected: CheckinService[];
    onChange: (value: number, segmentId: string, passengerId: string) => void;
    segmentId: string;
    passengerId: string;
}

export function ServiceItem({
    id: guid,
    image,
    name = '',
    dimensions = '',
    description = '',
    segments = [],
    applicability = [],
    checkinServices = [],
    hasBookedServices = false,
    minPrice = 0,
    Action,
    applyServices = () => {},
    showDetails,
    setShowDetails,
    isDisabledImgInModal = false,
}: Props) {
    const { setError, setIsLoading: setIsGlobalLoading } = useAction();
    const [isLoading, setLoading] = useState(false);
    const [selected, setSelected] = useState<CheckinService[]>([]);
    const total = getTotalPrice(selected, applicability);
    const formattedTotalPrice = formatPrice(total, CURRENCIES.RUR.value, true, true);
    const updateSelected = useCallback(() => setSelected(checkinServices), [checkinServices]);
    const hasChangeInBasket = getHasChangeInBasket(checkinServices, selected);

    useEffect(() => {
        updateSelected();
    }, [updateSelected]);

    const applyService = async (typeOfApplication: string) => {
        setLoading(true);

        try {
            await applyServices(selected, checkinServices, typeOfApplication);
            setShowDetails(false);
        } catch (e) {
            setError(extractError(e));
            updateSelected();
            setShowDetails(false);
        } finally {
            setLoading(false);
        }
    };

    const removeService = async () => {
        setIsGlobalLoading(true);

        try {
            await applyServices(mapServicesForRemove(selected), checkinServices, TYPE_OF_APPLICATION.REMOVE);
            setIsGlobalLoading(false);
        } catch (e) {
            setIsGlobalLoading(false);
            updateSelected();
            setError(extractError(e));
        }
    };

    const onChange = (quantity: number, segmentIdAsm: string, passengerIdAsm: string) =>
        setSelected(changeSelectedServices({ selected, guid, segmentIdAsm, passengerIdAsm, quantity }));

    return (
        <>
            <Card
                imageSrc={image}
                title={name}
                subTitle={dimensions}
                alert={hasBookedServices ? note : ''}
                price={`от ${minPrice}`}
                formattedTotalPrice={formattedTotalPrice}
                hasTotalPrice={!!total}
                onAdd={() => setShowDetails(true)}
                onRemove={removeService}
            />
            {showDetails && (
                <ServiceItemModal
                    hasChangeInBasket={hasChangeInBasket}
                    imgSrc={image}
                    isDisabledImage={isDisabledImgInModal}
                    isLoading={isLoading}
                    title={name}
                    subtitle={dimensions}
                    description={description}
                    formattedTotalPrice={formattedTotalPrice}
                    onApplyService={() => applyService(TYPE_OF_APPLICATION.SAVE)}
                >
                    {segments.map(({ segmentIdAsm, direction, departureCityName, arrivalCityName, passengers }) => (
                        <Segment
                            key={segmentIdAsm}
                            direction={direction || ''}
                            departure={departureCityName || ''}
                            arrival={arrivalCityName || ''}
                        >
                            {passengers.map(
                                ({ firstName, lastName, passengerIdAsm, price, bookedQuantity, formattedOldPrice }) => (
                                    <Passenger
                                        firstName={firstName}
                                        lastName={lastName}
                                        key={passengerIdAsm}
                                        note={bookedQuantity ? `${note} \u00D7 ${bookedQuantity}` : ''}
                                        onAdd={() => onChange(1, segmentIdAsm, passengerIdAsm)}
                                        Action={
                                            <Action
                                                selected={selected}
                                                onChange={onChange}
                                                segmentId={segmentIdAsm}
                                                passengerId={passengerIdAsm}
                                            />
                                        }
                                        price={price}
                                        formattedOldPrice={formattedOldPrice}
                                        isSelected={getIsSelected(selected, segmentIdAsm, passengerIdAsm)}
                                    />
                                )
                            )}
                        </Segment>
                    ))}
                </ServiceItemModal>
            )}
        </>
    );
}
