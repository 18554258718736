import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { Link } from 'react-router';
import { ReactComponent as Close } from 'icons/interface/close.svg';
import { ReactComponent as Search } from 'icons/interface/search.svg';
import { simpleHttpManager as simpleRequest } from 'managers/simple';
import { API_URLS } from 'consts';
import { useBonusesLink } from 'utils/hooks';
import { useLocale } from 'locales/index';

import MainMenu from './main-menu';
import MobileMenu from './mobile-menu';
import defaultMenuItems from './defaultMenuItems';

export default function Menu({
    media = {},
    isAuth = false,
    isOpen = false,
    handleClose = () => {},
    indexRoute = '',
    isEmployee = false,
    baseUrl = 'https://www.utair.ru',
}) {
    const { language: lng } = useLocale();

    const [menuItems, setMenuItems] = useState(defaultMenuItems);

    const bonusesLink = useBonusesLink();

    useEffect(() => {
        simpleRequest
            .get(`${API_URLS.MENU.GET_CORE_MENU}${lng === 'en' ? '?lang=en' : ''}`)
            .then(({ result }) => setMenuItems(result))
            // eslint-disable-next-line no-console
            .catch(console.warn);
    }, [lng]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }, [isOpen]);

    if (media.phone) {
        return <MobileMenu isOpen={isOpen} onClose={handleClose} />;
    }

    return (
        <div id="header-menu" className="header-menu" aria-hidden={!isOpen}>
            <div className="wrapper">
                <button
                    className="header-close"
                    type="button"
                    aria-label={i18next.t('common.close_menu')}
                    title={i18next.t('common.close')}
                    onClick={handleClose}
                >
                    <Close width="1em" height="1em" />
                </button>

                <ul className="RowMenu">
                    <li className="inlineBlock fs18">
                        <Link to={indexRoute} onClick={handleClose}>
                            {i18next.t('header.menu.buy')}
                        </Link>
                    </li>{' '}
                    <li className="inlineBlock fs18">
                        <Link to={`${indexRoute}check-in-new`} onClick={handleClose}>
                            {i18next.t('header.menu.check_in')}
                        </Link>
                    </li>{' '}
                    {isAuth && lng !== 'en' && (
                        <>
                            <li className="inlineBlock fs18">
                                <Link to="/order-manage" onClick={handleClose}>
                                    {i18next.t('header.menu.orders')}
                                </Link>
                            </li>{' '}
                            <li className="inlineBlock fs18">
                                <Link to={bonusesLink} onClick={handleClose}>
                                    {i18next.t('header.menu.miles')}
                                </Link>
                            </li>
                        </>
                    )}
                    {isAuth && lng !== 'en' && isEmployee && (
                        <>
                            {' '}
                            <li className="inlineBlock fs18">
                                <Link to="/sb" onClick={handleClose}>
                                    {i18next.t('header.menu.status_business')}
                                </Link>
                            </li>
                        </>
                    )}
                </ul>

                <form action={`https://www.utair.ru/${indexRoute}search`} method="get" className="Search !mb40">
                    <input
                        className="Search-input Input"
                        type="text"
                        placeholder={i18next.t('common.search')}
                        name="q"
                    />

                    <button className="Search-go" type="submit" title={i18next.t('common.start_search')}>
                        <Search width="1.13em" height="1.13em" />
                    </button>
                </form>

                <MainMenu links={menuItems} lng={lng} baseUrl={baseUrl} />
            </div>
        </div>
    );
}
