import {SET_MODAL_TYPE, SET_MODAL_IS_SHOW, SET_MODAL_DATA} from 'actions/family';
import {RESET_BONUSES} from 'actions/bonuses';

const INITIAL_STATE = {
    type: null,
    isShow: false,
    data: {}
};

const modals = (state = INITIAL_STATE, {type, payload}) => {
    switch (type) {
        case SET_MODAL_TYPE:
        case SET_MODAL_DATA:
        case SET_MODAL_IS_SHOW:
            return {...state, ...payload};
        case RESET_BONUSES:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default modals;
