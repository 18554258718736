import React from 'react';
import type { RouteProps } from 'react-router';
import { IndexRoute, Route } from 'react-router';

import DynamicImport from 'components/common/dynamic.import';

const UpgradeBusinessPage = (props: RouteProps) => (
    <DynamicImport load={() => import('./upgrade-business')} {...props} />
);
const UpgradeBusinessSuccessPage = (props: RouteProps) => (
    <DynamicImport load={() => import('./upgrade-business-success')} {...props} />
);

export const upgradeRoutes = () => (
    <Route path="upgrade">
        <Route path="upgrade-business">
            <IndexRoute component={UpgradeBusinessPage} />
            <Route path="success" component={UpgradeBusinessSuccessPage} />
        </Route>
    </Route>
);
