import { useCallback, useState } from 'react';

import { useLocale } from 'locales/index';
import { extractError } from 'schedule/shared/api';
import { type City, useAction } from 'schedule/shared/model';

import { type GetCitiesApiParams, getCitiesApi } from '../api';
import { mapCities } from '../lib';

export function useGetCities() {
    const [cities, setCities] = useState<City[]>([]);
    const { setError } = useAction();
    const { language } = useLocale();

    const getCities = useCallback(
        async ({ query, forCity, popular, limit }: GetCitiesApiParams): Promise<City[] | undefined> => {
            try {
                const { data } = await getCitiesApi({ query, forCity, popular, limit });
                const mapped = mapCities(data, language);

                setCities(mapped);

                return mapped;
            } catch (error) {
                setError(extractError(error));

                throw error;
            }
        },
        [language, setError]
    );

    return { cities, getCities };
}
