import React from 'react';
import { connect } from 'react-redux';
import { every, trim, find } from 'lodash';
import url from 'url';
import { setIsOpenModal, openCheckinPage as openCheckinPageAction } from 'actions/checkin';
import { getIsModalOpen, getOrder, getSearchFormData } from 'selectors/checkin';
import pushMetricsEventAction from 'actions/metrics';
import { METRICS_EVENT_NAMES } from 'constants/metrics';
import Modal from 'components/common/modal-new';

class CheckinModal extends React.PureComponent {
    handleAgree = () => {
        const {
            data: { flight, lastName },
            order,
            onClose,
            pushMetricsEvent,
        } = this.props;
        const flightnumber = flight.replace(/(^UT)/, '');

        const segment = find(order.segments, { flight_number: flightnumber }) || order.segments[0];

        const query = {
            flight_num: flightnumber,
            flight: `${segment.departure_city_full_name} - ${segment.arrival_city_full_name}`,
            last_name: lastName,
            rloc: order.rloc,
            email: order.ctc_mail,
            ticket: order.tickets_active[0] ? order.tickets_active[0].docno : null,
            departure_time: segment.departure_unixtime,
            utm_source: 'check_in',
        };

        const upgradeUrl = '/upgrade/upgrade-business';

        const path = upgradeUrl + url.format({ query });
        window.open(path, '_blank');
        onClose();
        pushMetricsEvent(METRICS_EVENT_NAMES.REDIRECT_TO_UPGRADE);
    };

    handleDisagree = () => {
        const {
            data: { flight, locator, lastName },
            openCheckinPage,
            onClose,
            pushMetricsEvent,
        } = this.props;

        if (every([flight, locator, lastName])) {
            const flightnumber = flight.replace(/(^UT)/, '');
            const lastname = trim(lastName).split(' ')[0];

            openCheckinPage(flightnumber, lastname, locator);
        }

        onClose();
        pushMetricsEvent(METRICS_EVENT_NAMES.DISCARD_UPGRADE);
    };

    render() {
        const {
            isOpen,
            data: { flight },
            order,
        } = this.props;

        if (!isOpen) {
            return null;
        }

        const flightnumber = flight.replace(/(^UT)/, '');

        const segment = find(order.segments, { flight_number: flightnumber }) || order.segments[0];
        const flightText = `${segment.departure_city_full_name} - ${segment.arrival_city_full_name}`;

        return (
            <Modal isOpen={isOpen} onClose={this.handleDisagree}>
                <div className="modal-header">
                    Пересядьте в евробизнес и летите с комфортом на вашем рейсе {flightText}
                </div>

                <div className="text-center mb-20">
                    <button className="Button mr-20" onClick={this.handleAgree}>
                        Пересесть в евробизнес
                    </button>

                    <button className="Button -grayLight" onClick={this.handleDisagree}>
                        Продолжить регистрацию
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    isOpen: getIsModalOpen(state),
    data: getSearchFormData(state),
    order: getOrder(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(setIsOpenModal(false)),
    openCheckinPage: (flightnumber, lastName, ticket) =>
        dispatch(openCheckinPageAction(flightnumber, lastName, ticket)),
    pushMetricsEvent: (eventName, eventData) => dispatch(pushMetricsEventAction(eventName, eventData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckinModal);
