import { call, put, takeLatest } from 'redux-saga/effects';
import { utairHttpManager as request } from 'managers/utair';
import { API_URLS } from 'consts';
import Url from 'url';
import {
    FETCH_ONLINE_PANEL_REQUEST,
    FETCH_ONLINE_PANEL_SUCCESS,
    FETCH_ONLINE_PANEL_FAILURE,
    FETCH_ONLINE_PANEL_CITIES_REQUEST,
    FETCH_ONLINE_PANEL_CITIES_SUCCESS,
    FETCH_ONLINE_PANEL_CITIES_FAILURE,
} from 'actions/online-panel';

const requestOnlinePanelData = (params) => request.get(`${API_URLS.ONLINE_PANEL}${Url.format({ query: params })}`);

const requestOnlinePanelCities = (q) => {
    const queryString = Url.format({ query: { q: q || '', limit: 7 } });

    return request.get(`${API_URLS.CITIES.ALL}${queryString}`);
};

function* fetchOnlinePanelData(action) {
    try {
        const response = yield call(requestOnlinePanelData, action.payload);
        yield put({
            type: FETCH_ONLINE_PANEL_SUCCESS,
            payload: response || {},
        });
    } catch (e) {
        yield put({
            type: FETCH_ONLINE_PANEL_FAILURE,
            payload: {},
        });
    }
}

function* fetchOnlinePanelCities(action) {
    try {
        const response = yield call(requestOnlinePanelCities, action.payload);
        yield put({
            type: FETCH_ONLINE_PANEL_CITIES_SUCCESS,
            payload: response.data || [],
        });
    } catch (e) {
        yield put({
            type: FETCH_ONLINE_PANEL_CITIES_FAILURE,
            payload: [],
        });
    }
}

export function* OnlinePanelWatcher() {
    yield takeLatest(FETCH_ONLINE_PANEL_REQUEST, fetchOnlinePanelData);
    yield takeLatest(FETCH_ONLINE_PANEL_CITIES_REQUEST, fetchOnlinePanelCities);
}
