/**
 * city data for search and info panel
 * @typedef {Object} City
 * @property {string} code - city code (ex: AER, MOW)
 * @property {string} [name] - city name on current language
 * @property {Object<string, string>} [name_translations] - translations of city name
 */

/**
 * passengers data for search
 * @typedef {Object} PassengersCountData
 * @property {number} adultsCount
 * @property {?number} [childrenCount]
 * @property {?number} [infantsCount]
 */

export const SEARCH_CACHE_NAME = 'SEARCH_WIDGET_CACHE';

export const SET_IS_TWO_WAY = 'search-widget/SET_IS_TWO_WAY';
export const SET_PASSENGERS = 'search-widget/SET_PASSENGERS';
export const SET_FROM_CITY = 'search-widget/SET_FROM_CITY';
export const SET_DEST_CITY = 'search-widget/SET_DEST_CITY';
export const SWAP_CITIES = 'search-widget/SWAP_CITIES';
export const SET_FROM_DATE = 'search-widget/SET_FROM_DATE';
export const SET_BACK_DATE = 'search-widget/SET_BACK_DATE';
export const SET_AVAILABLE_FROM_DATES = 'search-widget/SET_AVAILABLE_FROM_DATES';
export const SET_AVAILABLE_BACK_DATES = 'search-widget/SET_AVAILABLE_BACK_DATES';
export const SET_TICKET_TYPE = 'search-widget/SET_TICKET_TYPE';
export const SET_UNACCOMPANIED_MINOR = 'search-widget/SET_UNACCOMPANIED_MINOR';
export const SET_CITIES_TO = 'search-widget/SET_CITIES_TO';
export const SET_CITIES_FROM = 'search-widget/SET_CITIES_FROM';
export const SET_CITIES_POPULAR = 'search-widget/SET_CITIES_POPULAR';
export const SET_DEFAULT_CITIES_TO = 'search-widget/SET_DEFAULT_CITIES_TO';

export const SET_CITIES_ERROR = 'search-widget/SET_CITIES_ERROR';

/**
 * @param {PassengersCountData} passengers
 */
export const setPassengers = (passengers) => ({
    type: SET_PASSENGERS,
    payload: passengers,
});

/**
 * @param {City} fromCity
 */
export const setFromCity = (fromCity) => ({
    type: SET_FROM_CITY,
    payload: fromCity,
});

/**
 * @param {City} destCity
 */
export const setDestCity = (destCity) => ({
    type: SET_DEST_CITY,
    payload: destCity,
});

export const swapCities = () => ({
    type: SWAP_CITIES,
});

/**
 * @param {string} fromDate - in format 'DD.MM.YYYY'
 */
export const setFromDate = (fromDate) => ({
    type: SET_FROM_DATE,
    payload: fromDate,
});

export const setAvailableFromDates = (availableFromDates) => ({
    type: SET_AVAILABLE_FROM_DATES,
    payload: availableFromDates,
});

/**
 * @param {string} backDate - in format 'DD.MM.YYYY'
 */
export const setBackDate = (backDate) => ({
    type: SET_BACK_DATE,
    payload: backDate,
});

export const setAvailableBackDates = (availableBackDates) => ({
    type: SET_AVAILABLE_BACK_DATES,
    payload: availableBackDates,
});

/**
 * @param {City[]} cities
 */
export const setCitiesPopular = (cities) => ({
    type: SET_CITIES_POPULAR,
    payload: cities,
});

/**
 * @param {City[]} cities
 */
export const setCitiesFrom = (cities) => ({
    type: SET_CITIES_FROM,
    payload: cities,
});

/**
 * @param {City[]} cities
 */
export const setCitiesTo = (cities) => ({
    type: SET_CITIES_TO,
    payload: cities,
});

/**
 * @param {City[]} cities
 */
export const setDefaultCitiesTo = (cities) => ({
    type: SET_DEFAULT_CITIES_TO,
    payload: cities,
});

/**
 * @param {*} error
 */
export const setCitiesError = (error) => ({
    type: SET_CITIES_ERROR,
    payload: error,
});

export const setTicketType = (ticketType) => ({
    type: SET_TICKET_TYPE,
    payload: ticketType,
});

export const toggleUnaccompaniedMinor = (isChecked) => ({
    type: SET_UNACCOMPANIED_MINOR,
    payload: isChecked,
});

export const setIsTwoWay = (isTwoWay) => ({
    type: SET_IS_TWO_WAY,
    payload: isTwoWay,
});
