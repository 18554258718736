import {
    SET_USER_AUTH_ATTEMPT_ID,
    SET_USER_LOGIN,
    SET_IS_AUTH_USER,
    TOGGLE_MODAL,
    TOGGLE_MODAL_MODE
} from '../actions/auth';

/**
 * @type {Object}
 * @property {?string} userAuthAttemptId
 * @property {?string} userLogin
 * @property {boolean} isAuthUser
 */
const INITIAL_STATE = {
    userAuthAttemptId: null,
    userLogin: null,
    isAuthUser: false,
    isModalOpen: false,
    modalMode: true
};

const auth = (state = INITIAL_STATE, {type, payload}) => {
    switch (type) {
        case SET_USER_AUTH_ATTEMPT_ID:
            return {...state, userAuthAttemptId: payload};

        case SET_USER_LOGIN:
            return {...state, userLogin: payload};

        case SET_IS_AUTH_USER:
            return {...state, isAuthUser: payload};

        case TOGGLE_MODAL:
            return {...state, isModalOpen: payload};

        case TOGGLE_MODAL_MODE:
            return {...state, modalMode: payload};

        default:
            return state;
    }
};

export default auth;
