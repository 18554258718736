function getErrorWithNonEnumerableProps(key, value) {
    if (value instanceof Error) {
        return {
            ...value,
            name: value.name,
            message: value.message,
            // can be enabled when debugging complex errors
            // stack: value.stack,
        };
    }

    return value;
}

export function serializeError(error) {
    if (error instanceof Error || error.data instanceof Error) {
        return JSON.stringify(error, getErrorWithNonEnumerableProps);
    }

    return error;
}
