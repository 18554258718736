/* eslint-disable */
/* eslint-disable no-restricted-syntax, no-constant-condition */
import { takeLatest, call, fork, put, take, delay } from 'redux-saga/effects';
import moment from 'moment';
import { START_POLLING_DOPS, updateActualOrder, pollActualOrder, FETCH_ACTUAL } from 'actions/orders';
import { utairHttpManager as manager } from 'managers/utair';
import { API_URLS } from 'consts';

// Constants

const STORAGE_KEY = 'POLLING_DOPS';

// Requests

const pollRequest = (rloc, orderId) => manager.get(API_URLS.FLIGHTS.ORDER(rloc, orderId), null, true);

// Sagas

const pollingSaga = function* ({ payload: { rloc, orderId } }) {
    const pollingStack = getPollingStack();
    if (!pollingStack.some((x) => x.rloc === rloc && x.orderId === orderId)) {
        pollingStack.push({ rloc, orderId, timestamp: moment().valueOf() });
        setPollingStack(pollingStack);
    }

    while (true) {
        const { payload: orders } = yield take(FETCH_ACTUAL);
        const stack = getPollingStack();
        if (!stack.length) {
            return;
        }

        for (const order of stack) {
            if (orders.some((x) => x.rloc === order.rloc && x.order_id === order.orderId)) {
                yield fork(poll, order);
            }
        }
    }
};

const poll = function* ({ rloc, orderId, timestamp }) {
    yield put(pollActualOrder({ rloc, orderId }));

    while (true) {
        try {
            const order = yield call(pollRequest, rloc, orderId);
            console.log(`Order ID: ${order.order_id}, Rloc: ${order.rloc}, Status: ${order.status}`);

            if (moment().valueOf() - timestamp > 3 * 60 * 1000) {
                const pollingStack = getPollingStack();
                const index = pollingStack.findIndex((x) => x.orderId === orderId && x.rloc === rloc);
                pollingStack.splice(index, 1);
                setPollingStack(pollingStack);

                yield put(updateActualOrder(order));
                return;
            }
        } catch (e) {
            console.log('Polling exception: ', e);
        } finally {
            yield delay(5000);
        }
    }
};

const pollingDopsSaga = function* () {
    yield takeLatest(START_POLLING_DOPS, pollingSaga);
};

// Aux

const getPollingStack = () => JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');

const setPollingStack = (pollingStack) => localStorage.setItem(STORAGE_KEY, JSON.stringify(pollingStack));

export default pollingDopsSaga;
