import React from 'react';
import AdaptiveLayout from 'components/AdaptiveLayout';
import { Stepper } from 'checkin/shared/ui/Stepper';

import { STEPS } from './conts';

export function CheckInLayout({ children, params: { cartId = '' }, location: { pathname } }) {
    const step = pathname.split('/').reverse()[1];

    return (
        <AdaptiveLayout>
            <AdaptiveLayout.Stepper>
                <Stepper step={step} steps={STEPS} cartId={cartId} />
            </AdaptiveLayout.Stepper>

            {children}
        </AdaptiveLayout>
    );
}
