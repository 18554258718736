exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SignUpAgreement-module__agreementText--3s-i4{margin-top:12px;font-size:12px;line-height:16px}", ""]);

// exports
exports.locals = {
	"agreementText": "SignUpAgreement-module__agreementText--3s-i4"
};