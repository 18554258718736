/* eslint-disable */
import moment from 'moment';
import i18next from 'i18next';
import { isForeignPhone } from 'utils/phone';
import { EMAIL_REGEXP, LOYALTY_CARD } from 'consts';

const minBirthdayYear = 1900;

const dateFormat = 'DD.MM.YYYY';

const minBirthdayDate = moment('01.01.1900', dateFormat);

const parseIntFromDate = (date) => parseInt(date.format('x'), 10);

function validateRuPhone(phone) {
    if (phone.length !== 11) {
        return i18next.t('validator.errors.phone_format');
    }

    if (![3, 4, 5, 6, 8, 9].includes(Number(phone[1]))) {
        return i18next.t('validator.errors.phone_operator_code');
    }

    return true;
}

function validateForeignPhone(phone) {
    if (phone.length < 5 || phone.length > 15) {
        return i18next.t('validator.errors.phone_foreign_format');
    }

    return true;
}

function isValidDate(dateString, divider = '.') {
    const dateItems = dateString.split(divider);

    const day = Number(dateItems[0]);
    const month = Number(dateItems[1]) - 1;
    const year = Number(dateItems[2]);

    const testDate = new Date(year, month, day);

    const testYear = testDate.getFullYear();

    const isYearValid = testYear === year || (testYear === minBirthdayYear && year <= minBirthdayYear);

    return isYearValid && testDate.getMonth() === month && testDate.getDate() === day;
}

export const validator = {
    phone(value, isRequired = false) {
        const phoneReg = /[0-9]/g;
        const phone = value ? value.match(phoneReg) : null;

        if (phone === null) {
            return isRequired ? i18next.t('validator.errors.phone_required') : true;
        }

        const incorrectPhoneSymbols = /[^\d\s+()-]/g;

        if (value.match(incorrectPhoneSymbols)) {
            return isForeignPhone(value)
                ? i18next.t('validator.errors.phone_foreign_format')
                : i18next.t('validator.errors.phone_format');
        }

        return isForeignPhone(value) ? validateForeignPhone(phone) : validateRuPhone(phone);
    },

    email(value, isRequired) {
        if (value && value !== '') {
            if (!EMAIL_REGEXP.test(value)) {
                return i18next.t('validator.errors.email_format');
            }
        } else if (isRequired) {
            return i18next.t('validator.errors.email_required');
        }

        return true;
    },

    birthday(value, isRequired) {
        if (!value) {
            return isRequired ? i18next.t('validator.errors.birthday_required') : true;
        }

        const current = moment(value, dateFormat);
        const now = moment();
        const currentInt = parseIntFromDate(current);

        if (!isValidDate(value)) {
            return i18next.t('validator.errors.birthday_format');
        }

        if (currentInt > parseIntFromDate(now)) {
            return i18next.t('validator.errors.birthday_late', { date: now.format(dateFormat) });
        }

        if (currentInt < parseIntFromDate(minBirthdayDate)) {
            return i18next.t('validator.errors.birthday_early');
        }

        return true;
    },

    text(value, type, isRequired = false) {
        if (value && value !== '') {
            const reg = /^[а-яёa-z -]*$/i;

            if (!reg.test(value)) {
                return i18next.t(`validator.errors.${type}_format`);
            }

            if (value.length < 2) {
                return i18next.t(`validator.errors.${type}_length`);
            }
        } else if (isRequired) {
            return i18next.t(`validator.errors.${type}_required`);
        }

        return true;
    },

    // Old Add style validator. Switch to 'text'
    name(value, isRequired = false) {
        if (value && value !== '') {
            const reg = /^[а-яёa-z -]*$/i;

            if (!reg.test(value)) {
                return i18next.t('validator.errors.name_format');
            }

            if (value.length < 2) {
                return i18next.t('validator.errors.name_length');
            }
        } else if (isRequired) {
            return i18next.t('validator.errors.name_required');
        }

        return true;
    },

    code(value, length = 4, isConfirmationByCall) {
        if (!value || value.length < length) {
            return isConfirmationByCall
                ? i18next.t('validator.errors.call_required')
                : i18next.t('validator.errors.code_required');
        }

        if (!/\d{4}$/.test(value)) {
            return isConfirmationByCall
                ? i18next.t('validator.errors.call_incorrect')
                : i18next.t('validator.errors.code_incorrect');
        }

        return true;
    },

    card(value, isRequired = false) {
        const card = value.replace(/\s/g, '');
        // fixme по моему не правильно в валидаторе формата чекать состояние формы
        if (!isRequired && !card) return true;
        return LOYALTY_CARD.test(card) || i18next.t('validator.errors.status_card_incorrect');
    },

    login(value, checkCard = false) {
        if (!value && value === '') {
            return {
                valid: false,
                type: 'email',
                msg: i18next.t('validator.errors.login_incorrect'),
            };
        }

        const tests = {
            email: validator.email(value),
            phone: validator.phone(value, true),
            card: validator.card(value),
        };

        if (tests.email === true) {
            return {
                valid: true,
                type: 'email',
                msg: '',
            };
        }

        if (checkCard && tests.card === true) {
            return {
                valid: true,
                type: 'card',
                msg: '',
            };
        }

        if (tests.phone === true) {
            return {
                valid: true,
                type: 'phone',
                msg: '',
            };
        }

        return {
            valid: false,
            type: 'email',
            msg: i18next.t('validator.errors.login_incorrect'),
        };
    },

    locator(value, isRequired) {
        if (!value && !isRequired) {
            return true;
        }

        if (value.length < 6) {
            return i18next.t('validator.errors.locator_required');
        }

        return true;
    },

    flightNumber(value, isRequired) {
        if (!value && !isRequired) {
            return true;
        }

        if (value.length < 5) {
            return i18next.t('validator.errors.flightnumber_required');
        }

        return true;
    },
};
