import type { Moment } from 'moment';
import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { CustomError } from 'schedule/shared/api/createError';

import type { City } from './types';

export interface DataContextType {
    isDirect: boolean;
    departureCity: City | null;
    arrivalCity: City | null;
    nearestCity: City | null;
    departureDateFrom: Moment | null;
    departureDateTo: Moment | null;
    cities: City[];
    firstAvailableDate: Nullable<DateIso>;
}
export const initialDataState: DataContextType = {
    isDirect: false,
    departureCity: null,
    arrivalCity: null,
    nearestCity: null,
    departureDateFrom: null,
    departureDateTo: null,
    cities: [],
    firstAvailableDate: null,
};
export const DataContext = createContext<DataContextType>(initialDataState);

export interface ActionContextType {
    setData: Dispatch<SetStateAction<DataContextType>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setError: Dispatch<SetStateAction<CustomError | null>>;
}
const initialActionState: ActionContextType = {
    setData: () => {
        throw new Error("Don't use default value");
    },
    setIsLoading: () => {
        throw new Error("Don't use default value");
    },
    setError: () => {
        throw new Error("Don't use default value");
    },
};
export const ActionContext = createContext<ActionContextType>(initialActionState);

export interface QueryContextType {
    isLoading: boolean;
    error: CustomError | null;
}
const initialQueryState = {
    isLoading: false,
    error: null,
};
export const QueryContext = createContext<QueryContextType>(initialQueryState);
