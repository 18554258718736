exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Layout-module__title--33FQb{color:#fff;font-size:40px;font-style:normal;font-weight:400;line-height:48px}@media screen and (max-width: 600px){.Layout-module__title--33FQb{font-size:20px;font-weight:400;line-height:28px;text-align:left}}.Layout-module__root--3-yeg{display:flex;flex-direction:column;flex-wrap:nowrap;background-color:#ebeef3;width:100%;height:100%;flex:1 0 0}.Layout-module__header--2TdW1,.Layout-module__footer--1j9Vv{background-color:#003594;flex-shrink:0}.Layout-module__content--2WY4S{flex:1 1 auto;overflow:auto;min-height:66vmin}.Layout-module__footer--1j9Vv{background-color:#003594;color:rgba(255,255,255,.7)}", ""]);

// exports
exports.locals = {
	"title": "Layout-module__title--33FQb",
	"root": "Layout-module__root--3-yeg",
	"header": "Layout-module__header--2TdW1",
	"footer": "Layout-module__footer--1j9Vv",
	"content": "Layout-module__content--2WY4S"
};