/* eslint-disable */
import { takeEvery, all } from 'redux-saga/effects';
import {RESET_CACHE} from 'actions/reset-cache';
import {deleteAllCookies} from '../utils/delete-all-cookies';

function* resetCache() {
    yield localStorage.clear();
    yield deleteAllCookies();
}

function* resetCacheSaga() {
    yield all([takeEvery(RESET_CACHE, resetCache)]);
}

export default resetCacheSaga;
