/* eslint-disable */
/* eslint react/no-danger: off */
import React from 'react';
import classNames from 'classnames';
import Swipeable from 'react-swipeable';

export default class Message extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            closingClass: ''
        };

        this.timeout = 0;
    }

    transitionEndHandler = () => {
        const {id, isClosed, removeHandler} = this.props;

        if (isClosed) {
            removeHandler(id);
        }
    };

    swipingEndHandler = (e, deltaX) => {
        this.setState({
            closingClass: deltaX > 0 ? 'hide-left' : 'hide-right'
        });

        setTimeout(() => {
            const {id, removeHandler} = this.props;

            removeHandler(id);
        }, 400);
    };

    render() {
        const {id, type, text, isClosed, onClose, swipeEnabled} = this.props;
        const {closingClass} = this.state;

        return (
            <Swipeable onSwiped={this.swipingEndHandler}>
                <div
                    className={classNames('sysmessages-message', `${type}`, closingClass, {
                        'is-hidden': closingClass || isClosed,
                        'hide-right': swipeEnabled && !closingClass
                    })}
                    onTransitionEnd={this.transitionEndHandler}
                    style={{zIndex: 9999 + id}}
                >
                    <div
                        className="sysmessages-messageText"
                        dangerouslySetInnerHTML={{
                            __html: text
                        }}
                    />
                    <button className="sysmessages-messageClose" onClick={onClose} />
                </div>
            </Swipeable>
        );
    }
}
