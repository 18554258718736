/* eslint-disable */
import { AB } from 'constants/ab';
import {cacheManager} from 'utils/cache-manager';

const getTest = (testName) => AB.find((test) => test.name === testName);

const getRandomVariant = (test) => {
    const fullWeight = test.variants.reduce((acc, variant) => acc + variant.weight, 0);
    let currentWeight = Math.random() * fullWeight;

    return test.variants.find((variant) => {
        currentWeight -= variant.weight;
        return currentWeight <= 0;
    });
};

const setTestValue = (cacheName, value, lifetime) => {
    cacheManager.setItem(cacheName, value, lifetime);
};

export const getABTestValue = (testName) => {
    const test = getTest(testName);

    if (!test) {
        return null;
    }

    const cacheName = `ab_${test.name}`;
    const cachedValue = cacheManager.getItem(cacheName);

    if (cachedValue !== null) {
        return cachedValue;
    }

    const randomVariant = getRandomVariant(test);
    const randomValue = randomVariant.value;

    setTestValue(cacheName, randomValue, test.lifeTime);

    return randomValue;
};
