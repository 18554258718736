/* eslint-disable max-len */
export const errors = {
    receive_data: 'Ошибка получения данных',
    initials_not_editable: 'Вы можете редактировать только фамилию',
    empty_name: 'Пожалуйста, введите фамилию, имя и отчество',
    incorrect_address: 'должен содержать название населенного пункта и улицы.',
    email_exists: 'Такой email уже зарегистрирован',
    phone_exists: 'Такой номер телефона уже зарегистрирован',
    contact_exists: 'Контакт подтверждён в профиле другого пользователя. Пожалуйста, обратитесь в\u00A0Службу поддержки',
    verify_code_error: 'Проверьте правильность кода или отправьте код еще раз',
    minutes_number_0: '{{count}} минуту',
    minutes_number_1: '{{count}} минуты',
    minutes_number_2: '{{count}} минут',
    promocode: {
        fail: 'ошибка применения промокода',
        notFound: 'Промокод не найден',
        seriesNotFound: 'Акция приостановлена',
        seriesFinished: 'Акция завершена',
        seriesInactive: 'Акция неактивна',
        promoCodeSpend: 'Промокод был использован ранее',
        wrongPromoType: 'Этот промокод может быть использован только в бронировании авиабилетов'
    }
};
