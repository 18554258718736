/* eslint-disable */
import get from 'lodash/get';

import { loginConfirm as authLoginConfirm, login as authLogin } from 'actions/auth';

import { utairHttpManager as request } from 'managers/utair';

import i18next from 'i18next';

import { API_URLS } from 'consts';
import { ERROR_TYPES } from 'constants/errorTypes';

import { getUserLogin, getCheckType, getGDPRAgreementDate } from 'selectors/sign';
import { getSocialData } from 'components/Account/social/selectors';

/**
 * Reducer constants
 */
export const SET_STEP = 'SET_STEP';
export const SET_LOGIN = 'SET_LOGIN';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_CODE = 'SET_CODE';
export const SET_CODE_ERROR = 'SET_CODE_ERROR';
export const GETTING_TOKEN_FAILED = 'GETTING_TOKEN_FAILED';
export const SET_SIGN_BUSY = 'SET_SIGN_BUSY';
export const SET_CHECK_TYPE = 'SET_CHECK_TYPE';
export const SET_CODE_MESSAGE = 'SET_CODE_MESSAGE';
export const CLEAR_SIGN_FORM = 'CLEAR_SIGN_FORM';
export const SET_GDPR_AGREEMENT_DATE = 'SET_GDPR_AGREEMENT_DATE';
export const SET_CONFIRMATION_TYPE = 'SET_CONFIRMATION_TYPE';

export const setStep = (step) => (dispatch) => {
    dispatch({
        type: 'SET_STEP',
        step,
    });
};

export const setLogin = (login) => (dispatch) => {
    dispatch({
        type: SET_LOGIN,
        login,
    });
};

export const setLoginError = (error) => (dispatch) => {
    dispatch({
        type: SET_LOGIN_ERROR,
        error,
    });
};

export const setCode = (code) => (dispatch) => {
    dispatch({
        type: SET_CODE,
        code,
    });
};

export const setCodeError = (error) => (dispatch) => {
    dispatch({
        type: SET_CODE_ERROR,
        error,
    });
};

export const signGettingTokenFailed = () => ({
    type: GETTING_TOKEN_FAILED,
});

export const setBusy = (isBusy) => (dispatch) => {
    dispatch({
        type: SET_SIGN_BUSY,
        isBusy,
    });
};

/**
 * Set authentication type
 * @param {string} checkType(email|phone)
 */
export const setCheckType = (checkType) => (dispatch) => {
    dispatch({
        type: SET_CHECK_TYPE,
        checkType,
    });
};

export const setConfirmationType = (confirmationType) => ({
    type: SET_CONFIRMATION_TYPE,
    payload: confirmationType,
});

export const _clearErrors = () => (dispatch) => {
    dispatch(setCodeError(''));
    dispatch(setLoginError(''));
};

/**
 * Clear all input fields and error
 */
export const resetForm = () => (dispatch) =>
    dispatch({
        type: CLEAR_SIGN_FORM,
    });

/**
 * Start user sign chain
 * @param {String} referralLinkId - Referral link Id
 * @return {Object} - On sign success return login, else return error message
 */
export const registerUser = (referralLinkId) => (dispatch, getState) => {
    const state = getState();
    const login = getUserLogin(state);
    const GDPRAgreementDate = getGDPRAgreementDate(state);
    let type = getCheckType(state);
    // HOTFIX из-за того, что у нас криво определяется тип
    // логина происходит ошибка при регистрации
    // проблема с определением типа логина будет исправлена в https://jira.utair.ru/browse/UTFRNT-2256.
    if (type === 'card') {
        type = 'phone';
    }

    dispatch(setBusy(true));

    return _registration({ type, login, referralLinkId, GDPRAgreementDate })
        .then((login) => {
            dispatch(loginAction(login));
            dispatch(setStep(2));
            dispatch(setBusy(false));
        })
        .catch((err) => dispatch(_registrationErrorHandler(err)));
};

/**
 * Fetch user sign request
 * @param {String} [type=(email|phone)] - Type of sign
 * @param {String} login - User login
 * @param {Number} GDPRAgreementDate - Date(unix timestamp) when user accepts GDPR
 * @param {String} referralLinkId - Referral link Id
 * @param socialData
 * @return {String} - Registred login
 */
export const _registration = ({ type, login, GDPRAgreementDate, socialData = null, referralLinkId = null }) => {
    let params = {};

    if (referralLinkId) {
        params.referralLinkId = referralLinkId;
    }

    if (type === 'phone') {
        params.phone = login;
    } else {
        params.email = login;
    }

    if (socialData) {
        const tmp = {};

        tmp[socialData.network] = {
            ident: socialData.uid,
            token: socialData.access_token,
        };

        params.social = tmp;

        params = Object.assign({}, params, socialData);
    }

    if (GDPRAgreementDate) {
        params.confirmationGDPRDate = GDPRAgreementDate;
    }

    return request.post(API_URLS.PROFILE.USER, params).then(() => login);
};

/**
 * Sending confirm code request
 * @param login
 */

export const confirmCode = (login) => (dispatch, getState) => {
    const socialData = getSocialData(getState());

    dispatch(setBusy(true));

    return dispatch(authLoginConfirm(login))
        .then((response) => {
            if (socialData) {
                _registration({
                    type: 'email',
                    login: socialData.email,
                    socialData,
                });
            }
            dispatch(setBusy(false));
            return response;
        })
        .catch((err) => dispatch(_confirmCodeErrorHandler(err)));
};

/**
 * Init Sign in proccess
 * @param {string} login
 */
export const loginAction = (login, type) => (dispatch, getState) => {
    dispatch(setBusy(true));

    const login = login || getUserLogin(getState());
    const type = type || getCheckType(getState());

    dispatch({
        type: SET_CODE_MESSAGE,
        codeMessage: '',
    });

    dispatch(_clearErrors());

    return dispatch(authLogin(login))
        .then((data) => {
            dispatch({
                type: SET_CODE_MESSAGE,
                codeMessage: i18next.t(`sign.code_sended_to_${data.channel}`),
            });

            dispatch(setStep(2));
            dispatch(setBusy(false));
        })
        .catch((err) => dispatch(_loginErrorHandler(err, type)));
};

/**
 * Error handlers
 */

const _confirmCodeErrorHandler = (error) => (dispatch) => {
    /**
     * Так как обработчик ошибок срабатывает на вызов разных методов то объект ошибки
     *  может быть разным
     */
    const url = get(error, 'url');
    const status = get(error, 'status');

    /**
     * Если от метода API_URLS.AUTH.TOKEN пришел статус 401 значит не дошел
     *  Authorization заголовок
     *  @link https://jira.utair.ru/browse/UTFRNT-1060
     */
    if (status === 401 && url.includes(API_URLS.AUTH.TOKEN)) {
        dispatch(signGettingTokenFailed());
        dispatch(setBusy(false));
        throw error;
    }

    try {
        switch (error.code) {
            case ERROR_TYPES.FORBIDDEN:
                dispatch(setCodeError(i18next.t('sign.error.too_many_requests')));
                break;

            case ERROR_TYPES.INTERNAL_SERVER_ERROR:
                dispatch(setCodeError(i18next.t('common.unknown_error')));
                break;

            default:
                dispatch(setCodeError(i18next.t('sign.error.incorrect_code')));
                break;
        }
    } catch (e) {
        console.error(e);
        dispatch(setCodeError(i18next.t('common.unknown_error')));
    }

    dispatch(setBusy(false));

    throw error;
};

const _registrationErrorHandler = (error) => (dispatch, getState) => {
    try {
        const socialData = getSocialData(getState());
        const { checkType } = getState().sign;

        switch (error.code) {
            case ERROR_TYPES.CONFLICT:
                if (!socialData) {
                    dispatch(setLoginError(i18next.t(`sign.error.social_${checkType}_exists`)));

                    dispatch(setBusy(false));
                } else {
                    dispatch(setBusy(false));
                }

                break;

            case ERROR_TYPES.BAD_REQUEST:
                dispatch(setBusy(false));

                dispatch(setLoginError(i18next.t('validator.errors.phone_format')));

                break;

            case ERROR_TYPES.INTERNAL_SERVER_ERROR:
                dispatch(setBusy(false));

                dispatch(setCodeError(i18next.t('common.unknown_error')));

                break;

            default:
                dispatch(setBusy(false));

                dispatch(setLoginError(i18next.t('sign.error.api_error')));

                break;
        }
    } catch (e) {
        dispatch(setBusy(false));

        console.error(e);

        dispatch(setLoginError(i18next.t('common.unknown_error')));
    }

    throw error;
};

const _loginErrorHandler = (error, type) => (dispatch) => {
    const errorCode = get(error, 'data.meta.error_code');

    switch (errorCode) {
        case 40101:
            dispatch(setLoginError(i18next.t('sign.error.login_not_found')));
            break;

        case 40104:
            dispatch(setLoginError(i18next.t('sign.error.phone_disabled')));
            break;

        case 40301:
            dispatch(setLoginError(i18next.t('sign.error.too_many_requests')));
            break;

        case 40302:
        case 40303:
            dispatch(setLoginError(i18next.t('sign.error.user_blocked')));
            break;

        case 40031:
            dispatch(setLoginError(i18next.t('sign.error.send_code_error')));
            break;

        case 40030:
            dispatch(setLoginError(i18next.t('sign.error.phone_incorrect')));
            break;

        // card auth
        case 40004:
            dispatch(setLoginError(i18next.t('sign.error.no_contacts')));
            break;

        default:
            dispatch(setLoginError(i18next.t('common.unknown_error')));
    }

    dispatch(setBusy(false));

    throw error;
};

export const setGDPRAgreementDate = (data) => ({
    type: SET_GDPR_AGREEMENT_DATE,
    payload: data,
});
