export const OPEN_CHAT_WIDGET_WITH_CONTEXT = 'OPEN_CHAT_WIDGET_WITH_CONTEXT';
export const OPEN_CHAT_WIDGET = 'OPEN_CHAT_WIDGET';
export const CLOSE_CHAT_WIDGET = 'CLOSE_CHAT_WIDGET';
export const SET_CHAT_WIDGET_CONTEXT = 'SET_CHAT_WIDGET_CONTEXT';
export const CLEAR_CHAT_WIDGET_CONTEXT = 'CLEAR_CHAT_WIDGET_CONTEXT';

export const openChatWidgetWithContext =
    (context = null) =>
    (dispatch) =>
        dispatch({
            type: OPEN_CHAT_WIDGET_WITH_CONTEXT,
            payload: context,
        });

export const openChatWidget = () => (dispatch) =>
    dispatch({
        type: OPEN_CHAT_WIDGET,
    });

export const closeChatWidget = () => (dispatch) =>
    dispatch({
        type: CLOSE_CHAT_WIDGET,
    });

export const clearChatWidgetContext = () => (dispatch) =>
    dispatch({
        type: CLEAR_CHAT_WIDGET_CONTEXT,
    });
