/* eslint-disable prefer-destructuring */
/* eslint-disable no-throw-literal */

import _ from 'lodash';

import { getMillisecondsFromDate } from './formatter';
import { APP_ERROR_CODES } from '../constants/errorTypes';

export const parseUserInitials = (name, revert = false) => {
    const initials = name.split(' ').filter((v) => v !== '');

    const res = {
        name: '',
        surname: '',
    };

    if (revert) {
        if (initials[0]) res.name = initials[0];
        if (initials.slice(-1)) res.surname = initials.slice(-1).join(' ');
        if (initials.slice(1, -1)) res.lastname = initials.slice(1, -1).join(' ');
    } else {
        if (initials[1]) res.name = initials[1];
        if (initials[0]) res.surname = initials[0];
        if (initials.length > 1) res.lastname = initials.slice(2).join(' ');
    }

    return res;
};

export const normalizeSpaces = (initial) => (initial || '').trim().replace(/\s+/g, ' ');

/**
 * Normalize username using saved initials or name string
 * @param sourceName
 * @param initials
 * @returns {Object}
 */
export const normalizeName = (sourceName, initials) => {
    const { name, secondName, lastname, surname } = initials || parseUserInitials(sourceName);

    if (!name || !surname) {
        throw {
            data: {
                meta: { error_code: APP_ERROR_CODES.EMPTY_USER_NAME },
            },
        };
    }

    const normalizedPatronymic = normalizeSpaces(secondName || lastname);

    const secondNameData = normalizedPatronymic ? { secondName: normalizedPatronymic } : {};

    return {
        name: normalizeSpaces(name),
        surname: normalizeSpaces(surname),
        ...secondNameData,
    };
};

/**
 * Normalize user fields to fetch
 * @param {Object} userFields - fields
 * @return {Object} - prepared fields
 */
export const normalizeUser = (userFields) => {
    const { birthday, name, email, phone, address } = userFields;

    if (!birthday && !email && !phone && !address) {
        return userFields;
    }

    const userBirthdayData = birthday ? { birthday: getMillisecondsFromDate(birthday) } : {};

    const userConfirmInitialsData = name ? { confirmInitials: true } : {};

    const userAddressData = address
        ? {
              address: {
                  address: userFields.address,
                  zipCode: '',
                  country: 'RUS',
                  city: '',
              },
          }
        : {};

    return {
        ...userFields,
        ...userBirthdayData,
        ...userConfirmInitialsData,
        ...userAddressData,
    };
};

export const normalizeTransactions = (transactions) =>
    _.map(transactions, (transaction) => {
        let operation = transaction.type.toLowerCase();

        if (operation === 'redemption') {
            switch (transaction.partner_code) {
                case 'CONVERSION':
                    operation = 'conversion';
                    break;
                case 'EXP':
                    operation = 'expiration';
                    break;
                case 'IPS':
                    operation = !transaction.rloc ? 'nar_ips' : 'nar_order';
                    break;
                default:
                    operation = 'redemption';
            }
        }

        return {
            ...transaction,
            operation,
        };
    });

export const normalizeRLOC = (rloc) => {
    if (!rloc) {
        return null;
    }

    return rloc.split('/').shift();
};
