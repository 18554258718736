import { call, put, takeLatest } from 'redux-saga/effects';
import { utairHttpManager as request } from 'managers/utair';
import {
    FETCH_USER_ORDERS_CHECKIN_REQUEST,
    FETCH_USER_ORDERS_CHECKIN_SUCCESS,
    FETCH_USER_ORDERS_CHECKIN_FAILURE,
} from 'actions/checkin';
import { API_URLS } from 'consts';
import { ERROR_TYPES } from 'constants/errorTypes';

function requestUserOrdersCheckin(isUserAuth) {
    return request.get({ url: API_URLS.ORDERS_V2.CHECKIN, isUserAuth }).then((response) => response.objects || []);
}

function* worker(action) {
    const { isUserAuth } = action.payload;

    try {
        let respData = [];
        const response = yield call(requestUserOrdersCheckin, isUserAuth);

        if (response.length && response.every((x) => x.segments.length)) {
            respData = response;
        }

        yield put({
            type: FETCH_USER_ORDERS_CHECKIN_SUCCESS,
            payload: respData,
        });
    } catch (e) {
        console.error(e); // eslint-disable-line no-console

        if (e.code === ERROR_TYPES.INTERNAL_SERVER_ERROR) {
            yield put({
                type: FETCH_USER_ORDERS_CHECKIN_SUCCESS,
                payload: [],
            });
        } else {
            yield put({ type: FETCH_USER_ORDERS_CHECKIN_FAILURE });
        }
    }
}

export function* UserOrdersCheckinWatcher() {
    yield takeLatest(FETCH_USER_ORDERS_CHECKIN_REQUEST, worker);
}
