import { every, find } from 'lodash';
import moment from 'moment';
import url from 'url';
import { API_URLS, URLS } from 'consts';
import { utairHttpManager as request } from 'managers/utair';
import { getABTestValue } from 'utils/ab-manager';
import pushMetricsEvent from 'actions/metrics';
import { METRICS_EVENT_NAMES } from 'constants/metrics';

import { getLanguage } from '../locales';

const ACTIONS_PREFIX = 'actions/checkin/';
export const SET_CHECKIN_LOCATOR = `${ACTIONS_PREFIX}SET_CHECKIN_LOCATOR`;
export const SET_CHECKIN_LASTNAME = `${ACTIONS_PREFIX}SET_CHECKIN_LASTNAME`;
export const SET_CHECKIN_FLIGHT = `${ACTIONS_PREFIX}SET_CHECKIN_FLIGHT`;
export const SET_IS_MODAL_OPEN = `${ACTIONS_PREFIX}SET_IS_MODAL_OPEN`;
export const SET_IS_BOARDING_PASS_MODAL_OPEN = `${ACTIONS_PREFIX}SET_IS_BOARDING_PASS_MODAL_OPEN`;
export const SET_IS_FLIGHT_NOT_FOUND = `${ACTIONS_PREFIX}SET_IS_FLIGHT_NOT_FOUND`;
export const SET_ONLY_OFFLINE_REGISTRATION = `${ACTIONS_PREFIX}SET_ONLY_OFFLINE_REGISTRATION`;
export const SET_IS_BOOKING_NOT_FOUND = `${ACTIONS_PREFIX}SET_IS_BOOKING_NOT_FOUND`;
export const SET_IS_FAILURE = `${ACTIONS_PREFIX}SET_IS_FAILURE`;
export const SET_ORDER_DATA = `${ACTIONS_PREFIX}SET_ORDER_DATA`;
export const FETCH_USER_ORDERS_CHECKIN_REQUEST = `${ACTIONS_PREFIX}FETCH_USER_ORDERS_CHECKIN_REQUEST`;
export const FETCH_USER_ORDERS_CHECKIN_SUCCESS = `${ACTIONS_PREFIX}FETCH_USER_ORDERS_CHECKIN_SUCCESS`;
export const FETCH_USER_ORDERS_CHECKIN_FAILURE = `${ACTIONS_PREFIX}FETCH_USER_ORDERS_CHECKIN_FAILURE`;
export const FETCH_ORDERS_CHECKIN_REQUEST = `${ACTIONS_PREFIX}FETCH_ORDERS_CHECKIN_REQUEST`;
export const FETCH_ORDERS_CHECKIN_SUCCESS = `${ACTIONS_PREFIX}FETCH_ORDERS_CHECKIN_SUCCESS`;
export const FETCH_ORDERS_CHECKIN_FAILURE = `${ACTIONS_PREFIX}FETCH_ORDERS_CHECKIN_FAILURE`;
export const START_FETCHING = `${ACTIONS_PREFIX}START_FETCHING`;
export const FINISH_FETCHING = `${ACTIONS_PREFIX}FINISH_FETCHING`;
export const START_TRANSITION_TO_REGISTRATION = `${ACTIONS_PREFIX}START_TRANSITION_TO_REGISTRATION`;

export const setCheckinLocator = (locator) => ({
    type: SET_CHECKIN_LOCATOR,
    payload: locator,
});

export const setCheckinLastName = (lastName) => ({
    type: SET_CHECKIN_LASTNAME,
    payload: lastName,
});

export const setCheckinFlight = (flight) => ({
    type: SET_CHECKIN_FLIGHT,
    payload: flight,
});

export const setIsOpenModal = (isOpen) => ({
    type: SET_IS_MODAL_OPEN,
    payload: isOpen,
});

export const setIsOpenBoardingPassModal = (isOpen) => ({
    type: SET_IS_BOARDING_PASS_MODAL_OPEN,
    payload: isOpen,
});

export const setIsFlightNotFound = (isOpen) => ({
    type: SET_IS_FLIGHT_NOT_FOUND,
    payload: isOpen,
});

export const setOnlyOfflineRegistrationMessage = (message) => ({
    type: SET_ONLY_OFFLINE_REGISTRATION,
    payload: message,
});

export const setIsBookingNotFound = (isOpen) => ({
    type: SET_IS_BOOKING_NOT_FOUND,
    payload: isOpen,
});

export const setIsFailure = (isOpen) => ({
    type: SET_IS_FAILURE,
    payload: isOpen,
});

export const setOrderData = (data) => ({
    type: SET_ORDER_DATA,
    payload: data,
});

export const setStartTransitionToRegistration = () => ({
    type: START_TRANSITION_TO_REGISTRATION,
});

const getAvailableBusinessSeatsCount = (segment) => {
    const params = {
        departure: segment.departure_airport_code,
        arrival: segment.arrival_airport_code,
        date: segment.departure_date,
        flight: segment.flight_number,
        company: segment.ak,
    };

    return request.get(API_URLS.FLIGHTS.AVAILABLE_SEATS, params, false).then((seats) => seats.business);
};

export const openCheckinPage = (flightnumber, lastName, ticket) => (dispatch) => {
    dispatch(setStartTransitionToRegistration());
    const lang = getLanguage();
    const newSeats = getABTestValue('newSeats');
    const baseQuery = url.format({
        query: {
            flightnumber: flightnumber.toUpperCase().replace(/[A-ZА-Я]/gi, ''),
            lastname: lastName,
            ticket,
            utm_source: 'main_desktop',
            new_seats: newSeats,
            sid: 'Momondo_UT',
        },
    });

    // dataForTais - хранит в себе все query параметры, так надо для интеграции с ТАИС https://jira.utair.ru/browse/UTFRNT-181
    const dataForTais = url
        .format({
            query: { data: baseQuery.replace('?', '') },
        })
        .replace('?', '');
    const params = `${baseQuery}&${dataForTais}`;

    window.location.href = URLS.CHECK_IN(params, lang);
};

export const checkinFlight = (doc, lastName, flight) => (dispatch) => {
    // Период времени до начала регистрации до которого доступен апгрейд, в секундах (1 час)
    const AVAILABLE_TIME = 60 * 60;
    const START_SELLS_INTERVAL = 36 * 60 * 60;

    if (!every([doc, lastName, flight])) {
        return false;
    }

    dispatch(setCheckinFlight(flight));
    dispatch(setCheckinLocator(doc));
    dispatch(setCheckinLastName(lastName));
    dispatch({ type: START_FETCHING });

    return request
        .get({
            url: API_URLS.ORDERS.ORDER_BY_RLOC_AND_LAST_NAME(doc, lastName),
            jsonQuery: { flight_num: flight },
            query: { filters: ['segments', 'tickets_active', 'tickets_returned'] },
        })
        .then((order) => {
            const segment = find(order.segments, { flight_number: flight }) || order.segments[0];

            if (!segment.is_valid_for_upgrade) {
                return 0;
            }

            if (segment.class === 'C') {
                return 0;
            }

            const secondsBeforeDeparture = Number(segment.departure_unixtime) - moment.utc().unix();

            if (secondsBeforeDeparture < AVAILABLE_TIME || secondsBeforeDeparture > START_SELLS_INTERVAL) {
                return 0;
            }

            dispatch(setOrderData(order));

            return getAvailableBusinessSeatsCount(segment);
        })
        .then((availableBusinessSeatsCount) => {
            if (availableBusinessSeatsCount > 0) {
                dispatch(
                    pushMetricsEvent(METRICS_EVENT_NAMES.UPGRADE_AVAILABLE_SHOWN, {
                        available_business: availableBusinessSeatsCount,
                    })
                );
                dispatch({ type: FINISH_FETCHING });

                return dispatch(setIsOpenModal(true));
            }

            return dispatch(openCheckinPage(flight, lastName, doc));
        })
        .catch((error) => {
            console.error(error); // eslint-disable-line no-console
            dispatch(openCheckinPage(flight, lastName, doc));
        });
};

export const fetchBoardingPass = (astraPassId) => () =>
    request.post(API_URLS.CHECK_IN.BOARDING_PASS, { passenger_id: astraPassId });

export const searchUserOrders = (isUserAuth) => ({
    type: FETCH_USER_ORDERS_CHECKIN_REQUEST,
    payload: { isUserAuth },
});

export const searchOrder = ({ locator, lastName }) => ({
    type: FETCH_ORDERS_CHECKIN_REQUEST,
    payload: { locator, lastName },
});
