import { utairHttpManager as request } from 'managers/utair';
import { API_URLS } from 'consts';
import * as TYPES from 'constants/userTypes';

export const updateUserBenefits = (data) => ({
    type: TYPES.SET_AVAILABLE_BENEFITS,
    data,
});

export const mapUserBenefits = (data) => {
    if (!data || Object.keys(data).length === 0) return [];

    return data.types_of_benefits.map((benefit) => benefit.toUpperCase());
};

export const fetchUserBenefits = () => request.get(API_URLS.PROFILE.AVAILABLE_BENEFITS, null, true);
