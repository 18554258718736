export const validator = {
    errors: {
        birthday_format: 'Некорректно указана дата рождения',
        birthday_required: 'Пожалуйста, укажите дату рождения',
        birthday_late: 'Дата рождения не может быть позже {{date}}',
        birthday_early: 'Дата рождения не может быть раньше 01.01.1900',
        call_incorrect: 'Некорректные последние 4 цифры номера входящего звонка',
        call_required: 'Введите последние 4 цифры номера входящего звонка',
        checkinDoc_required: 'Укажите номер документа, или номер заказа, или номер билета',
        code_required: 'Введите код подтверждения',
        code_incorrect: 'Некорректный код подверждения',
        dates_required: 'Выберите дату',
        ticket_type_required: 'Выберите тип билета',
        destCity_required: 'Укажите город прибытия',
        differentCities_required: 'Укажите разные города',
        incorrect_city_text: 'Укажите город используя латинские или русские буквы',
        docvalid_format: 'Некорректно указана дата',
        docvalid_required: 'Пожалуйста, укажите дату',
        email_format: 'Пожалуйста, введите корректный адрес эл. почты',
        email_required: 'Пожалуйста, укажите Email',
        firstName_format: 'Имя может содержать только буквы, пробел или тире',
        firstName_required: 'Укажите имя',
        firstName_length: 'Имя должно быть больше 1 символа',
        flightnumber_required: 'Укажите номер рейса',
        fromCity_required: 'Укажите город отправления',
        lastName_format: 'Фамилия может содержать только буквы, пробел или тире',
        lastName_required: 'Укажите фамилию',
        lastName_length: 'Фамилия должна быть больше 1 символа',
        locator_required: 'Укажите номер паспорта, или номер заказа, или номер билета',
        login_incorrect: 'Пожалуйста, введите корректно ваш логин',
        name_format: 'Имя может содержать только буквы, пробел или тире',
        name_required: 'Укажите фамилию, имя и отчество',
        name_length: 'Ф.И.О должно быть больше 1 символа',
        phone_format: 'Пожалуйста, введите номер телефона в формате +Х (ХХХ) ХХХ-ХХ-ХХ',
        phone_foreign_format: 'Пожалуйста, введите номер телефона, 5-15 цифр',
        phone_required: 'Пожалуйста, укажите Ваш номер телефона',
        phone_operator_code: 'Код города/оператора должен начинаться с цифры 3, 4, 5, 6, 8, 9',
        status_card_incorrect: 'Номер карты должен быть 10, 11, 13 или 16 цифр',
    },
};
