import { type StatusesWieght } from './types';

export const UNKNOWN = 'UNKNOWN';

export const STATUSES_WEIGHT: StatusesWieght = {
    [UNKNOWN]: 0,
    START: 10,
    BASIC: 20,
    BRONZE: 30,
    SILVER: 40,
    GOLD: 50,
    PLATINUM: 60,
};
