import React from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { AVATARS } from 'constants/avatars';
import { formatNumber, getCountForPlural } from 'utils';
import { ReactComponent as ProfileIcon } from 'icons/interface/outlined-profile.svg';
import { getBalance } from 'selectors/bonuses';
import { useLocale } from 'locales/index';
import classNames from 'classnames';

import {
    getUserData,
    getUserAvatar,
    getUserName as getUserNameSelector,
    getUserLastName,
} from '../../../selectors/user';
import styles from './Header.module.scss';

export default function ProfileLink({ isMobile = false, isIndexRoute = false, isBonusesBalanceError = false }) {
    const avatar = useSelector(getUserAvatar);

    const balance = useSelector(getBalance);

    const actualBalance = balance.familyMilesCount || balance.milesCount;

    const userData = useSelector(getUserData);

    const firstName = useSelector(getUserNameSelector);

    const lastName = useSelector(getUserLastName);

    const { routePrefix } = useLocale();

    const getUserName = () => {
        if (!userData) {
            return i18next.t('header.welcome');
        }

        const { email, phone } = userData;

        return firstName && lastName ? `${firstName} ${lastName.charAt(0)}.` : email || phone;
    };

    return (
        <div className={isMobile ? styles.mobileProfileLink : 'header-user'}>
            <a href={isMobile ? '/m/user' : `${routePrefix}user`}>
                {isMobile && (
                    <div className={styles.userAvatarWrapper}>
                        {avatar ? (
                            <img src={`data:image/jpeg;base64,${avatar}`} alt="" />
                        ) : (
                            <ProfileIcon className={!isIndexRoute ? styles.mobileProfileLinkFilled : ''} />
                        )}
                        {isBonusesBalanceError && (
                            <div
                                className={classNames(
                                    styles.balanceErrorIndicator,
                                    !isIndexRoute && styles.balanceErrorIndicatorLight
                                )}
                            />
                        )}
                    </div>
                )}
                {!isMobile && (
                    <img
                        src={`data:image/jpeg;base64,${avatar || AVATARS[0]}`}
                        className="inlineBlock-top"
                        style={{ width: '1.5em', height: '1.5em' }}
                        alt=""
                    />
                )}
                <div className="inlineBlock-top phonablet-hide">
                    {getUserName()}
                    {!!actualBalance && (
                        <span className="header-userBonus">
                            {formatNumber(actualBalance)}{' '}
                            {i18next.t('common.mile_interval', {
                                postProcess: 'interval',
                                count: getCountForPlural(actualBalance),
                            })}
                        </span>
                    )}
                    {balance.deposits &&
                        balance.deposits.map(
                            (deposit) =>
                                deposit.balance > 0 && (
                                    <span className="header-userBonus" key={deposit.depositId}>
                                        {`${deposit.balanceString} `}

                                        {i18next.t('common.for_refund')}
                                    </span>
                                )
                        )}
                </div>
            </a>
        </div>
    );
}
