/* eslint-disable camelcase */
import { booking } from './booking';
import { check_in } from './check-in';
import { common } from './common';
import { companions } from './companions';
import { footer } from './footer';
import { header } from './header';
import { nav_menu_links } from './nav_menu_links';
import { main_page } from './main-page';
import { flights_search } from './flights-search';
import { sign } from './sign';
import { social_auth } from './social-auth';
import { validator } from './validator';
import { notifications } from './notifications';
import { onlinePanel } from './online-panel';

export default {
    booking,
    check_in,
    common,
    companions,
    footer,
    flights_search,
    header,
    nav_menu_links,
    main_page,
    sign,
    social_auth,
    validator,
    notifications,
    onlinePanel,
};
