/* eslint-disable */
import React from 'react';
import i18next from 'i18next';

import { GlobalPreloader } from 'components/common';

const Fallback = () => (
    <div className="text-center !mt40">
        <p className="big !mb40">{i18next.t('common.unknown_error')}</p>
        <button type="button" className="Button" onClick={() => window.location.reload()}>
            {i18next.t('common.reload')}
        </button>
    </div>
);

class DynamicImport extends React.Component {
    state = {
        component: null,
        error: false,
    };

    componentWillMount() {
        this.props
            .load()
            .then((component) => {
                this.setState(() => ({
                    component: component.default ? component.default : component,
                }));
            })
            .catch((e) => this.setState({ error: true }));
    }
    render() {
        const Component = this.state.component;
        const { error } = this.state;

        if (error) {
            return <Fallback />;
        }

        if (Component === null) {
            return <GlobalPreloader />;
        }

        return <Component {...this.props} />;
    }
}

export default DynamicImport;
