export const FETCH_UNSUBSCRIBE_MESSAGE_INFO = 'components/subscriptions/FETCH_MESSAGE_INFO';
export const SET_UNSUBSCRIBE_FETCH_STATE = 'components/subscriptions/SET_FETCH_STATE';
export const SET_UNSUBSCRIBE_MESSAGE_DATA = 'components/subscriptions/SET_MESSAGE_DATA';
export const SET_UNSUBSCRIBE_ERROR = 'components/subscriptions/SET_ERROR';
export const SAVE_UNSUBSCRIBE_SUBSCRIPTIONS = 'components/subscriptions/SAVE_SUBSCRIPTIONS';
export const SET_UNSUBSCRIBE_SUCCESS = 'components/subscriptions/SET_SUCCESS';

export const FETCH_USER_SUBSCRIPTIONS = 'components/subscriptions/FETCH_USER_SUBSCRIPTIONS';
export const SET_USER_FETCH_STATE = 'components/subscriptions/SET_USER_FETCH_STATE';
export const SET_USER_SUBSCRIPTIONS = 'components/subscriptions/SET_USER_SUBSCRIPTIONS';
export const SET_USER_ERROR = 'components/subscriptions/SET_USER_ERROR';
export const UPDATE_USER_SUBSCRIPTIONS = 'components/subscriptions/UPDATE_USER_SUBSCRIPTIONS';
