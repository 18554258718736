import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';

import { ReactComponent as CloseIcon } from 'shared/assets/icons/close-rounded.svg';

import styles from './NotificationMessage.module.scss';
import type { Message } from './types';

interface Props {
    message: Message;
    onClose: (message: Message) => void;
    timeLimit: number;
}

export function NotificationMessage({ message, onClose, timeLimit }: Props) {
    const handleClose = useCallback(() => onClose(message), [onClose]);

    useEffect(() => {
        const timerId = setTimeout(handleClose, timeLimit);

        return () => clearTimeout(timerId);
    }, [handleClose, timeLimit]);

    return (
        <div className={cn(styles.root, styles[message.type])}>
            <div className={styles.text}>{message.text}</div>
            <button type="button" className={styles.button} onClick={handleClose}>
                <CloseIcon className={styles.icon} />
            </button>
        </div>
    );
}
