exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FlightNotFoundModal-module__modalContentText--F5e8K{opacity:50%}", ""]);

// exports
exports.locals = {
	"modalContentText": "FlightNotFoundModal-module__modalContentText--F5e8K"
};