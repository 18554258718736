import { API_BASE_URL } from './apiBaseUrl';
import { createError } from './createError';
import { getHeaders } from './getHeaders';

export async function fetcher(url, params = {}, returnRawResponse = false) {
    const headers = await getHeaders(params.headers);

    const response = await fetch(`${API_BASE_URL}/${url}`, { ...params, headers });

    if (response.ok) {
        return returnRawResponse ? response : response.json();
    }

    return createError(response);
}
