/* eslint-disable max-len */
export const companions = {
    title: 'Попутчики',
    companion: 'Имя и фамилия попутчика',
    add: {
        button: 'Добавить попутчика',
        description_1:
            'Вы можете добавить сюда информацию о своих родных и близких, с которыми собираетесь часто летать.',
        description_2: 'При покупке билетов данные пассажиров будут заполняться автоматически, экономя вам время.',
        saved: 'Попутчик добавлен',
        error: 'Ошибка добавления попутчика. Обязательные поля для ввода (Фамилия + Имя, Дата рождения, Пол, Документ).'
    },
    edit: {
        saved: 'Данные попутчика сохранены',
        error: 'Ошибка сохранения данных попутчика'
    },
    delete: {
        ok: 'Попутчик удалён',
        error: 'Ошибка удаления попутчика'
    },
    form: {
        name: {
            placeholder: 'Фамилия имя и отчество'
        },
        status: 'Карта лояльности Status'
    },
    confirm_delete: 'Подтвердите удаление попутчика',
    age: {
        adult: 'Adult',
        child: 'Child',
        baby: 'Infant'
    }
};
