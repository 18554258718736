import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import i18next from 'i18next';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { getIsAuthUser } from 'selectors/auth';
import { useTextFieldWithError } from 'utils/input';
import { Popover, POPOVER_DIRECTION } from 'components/common/popover';
import { GlobalPreloader } from 'components/common';
import { setCheckinLocator, setCheckinLastName, searchOrder } from 'actions/checkin';
import { getIsFetching, getSearchFormData } from 'selectors/checkin';

export function CheckInForm({ locatorQuery, lastNameQuery }) {
    const isFetching = useSelector(getIsFetching);
    const formData = useSelector(getSearchFormData);
    const dispatch = useDispatch();
    const isUserAuth = useSelector(getIsAuthUser, shallowEqual);
    const [locator, locatorError, createLocatorHandlers, validateLocator, setLocatorValue] = useTextFieldWithError(
        'locator',
        locatorQuery || formData.locator || (isUserAuth ? '' : Cookies.get('checkinform.locator')),
        '',
        null,
        (value) => value.replace(' ', '').toUpperCase()
    );

    const [lastName, lastNameError, createLastNameHandlers, validateLastName, setLastNameValue] = useTextFieldWithError(
        'lastName',
        lastNameQuery || formData.lastName || (isUserAuth ? '' : Cookies.get('checkinform.lastname')),
        '',
        null,
        (value) => value.replace(' ', '')
    );

    const getOrder = (id, surname) => {
        if ([validateLocator(), validateLastName()].some((errorText) => errorText !== '')) {
            return;
        }

        dispatch(setCheckinLocator(id));
        dispatch(setCheckinLastName(surname));

        if (!isUserAuth) {
            const options = { expires: 1 / 24 };
            Cookies.set('checkinform.locator', id, options);
            Cookies.set('checkinform.lastname', surname, options);
        }

        dispatch(searchOrder({ locator: id, lastName: surname }));
    };

    useEffect(() => {
        if (locatorQuery && lastNameQuery) getOrder(locatorQuery, lastNameQuery);
    }, [locatorQuery, lastNameQuery]);

    const clearLocator = () => {
        setLocatorValue('');
        Cookies.remove('checkinform.locator');
    };

    const clearLastName = () => {
        setLastNameValue('');
        Cookies.remove('checkinform.lastname');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        getOrder(locator, lastName);
    };

    return (
        <div className="container-wide">
            <form className="SearchWidget" onSubmit={handleSubmit} noValidate>
                <div className="SearchWidget-box -ticket">
                    <Popover
                        defaultDirection={POPOVER_DIRECTION.BOTTOM}
                        tooltip={<div>{i18next.t('validator.errors.checkinDoc_required')}</div>}
                        isShown={locatorError}
                        disableHover
                    >
                        <input
                            className={classNames('SearchWidget-input', { 'is-error': locatorError })}
                            type="text"
                            placeholder={i18next.t('check_in.document')}
                            value={locator}
                            spellCheck={false}
                            required="required"
                            {...createLocatorHandlers()}
                        />
                    </Popover>
                    <span aria-hidden="true" className="SearchWidget-reset" onClick={clearLocator}>
                        &#xd7;
                    </span>
                </div>

                <div className="SearchWidget-box -name">
                    <Popover
                        defaultDirection={POPOVER_DIRECTION.BOTTOM}
                        tooltip={<div>{i18next.t('validator.errors.lastName_required')}</div>}
                        isShown={lastNameError}
                        disableHover
                    >
                        <input
                            className={classNames('SearchWidget-input', { 'is-error': lastNameError })}
                            type="text"
                            placeholder={i18next.t('check_in.lastname')}
                            value={lastName}
                            spellCheck={false}
                            required="required"
                            {...createLastNameHandlers()}
                        />
                    </Popover>
                    <span aria-hidden="true" className="SearchWidget-reset" onClick={clearLastName}>
                        &#xd7;
                    </span>
                </div>

                <div className="SearchWidget-box -button2">
                    <button
                        className={classNames('Button', '-grayLight', 'SearchWidget-button', {
                            '-preloader': isFetching,
                        })}
                        type="submit"
                    >
                        {i18next.t('check_in.order_lookup')}
                    </button>
                </div>
            </form>

            {isFetching && <GlobalPreloader isWhite />}
        </div>
    );
}
