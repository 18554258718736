import { createSelector } from '@reduxjs/toolkit';

const getChatWidgetStore = (state) => state.chatWidget;

export const getIsChatWidgetOpen = createSelector(getChatWidgetStore, ({ isWidgetOpen }) => isWidgetOpen);

export const getChatWidgetContextProvided = createSelector(
    getChatWidgetStore,
    ({ contextProvided }) => contextProvided
);

export const getChatWidgetContext = createSelector(getChatWidgetStore, ({ widgetContext }) => widgetContext);
