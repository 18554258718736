import { Preloader } from '@public-projects/utair-ui-kit';
import React from 'react';

import styles from './Loader.module.scss';

export function Loader({ isLoading = false }) {
    return (
        isLoading && (
            <div className={styles.root}>
                <Preloader isSmall />
            </div>
        )
    );
}
