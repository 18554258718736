import React, { useEffect } from 'react';
import { withTransaction } from '@elastic/apm-rum-react';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { getIsAuthUser } from 'selectors/auth';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'actions/auth';

import PromoBlock from '../../components/PromoBlock';
import SearchForm from './SearchForm';

function Main({ location }) {
    const dispatch = useDispatch();
    const isAuthUser = useSelector(getIsAuthUser);

    useEffect(() => {
        if (!isAuthUser && location.search.includes('auth_modal=true')) {
            dispatch(toggleModal(true));
        }
    }, [dispatch, isAuthUser, location.search]);

    return (
        <>
            <Helmet>
                <meta name="description" content={t('main_page.meta.description')} />
            </Helmet>
            <SearchForm />
            <div className="container-wide !pt64">
                <PromoBlock />
            </div>
        </>
    );
}

export default withTransaction('Main', 'page')(Main);
