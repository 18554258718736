import { REDIRECT_LINKS } from 'consts';

export const tabLinks = [
    {
        to: REDIRECT_LINKS.TICKETS,
        label: 'nav_menu_links.tickets',
        isShowTicketsLink: true,
        isExternalOnMobile: true,
    },
    {
        to: REDIRECT_LINKS.ROOT,
        label: 'nav_menu_links.buy',
        isExternalOnMobile: true,
    },
    {
        to: REDIRECT_LINKS.HELICOPTERS,
        label: 'nav_menu_links.helicopters',
        availableOnlyWithDirectLink: true,
        onlyRu: true,
    },
    {
        to: REDIRECT_LINKS.CHECK_IN_NEW,
        label: 'nav_menu_links.check_in',
        isExternalOnMobile: true,
    },
    {
        to: REDIRECT_LINKS.ORDER_MANAGE,
        label: 'nav_menu_links.order_manage',
        onlyRu: true,
        isExternalOnMobile: true,
    },
    {
        to: REDIRECT_LINKS.BONUSES,
        label: 'nav_menu_links.bonuses',
        shortLabel: 'nav_menu_links.bonuses_short',
        onlyRu: true,
        onlyAuth: true,
    },
    {
        to: REDIRECT_LINKS.UTAIR_STATUS,
        isExternal: true,
        label: 'nav_menu_links.utair_status',
        onlyRu: true,
        unauthorizedOnly: true,
    },
    {
        to: REDIRECT_LINKS.USER,
        label: 'nav_menu_links.user',
        onlyRu: true,
        onlyAuth: true,
    },
];

export const mobileTabLinks = [
    {
        to: REDIRECT_LINKS.TICKETS_MOBILE,
        label: 'nav_menu_links.tickets_mobile',
        isExternalOnMobile: true,
        passURLParams: true,
        isShowTicketsLink: true,
    },
    {
        to: REDIRECT_LINKS.ROOT,
        label: 'nav_menu_links.buy_mobile',
        isExternalOnMobile: true,
    },
    {
        to: REDIRECT_LINKS.CHECK_IN_MOBILE,
        label: 'nav_menu_links.check_in',
        isExternalOnMobile: true,
    },
    {
        to: REDIRECT_LINKS.ORDER_MANAGE,
        label: 'nav_menu_links.order_manage',
        onlyRu: true,
        isExternalOnMobile: true,
    },
    {
        to: REDIRECT_LINKS.HELICOPTERS,
        label: 'nav_menu_links.helicopters',
        availableOnlyWithDirectLink: true,
        onlyRu: true,
    },
];
