function getIsPath(pathname = '', end = '') {
    return pathname.endsWith(`/${end}`) || pathname.endsWith(`/${end}/`);
}

export function getIsRoot(pathname = '') {
    return ['/', '/en', '/en/'].includes(pathname);
}

export function getIsOnlinePanel(pathname = '') {
    return getIsPath(pathname, 'onlinepanel');
}

export function getIsCheckIn(pathname = '') {
    return getIsPath(pathname, 'check-in');
}

export function getIsOrderManage(pathname = '') {
    return getIsPath(pathname, 'order-manage');
}

export function getIsCheckInTickets(pathname = '') {
    return getIsPath(pathname, 'tickets');
}

export function getIsCheckInNew(pathname = '') {
    return getIsPath(pathname, 'check-in-new');
}

export function getIsHelicopters(pathname = '') {
    return getIsPath(pathname, 'helicopters');
}

export function getIsSchedule(pathname = '') {
    return getIsPath(pathname, 'schedule') || pathname.includes('/schedule/');
}

export function getIsAppSuspension(pathname = '') {
    return getIsPath(pathname, 'app_suspension');
}

export const getIsIndexPage = (pathname = '') =>
    getIsRoot(pathname) ||
    getIsCheckIn(pathname) ||
    getIsOrderManage(pathname) ||
    getIsAppSuspension(pathname) ||
    getIsCheckInNew(pathname) ||
    getIsSchedule(pathname) ||
    getIsCheckInTickets(pathname) ||
    getIsOnlinePanel(pathname) ||
    getIsHelicopters(pathname);

export const getIsHiddenFooter = (pathname = '') => getIsPath(pathname, 'app_suspension');
