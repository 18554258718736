exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ModalWrapper-module__root--3eQwQ{position:fixed;box-sizing:border-box;z-index:100;color:#111820;background:#fff;opacity:0;margin:0;top:0;left:0;transform:translate(0, -200%);transition:all .3s;width:100%}.ModalWrapper-module__isOpen--37_Er{opacity:1;transform:translate(0, 0)}@media(min-width: 960px){.ModalWrapper-module__root--3eQwQ{left:50%;border-radius:6px;transform:translate(-50%, -200%);width:inherit}.ModalWrapper-module__isOpen--37_Er{margin:7.5vh 0;transform:translate(-50%, 0)}}", ""]);

// exports
exports.locals = {
	"root": "ModalWrapper-module__root--3eQwQ",
	"isOpen": "ModalWrapper-module__isOpen--37_Er"
};