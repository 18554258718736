import cn from 'classnames';
import React from 'react';

import styles from './Button.module.scss';

type Icon = JSX.Element;

export type Props = JSX.IntrinsicElements['button'] & {
    variant?: 'primary' | 'secondary' | 'text';
    size?: 'large' | 'medium' | 'small';
    inverted?: boolean;
    fullWidth?: boolean;
    startIcon?: Icon;
    endIcon?: Icon;
};

export function Button({
    children,
    className = '',
    type = 'button',
    variant = 'primary',
    size = 'medium',
    inverted = false,
    startIcon = undefined,
    endIcon = undefined,
    fullWidth = false,
    ...rest
}: Props) {
    const btnClass = cn(styles.button, {
        // [styles.button_theme_primary_inverted]: inverted && variant === 'primary',
        // [styles.button_theme_secondary_inverted]: inverted && variant === 'secondary',
        [styles.inverted]: !!inverted,
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.text]: variant === 'text',
        [styles.sizeLarge]: size === 'large',
        [styles.sizeMedium]: size === 'medium',
        [styles.sizeSmall]: size === 'small',
        [styles.fullWidth]: fullWidth,
        [className]: !!className,
    });

    return (
        <button type={type} className={btnClass} {...rest}>
            {!!startIcon && (
                <span
                    className={cn(styles.icon, {
                        [styles.iconStart]: !!children,
                    })}
                >
                    {startIcon}
                </span>
            )}
            {children}
            {!!endIcon && (
                <span
                    className={cn(styles.icon, {
                        [styles.iconEnd]: !!children,
                    })}
                >
                    {endIcon}
                </span>
            )}
        </button>
    );
}
