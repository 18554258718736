export const COUNTRY_CODES = {
    KG: 'KG',
    RU: 'RU',
    TR: 'TR',
    AE: 'AE',
    UZ: 'UZ',
    KZ: 'KZ',
    AM: 'AM',
    AZ: 'AZ',
    TJ: 'TJ',
    IQ: 'IQ',
} as const;
