import { getClientAccessToken, getUserAccessToken } from 'actions/auth';

export async function getToken(): Promise<string> {
    let token: string;

    try {
        token = await getUserAccessToken();
    } catch {
        token = await getClientAccessToken();
    }

    return token;
}
