/* eslint-disable */
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import encodeurl from 'encodeurl';

import { ERROR_TYPES } from 'constants/errorTypes';

const fetchWrapper = (url, ...ars) => {
    const encodedURL = encodeurl(url);

    return fetch(encodedURL, ...ars).then((response) => {
        if (response.status >= 400) {
            throw response;
        }

        return response;
    });
};

const runningRequests = Object.create(null);

export const cachedFetchWrapper = (url, ...ars) => {
    if (ars[0].method === 'GET') {
        if (runningRequests[url]) {
            return runningRequests[url];
        }

        const cachedFetch = () =>
            fetchWrapper(url, ...ars).finally(() => {
                if (ars[0].method === 'GET' && runningRequests[url]) {
                    delete runningRequests[url];
                }
            });

        runningRequests[url] = cachedFetch();

        return runningRequests[url];
    }

    return fetchWrapper(url, ...ars);
};

export default fetchWrapper;

/**
 * @param {Response} response
 * @return {Promise<Object|Blob|string>}
 */
export const parseBody = (response) => {
    if (response.data) {
        return response.data;
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType) {
        if (contentType.startsWith('application/json')) {
            response.data = response.json();
            return response.data;
        }

        if (contentType.startsWith('text/')) {
            response.data = response.text();
            return response.data;
        }
    }

    return response.blob();
};

/**
 * parse error as response
 * notice: use duck typing for parsed error
 * @param {Response|Error} error
 * @return {Promise<{code: string, data: *}>}
 * @private
 */
export const parseError = (error) => {
    if (error instanceof Response) {
        return parseBody(error).then((data) => {
            let code = ERROR_TYPES.UNKNOWN;

            if (error.status === 400) {
                code = ERROR_TYPES.BAD_REQUEST;
            } else if (error.status === 401) {
                code = ERROR_TYPES.UNAUTHORIZED;
            } else if (error.status === 403) {
                code = ERROR_TYPES.FORBIDDEN;
            } else if (error.status === 404) {
                code = ERROR_TYPES.NOT_FOUND;
            } else if (error.status === 409) {
                code = ERROR_TYPES.CONFLICT;
            } else if (error.status >= 500) {
                code = ERROR_TYPES.INTERNAL_SERVER_ERROR;
            }

            throw { code, data, status: error.status };
        });
    }

    if (error instanceof Object && Object.keys(ERROR_TYPES).some((key) => ERROR_TYPES[key] === error.code)) {
        throw error;
    }

    throw { code: ERROR_TYPES.UNKNOWN, data: error };
};
