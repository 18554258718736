import { fetcher } from 'checkin/shared/api';
import { toCamelCase } from 'checkin/shared/lib/toCamelCase';

export async function getOrderApi({
    rloc,
    lastName,
    flightNumber,
}: {
    rloc: string;
    lastName: string;
    flightNumber: string;
}) {
    const cleanRloc = rloc.split('/')[0];
    const params = {
        json: JSON.stringify({}),
        'filter[rloc]': cleanRloc,
        'filter[passenger_lastname]': lastName,
        'filter[flight_number]': flightNumber,
        fields: 'upgrade_data',
    };

    const response = await fetcher(`api/v2/orders?${new URLSearchParams(params).toString()}`);

    return toCamelCase(response);
}
