export const AB = [
    {
        name: 'newSeats',
        lifeTime: 1000 * 60 * 60 * 24 * 365,
        variants: [
            {
                weight: 50,
                value: 0
            },
            {
                weight: 50,
                value: 1
            }
        ]
    },
    {
        name: 'newFlow',
        lifeTime: 1000 * 60 * 60 * 24 * 365,
        variants: [
            {
                weight: 50,
                value: '0'
            },
            {
                weight: 50,
                value: '1'
            }
        ]
    }
];
