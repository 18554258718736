/* eslint-disable */
import { API_URLS } from 'consts';
import {createFSA} from 'utils';
import {utairHttpManager as request} from 'managers/utair';

export const FETCH_SCHEDULE = 'FETCH_SCHEDULE';
export const FETCH_SCHEDULE_CLAIM = 'FETCH_SCHEDULE_CLAIM';
export const FETCH_SCHEDULE_CLEAR = 'FETCH_SCHEDULE_CLEAR';

const SCHEDULE_CACHE_LIFE_TIME = 60000;

const CacheSettings = {
    lifeTime: SCHEDULE_CACHE_LIFE_TIME
};

const fetchScheduleFSA = createFSA(FETCH_SCHEDULE);

export const fetchSchedule = (params, loadMore) => (dispatch, getState) => {
    dispatch({type: FETCH_SCHEDULE_CLAIM});

    const schedule = getState().schedule.schedule;
    params = params || {};

    if (loadMore && schedule.total > schedule.start + schedule.limit) {
        params.start = schedule.start + schedule.limit;
        params.limit = schedule.limit;
    } else {
        params.start = 0;
        params.limit = 10;
        dispatch({type: FETCH_SCHEDULE_CLEAR});
    }

    return request
        .get(API_URLS.SCHEDULE.SEARCH, params, false, CacheSettings)
        .then((schedule) => dispatch(fetchScheduleFSA(schedule)))
        .catch(({data}) => dispatch(fetchScheduleFSA(new Error(data))));
};

export const findAirports = (name) => (dispatch, getState) =>
    request.get(API_URLS.SCHEDULE.AIRPORTS, {name}, false, CacheSettings);

export const subscribe = (params) => (dispatch, getState) =>
    request.post(API_URLS.SCHEDULE.SUBSCRIBE, params, getState().auth.isAuthUser);

export const confirm = (attemptId, code) => (dispatch, getState) =>
    request.post(API_URLS.SCHEDULE.CONFIRM, {attemptId, code}, false);
