import React from 'react';
import { createRoot } from 'react-dom/client';
import { init as initApm } from '@elastic/apm-rum';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'rc-slider/assets/index.css';
import i18nInit from './locales/init';
import '../styles/base.scss';
import '../styles/makeup-new/scss/base-new.scss';
import { App } from './app';

if (process.env.API_MOCKING === 'enabled' && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    const { worker } = require('../mocks');
    worker.start();
} else if (process.env.NODE_ENV !== 'development') {
    initApm({
        serviceName: 'main-spa',
        serverUrl: 'https://apm-lb.utair.io',
        environment: process.env.NODE_ENV,
        distributedTracingOrigins: ['https://b.utair.ru'],
    });
}

// hack for Safari - it uses cached web-pages
// see: https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
window.onpageshow = function (event) {
    if (event.persisted) {
        window.location.reload();
    }
};

const container = document.getElementById('root');
const root = createRoot(container);

i18nInit(() => root.render(<App />));

if (module.hot) {
    module.hot.accept('./app', () => {
        root.render(<App />);
    });
}
