/* eslint-disable */
import React from 'react';

function getMenuLink(url, baseUrl, lng) {
    if (url.startsWith('http') || url.startsWith('//')) {
        return url;
    }

    return baseUrl + (lng === 'en' ? '/en' : '') + url;
}

const MainMenu = ({links, lng, baseUrl}) => (
    <nav className="MainMenu">
        {links.map((group, ix) => (
            <ul className="MainMenu-group" key={ix}>
                <li className="MainMenu-title">
                    <a href={getMenuLink(group.url, baseUrl, lng)}>{group.name}</a>
                </li>

                {(group.children || []).map((child, cix) => (
                    <li key={cix}>
                        <a href={getMenuLink(child.url, baseUrl, lng)}>{child.name}</a>
                    </li>
                ))}
            </ul>
        ))}
    </nav>
);

export default MainMenu;
