// eslint-disable-next-line camelcase
export const pilot_meals = {
    purchase_pilot_food: 'Заказ питания для экипажей',
    flight_number: 'Номер рейса',
    flight_date: 'Дата рейса',
    last_name: 'Фамилия',
    first_name: 'Имя',
    last_and_first_names: 'Фамилия и имя',
    choose_what_you_like: 'Выберите из разнообразного меню то, что вам по вкусу.',
    order_number: 'Номер заказа',
    order_cancellation: 'Отмена оформленного заказа',
    success_order_cancellation: 'Заказ отменён успешно',
    action: {
        try_again: 'Попробовать еще раз',
        input_flight_number: 'Введите номер рейса',
        input_order_number: 'Введите номер заказа',
        input_flight_date: 'Введите дату рейса',
        input_last_name: 'Введите фамилию получателя',
        input_first_name: 'Введите имя получателя',
        input_last_and_first_names: 'Введите фамилию и имя в заказе',
        input_phone: 'Введите телефон',
        input_email: 'Введите E-mail для отправки чека',
        find: 'Найти',
        find_order: 'Найти заказ',
        confirm_cancellation: 'Подтвердить отмену',
    },
    error: {
        bad_request: 'Некорректно заполнены поля',
        not_found: 'Не найдено',
        forbidden: 'Время продажи услуг истекло',
        required_field: 'Это обязательное поле',
        wrong_date: 'Некорректная дата',
        fail_order_cancellation: 'Не удалось отменить заказ',
    },
};
