exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AdaptiveLayout-module__root--xMXft{display:flex;flex-direction:column;height:calc(100vh - 104px)}.AdaptiveLayout-module__stepper--2bxIE{display:flex;background-color:#fff;padding:0 14px}.AdaptiveLayout-module__main--3tKoE{flex:1 0;overflow-x:hidden;background-color:#ebeef3;padding:0 14px}.AdaptiveLayout-module__footer--32ZND{display:flex;align-items:center;padding:32px 14px 80px;background-color:#ebeef3}.footerMain{display:none}", ""]);

// exports
exports.locals = {
	"root": "AdaptiveLayout-module__root--xMXft",
	"stepper": "AdaptiveLayout-module__stepper--2bxIE",
	"main": "AdaptiveLayout-module__main--3tKoE",
	"footer": "AdaptiveLayout-module__footer--32ZND"
};