exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PassengerInfo-module__root--Whyws{display:flex;flex-direction:column}.PassengerInfo-module__name--32CBh{margin-bottom:2px}.PassengerInfo-module__document--3k_KC{display:flex;align-items:center;flex-wrap:wrap;font-size:14px;line-height:20px;color:rgba(94,110,135,.8)}.PassengerInfo-module__dot--1H41k{display:inline-block;background-color:rgba(94,110,135,.5);height:3px;width:3px;border-radius:50%;margin:0 8px}", ""]);

// exports
exports.locals = {
	"root": "PassengerInfo-module__root--Whyws",
	"name": "PassengerInfo-module__name--32CBh",
	"document": "PassengerInfo-module__document--3k_KC",
	"dot": "PassengerInfo-module__dot--1H41k"
};