/* eslint-disable max-len */
/* eslint-disable camelcase */
export const social_auth = {
    messages: {
        registration_exists:
            'Пользователь с таким Email уже зарегистрирован. Для привязки соц. сети Вам необходимо войти с помощью Email',
        login_exists: 'Для привязки соц. сети Вам необходимо первый раз войти с помощью Email',
        question: 'Если Вы уже зарегистрированы, Вам необходимо всего один раз войти на сайт для привязки соц сети.'
    }
};
