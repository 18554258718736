import cn from 'classnames';
import React from 'react';

import { NotificationMessage } from './NotificationMessage';
import styles from './Notifications.module.scss';
import type { Message } from './types';

interface Props {
    messages: Message[];
    onClose: (message: Message) => void;
    timeLimit?: number;
}

export function Notifications({ messages, onClose, timeLimit = 10000 }: Props) {
    return (
        <div className={cn(styles.root, messages.length && styles.active)}>
            {messages.map((message) => (
                <div className={styles.item} key={message.id}>
                    <NotificationMessage timeLimit={timeLimit} message={message} onClose={onClose} />
                </div>
            ))}
        </div>
    );
}
