exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Button-module__root--3fEtZ{min-width:94px}", ""]);

// exports
exports.locals = {
	"root": "Button-module__root--3fEtZ"
};