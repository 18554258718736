exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NavMenu-module__mobileNavMenu--IuqXK{position:relative;overflow-x:scroll;white-space:nowrap;scroll-behavior:smooth;width:91%;scrollbar-width:none;-ms-overflow-style:none;padding:10px 16px}.NavMenu-module__mobileNavMenu--IuqXK::-webkit-scrollbar{display:none}.NavMenu-module__mobileNavMenu--IuqXK>a{display:inline-block;border-bottom:none}.NavMenu-module__mobileNavMenu--IuqXK>a:first-child{margin-left:0}", ""]);

// exports
exports.locals = {
	"mobileNavMenu": "NavMenu-module__mobileNavMenu--IuqXK"
};