import { Button } from '@public-projects/utair-ui-kit';
import React from 'react';

import { Image } from '../Image';
import styles from './Card.module.scss';
import { ReactComponent as Next } from './icons/arrow-rounded.svg';
import { ReactComponent as Basket } from './icons/basket-rounded.svg';
import { ReactComponent as Selected } from './icons/selected.svg';

interface Props {
    imageSrc?: string;
    title?: string;
    subTitle?: string;
    alert?: string;
    price?: string;
    formattedTotalPrice?: string | null;
    hasTotalPrice?: boolean;
    onAdd?: () => void;
    onRemove?: () => void;
}

export function Card({
    imageSrc = '',
    title = '',
    subTitle = '',
    alert = '',
    price = '',
    formattedTotalPrice = '',
    hasTotalPrice = false,
    onAdd = () => {},
    onRemove = () => {},
}: Props) {
    return (
        <div className={styles.root} data-testid="checkIn-serviceCard">
            <Image className={styles.image} src={imageSrc} alt={title} />
            <div className={styles.content}>
                <div className={styles.title} data-testid="checkIn-serviceCard-title">
                    {title}
                    {hasTotalPrice && <Selected className={styles.icon} />}
                </div>
                <div className={styles.subTitle}>{subTitle}</div>
                <div className={styles.alert}>{alert}</div>
                <div className={styles.actions}>
                    {hasTotalPrice ? (
                        <>
                            <Button
                                className={styles.cancelButton}
                                size="small"
                                variant="text"
                                data-testid="checkIn-serviceCard-removeButton"
                                onClick={onRemove}
                            >
                                Отменить
                            </Button>
                            <Button
                                startIcon={<Basket />}
                                endIcon={<Next />}
                                size="small"
                                variant="secondary"
                                onClick={onAdd}
                            >
                                {formattedTotalPrice}
                            </Button>
                        </>
                    ) : (
                        <>
                            <div className={styles.price} data-testid="checkIn-serviceCard-price">
                                {price}
                            </div>
                            <Button size="small" data-testid="checkIn-serviceCard-addButton" onClick={onAdd}>
                                Добавить
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
