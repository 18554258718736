import React from 'react';

import { findService } from '../../lib';
import styles from './Counter.module.scss';

interface Props {
    selected: SelectedService[];
    segmentId: string;
    passengerId: string;
    onChange: (newValue: number, segmentId: string, passengerId: string) => void;
    min?: number;
    max?: number;
}
interface SelectedService {
    guid: string;
    passengerIdAsm: string;
    quantity: number;
    segmentIdAsm: string;
}

export function Counter({ selected, segmentId, passengerId, onChange, min = 0, max = 10 }: Props) {
    const value = findService(selected, segmentId, passengerId)?.quantity || 0;

    return (
        <div className={styles.counter}>
            <button
                className={styles.buttonLeft}
                onClick={() => onChange(value - 1 < min ? value : value - 1, segmentId, passengerId)}
                disabled={value === min}
            >
                -
            </button>
            <div className={styles.value}>{value}</div>
            <button
                className={styles.buttonRight}
                onClick={() => onChange(value + 1 > max ? value : value + 1, segmentId, passengerId)}
                disabled={value === max}
            >
                +
            </button>
        </div>
    );
}
