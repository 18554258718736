/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';

/**
 * Margin for all sides of popover. Use this but not the css
 * @access private
 * @type {number}
 */
const POPOVER_MARGIN = 16;

/**
 * Constant that represents the position of popover
 * @access public
 * @type {{TOP: string, RIGHT: string, BOTTOM: string, LEFT: string}}
 */
export const POPOVER_DIRECTION = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
};

const directionClassNames = {
    [POPOVER_DIRECTION.TOP]: '-up',
    [POPOVER_DIRECTION.RIGHT]: '-right',
    [POPOVER_DIRECTION.BOTTOM]: '-down',
    [POPOVER_DIRECTION.LEFT]: '-left',
};

const themeClasses = {
    blue: 'themeBlue',
};

/**
 * Popover component
 * @access public
 * @example <caption>Sample usage</caption>
 *   <Popover
 *     defaultDirection={POPOVER_DIRECTION.LEFT}
 *     tooltip={<div className="text-center">Sample</div>}
 *   >
 *      <button type="button" className="advantage-info">i</button>
 *   </Popover>
 */
export class Popover extends React.Component {
    /**
     * constructor
     * @param {Object} props
     * @param {?string} [props.defaultDirection] - default direction of popover
     * @param {React.Component} props.tooltip - tooltip element
     * @param {?boolean} props.isShown - explicitly show/hide tooltip
     * @param {?boolean} props.disableHover - disable mouse enter/leave behavior
     */
    constructor(props) {
        super(props);
        this.state = {
            direction: props.defaultDirection || POPOVER_DIRECTION.TOP,
            position: { top: '0px', left: '0px' },
            isShown: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { isShown, tooltip } = this.props;

        if (!prevProps.isShown && isShown) {
            this.calculatePosition();
            this.setState({ isShown: true });
        }

        if (prevProps.isShown && !isShown) {
            this.setState({ isShown: false });
        }

        if (prevProps.isShown && tooltip && prevProps.tooltip !== tooltip) {
            this.calculatePosition();
        }
    }

    render() {
        return [this.renderChildren(), this.renderTooltip()];
    }

    renderChildren() {
        const { disableHover } = this.props;
        return React.Children.map(this.props.children, (child) =>
            React.cloneElement(child, {
                ref: this.createRefHandler(child.ref),
                onMouseEnter: disableHover ? null : this.onMouseEnter,
                onMouseLeave: disableHover ? null : this.onMouseLeave,
            })
        );
    }

    renderTooltip() {
        const { tooltip, tooltipStyle = {}, theme, className = '' } = this.props;
        const { isShown, direction, position } = this.state;
        const portalStyle = { ...position, ...tooltipStyle };

        return ReactDOM.createPortal(
            <div
                ref={this.setTooltipEl}
                className={cn('Tooltip', directionClassNames[direction], theme && themeClasses[theme], className)}
                role="tooltip"
                aria-hidden={!isShown}
                style={portalStyle}
            >
                {tooltip}
            </div>,
            document.body
        );
    }

    createRefHandler = (refCallback) => (el) => {
        this.setContainerEl(el);

        if (typeof refCallback === 'function') {
            refCallback(el);
        }
    };

    setContainerEl = (el) => {
        this.containerEl = el;
    };

    setTooltipEl = (el) => {
        this.tooltipEl = el;
    };

    onMouseEnter = () => {
        this.calculatePosition();
        this.setState({ isShown: true });
    };

    onMouseLeave = () => {
        this.setState({ isShown: false });
    };

    /**
     * find optimal direction and calculate position
     */
    calculatePosition() {
        if (!this.containerEl.getBoundingClientRect) {
            /* eslint react/no-find-dom-node: 'off' */
            this.containerEl = ReactDOM.findDOMNode(this.containerEl);
        }
        const bodyHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        );
        const bodyWidth = document.body.clientWidth;

        const containerRect = this.containerEl.getBoundingClientRect();
        const containerTop = window.pageYOffset + containerRect.top;
        const containerLeft = window.pageXOffset + containerRect.left;
        const containerHeight = containerRect.height;
        const containerWidth = containerRect.width;

        const tooltipRect = this.tooltipEl.getBoundingClientRect();
        const tooltipHeight = tooltipRect.height;
        const tooltipWidth = tooltipRect.width;

        let direction = this.props.defaultDirection || POPOVER_DIRECTION.TOP;
        let top;
        let left;

        // tooltip doesn't fit on top
        if (direction === POPOVER_DIRECTION.TOP && containerRect.top < tooltipHeight + POPOVER_MARGIN) {
            direction = POPOVER_DIRECTION.BOTTOM;

            // tooltip doesn't fit on bottom
        } else if (
            direction === POPOVER_DIRECTION.BOTTOM &&
            document.documentElement.clientHeight - containerRect.bottom < tooltipHeight + POPOVER_MARGIN
        ) {
            direction = POPOVER_DIRECTION.TOP;

            // tooltip doesn't fit on right
        } else if (direction === POPOVER_DIRECTION.RIGHT && containerLeft > bodyWidth - tooltipWidth - POPOVER_MARGIN) {
            direction = POPOVER_DIRECTION.LEFT;

            // tooltip doesn't fit on left
        } else if (direction === POPOVER_DIRECTION.LEFT && containerLeft < tooltipWidth + POPOVER_MARGIN) {
            direction = POPOVER_DIRECTION.RIGHT;
        }

        switch (direction) {
            case POPOVER_DIRECTION.TOP:
                top = containerTop - tooltipHeight - POPOVER_MARGIN;
                left = containerLeft + containerWidth / 2 - tooltipWidth / 2;
                break;

            case POPOVER_DIRECTION.BOTTOM:
                top = containerTop + containerHeight + POPOVER_MARGIN;
                left = containerLeft + containerWidth / 2 - tooltipWidth / 2;
                break;

            case POPOVER_DIRECTION.RIGHT:
                top = containerTop + containerHeight / 2 - tooltipHeight / 2;
                left = containerLeft + containerWidth + POPOVER_MARGIN;
                break;

            default:
                top = containerTop + containerHeight / 2 - tooltipHeight / 2;
                left = containerLeft - tooltipWidth - POPOVER_MARGIN;
                break;
        }

        if (left < 0) {
            left = 0;
        }
        if (left > bodyWidth - tooltipWidth) {
            left = bodyWidth - tooltipWidth - 10;
        }
        if (top < 0) {
            top = 0;
        }
        if (top > bodyHeight - tooltipHeight) {
            top = bodyHeight - tooltipHeight - 10;
        }

        this.setState({
            position: {
                top: `${top}px`,
                left: `${left}px`,
            },
            direction,
        });
    }
}
