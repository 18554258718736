/* eslint-disable react/require-default-props */
import { Popover } from '@public-projects/utair-ui-kit';
import cn from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';

import { AutocompleteCity } from 'schedule/entities/city';
import { useIsMobile } from 'schedule/shared/lib';
import type { City } from 'schedule/shared/model';

import { ReactComponent as IconSwap } from './icon-swap.svg';
import styles from './Route.module.scss';

type RouteProps = {
    quickFiltersCityFrom?: ReactNode;
    quickFiltersCityTo?: ReactNode;
    values: {
        cityFrom: City | null;
        cityTo: City | null;
    };
    onChange: (params: { cityFrom: City | null; cityTo: City | null }) => void;
};

export function Route({
    values: { cityFrom, cityTo },
    quickFiltersCityFrom,
    quickFiltersCityTo,
    onChange,
}: RouteProps) {
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const isError = !!cityFrom?.code && !!cityTo?.code && cityFrom.code === cityTo.code;
    const isMobile = useIsMobile(600);

    return (
        <div className={styles.route}>
            <div className={styles.citySelectorWrapper}>
                <AutocompleteCity
                    className={cn(styles.citySelector, styles.cityFrom, {
                        [styles.isError]: isError && !isMobile,
                    })}
                    initialCity={cityFrom}
                    placeholder="Откуда"
                    onChange={(payload: City | null) => onChange({ cityFrom: payload, cityTo })}
                />
                {!!quickFiltersCityFrom && quickFiltersCityFrom}
            </div>
            <div className={styles.swapper}>
                <div className={styles.divider} />
                <IconSwap
                    className={cn(styles.icon)}
                    onClick={() => (cityFrom && cityTo ? onChange({ cityFrom: cityTo, cityTo: cityFrom }) : null)}
                />
            </div>
            <div ref={anchorRef} className={styles.citySelectorWrapper}>
                <AutocompleteCity
                    className={cn(styles.citySelector, styles.cityTo, {
                        [styles.isError]: isError,
                    })}
                    initialCity={cityTo}
                    placeholder="Куда угодно"
                    onChange={(payload: City | null) => onChange({ cityFrom, cityTo: payload })}
                />
                <Popover
                    anchorRef={anchorRef}
                    opened={isError}
                    placement="bottomEnd"
                    variant="secondary"
                    className={styles.tooltip}
                >
                    Укажите разные города
                </Popover>
                {!!quickFiltersCityTo && quickFiltersCityTo}
            </div>
        </div>
    );
}
