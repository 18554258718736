exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BoardingPassModal-module__boardingPassRow--_ewRo{display:flex;align-items:center;justify-content:space-between;padding:16px;background-color:#f6f8fc;border-radius:6px;margin-bottom:8px}.BoardingPassModal-module__downloadButton--13uh_{padding:0}.BoardingPassModal-module__icon--2vnml{color:rgba(94,110,135,.8)}", ""]);

// exports
exports.locals = {
	"boardingPassRow": "BoardingPassModal-module__boardingPassRow--_ewRo",
	"downloadButton": "BoardingPassModal-module__downloadButton--13uh_",
	"icon": "BoardingPassModal-module__icon--2vnml"
};