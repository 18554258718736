import { API_URLS } from 'consts';
import i18next from 'i18next';
import { ConfirmAttemptsExceededError } from 'components/Account/ConfirmAttemptsExceededError';

import captchaManager from '../../utils/captcha-manager';
import fetch, { parseBody } from '../../utils/fetch';
import { utairHttpManager as request } from '../../managers/utair';

export const errorCodes = {
    40101: 'login_not_found',
    40104: 'phone_disabled',
    40301: 'too_many_requests',
    40302: 'user_blocked',
    40303: 'user_blocked',
    40030: 'phone_incorrect',
    40031: 'send_code_error',
    40004: 'no_contacts',
};

export const confirmAttemptsExceededError = 40320;

export const getAuthConfirmError = (errorCode) => {
    if (errorCode === confirmAttemptsExceededError) {
        return ConfirmAttemptsExceededError;
    }

    return i18next.t(errorCodes[errorCode] ? `sign.error.${errorCodes[errorCode]}` : 'common.unknown_error');
};

export const authLogin = (login) =>
    captchaManager
        .getCaptchaToken()
        .then((captchaToken) =>
            fetch(API_URLS.AUTH.LOGIN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'g-recaptcha-response': captchaToken,
                },
                body: JSON.stringify({ login, confirmation_type: 'standard' }),
            })
        )
        .then(parseBody);

export const signUpUser = (type, login, confirmationGDPRDate) => {
    const params = { [type]: login, confirmationGDPRDate };

    return request.post(API_URLS.PROFILE.USER, params);
};
