/* eslint-disable */
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { t } from 'i18next';

import NumberInput from 'components/common/number.input-new';

import {
    FLIGHT_MAX_PASSENGERS_COUNT,
    FLIGHT_MAX_PASSENGERS_COUNT_CHILDREN_ALONE,
    FLIGHT_MAX_PASSENGERS_COUNT_WITH_STANDBY,
    FLIGHT_MAX_PASSENGERS_COUNT_WITH_VACATION,
} from 'constants/passengerTypes';
import { UnaccompaniedMinorCheckbox } from 'entities/cart/ui/UnaccompaniedMinorCheckbox';
import classNames from 'classnames';

/**
 * Do not employ onClick handler because it interfers with onFocus handler, and here we
 * preserve Tab key and mouse focusing, so they both function in parallel
 */
export class Passengers extends React.Component {
    state = {
        isFocused: false,
        isCheckedUnaccompaniedMinor: this.props.isCheckedUnaccompaniedMinor,
    };

    get maxCount() {
        const {
            passengers: { adultsCount, childrenCount, infantsCount, employeesCount = 0, teenagersCount = 0 },
            isStandby,
            isStandbyTicketType,
            isVacationTicketType,
        } = this.props;

        if (isVacationTicketType) {
            const total = FLIGHT_MAX_PASSENGERS_COUNT_WITH_VACATION;
            const currentTotal = adultsCount + teenagersCount + childrenCount + infantsCount + employeesCount;
            const availableToMax = total - currentTotal;
            const infantsLimit = adultsCount + employeesCount;

            const maxAdults = total - teenagersCount - childrenCount - infantsCount - employeesCount;
            const maxTeenagers = availableToMax + teenagersCount;
            const maxChildren = availableToMax + childrenCount;
            const maxInfants = total - adultsCount - teenagersCount - childrenCount - employeesCount;
            const maxEmployees = total - adultsCount - teenagersCount - childrenCount - infantsCount;

            return {
                adults: Math.min(2, maxAdults),
                teenagers: Math.min(total, maxTeenagers), // Родственник от 12 до 18 лет, далее считаем как adult, количество без ограничений
                children: Math.min(total, maxChildren),
                infants: Math.min(infantsLimit, maxInfants),
                employees: Math.min(1, maxEmployees),
            };
        }

        const getMaxPassengersTotal = () => {
            if (isStandby) return FLIGHT_MAX_PASSENGERS_COUNT_WITH_STANDBY;
            if (this.state.isCheckedUnaccompaniedMinor) return FLIGHT_MAX_PASSENGERS_COUNT_CHILDREN_ALONE;
            return FLIGHT_MAX_PASSENGERS_COUNT;
        };

        const total = getMaxPassengersTotal();

        const currentTotal = adultsCount + childrenCount + infantsCount + employeesCount;
        const availableToMax = total - currentTotal;
        const infantsLimit = adultsCount + employeesCount;

        const maxAdults = total - childrenCount - infantsCount - employeesCount;
        const maxTeenagers = availableToMax;
        const maxChildren = availableToMax + childrenCount;
        const maxInfants = total - adultsCount - childrenCount - employeesCount;
        const maxEmployees = total - adultsCount - childrenCount - infantsCount;

        if (isStandbyTicketType)
            return {
                adults: Math.min(2, maxAdults),
                children: Math.min(total, maxChildren),
                infants: Math.min(infantsLimit, maxInfants),
                employees: Math.min(1, maxEmployees),
            };

        return {
            adults: Math.min(total, total - childrenCount - infantsCount),
            children: Math.min(total, maxChildren),
            infants: Math.min(infantsLimit, maxInfants),
        };
    }

    componentDidMount() {
        this.props.onRef && this.props.onRef(this);
    }

    handleMouseDown = (e) => {
        e.preventDefault();
        this.props.toggleFocusedPassengers(!this.state.isFocused);
        if (this.state.isFocused) {
            this.setState({ isFocused: false }, () => this.inputEl.blur());
        } else {
            this.setState({ isFocused: true }, () => this.inputEl.focus());
        }
    };

    onChangeEmployeesCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, employeesCount: value });
    };

    onChangeAdultsCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, adultsCount: value });
    };

    onChangeTeenagersCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, teenagersCount: value });
    };

    onChangeChildrenCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, childrenCount: value });
    };

    onChangeInfantsCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, infantsCount: value });
    };

    onCheckedUnaccompaniedMinor = () => {
        this.setState({ isCheckedUnaccompaniedMinor: !this.state.isCheckedUnaccompaniedMinor });
        this.props.onCheckedUnaccompaniedMinor(!this.state.isCheckedUnaccompaniedMinor);
    };

    handleFocus = () => {
        this.setState({ isFocused: true });
    };

    /**
     * leave on Tab press
     */
    handleKeyDown = (e) => {
        if (e.keyCode === 9) {
            this.setState({ isFocused: false });
        }
    };

    /**
     * @uses react-onclickoutside
     */
    handleClickOutside = () => {
        this.setState({ isFocused: false });
    };

    focus = () => {
        this.inputEl.focus();
        this.handleFocus();
    };

    setInputEl = (el) => {
        this.inputEl = el;
    };

    render() {
        const {
            passengers: { adultsCount, childrenCount, infantsCount, employeesCount = 0, teenagersCount = 0 },
            isStandbyTicketType,
            error,
            isVacationTicketType,
        } = this.props;
        const maxCount = this.maxCount;
        const passengersTotal = adultsCount + childrenCount + infantsCount + employeesCount + teenagersCount;

        const isBenefitTicketType = isStandbyTicketType || isVacationTicketType;

        if (this.state.isCheckedUnaccompaniedMinor && !isBenefitTicketType) {
            return (
                <div className="SearchWidget-box -passengers">
                    <input
                        onFocus={this.handleFocus}
                        onMouseDown={this.handleMouseDown}
                        onKeyDown={this.handleKeyDown}
                        ref={this.setInputEl}
                        className={classNames(`SearchWidget-input -select`, {
                            'is-error': error && !this.state.isFocused,
                        })}
                        type="text"
                        value={t('booking.passenger_interval', {
                            postProcess: 'interval',
                            count: passengersTotal,
                        })}
                        readOnly
                        tabIndex="1"
                    />

                    <div className="SearchWidget-dropdown -padding" aria-hidden={!this.state.isFocused}>
                        <div className="Grid-2 -middle -nogutter !mb24">
                            <p className="Col unaccompaniedMinor">{t(`booking.teenagerUnaccompaniedMinor`)}</p>
                            <div className="Col">
                                <NumberInput
                                    key={`children${childrenCount}`}
                                    className="block-right"
                                    value={adultsCount}
                                    minValue={0}
                                    maxValue={maxCount.adults}
                                    onChange={this.onChangeAdultsCount}
                                />
                            </div>
                        </div>
                        <div className="Grid-2 -middle -nogutter !mb24">
                            <p className="Col unaccompaniedMinor">{t(`booking.childrenUnaccompaniedMinor`)}</p>
                            <div className="Col">
                                <NumberInput
                                    key={`children${childrenCount}`}
                                    className="block-right"
                                    value={childrenCount}
                                    minValue={0}
                                    maxValue={maxCount.children}
                                    onChange={this.onChangeChildrenCount}
                                />
                            </div>
                        </div>
                        <div className="Grid-2 -middle -nogutter">
                            <div className="Col line">
                                <UnaccompaniedMinorCheckbox
                                    isChecked={this.state.isCheckedUnaccompaniedMinor}
                                    onChange={this.onCheckedUnaccompaniedMinor}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="SearchWidget-box -passengers">
                <input
                    onFocus={this.handleFocus}
                    onMouseDown={this.handleMouseDown}
                    onKeyDown={this.handleKeyDown}
                    ref={this.setInputEl}
                    className="SearchWidget-input -select"
                    type="text"
                    value={t('booking.passenger_interval', {
                        postProcess: 'interval',
                        count: passengersTotal,
                    })}
                    readOnly
                    tabIndex="1"
                />

                <div className="SearchWidget-dropdown -padding" aria-hidden={!this.state.isFocused}>
                    {isBenefitTicketType && (
                        <div className="Grid-2 -middle -nogutter !mb24">
                            <p className="Col">{t('booking.employees')}</p>
                            <div className="Col">
                                <NumberInput
                                    /**
                                    Параметр key нужен для правильного обновления, почему так смотри ссылку:
                                    https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
                                */
                                    key={`employees${employeesCount}`}
                                    className="block-right"
                                    value={employeesCount}
                                    minValue={adultsCount ? 0 : 1}
                                    maxValue={maxCount.employees}
                                    onChange={this.onChangeEmployeesCount}
                                />
                            </div>
                        </div>
                    )}

                    <div className="Grid-2 -middle -nogutter !mb24">
                        <p className="Col">
                            {t(`booking.${!isBenefitTicketType ? 'adults' : 'relative'}`)}
                            {isStandbyTicketType && <span className="caption block">Взрослый</span>}
                            {isVacationTicketType && <span className="caption block">От 18 лет</span>}
                        </p>
                        <div className="Col">
                            <NumberInput
                                key={`adults${adultsCount}`}
                                className="block-right"
                                value={adultsCount}
                                minValue={employeesCount ? 0 : 1}
                                maxValue={maxCount.adults}
                                onChange={this.onChangeAdultsCount}
                            />
                        </div>
                    </div>

                    {isVacationTicketType && (
                        <div className="Grid-2 -middle -nogutter !mb24">
                            <p className="Col">
                                {t(`booking.relative`)}
                                <span className="caption block">От 12 до 18 лет</span>
                            </p>
                            <div className="Col">
                                <NumberInput
                                    key={`teenagers${teenagersCount}`}
                                    className="block-right"
                                    value={teenagersCount}
                                    minValue={0}
                                    maxValue={maxCount.teenagers}
                                    onChange={this.onChangeTeenagersCount}
                                />
                            </div>
                        </div>
                    )}

                    <div className="Grid-2 -middle -nogutter !mb24">
                        <p className="Col">
                            {t(`booking.${!isBenefitTicketType ? 'children' : 'relative'}`)}
                            {isBenefitTicketType && <span className="caption block">До 12 лет</span>}
                        </p>
                        <div className="Col">
                            <NumberInput
                                key={`children${childrenCount}`}
                                className="block-right"
                                value={childrenCount}
                                minValue={0}
                                maxValue={maxCount.children}
                                onChange={this.onChangeChildrenCount}
                            />
                        </div>
                    </div>

                    <div className="Grid-2 -middle -nogutter">
                        <p className="Col">
                            {t(`booking.${!isBenefitTicketType ? 'infants' : 'relative'}`)}
                            <span className="caption block">
                                {!isBenefitTicketType ? t('booking.infants_note') : 'До 2 лет без места'}
                            </span>
                        </p>
                        <div className="Col">
                            <NumberInput
                                key={`infants${infantsCount}`}
                                className="block-right"
                                value={infantsCount}
                                minValue={0}
                                maxValue={maxCount.infants}
                                onChange={this.onChangeInfantsCount}
                            />
                        </div>
                    </div>
                    {!isBenefitTicketType && (
                        <div className="Grid-2 -middle -nogutter !mt24">
                            <div className="Col line">
                                <UnaccompaniedMinorCheckbox
                                    isChecked={this.state.isCheckedUnaccompaniedMinor}
                                    onChange={this.onCheckedUnaccompaniedMinor}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const WrappedPassengers = onClickOutside(Passengers);
