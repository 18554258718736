import type { PropsWithChildren } from 'react';
import React, { useMemo, useState } from 'react';

import type { CustomError } from 'schedule/shared/api/createError';

import type { DataContextType } from './context';
import { ActionContext, DataContext, QueryContext, initialDataState } from './context';

type StoreProviderProps = PropsWithChildren<unknown>;

export const StoreProvider = ({ children = undefined }: StoreProviderProps) => {
    const [error, setError] = useState<CustomError | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, setData] = useState<DataContextType>(initialDataState);

    const action = useMemo(() => ({ setData, setIsLoading, setError }), []);

    return (
        <ActionContext.Provider value={action}>
            <QueryContext.Provider value={{ isLoading, error }}>
                <DataContext.Provider value={data}>{children}</DataContext.Provider>
            </QueryContext.Provider>
        </ActionContext.Provider>
    );
};
