export type CustomError = Error & {
    info: Record<string, string> | undefined;
    status: number;
};

export async function createError(response: Response): Promise<CustomError> {
    const error = new Error('An error occurred while fetching the data.') as CustomError;
    error.info = await response.json();
    error.status = response.status;
    throw error;
}
