import { SET_IS_LOADING, SET_INITIAL_DATA, RESET } from 'actions/change-personal-data';

const INITIAL_STATE = {
    rloc: null,
    lastName: null,
    reservation: null,
    segments: [],
    otherPassengers: [],
    isLoading: false,
};

export default function changePersonalData(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case SET_IS_LOADING:
            return { ...state, isLoading: payload };
        case SET_INITIAL_DATA:
            return { ...state, ...payload };
        case RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
}
