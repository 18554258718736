/* eslint-disable max-len */
// eslint-disable-next-line camelcase
export const flights_search = {
    from: 'From',
    to: 'To',
    one_way: 'One way',
    two_ways: 'Return ticket',
    dates_placeholder: 'Departure date',
    ticket_type: 'Ticket type',
};
