import { call, put, takeLatest } from 'redux-saga/effects';
import { utairHttpManager as request } from 'managers/utair';
import {
    FETCH_ORDERS_CHECKIN_REQUEST,
    FETCH_ORDERS_CHECKIN_SUCCESS,
    FETCH_ORDERS_CHECKIN_FAILURE,
    setIsBookingNotFound,
    setIsFlightNotFound,
    setOnlyOfflineRegistrationMessage,
} from 'actions/checkin';
import { API_URLS } from 'consts';
import { ERROR_TYPES } from 'constants/errorTypes';

function requestOrdersCheckin(locator, lastName) {
    return request
        .get({
            url: API_URLS.ORDERS_V2.CHECKIN_APP,
            query: {
                locator,
                passenger_lastname: lastName,
            },
            withCaptcha: true,
        })
        .then((response) => response.objects || []);
}

function* worker(action) {
    const { locator, lastName } = action.payload;

    try {
        let respData = [];
        const response = yield call(requestOrdersCheckin, locator, lastName);

        if (response.length && response.every((x) => x.segments.length)) {
            respData = response;
        } else if (!response.length) {
            yield put(setIsBookingNotFound(true));
        } else {
            yield put(setIsFlightNotFound(true));
        }

        yield put({
            type: FETCH_ORDERS_CHECKIN_SUCCESS,
            payload: respData,
        });
    } catch (e) {
        console.error(e); // eslint-disable-line no-console

        if (e.code === ERROR_TYPES.INTERNAL_SERVER_ERROR) {
            yield put(setIsBookingNotFound(true));
            yield put({
                type: FETCH_ORDERS_CHECKIN_SUCCESS,
                payload: [],
            });
        } else if (e.code === ERROR_TYPES.BAD_REQUEST && e.data.meta.error_code === 10310) {
            yield put(setIsFlightNotFound(true));
            yield put({
                type: FETCH_ORDERS_CHECKIN_SUCCESS,
                payload: [],
            });
        } else if (e.code === ERROR_TYPES.BAD_REQUEST && e.data.meta.error_code === 10311) {
            yield put(setOnlyOfflineRegistrationMessage(e.data.meta.error_message));
            yield put({
                type: FETCH_ORDERS_CHECKIN_SUCCESS,
                payload: [],
            });
        } else {
            yield put({ type: FETCH_ORDERS_CHECKIN_FAILURE });
        }
    }
}

export function* OrdersCheckinWatcher() {
    yield takeLatest(FETCH_ORDERS_CHECKIN_REQUEST, worker);
}
