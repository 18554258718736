exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Notifications-module__root--24LLZ{position:fixed;top:0;right:0;left:0;z-index:900;transform:translateY(-100%);opacity:0;transition:transform .3s ease-out,opacity .3s ease-out}.Notifications-module__item--1nwKS{margin-bottom:8px}.Notifications-module__active--3Vipx{transform:translateY(0);opacity:1}", ""]);

// exports
exports.locals = {
	"root": "Notifications-module__root--24LLZ",
	"item": "Notifications-module__item--1nwKS",
	"active": "Notifications-module__active--3Vipx"
};