exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UnaccompaniedMinorCheckbox-module__root--3xSGv{display:flex;margin-top:10px}.UnaccompaniedMinorCheckbox-module__checkbox--3VbWT{font-size:14px}.UnaccompaniedMinorCheckbox-module__icon--9XhHF{width:20px;height:20px;color:#003594;margin:2px 0 0 5px}.UnaccompaniedMinorCheckbox-module__tooltip--Vvr2t{padding:8px 12px;font-size:12px;z-index:100;max-width:234px;box-sizing:border-box;background-color:#003594;opacity:.9;color:#fff;border-radius:4px;margin-left:-90px}.UnaccompaniedMinorCheckbox-module__tooltip--Vvr2t::before{position:absolute;z-index:1;width:0;height:0;border-color:transparent;border-style:solid;border-width:6px 6px;left:90%;content:\"\";pointer-events:none}.UnaccompaniedMinorCheckbox-module__tooltipLink--2xsFB{color:#fff;opacity:.7}", ""]);

// exports
exports.locals = {
	"root": "UnaccompaniedMinorCheckbox-module__root--3xSGv",
	"checkbox": "UnaccompaniedMinorCheckbox-module__checkbox--3VbWT",
	"icon": "UnaccompaniedMinorCheckbox-module__icon--9XhHF",
	"tooltip": "UnaccompaniedMinorCheckbox-module__tooltip--Vvr2t",
	"tooltipLink": "UnaccompaniedMinorCheckbox-module__tooltipLink--2xsFB"
};