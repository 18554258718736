/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const Tooltip = ({className, content, children, preWrapped, isHtml, as, visible, elementProps}) => {
    const Container = as;
    if (isHtml) {
        return (
            <Container className={classNames({tooltip: visible}, className)} {...elementProps}>
                {children}
                <div className="tooltip-html">
                    {preWrapped && <div className="pre">{content}</div>}
                    {!preWrapped && content}
                </div>
            </Container>
        );
    }

    return (
        <Container className={classNames({tooltip: visible}, className)} data-tooltip={content} {...elementProps}>
            {children}
        </Container>
    );
};

Tooltip.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    preWrapped: PropTypes.bool,
    visible: PropTypes.bool,
    isHtml: PropTypes.bool,
    as: PropTypes.string,
    elementProps: PropTypes.shape(),
    className: PropTypes.string
};

Tooltip.defaultProps = {
    as: 'div',
    content: '',
    preWrapped: true,
    isHtml: true,
    visible: true,
    elementProps: {},
    className: 'inlineBlock'
};
