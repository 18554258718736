import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { setUserLogin, setUserAuthAttemptId, loginConfirm } from 'actions/auth';
import { browserHistory } from 'react-router';
import { getUserData } from 'actions/user';

import { START_LINK_AUTH } from './index';

const linkAuthSaga = function* ({ payload: { email, attemptId, code, redirectUrl } }) {
    const isAuthUser = yield select((state) => state.auth.isAuthUser);

    if (!isAuthUser) {
        try {
            yield put(setUserLogin(email));
            yield put(setUserAuthAttemptId(attemptId));
            yield putResolve(loginConfirm(code));
        } catch {
            browserHistory.push(redirectUrl);
        }
    }

    yield putResolve(getUserData());
    browserHistory.push(redirectUrl);
};

const linkAuthWatcherSaga = function* () {
    yield takeLatest(START_LINK_AUTH, linkAuthSaga);
};

export default linkAuthWatcherSaga;
