import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import i18next from 'i18next';
import { withRouter } from 'react-router';
import { getCanUserBuyStandby } from 'selectors/user';
import { getIsIndexPage } from 'utils/paths'; // fixme надо через темы делать, те css
import { getIsAuthUser } from 'selectors/auth';
import { REDIRECT_LINKS } from 'consts';
import { ticketsModel } from 'checkin/entities/tickets';

import { useLocale, languages } from '../../locales';
import NavMenuItem from './NavMenuItem';
import { tabLinks, mobileTabLinks } from './links';
import styles from './NavMenu.module.scss';

const fullLabelsMinWindowWidth = 1122;

const noTabs = (links, pathname, localePrefix) => {
    const allLinks = links.map((link) => link.to);

    const path = pathname.replace(/\/$/, '');
    const resultPath = path.startsWith(localePrefix) ? path.slice(localePrefix.length) || REDIRECT_LINKS.ROOT : path;

    return !allLinks.includes(resultPath);
};

function NavMenu({ location: { pathname, query = {} } }) {
    const getUserTickets = ticketsModel.useGetUserTickets();
    const tickets = ticketsModel.useData();

    const isAuth = useSelector(getIsAuthUser);

    const canUserBuyStandby = useSelector(getCanUserBuyStandby);

    const media = useSelector((state) => state.media) || {};

    const { language: lng } = useLocale();

    const localePrefix = lng === 'ru' ? '' : `/${lng}`;

    const { desktop, phone: isMobile } = media;

    const [hasActiveTickets, setHasActiveTickets] = useState(false);

    const [displayShortLabels, setDisplayShortLabels] = useState(true);

    const searchParams = useRef('');

    useEffect(() => {
        if (!isAuth || (query.identifier && query.last_name)) return;

        getUserTickets();
    }, [getUserTickets, isAuth, query.identifier, query.last_name]);

    useEffect(() => {
        setHasActiveTickets(!!tickets?.length);
    }, [tickets?.length]);

    const handleWindowSizeChange = () => setDisplayShortLabels(window.innerWidth < fullLabelsMinWindowWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isShow = ({
        onlyAuth,
        unauthorizedOnly,
        onlyRu,
        isStandby,
        isShowTicketsLink,
        availableOnlyWithDirectLink,
    }) => {
        const isTicketsPathname = pathname.includes(REDIRECT_LINKS.TICKETS);

        switch (true) {
            case onlyAuth && !isAuth:
            case unauthorizedOnly && isAuth:
            case onlyRu && ![languages.ru].includes(lng):
            case isStandby && !canUserBuyStandby:
            case availableOnlyWithDirectLink && !pathname.includes(REDIRECT_LINKS.HELICOPTERS):
            case isShowTicketsLink && isAuth && !isTicketsPathname && !hasActiveTickets:
            case isShowTicketsLink && !isAuth && !isTicketsPathname:
                return false;
            default:
                return true;
        }
    };

    const navLinks = isMobile ? mobileTabLinks : tabLinks;

    if (noTabs(navLinks, pathname, localePrefix)) {
        return null;
    }

    return (
        <div className={classNames(!isMobile && 'container')}>
            <div
                className={classNames(isMobile ? styles.mobileNavMenu : 'MenuTabs', {
                    MenuTabs_color_dark: !getIsIndexPage(pathname),
                })}
            >
                {navLinks
                    .filter(isShow)
                    .map(({ to, isExternal, isExternalOnMobile, label, shortLabel, passURLParams }) => {
                        const shouldRedirectToFlutterApp =
                            !desktop &&
                            (pathname.includes(REDIRECT_LINKS.ORDER_MANAGE) || pathname.includes('/orders/services/'));

                        const preparedTo =
                            shouldRedirectToFlutterApp && to === REDIRECT_LINKS.ROOT ? '/m/' : `${localePrefix}${to}`;

                        return (
                            <NavMenuItem
                                key={to}
                                to={`${preparedTo}${passURLParams ? searchParams.current : ''}`}
                                pathname={pathname}
                                isExternal={isExternal || (isExternalOnMobile && !desktop)}
                            >
                                {i18next.t(shortLabel && displayShortLabels ? shortLabel : label)}
                            </NavMenuItem>
                        );
                    })}
            </div>
        </div>
    );
}

export default withRouter(NavMenu);
