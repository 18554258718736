/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { Discount, DiscountPrice, DiscountValue, TypeOfDiscount } from './types';

const initialState: Discount = {
    selectedDiscount: null,
    value: null,
    error: null,
    isLoading: false,
    discountedPrice: null,
    discountAmount: null,
    isMileAvailable: false,
};

const discountSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        changeSelectedDiscount(state, { payload }: PayloadAction<TypeOfDiscount>) {
            state.selectedDiscount = payload;
        },
        addError(state, { payload }: PayloadAction<string | null>) {
            state.error = payload;
        },
        toggleIsLoading(state, { payload }: PayloadAction<boolean>) {
            state.isLoading = payload;
        },
        addDiscount(
            state,
            {
                payload,
            }: PayloadAction<{
                discountedPrice: DiscountPrice;
                value: DiscountValue;
                selectedDiscount: TypeOfDiscount;
                discountAmount: number;
            }>
        ) {
            state.discountedPrice = payload.discountedPrice;
            state.value = payload.value;
            state.selectedDiscount = payload.selectedDiscount;
            state.discountAmount = payload.discountAmount;
        },
        resetDiscount(state) {
            state.selectedDiscount = null;
            state.discountedPrice = null;
            state.error = null;
            state.value = null;
        },
        setIsMileAvailable(state, { payload }: PayloadAction<boolean>) {
            state.isMileAvailable = payload;
        },
    },
});

export const selectors = {
    selectedDiscount(state: RootState) {
        return state.upgrade.discount.selectedDiscount;
    },
    value(state: RootState) {
        return state.upgrade.discount.value;
    },
    error(state: RootState) {
        return state.upgrade.discount.error;
    },
    isLoading(state: RootState) {
        return state.upgrade.discount.isLoading;
    },
    discountedPrice(state: RootState) {
        return state.upgrade.discount.discountedPrice;
    },
    discountAmount(state: RootState) {
        return state.upgrade.discount.discountAmount;
    },
    isMileAvailable(state: RootState) {
        return state.upgrade.discount.isMileAvailable;
    },
};

export const { name, reducer, actions } = discountSlice;
