import {
    FETCH_SELECTED_ORDER,
    SET_FETCH_ORDER_ERROR,
    FETCH_ACTUAL,
    FETCH_HISTORY,
    SET_FETCHING,
    CLEAR_ACTUAL,
    CLEAR_HISTORY,
    SET_FILTER_FLIGHT_NUMBER,
    SET_RETURN_IS_FETCHING,
    SET_RETURN_RESULT_ID,
    SET_RETURN_ERROR,
    SET_SESSION_TOKEN,
    SET_APPLICABLE_SERVICE,
    SET_BASKET_SECTION,
    UPDATE_BASKET,
    CLEAR_BASKET,
    SET_PAYMENT_PROCESS,
    SET_PAYMENT_ERROR,
    SET_PAYMENT_REDIRECT_URL,
    POLL_ACTUAL_ORDER,
    UDPATE_ACTUAL_ORDER,
    SET_DELAYED_PAYMENT,
    SET_IS_RESULT_MODAL_VISIBLE,
    SET_MPS_PAYMENT_URL,
    SET_SEAT_MAP,
    SET_PERSONAL_SALE_UTM,
} from 'actions/orders';
import { UPDATE_ECOMMERCE_BASKET } from 'actions/ecommerce';
import { ADDITIONAL_SERVICE_TYPES } from 'constants/additionalServiceTypes';

const { BAGGAGE, MEAL, BUSINESS_ROOM, SEAT, INSURANCE } = ADDITIONAL_SERVICE_TYPES;

const INITIAL_DATA = {
    searchParams: {
        q: null,
        flightNumber: null,
        date: null,
    },
    actual: {
        data: [],
        total: null,
        pagination: {
            skip: 0,
            limit: 10,
        },
    },
    history: {
        data: [],
        total: null,
        pagination: {
            skip: 0,
            limit: 10,
        },
        sort: -1,
    },
    services: [],
    isFetching: false,
    applicableServices: {},
    selectedOrder: {
        seatmap: null,
        sessionToken: null,
        order: null,
        services: null,
        basket: {
            id: null,
            lastModified: null,
            total: 0,
            services: {
                [BAGGAGE.value]: [],
                [MEAL.value]: [],
                [BUSINESS_ROOM.value]: [],
                [SEAT.value]: [],
                [INSURANCE.value]: false,
            },
        },
        ecommerceBasket: [],
        fetchingError: null,
        paymentError: null,
        isPaymentProcess: false,
        redirectUrl: null,
        delayedPayment: false,
        isResultModalVisible: false,
        MPSPaymentUrl: null,
    },
    personalSaleUTM: null,

    return: {
        orderId: null,
        resultId: null,
        passengers: [],
        isInvoluntary: false,
        error: null,
        isFetching: false,
    },
};

const orders = (state = INITIAL_DATA, { type, payload }) => {
    switch (type) {
        case FETCH_SELECTED_ORDER:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    order: payload.order,
                    services: payload.services,
                },
            };

        case SET_FETCH_ORDER_ERROR:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    fetchingError: payload,
                },
            };

        case SET_SEAT_MAP:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    seatmap: payload,
                },
            };

        case FETCH_ACTUAL:
            return {
                ...state,
                actual: { ...state.actual, data: state.actual.data.concat(payload) },
            };

        case FETCH_HISTORY:
            return {
                ...state,
                history: { ...state.history, data: state.history.data.concat(payload) },
            };

        case SET_FETCHING:
            return {
                ...state,
                isFetching: payload,
            };

        case CLEAR_ACTUAL:
            return {
                ...state,
                actual: {
                    data: [],
                    total: null,
                    pagination: {
                        skip: 0,
                        limit: 10,
                    },
                },
            };

        case CLEAR_HISTORY:
            return {
                ...state,
                history: {
                    data: [],
                    total: null,
                    pagination: {
                        skip: 0,
                        limit: 10,
                    },
                    sort: -1,
                },
            };

        case SET_FILTER_FLIGHT_NUMBER:
            return {
                ...state,
                searchParams: { ...state.searchParams, flightNumber: payload },
            };

        case SET_SESSION_TOKEN:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    sessionToken: payload,
                },
            };

        case SET_APPLICABLE_SERVICE:
            return {
                ...state,
                applicableServices: {
                    ...state.applicableServices,
                    [payload.rloc]: payload.applicableServices,
                },
            };

        case SET_BASKET_SECTION:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    basket: {
                        ...state.selectedOrder.basket,
                        // todo поcчитать total
                        services: {
                            ...state.selectedOrder.basket.services,
                            [payload.serviceKey]: payload.data,
                        },
                    },
                },
            };

        case UPDATE_BASKET:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    basket: {
                        ...state.selectedOrder.basket,
                        id: payload.cartId,
                        lastModified: payload.lastModified,
                    },
                },
            };

        case UPDATE_ECOMMERCE_BASKET:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    ecommerceBasket: payload,
                },
            };

        case CLEAR_BASKET:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    basket: { ...INITIAL_DATA.selectedOrder.basket },
                },
            };

        case SET_PAYMENT_PROCESS:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    isPaymentProcess: payload,
                },
            };

        case SET_PAYMENT_ERROR:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    paymentError: payload,
                },
            };

        case SET_PAYMENT_REDIRECT_URL:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    redirectUrl: payload,
                },
            };

        case SET_DELAYED_PAYMENT:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    delayedPayment: payload,
                },
            };

        case SET_IS_RESULT_MODAL_VISIBLE:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    isResultModalVisible: payload,
                },
            };

        case SET_MPS_PAYMENT_URL:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    MPSPaymentUrl: payload,
                },
            };

        case SET_RETURN_RESULT_ID:
            return {
                ...state,
                return: {
                    ...state.return,
                    resultId: payload,
                },
            };

        case SET_RETURN_ERROR:
            return {
                ...state,
                return: {
                    ...state.return,
                    error: payload,
                },
            };

        case SET_RETURN_IS_FETCHING:
            return {
                ...state,
                return: {
                    ...state.return,
                    isFetching: payload,
                },
            };

        case POLL_ACTUAL_ORDER: {
            const { orderId, rloc } = payload;
            const updatedData = state.actual.data.map((x) =>
                x.order_id === orderId && x.rloc === rloc ? { ...x, updating: true } : x
            );

            return {
                ...state,
                actual: {
                    ...state.actual,
                    data: updatedData,
                },
            };
        }

        case UDPATE_ACTUAL_ORDER: {
            const { order_id: orderId, rloc } = payload;
            const updatedData = state.actual.data.map((x) => (x.order_id === orderId && x.rloc === rloc ? payload : x));

            return {
                ...state,
                actual: {
                    ...state.actual,
                    data: updatedData,
                },
            };
        }

        case SET_PERSONAL_SALE_UTM:
            return {
                ...state,
                personalSaleUTM: payload,
            };

        default:
            return state;
    }
};

export default orders;
