import i18next from 'i18next';
import { put, call, select, takeLatest, all, delay } from 'redux-saga/effects';
import { getBasketUrlString, getSelectedOrder, getSelectedOrderBasket } from 'selectors/orders';
import {
    checkCartPayment,
    setPaymentError,
    setPaymentProcess,
    setIsResultModalVisible,
    setMPSPaymentUrl,
    updateBasket,
    PAYMENT_SELECTED_ORDER,
    CHECK_CART_PAYMENT,
} from 'actions/orders';
import { addMessage } from 'actions/sysmessages';
import { ERROR_TYPES } from 'constants/errorTypes';
import metrics from 'managers/metrics';
import { additionalPurchaseHttpManager } from 'managers/addditional-purchase';
import { API_URLS } from 'consts';

function servicesFromBasket(basket) {
    return Object.values(basket)
        .filter((services) => Array.isArray(services) && services.length > 0)
        .reduce((acc, services) => [...acc, ...services], [])
        .map((item) => {
            let text;

            if (item.freetext) {
                text = { text: item.freetext };
            }

            if (item.text) {
                text = { text: item.text };
            }

            return {
                passenger_id: item.passengerId,
                segment_id: item.segmentId,
                qtty: item.count,
                guid: item.guid,
                dynamic_key: item.dynamicKey,
                ...text,
            };
        });
}

export function* getCartWorker() {
    const {
        order: { rloc, passengers },
        basket,
        personalSaleUTM = {},
    } = yield all({
        order: select(getSelectedOrder),
        basket: select(getSelectedOrderBasket),
        personalSaleUTM: select((state) => state.orders.personalSaleUTM),
    });

    const params = {
        rloc: rloc.split('/')[0],
        last_name: passengers[0].last_name,
        services: yield call(servicesFromBasket, basket.services),
        ...personalSaleUTM,
    };

    const {
        responseHeaders,
        result: { id },
    } = yield call([additionalPurchaseHttpManager, 'post'], API_URLS.ADDITIONAL_PURCHASE.CART, params);
    const lastModified = responseHeaders.get('Last-Modified');
    yield put(updateBasket({ cartId: id, lastModified }));

    return { id, lastModified };
}

function* paySelectedOrderSaga() {
    try {
        yield put(setPaymentProcess(true));
        const [basketUrlString, cart] = yield all([select(getBasketUrlString), call(getCartWorker)]);
        const { location } = window;
        const paymentParams = {
            cart_id: cart.id,
            success_url: encodeURI(`${location.href}?${basketUrlString}&payment_success=1&cartId=${cart.id}`),
            failure_url: encodeURI(`${location.href}?${basketUrlString}&payment_success=0`),
        };

        const { result } = yield call(
            [additionalPurchaseHttpManager, 'post'],
            API_URLS.ADDITIONAL_PURCHASE.PAYMENT(cart.id),
            paymentParams,
            cart.lastModified
        );

        if (!result.redirect_url) {
            yield put(setPaymentError({ code: ERROR_TYPES.BAD_REQUEST }));

            return;
        }

        const delayedPayment = yield select((state) => state.orders.selectedOrder.delayedPayment);

        if (delayedPayment) {
            yield put(setMPSPaymentUrl(result.redirect_url));
            yield put(setIsResultModalVisible(true));

            return;
        }

        yield call([location, 'assign'], result.redirect_url);
    } catch (error) {
        yield put(setPaymentError(error));
    } finally {
        yield put(setPaymentProcess(false));
    }
}

const cartPaymentStatus = {
    acknowledged: 'ACKNOWLEDGED',
    rejected: 'REJECTED',
    confirmed: 'CONFIRMED',
};

function* checkCartPaymentSaga({ payload }) {
    const errorMessage = i18next.t('orders.payment.failed');
    const eventType = 'error';

    const sendError = (detail) =>
        metrics.pushEvent(eventType, {
            type: 'modal',
            text: errorMessage,
            detail,
        });

    try {
        yield put(setPaymentProcess(true));
        const { result: cart } = yield call(
            [additionalPurchaseHttpManager, 'get'],
            `${API_URLS.ADDITIONAL_PURCHASE.CART}${payload}/`
        );

        if (cart.status === cartPaymentStatus.acknowledged) {
            metrics.pushEvent('payment_successful', {
                channel: 'asm',
                cart_id: cart.id,
                rloc: cart.rloc,
            });
            yield put(addMessage(i18next.t('orders.payment.success'), 'success'));
            window.location.href = `${window.location.origin}/order-manage?payment_success=1&rloc=${cart.rloc}&last_name=${cart.last_name}`;
        } else if (cart.status === cartPaymentStatus.rejected) {
            yield put(setPaymentProcess(false));
            yield put(addMessage(errorMessage, eventType));
            sendError('method: checkCartPaymentSaga, payment status = rejected');
            yield call(getCartWorker);
        } else {
            yield delay(10000);
            yield put(checkCartPayment(cart.id));
        }
    } catch (e) {
        yield put(setPaymentProcess(false));
        yield put(addMessage(errorMessage, eventType));
        sendError(e);
    }
}

export default function* paymentSelectedOrderWatcher() {
    yield takeLatest(PAYMENT_SELECTED_ORDER, paySelectedOrderSaga);
    yield takeLatest(CHECK_CART_PAYMENT, checkCartPaymentSaga);
}
