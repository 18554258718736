import { useEffect } from 'react';
import { servicesModel } from 'checkin/entities/services';

function Services({ children, params: { cartId = '' } }) {
    const getServices = servicesModel.useGetServices();
    const { error } = servicesModel.useQuery();

    useEffect(() => {
        if (!error?.code) {
            getServices(cartId);
        }
    }, [cartId, getServices, error?.code]);

    return children;
}

export const ServicesProvider = servicesModel.withContext(Services);
