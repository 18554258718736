import { browserHistory } from 'react-router';

const SCREEN_LOCATIONS = (path) => {
    switch (true) {
        case /check-in-new$/.test(path):
            return 'checkin_main_screen';
        case /passengers\/(w*)/.test(path):
            return 'checkin_passengers_screen';
        case /seats/.test(path):
            return 'checkin_seatmap_screen';
        case /baggage/.test(path):
            return 'checkin_baggage_screen';
        case /meal/.test(path):
            return 'checkin_meal_screen';
        case /check-in-new\/(w*)/.test(path):
            return 'checkin_services_screen';
        case /payment\/(w*)/.test(path):
            return 'checkin_payment_screen';
        case /summary\/(w*)/.test(path):
            return 'checkin_summary_screen';
        case /failure\/(w*)/.test(path):
            return 'checkin_failure_screen';
        case /tickets/.test(path):
            return 'checkin_tickets_screen';
        default:
            return 'unknown_screen';
    }
};

function savePrevScreenName(pathname) {
    const currentScreen = SCREEN_LOCATIONS(pathname);
    const prevScreen = sessionStorage.getItem('currentScreen');

    if (currentScreen !== prevScreen) {
        sessionStorage.setItem('currentScreen', currentScreen);
        sessionStorage.setItem('prevScreen', prevScreen);
    }
}

export function startSavePrevScreenName() {
    savePrevScreenName(browserHistory.getCurrentLocation().pathname);
    browserHistory.listen(({ pathname }) => savePrevScreenName(pathname));
}
