/* eslint-disable react/require-default-props */
import cn from 'classnames';
import Flags from 'country-flag-icons/react/1x1';
import React from 'react';
import { Link } from 'react-router';

import { sharedRoutes } from 'schedule/shared/config';
import { generatePath, getEndOfWeek, getIsoDate, getStartOfWeek } from 'schedule/shared/lib';
import { useAction, useData } from 'schedule/shared/model';

import { COUNTRY_CODES } from '../../lib';
import styles from './DestinationCity.module.scss';
import { ReactComponent as IconNext } from './icon-next.svg';
import { InternationalIcon1x1 } from './InternationalIcon';

type DestinationCityProps = {
    cityName: string;
    cityCode: string;
    countryCode?: string;
    countryName?: string;
    isDirect: boolean;
};

const renderCountryFlag = (countryCode: string) => {
    switch (countryCode) {
        case COUNTRY_CODES.AM:
            return <Flags.AM title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.AZ:
            return <Flags.AZ title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.AE:
            return <Flags.AE title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.IQ:
            return <Flags.IQ title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.KG:
            return <Flags.KG title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.KZ:
            return <Flags.KZ title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.RU:
            return <Flags.RU title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.TJ:
            return <Flags.TJ title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.TR:
            return <Flags.TR title={countryCode} className={styles.countryFlag} />;

        case COUNTRY_CODES.UZ:
            return <Flags.UZ title={countryCode} className={styles.countryFlag} />;

        default:
            return <InternationalIcon1x1 title={countryCode} className={styles.countryFlag} />;
    }
};

export function DestinationCity({
    cityName,
    cityCode,
    countryCode = '',
    countryName = '',
    isDirect,
}: DestinationCityProps) {
    const { departureCity, departureDateFrom, departureDateTo } = useData();
    const { setData } = useAction();

    const handleClick = () => {
        setData((cur) => ({ ...cur, arrivalCity: { code: cityCode, countryCode, countryName, name: cityName } }));
    };

    const url = generatePath(
        sharedRoutes.PATHS.ROUTE_SCHEDULE_PAGE,
        {
            cityFrom: departureCity?.code || null,
            cityTo: cityCode,
        },
        {
            dateFrom: getIsoDate(departureDateFrom || getStartOfWeek()),
            dateTo: getIsoDate(departureDateTo || getEndOfWeek()),
        }
    );

    return (
        <Link to={url} onClick={handleClick} className={styles.root}>
            {renderCountryFlag(countryCode.toUpperCase())}
            <div className={styles.route}>
                <div className={styles.cityName}>{cityName}</div>
                <div className={cn(styles.routeType, { [styles.direct]: isDirect })}>
                    {isDirect ? 'Без пересадок' : 'С пересадкой'}
                </div>
            </div>
            <IconNext className={styles.icon} />
        </Link>
    );
}
