/* eslint-disable */
import _ from 'lodash';
import {
    ADD_COMPANION,
    EDIT_COMPANION,
    DELETE_COMPANION,
    SET_EDIT_MODE,
    SET_COMPANIONS,
    SET_FETCH_MODE,
} from 'actions/companions.user';

const INITIALSTATE = {
    isFetching: false,
    list: [],
};

const companions = (state = INITIALSTATE, payload) => {
    switch (payload.type) {
        case SET_COMPANIONS: {
            return {
                ...state,
                list: payload.companions,
            };
        }

        case ADD_COMPANION: {
            const companion = { ...payload.data };
            const currentCompanions = [...state.list];

            companion.id = _.random(1000, 9999, false);
            companion.isEdit = false;

            currentCompanions.push(companion);

            return {
                ...state,
                list: currentCompanions,
            };
        }

        case SET_EDIT_MODE: {
            return {
                ...state,
                list: state.list.map((item) => {
                    if (item.id !== payload.id) return item;

                    return {
                        ...item,
                        isEdit: !item.isEdit,
                    };
                }),
            };
        }

        case EDIT_COMPANION: {
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id !== payload.data.id
                        ? item
                        : {
                              ...item,
                              ...payload.data,
                          }
                ),
            };
        }

        case DELETE_COMPANION: {
            return {
                ...state,
                list: state.list.filter((i) => i.id !== payload.id),
            };
        }

        case SET_FETCH_MODE: {
            return {
                ...state,
                isFetching: payload.fetching,
            };
        }

        default: {
            return state;
        }
    }
};

export default companions;
