import { combineReducers } from '@reduxjs/toolkit';
import * as TYPE from 'constants/subscriptionsTypes';

const UNSUBSCRIBE_MAP = {
    [TYPE.SET_UNSUBSCRIBE_FETCH_STATE]: (payload) => ({
        isFetch: payload,
    }),
    [TYPE.SET_UNSUBSCRIBE_MESSAGE_DATA]: (payload) => ({
        isFetch: false,
        messageInfo: payload,
    }),
    [TYPE.SET_UNSUBSCRIBE_ERROR]: (payload) => ({
        error: payload,
    }),
    [TYPE.SET_UNSUBSCRIBE_SUCCESS]: (payload) => ({
        success: payload,
    }),
};

const unsubscribeReduces = (
    state = {
        isFetch: false,
        messageInfo: {},
        error: null,
        success: false,
    },
    { type, payload }
) =>
    !UNSUBSCRIBE_MAP[type]
        ? state
        : {
              ...state,
              ...UNSUBSCRIBE_MAP[type](payload),
          };

const USER_MAP = {
    [TYPE.SET_USER_FETCH_STATE]: (payload) => ({
        isFetch: payload,
    }),
    [TYPE.SET_USER_SUBSCRIPTIONS]: (payload) => ({
        isFetch: false,
        subscriptions: payload,
    }),
};

const userReduces = (
    state = {
        isFetch: true,
        subscriptions: {},
    },
    { type, payload }
) =>
    !USER_MAP[type]
        ? state
        : {
              ...state,
              ...USER_MAP[type](payload),
          };

export default combineReducers({
    unsubscribe: unsubscribeReduces,
    user: userReduces,
});
