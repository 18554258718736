import moment from 'moment';
import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router';

import { cityModel } from 'schedule/entities/city';
import { useAction, useData } from 'schedule/shared/model';
import Layout from 'schedule/shared/ui/Layout';

import { SearchForm } from '../search-form';
import styles from './Layout.module.scss';

type RouteParams = {
    cityFrom?: string;
    cityTo?: string;
};
type QueryParams = {
    dateFrom?: string;
    dateTo?: string;
};
type PageProps = RouteComponentProps<RouteParams, RouteParams, unknown, QueryParams>;

export function ScheduleLayout({ children = null, params, router, location }: PropsWithChildren<PageProps>) {
    const { departureCity, arrivalCity } = useData();
    const { setIsLoading, setData } = useAction();
    const { cityFrom, cityTo } = params;
    const {
        query: { dateFrom, dateTo },
    } = location;
    const getNearestCity = cityModel.useGetNearestCities();
    const { getCities } = cityModel.useGetCities();

    useEffect(() => {
        if (!departureCity?.code && !cityFrom) {
            getNearestCity();
        }
    }, [cityFrom, departureCity?.code, getNearestCity]);

    useEffect(() => {
        if (cityFrom && !departureCity?.code) {
            setIsLoading(true);
            getCities({ query: cityFrom, limit: 1 })
                .then((data) => {
                    if (data && data.length) {
                        const depCity = data[0];
                        setData((cur) => ({ ...cur, departureCity: depCity }));
                    }
                })
                .finally(() => setIsLoading(false));
        }

        if (cityTo && !arrivalCity?.code) {
            setIsLoading(true);
            getCities({ query: cityTo, limit: 1 })
                .then((data) => {
                    if (data && data.length) {
                        const arrCity = data[0];
                        setData((cur) => ({ ...cur, arrivalCity: arrCity }));
                    }
                })
                .finally(() => setIsLoading(false));
        }

        setData((cur) => ({
            ...cur,
            departureDateFrom: dateFrom ? moment(dateFrom, 'YYYY-MM-DD') : null,
            departureDateTo: dateTo ? moment(dateTo, 'YYYY-MM-DD') : null,
        }));
    }, [cityFrom, cityTo, dateFrom, dateTo]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout rootClassName={styles.root}>
            <Layout.Header className={styles.header}>
                <div className="container !pt16 !pb16">
                    <div className={styles.title}>Расписание полетов</div>
                    <SearchForm router={router} />
                </div>
            </Layout.Header>
            <Layout.Content className={styles.content}>
                <div className="container !pt32 !pb32">{children}</div>
            </Layout.Content>
            <Layout.Footer className={styles.footer}>
                <div className="container !pt16 !pb16">
                    <div>&copy; 2024</div>
                    <div>ПАО «Авиакомпания «ЮТэйр»</div>
                </div>
            </Layout.Footer>
        </Layout>
    );
}
