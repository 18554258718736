exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SingUp-module__radioButtonsWrapper--1AGGV{width:168px}.SingUp-module__agreementWrapper--2o4hq{margin-bottom:16px}.SingUp-module__buttonWrapper--2TEEB+.SingUp-module__buttonWrapper--2TEEB{margin-top:24px}", ""]);

// exports
exports.locals = {
	"radioButtonsWrapper": "SingUp-module__radioButtonsWrapper--1AGGV",
	"agreementWrapper": "SingUp-module__agreementWrapper--2o4hq",
	"buttonWrapper": "SingUp-module__buttonWrapper--2TEEB"
};