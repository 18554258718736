exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Counter-module__counter--2NqxS{display:inline-flex;align-items:center;background-color:#ebeef3;border-radius:4px}.Counter-module__button--2hqLG,.Counter-module__buttonRight--1Ls6G,.Counter-module__buttonLeft--1WWER{width:32px;height:32px;border:0;outline:none;background-color:#ebeef3}.Counter-module__buttonLeft--1WWER{border-top-left-radius:4px;border-bottom-left-radius:4px}.Counter-module__buttonRight--1Ls6G{border-top-right-radius:4px;border-bottom-right-radius:4px}.Counter-module__button--2hqLG:hover,.Counter-module__buttonLeft--1WWER:hover,.Counter-module__buttonRight--1Ls6G:hover,.Counter-module__button--2hqLG:focus,.Counter-module__buttonLeft--1WWER:focus,.Counter-module__buttonRight--1Ls6G:focus{background-color:#cbd3e0}.Counter-module__value--3FYF9{width:30px;color:#111820;font-size:14px;line-height:20px;text-align:center}", ""]);

// exports
exports.locals = {
	"counter": "Counter-module__counter--2NqxS",
	"button": "Counter-module__button--2hqLG",
	"buttonRight": "Counter-module__buttonRight--1Ls6G",
	"buttonLeft": "Counter-module__buttonLeft--1WWER",
	"value": "Counter-module__value--3FYF9"
};