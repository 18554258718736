/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import {ReactComponent as Close} from 'icons/interface/close.svg';

const styles = {
    closeIcon: {
        width: '1.5em',
        height: '1.5em'
    }
};

const Modal = ({
    isOpen,
    isTop,
    isBig,
    isSmall,
    isCalendar,
    isPadding,
    onClose,
    children,
    hideClose,
    classNamePadding,
    className
}) =>
    ReactDOM.createPortal(
        <div className={classNames('Modal', className)} aria-hidden={!isOpen}>
            <div className={classNames('Modal-container', {'-top': isTop})}>
                <div
                    className={classNames('Modal-content', {
                        'Modal-content--big': isBig,
                        'Modal-content--small': isSmall,
                        'Modal-content--calendar': isCalendar
                    })}
                    data-testid='modal-content'
                >
                    {!hideClose && (
                        <button
                            type="button"
                            className="Modal-close"
                            title="Закрыть"
                            aria-label="Закрыть модальное окно"
                            onClick={onClose}
                            data-testid='modal-close-button'
                        >
                            <Close style={styles.closeIcon} aria-hidden={!isOpen} />
                        </button>
                    )}

                    {isPadding && <div className={'Modal-padding ' + classNamePadding}>{children}</div>}
                    {!isPadding && children}
                </div>
            </div>
        </div>,
        document.body
    );

Modal.propsTypes = {
    isOpen: PropTypes.bool,
    isTop: PropTypes.bool,
    isBig: PropTypes.bool,
    isSmall: PropTypes.bool,
    isCalendar: PropTypes.bool,
    isPadding: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.element,
    hideClose: PropTypes.bool,
    classNamePadding: PropTypes.string
};

Modal.defaultProps = {
    isOpen: false,
    isTop: false,
    isBig: false,
    isSmall: false,
    isCalendar: false,
    isPadding: true,
    onClose: () => {},
    children: null,
    hideClose: false,
    classNamePadding: ''
};

export default Modal;
