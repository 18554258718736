import { fetcher } from 'checkin/shared/api';
import { toCamelCase } from 'checkin/shared/lib/toCamelCase';
import { ERRORS } from 'checkin/shared/consts';

export async function getTicketsApi(identifier, lastName) {
    // https://digital-ms-mcs-checkin-staging.dev.utair.io/docs#/Find%20Tickets/find_tickets_checkin_api_v1_tickets_get
    const { data } = await fetcher(`checkin/api/v1/tickets?identifier=${identifier}&lastName=${lastName}`);

    return toCamelCase(data);
}

export async function getUserTicketsApi() {
    // https://digital-ms-mcs-checkin-staging.dev.utair.io/docs#/Find%20Tickets/find_tickets_checkin_api_v1_tickets_user_get
    const { data } = await fetcher(`checkin/api/v1/tickets/user`);

    return toCamelCase(data);
}

export const TICKETS_ERROR_CODES = {
    TICKETS_NOT_FOUND: 14001,
    GROUP_IS_TOO_LARGE_FOR_GETTING_BOARDING_PASSES: 1025,
};

export function getTicketsError(errorCode) {
    switch (errorCode) {
        case TICKETS_ERROR_CODES.TICKETS_NOT_FOUND:
            return 'У вас нет активных зарегистрированных рейсов';

        case TICKETS_ERROR_CODES.GROUP_IS_TOO_LARGE_FOR_GETTING_BOARDING_PASSES:
            return 'Получение посадочных талонов для групп свыше 9 человек доступно только в аэропорту';

        default:
            return ERRORS.UNKNOWN_ERROR;
    }
}
