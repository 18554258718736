export const COOKIE_NAMES = {
    IDENTIFIER: 'checkin_form_new.identifier',
    LASTNAME: 'checkin_form_new.lastname',
};

export const OPTIONS = { expires: 1 / 24 };

export const PATHS = {
    SEARCH: '/check-in-new',
    PASSENGERS: '/check-in-new/passengers',
    SEATS: '/check-in-new/services/seats',
    BAGGAGE: '/check-in-new/services/baggage',
    MEAL: '/check-in-new/services/meal',
    SERVICES: '/check-in-new/services',
    PAYMENT: '/check-in-new/payment',
    SUMMARY: '/check-in-new/summary',
    FAILURE: '/check-in-new/failure',
    TICKETS: '/tickets',
};

export const ERRORS = {
    UNKNOWN_ERROR: 'Неизвестная ошибка',
};
