exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GridNext-module__root--1_7wY{display:flex;flex-wrap:wrap;padding:0 8px 16px}.GridNext-module__item--1rA50,.GridNext-module__item50--3t_MU{margin:16px 8px 0}.GridNext-module__item50--3t_MU{flex:0 0 calc(50% - 16px)}", ""]);

// exports
exports.locals = {
	"root": "GridNext-module__root--1_7wY",
	"item": "GridNext-module__item--1rA50",
	"item50": "GridNext-module__item50--3t_MU"
};