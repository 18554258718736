export const PASSENGER_TYPES = [
    { value: 'adult', apiKey: 'ADT' },
    { value: 'child', apiKey: 'CHD' },
    { value: 'infant', apiKey: 'INF' },
];

export const PASSENGER_API_KEYS = {
    ADULT: 'ADT',
    CHILD: 'CHD',
    INFANT: 'INF',
};

export const PASSENGER_PREFILL_TYPES = {
    ALL: 'all',
    ADULT: 'adult',
    CHILD: 'child',
};

export const FLIGHT_MAX_PASSENGERS_COUNT = 9;
export const FLIGHT_MAX_PASSENGERS_COUNT_WITH_VACATION = 9;
export const FLIGHT_MAX_PASSENGERS_COUNT_WITH_STANDBY = 9;
export const FLIGHT_MAX_PASSENGERS_COUNT_CHILDREN_ALONE = 3;
