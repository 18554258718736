/* eslint-disable */
import { call, all, takeEvery, put } from 'redux-saga/effects';

import * as TYPE from 'constants/subscriptionsTypes';

import {
    requestUserSubscriptions,
    fetchUserFetchStateFSA,
    setUserSubscriptionsFSA,
    userSubscriptionsErrorFSA,
    updateUserSubscriptionsRequest
} from 'actions/subscriptions';

function* fetchUserSubscriptionsSaga() {
    yield put(fetchUserFetchStateFSA(true));
    yield put(userSubscriptionsErrorFSA(null));
    try {
        const subscriptions = yield call(() => requestUserSubscriptions());
        yield put(setUserSubscriptionsFSA(subscriptions));
    } catch (e) {
        yield put(userSubscriptionsErrorFSA(e));
        yield put(fetchUserFetchStateFSA(false));
    }
}

function* updateUserSubscriptionsSaga({payload}) {
    yield put(fetchUserFetchStateFSA(true));
    yield put(userSubscriptionsErrorFSA(null));

    try {
        yield call(() => updateUserSubscriptionsRequest(payload));
        yield put(setUserSubscriptionsFSA(payload));
    } catch (e) {
        yield put(userSubscriptionsErrorFSA(e));
    }
    yield put(fetchUserFetchStateFSA(false));
}

export default function* () {
    yield all([
        takeEvery(TYPE.FETCH_USER_SUBSCRIPTIONS, fetchUserSubscriptionsSaga),
        takeEvery(TYPE.UPDATE_USER_SUBSCRIPTIONS, updateUserSubscriptionsSaga)
    ]);
}
