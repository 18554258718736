import { createSelector } from '@reduxjs/toolkit';

const getSignStore = (state) => state.sign;

export const getRegistrationState = createSelector(getSignStore, (store) => store.step);

export const getUserLogin = createSelector(getSignStore, (store) => store.inputValue);

export const getLoginError = createSelector(getSignStore, (store) => store.inputError);

export const getCode = createSelector(getSignStore, (store) => store.codeValue);

export const getCodeError = createSelector(getSignStore, (store) => store.codeError);

export const getHasTokenError = createSelector(getSignStore, (store) => store.hasTokenError);

export const getBusyState = createSelector(getSignStore, (store) => store.isBusy);

export const getCheckType = createSelector(getSignStore, (store) => store.checkType);

export const getCodeMessage = createSelector(getSignStore, (store) => store.code_message);

export const getGDPRAgreementDate = createSelector(getSignStore, (store) => store.GDPRAgreementDate);

export const getIsConfirmationByCall = createSelector(getSignStore, (store) => store.confirmationType === 'call_code');
