/* eslint-disable no-use-before-define */
import store from 'store';
import { API_URLS, LOCAL_STORAGE } from 'consts';
import { createFSA } from 'utils';
import { utairHttpManager as request } from 'managers/utair';
import { additionalPurchaseHttpManager } from 'managers/addditional-purchase';
import { getSelectedOrderBasket, updateBasketSection, getSelectedOrder } from 'selectors/orders';

export const SET_SESSION = 'SET_SESSION';
export const FETCH_SELECTED_ORDER = 'FETCH_SELECTED_ORDER';
export const SET_FETCH_ORDER_ERROR = 'SET_FETCH_ORDER_ERROR';

export const SET_SEAT_MAP = 'SET_SEAT_MAP';

export const FETCH_ACTUAL = 'FETCH_ACTUAL';
export const FETCH_HISTORY = 'FETCH_HISTORY';
export const CLEAR_ACTUAL = 'CLEAR_ACTUAL';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const SET_FETCHING = 'SET_FETCHING';

export const SET_FILTER_FLIGHT_NUMBER = 'SET_FILTER_FLIGHT_NUMBER';

export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
export const SET_APPLICABLE_SERVICE = 'SET_APPLICABLE_SERVICE';
export const SET_BASKET_SECTION = 'SET_BASKET_SECTION';
export const UPDATE_BASKET = 'orders/selectedOrder/basket/UPDATE_BASKET';
export const CLEAR_BASKET = 'orders/basket/CLEAR_BASKET';
export const SET_PAYMENT_PROCESS = 'SET_PAYMENT_PROCESS';
export const SET_PAYMENT_ERROR = 'SET_PAYMENT_ERROR';
export const SET_PAYMENT_REDIRECT_URL = 'SET_PAYMENT_REDIRECT_URL';

export const SET_RETURN_RESULT_ID = 'SET_RETURN_RESULT_ID';
export const SET_RETURN_ERROR = 'SET_RETURN_ERROR';
export const SET_RETURN_IS_FETCHING = 'SET_RETURN_IS_FETCHING';

export const START_POLLING_DOPS = 'START_POLLING_DOPS';
export const UDPATE_ACTUAL_ORDER = 'UDPATE_ACTUAL_ORDER';
export const POLL_ACTUAL_ORDER = 'POLL_ACTUAL_ORDER';

export const ENTRANCE_TO_ORDER_SERVICES = 'ENTRANCE_TO_ORDER_SERVICES';
export const PAYMENT_SELECTED_ORDER = 'PAYMENT_SELECTED_ORDER';
export const CHECK_CART_PAYMENT = 'CHECK_CART_PAYMENT';

export const SET_DELAYED_PAYMENT = 'SET_DELAYED_PAYMENT';
export const SET_IS_RESULT_MODAL_VISIBLE = 'SET_IS_RESULT_MODAL_VISIBLE';
export const SET_MPS_PAYMENT_URL = 'SET_MPS_PAYMENT_URL';
export const SET_PERSONAL_SALE_UTM = 'SET_PERSONAL_SALE_UTM';

export const fetchSelectedOrderFSA = createFSA(FETCH_SELECTED_ORDER);

export const startSession = (currency) =>
    request
        .get(API_URLS.FLIGHTS.START_SESSION, { currency, additional_data: '{"search":{"mode":"site"}}' })
        .then((data) => {
            store.dispatch({
                type: SET_SESSION,
                payload: data.session_token,
            });
            localStorage.setItem(LOCAL_STORAGE.SESSION_TOKEN, data.session_token);

            return data.session_token;
        });

/**
 * fetch all services list
 */

export const entranceToOrderServices = (uuid, query) => ({
    type: ENTRANCE_TO_ORDER_SERVICES,
    payload: { uuid, query },
});

export const getOrderByUUID = (uuid) =>
    request.get({
        url: API_URLS.ORDERS_V2.ORDER_BY_UUID(uuid),
        withCaptcha: true,
    });

export const getAvailableServices = (params, isUserAuth) =>
    additionalPurchaseHttpManager.get(API_URLS.ADDITIONAL_PURCHASE.AVAILABLE_SERVICES, params, isUserAuth);

export const fetchSeatMap = (params) =>
    additionalPurchaseHttpManager.get(API_URLS.ADDITIONAL_PURCHASE.SEAT_MAP, params);

export const fetchTicket =
    (orderUuid = '', rloc = '') =>
    () =>
        startSession().then((sessionToken) => {
            const params = {
                session_token: sessionToken,
            };

            if (orderUuid) {
                params.order_uuid = orderUuid;
            }

            if (rloc) {
                params.rloc = rloc;
            }

            return request.get(API_URLS.FLIGHTS.TICKET, params, true);
        });

/**
 * find item of basket by {service} and {value}
 * and modify item or create new item
 * @param {Object} service
 * @param {BasketItem} value
 */
export const addToBasket = (service, value) => (dispatch, getState) => {
    const basket = getSelectedOrderBasket(getState());
    const data = updateBasketSection(basket, service, value);
    putBasketSection(service, data);
};

/**
 * find item of basket by {service} and {value}
 * and remove item
 * @param {Object} service type
 * @param {BasketItem} value
 */
export const removeFromBasket = (service, value) => (dispatch, getState) => {
    const basket = getSelectedOrderBasket(getState());
    const data = updateBasketSection(basket, service, value, true);
    putBasketSection(service, data);
};

function servicesFromBasket(basket) {
    return Object.values(basket)
        .filter((services) => Array.isArray(services) && services.length > 0)
        .reduce((acc, services) => [...acc, ...services], [])
        .map((item) => {
            let text;

            if (item.freetext) {
                text = { text: item.freetext };
            }

            if (item.text) {
                text = { text: item.text };
            }

            return {
                passenger_id: item.passengerId,
                segment_id: item.segmentId,
                qtty: item.count,
                guid: item.guid,
                dynamic_key: item.dynamicKey,
                ...text,
            };
        });
}

async function createBasket(basket, state) {
    const { rloc, passengers } = getSelectedOrder(state);
    const personalSaleUTM = state.orders.personalSaleUTM || {};
    const params = {
        rloc: rloc.split('/')[0],
        last_name: passengers[0].last_name,
        services: servicesFromBasket(basket.services),
        utm_tags: { ...personalSaleUTM },
    };
    const resp = await additionalPurchaseHttpManager.post(API_URLS.ADDITIONAL_PURCHASE.CART, params);

    return resp;
}

/**
 * replace data of basket section
 * @param {Object} service type
 * @param {BasketItem[] | boolean} data
 */
export const putBasketSection = (service, data) => async (dispatch, getState) => {
    dispatch(setBasketSection(service.value, data));
    dispatch({ type: SET_FETCHING, payload: true });

    const basket = getSelectedOrderBasket(getState()); // orders.selectedOrder.basket

    if (basket.id) {
        const resp = await additionalPurchaseHttpManager.put(
            `${API_URLS.ADDITIONAL_PURCHASE.CART}${basket.id}/`,
            { services: servicesFromBasket(basket.services) },
            basket.lastModified
        );
        dispatch(updateBasket({ cartId: basket.id, lastModified: resp.responseHeaders.get('Last-Modified') }));
    } else {
        const resp = await createBasket(basket, getState());
        dispatch(updateBasket({ cartId: resp.result.id, lastModified: resp.responseHeaders.get('Last-Modified') }));
    }

    dispatch({ type: SET_FETCHING, payload: false });
};

export const reCreateBasket = () => async (dispatch, getState) => {
    dispatch({ type: SET_FETCHING, payload: true });
    const basket = getSelectedOrderBasket(getState()); // orders.selectedOrder.basket
    const resp = await createBasket(basket, getState());
    dispatch(updateBasket({ cartId: resp.result.id, lastModified: resp.responseHeaders.get('Last-Modified') }));
    dispatch({ type: SET_FETCHING, payload: false });
};

/**
 * pay selected order
 */

export const getCart = (cartId) => additionalPurchaseHttpManager.get(`${API_URLS.ADDITIONAL_PURCHASE.CART}${cartId}/`);

export const setFetching = (isFetching) => ({
    type: SET_FETCHING,
    payload: isFetching,
});

export const setReturnResultId = (resultId) => ({
    type: SET_RETURN_RESULT_ID,
    payload: resultId,
});

export const setReturnError = (error) => ({
    type: SET_RETURN_ERROR,
    payload: error,
});

export const setReturnIsFetching = (value) => ({
    type: SET_RETURN_IS_FETCHING,
    payload: value,
});

export const setSessionToken = (sessionToken) => ({
    type: SET_SESSION_TOKEN,
    payload: sessionToken,
});

/**
 * add applicable services for order
 * @param {string} rloc
 * @param {boolean | Set<string>} applicableServices
 */
export const setApplicableService = (rloc, applicableServices) => ({
    type: SET_APPLICABLE_SERVICE,
    payload: { rloc, applicableServices },
});

export const setBasketSection = (serviceKey, data) => ({
    type: SET_BASKET_SECTION,
    payload: { serviceKey, data },
});

export const updateBasket = (payload) => ({
    type: UPDATE_BASKET,
    payload,
});

export const setPaymentRedirectUrl = (url) => ({
    type: SET_PAYMENT_REDIRECT_URL,
    payload: url,
});

export const PREFILL_BASKET = 'utair/actions/orders/PREFILL_BASKET';

export const prefillBasket = (payload) => ({
    type: PREFILL_BASKET,
    payload,
});

export const startPollingDops = (payload) => ({
    type: START_POLLING_DOPS,
    payload,
});

export const updateActualOrder = (payload) => ({
    type: UDPATE_ACTUAL_ORDER,
    payload,
});

export const pollActualOrder = (payload) => ({
    type: POLL_ACTUAL_ORDER,
    payload,
});

export const setDelayedPayment = (payload) => ({
    type: SET_DELAYED_PAYMENT,
    payload,
});

export const setIsResultModalVisible = (payload) => ({
    type: SET_IS_RESULT_MODAL_VISIBLE,
    payload,
});

export const setMPSPaymentUrl = (payload) => ({
    type: SET_MPS_PAYMENT_URL,
    payload,
});

export const setPaymentProcess = (payload) => ({
    type: SET_PAYMENT_PROCESS,
    payload,
});

export const checkCartPayment = (payload) => ({
    type: CHECK_CART_PAYMENT,
    payload,
});

export const setPaymentError = (payload) => ({
    type: SET_PAYMENT_ERROR,
    payload,
});

export const setFetchOrderError = (payload) => ({
    type: SET_FETCH_ORDER_ERROR,
    payload,
});

export const clearBasket = () => ({
    type: CLEAR_BASKET,
});

export const paymentSelectedOrder = (payload) => ({
    type: PAYMENT_SELECTED_ORDER,
    payload,
});

export const setSeatMap = (payload) => ({
    type: SET_SEAT_MAP,
    payload,
});

export const setPersonalSaleUTM = (payload) => ({
    type: SET_PERSONAL_SALE_UTM,
    payload,
});
