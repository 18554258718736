import {
    CLEAR_CHAT_WIDGET_CONTEXT,
    OPEN_CHAT_WIDGET,
    CLOSE_CHAT_WIDGET,
    OPEN_CHAT_WIDGET_WITH_CONTEXT,
} from 'actions/chat-widget';

const INITIAL_STATE = {
    isWidgetOpen: false,
    widgetContext: null,
    contextProvided: false,
};

const chatWidgetReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case OPEN_CHAT_WIDGET_WITH_CONTEXT: {
            return {
                ...state,
                isWidgetOpen: true,
                widgetContext: payload,
                contextProvided: true,
            };
        }

        case OPEN_CHAT_WIDGET: {
            return {
                ...state,
                isWidgetOpen: true,
                widgetContext: payload,
            };
        }

        case CLOSE_CHAT_WIDGET: {
            return {
                ...state,
                isWidgetOpen: false,
            };
        }

        case CLEAR_CHAT_WIDGET_CONTEXT: {
            return {
                ...INITIAL_STATE,
                isWidgetOpen: state.isWidgetOpen,
            };
        }

        default: {
            return state;
        }
    }
};

export default chatWidgetReducer;
