/* eslint-disable max-len */
/* eslint camelcase: [0, {properties: "never"}] */

export const flights_search = {
    from: 'Откуда',
    to: 'Куда',
    one_way: 'Только туда',
    two_ways: 'Туда-обратно',
    dates_placeholder: 'Дата вылета',
    ticket_type: 'Тип билета',
};
