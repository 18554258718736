import InternalLayout, { Content, Footer, Header } from './Layout';

export type { BasicProps as LayoutProps } from './Layout';

type InternalLayoutType = typeof InternalLayout;

type CompoundedComponent = InternalLayoutType & {
    Header: typeof Header;
    Footer: typeof Footer;
    Content: typeof Content;
};

const Layout = InternalLayout as CompoundedComponent;

Layout.Header = Header;
Layout.Footer = Footer;
Layout.Content = Content;

export default Layout;
