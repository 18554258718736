import {
    SET_IS_TWO_WAY,
    SET_PASSENGERS,
    SET_FROM_DATE,
    SET_BACK_DATE,
    SET_FROM_CITY,
    SET_DEST_CITY,
    SWAP_CITIES,
    SET_CITIES_TO,
    SET_CITIES_FROM,
    SET_CITIES_POPULAR,
    SET_DEFAULT_CITIES_TO,
    SET_CITIES_ERROR,
    SET_AVAILABLE_FROM_DATES,
    SET_AVAILABLE_BACK_DATES,
    SET_TICKET_TYPE,
    SET_UNACCOMPANIED_MINOR,
} from './actions';

export const INITIAL_DATA = {
    isTwoWay: true,
    fromCity: null,
    destCity: null,
    passengers: {
        employeesCount: 0,
        adultsCount: 1,
        childrenCount: 0,
        infantsCount: 0,
    },
    ticketType: null,
    fromDate: null,
    availableFromDates: [],
    backDate: null,
    availableBackDates: [],
    citiesFrom: [],
    citiesTo: [],
    citiesPopular: [],
    defaultCitiesTo: [],
    citiesError: null,
    isAvailableFromDatesLoaded: false,
    isAvailableBackDatesLoaded: false,

    utmCampaign: null,
    utmMedium: null,
    utmSource: null,
};

export default function reducer(state, { type, payload }) {
    switch (type) {
        case SET_PASSENGERS:
            return { ...state, passengers: payload };

        case SET_IS_TWO_WAY:
            return { ...state, isTwoWay: payload };

        case SET_FROM_CITY:
            return { ...state, fromCity: payload, isAvailableFromDatesLoaded: false };

        case SET_DEST_CITY:
            return { ...state, destCity: payload, isAvailableBackDatesLoaded: false };

        case SWAP_CITIES:
            return {
                ...state,
                fromCity: state.destCity,
                destCity: state.fromCity,
                isAvailableFromDatesLoaded: false,
                isAvailableBackDatesLoaded: false,
            };

        case SET_FROM_DATE:
            return { ...state, fromDate: payload };

        case SET_AVAILABLE_FROM_DATES:
            return { ...state, availableFromDates: payload, isAvailableFromDatesLoaded: true };

        case SET_BACK_DATE:
            return { ...state, backDate: payload };

        case SET_AVAILABLE_BACK_DATES:
            return { ...state, availableBackDates: payload, isAvailableBackDatesLoaded: true };

        case SET_CITIES_TO:
            return { ...state, citiesTo: payload };

        case SET_CITIES_FROM:
            return { ...state, citiesFrom: payload };

        case SET_CITIES_POPULAR:
            return { ...state, citiesPopular: payload };

        case SET_DEFAULT_CITIES_TO:
            return { ...state, defaultCitiesTo: payload };

        case SET_CITIES_ERROR:
            return { ...state, citiesError: payload };

        case SET_TICKET_TYPE:
            return { ...state, ticketType: payload };

        case SET_UNACCOMPANIED_MINOR:
            return { ...state, isCheckedUnaccompaniedMinor: payload };

        default:
            return state;
    }
}
