/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { UNKNOWN } from './consts';
import type { Cart } from './types';

const initialState: Cart = {
    cartId: '',
    cost: 0,
    lastModifyDate: null,
    paymentMethods: {
        creditCard: false,
        mile: false,
        promocode: false,
    },
    maxWeightStatus: UNKNOWN,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        createCart(state, { payload }: PayloadAction<Cart>) {
            state.cartId = payload.cartId;
            state.cost = payload.cost;
            state.lastModifyDate = payload.lastModifyDate;
            state.paymentMethods = payload.paymentMethods;
            state.maxWeightStatus = payload.maxWeightStatus;
        },
        updateCart(state, { payload }: PayloadAction<Omit<Cart, 'paymentMethods' | 'maxWeightStatus'>>) {
            state.cartId = payload.cartId;
            state.cost = payload.cost;
            state.lastModifyDate = payload.lastModifyDate;
        },
    },
});

export const selectors = {
    cost(state: RootState) {
        return state.upgrade.cart.cost;
    },
    cartId(state: RootState) {
        return state.upgrade.cart.cartId;
    },
    isAvailablePromoPayment(state: RootState) {
        return state.upgrade.cart.paymentMethods.promocode;
    },
    isAvailableMilePayment(state: RootState) {
        return state.upgrade.cart.paymentMethods.mile;
    },
};

export const { actions, name, reducer } = cartSlice;
