export const schedule = {
    title: 'Табло рейсов',
    radar_tooltip: 'Отследить полёт на flightradar24.com',
    subscribe_tooltip: 'Уведомлять об изменении статуса',
    date_est: 'расчетное',
    date_fact: 'фактическое',
    search_string: 'Введите номер рейса или город вылета/посадки',
    day_before: 'Вчера',
    today: 'Сегодня',
    day_after: 'Завтра',
    list: {
        flight: 'Рейс',
        departure: 'Вылет',
        plan: 'План',
        fact: 'Факт',
        arrival: 'Посадка',
        status: 'Статус',
        caption: 'Время вылета и посадки указано местное',
        load_more: 'Загрузить еще',
    },
    statuses: {
        'status-0': 'По расписанию',
        'status-1': 'Отменен',
        'status-2': 'Задержан',
        'status-3': 'Вылетел',
        'status-4': 'Посадка на запасном',
        'status-5': 'Совершил посадку',
        'status-6': 'Данные ожидаются',
        'status-7': 'Сел на запасной',
        'status-8': 'Взлетел с запасного',
    },
    fetching: 'Обновление списка рейсов',
    empty: 'Рейсы не найдены',
    subscribe: {
        title_subscribe: 'Подписаться на SMS оповещения',
        title_unsubscribe: 'Отписаться от SMS оповещения',
        subscribe: 'Подписаться',
        unsubscribe: 'Отписаться',
        confirm: 'Подтвердить',
        phone: 'Номер телефона',
        code: 'Код подтверждения',
        caption_1: 'Для того чтобы отказаться от рассылки, нажмите',
        caption_2: 'сюда',
        caption_3: 'и введите номер подписанного телефона',
        resend: 'Отправить еще раз',
        resend_text: 'Вы сможете отправить повторный запрос через ',
        resend_text_1: ' секунд.',
        error: {
            phone: 'Некорректный номер телефона',
            code: 'Некорректный код подтверждения',
            phone_subscribed: 'Указанный телефон уже подписан на этот рейс',
            phone_not_subscribed: 'Указанный телефон не подписан на этот рейс',
            incorrect_code: 'Неверный код подтверждения',
        },
    },
    meta: {
        title: 'Билеты Utair на официальном сайте - Напрямую дешевле!',
        description: 'Подробное расписание полетов на ближайшие даты на официальном сайте авиакомпании Ютэйр.',
        keywords:
            'расписание,онлайн расписание,расписание рейсов,расписание авиаперелетов,расписание рейсов по маршруту' +
            'перелет по маршруту,расписание авиаперелетов по маршруту,utair,ютейр,ютэйр,utr,ак ютейр,' +
            'ютеир,ютэир,расписание полетов',
    },
};
