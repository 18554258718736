const googleTagsManager = {
    push(data) {
        if (window.dataLayer) {
            return window.dataLayer.push(data);
        }

        return false;
    }
};

export default googleTagsManager;
