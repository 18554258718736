/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable padding-line-between-statements */
/* eslint-disable prefer-template */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import get from 'lodash/get';
import { API_URLS } from 'consts';
import { utairHttpManager as request } from 'managers/utair';
import { openCheckinPage } from 'actions/checkin';
import { fetchCountries } from 'actions/countries';

const ACTIONS_PREFIX = 'actions/order-manage/';

export const FIND_ORDERS_REQUEST = ACTIONS_PREFIX + 'FIND_ORDERS_REQUEST';
export const FIND_ORDERS_SUCCESS = ACTIONS_PREFIX + 'FIND_ORDERS_SUCCESS';
export const FIND_ORDERS_FAILURE = ACTIONS_PREFIX + 'FIND_ORDERS_FAILURE';
export const CONFIRM_CODE_REQUEST = ACTIONS_PREFIX + 'CONFIRM_CODE_REQUEST';
export const CONFIRM_CODE_SUCCESS = ACTIONS_PREFIX + 'CONFIRM_CODE_SUCCESS';
export const CONFIRM_CODE_FAILURE = ACTIONS_PREFIX + 'CONFIRM_CODE_FAILURE';
export const SET_CODE_CONFIRM_ERROR = ACTIONS_PREFIX + 'SET_CODE_CONFIRM_ERROR';
export const FETCH_CONTACTS_REQUEST = ACTIONS_PREFIX + 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCESS = ACTIONS_PREFIX + 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = ACTIONS_PREFIX + 'FETCH_CONTACTS_FAILURE';
export const GET_CODE_REQUEST = ACTIONS_PREFIX + 'GET_CODE_REQUEST';
export const GET_CODE_SUCCESS = ACTIONS_PREFIX + 'GET_CODE_SUCCESS';
export const GET_CODE_FAILURE = ACTIONS_PREFIX + 'GET_CODE_FAILURE';
export const FETCH_PAYMENT_LINK_REQUEST = ACTIONS_PREFIX + 'FETCH_PAYMENT_LINK_REQUEST';
export const FETCH_PAYMENT_LINK_SUCCESS = ACTIONS_PREFIX + 'FETCH_PAYMENT_LINK_SUCCESS';
export const FETCH_PAYMENT_LINK_FAILURE = ACTIONS_PREFIX + 'FETCH_PAYMENT_LINK_FAILURE';
export const SET_PARAMS = ACTIONS_PREFIX + 'SET_PARAMS';
export const SET_ORDERS = ACTIONS_PREFIX + 'SET_ORDERS';
export const SET_CONTACTS = ACTIONS_PREFIX + 'SET_CONTACTS';
export const SET_REFUND_SUM = ACTIONS_PREFIX + 'SET_REFUND_SUM';
export const SET_REFUND_KIND = ACTIONS_PREFIX + 'SET_REFUND_KIND';
export const SET_IS_ORDER_TKP = ACTIONS_PREFIX + 'SET_IS_ORDER_TKP';
export const SET_RLOC = ACTIONS_PREFIX + 'SET_RLOC';
export const SET_ATTEMPT_ID = ACTIONS_PREFIX + 'SET_ATTEMPT_ID';
export const SET_SELECTED_CONTACT = ACTIONS_PREFIX + 'SET_SELECTED_CONTACT';
export const SET_EMAIL_LIST = ACTIONS_PREFIX + 'SET_EMAIL_LIST';
export const SET_IS_LOADING = ACTIONS_PREFIX + 'SET_IS_LOADING';
export const ACTIVATE_ACCOUNT_REQUEST = ACTIONS_PREFIX + 'ACTIVATE_ACCOUNT_REQUEST';
export const ACTIVATE_ACCOUNT_SUCCESS = ACTIONS_PREFIX + 'ACTIVATE_ACCOUNT_SUCCESS';
export const ACTIVATE_ACCOUNT_FAILURE = ACTIONS_PREFIX + 'ACTIVATE_ACCOUNT_FAILURE';
export const SEND_NOTIFICATION_REQUEST = ACTIONS_PREFIX + 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = ACTIONS_PREFIX + 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = ACTIONS_PREFIX + 'SEND_NOTIFICATION_FAILURE';
export const SET_IS_NOTIFICATION_SUCCESS = ACTIONS_PREFIX + 'SET_IS_NOTIFICATION_SUCCESS';
export const SET_STEP = ACTIONS_PREFIX + 'SET_STEP';
export const SET_ERROR_CODE = ACTIONS_PREFIX + 'SET_ERROR_CODE';
export const SET_ERROR_DATA = ACTIONS_PREFIX + 'SET_ERROR_DATA';
export const RESET = ACTIONS_PREFIX + 'RESET';
export const FULL_RESET = ACTIONS_PREFIX + 'FULL_RESET';
export const SET_PAGE_SKIP = ACTIONS_PREFIX + 'SET_PAGE_SKIP';
export const SET_ORDERS_TOTAL_NUMBER = ACTIONS_PREFIX + 'SET_ORDERS_TOTAL_NUMBER';

export const DIALOG_STEP = {
    ERROR: 'ERROR',
    FINISH: 'FINISH',
    SELECT_CONTACT: 'SELECT_CONTACT',
    VOLUNTARY_ATTENTION: 'VOLUNTARY_ATTENTION',
    ENTER_CODE: 'ENTER_CODE',
    ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
};

export const commonErrorCodes = {
    ORDER_MADE_OFFLINE: 1101,
    unhandledError: '-',
    passengerAlreadyCheckin: 1106,
    returnRequestExist: 1110,
    returnRequestFopOnly: 1119,
    baseRefundError: 40010,
    orderNotFoundOrCanNotBeRefund: 40015,
    invalidData: 40020,
    errorDuringCalculation: 40025,
    orderAlreadyReturned: 40030,
    involuntaryRefundNotPossible: 40035,
    totalSumIsNotCorrect: 40040,
    orderRefundError: 40045,
    accountActivationIsFailed: 40050,
    refundNumberExceeded: 40310,
    orderNotFound: 40410,
    invalidContact: 40415,
    orderCanNotBeReturned: 40070,
    passengerHasAlreadyRegistered: 40065,
    TKPOrderCanNotBeReturned: 40075,
    tryAgainLater: 40080,
    firstReturnInsurance: 40081,
    hasAnotherAirline: 40082,
    delete: {
        orderNotFound: 10305,
        unableToBuildQuery: 10304,
        incorrectOrderUuid: 10307,
    },
    orderInArchive: 10116,
    orderConfirmAttemptsExceeded: 40320,
};
/**
 * Коды ошибок генерирумые на клиенте
 */
export const customErrorCodes = {
    returnIsNotAvailable: -10001,
    fetchPaymentLinkError: -20001,
    fetchPrintMkLink: -30001,
};

export const REFUND_KIND = {
    COMPENSATING: 'compensating',
    VOLUNTARY: 'voluntary',
    COMPELLED: 'compelled',
};

const findOrdersRequest = () => ({
    type: FIND_ORDERS_REQUEST,
});

const findOrdersSuccess = (orders) => ({
    type: FIND_ORDERS_SUCCESS,
    payload: orders,
});

const findOrdersFailure = () => ({
    type: FIND_ORDERS_FAILURE,
});

export const confirmCodeRequest = () => ({
    type: CONFIRM_CODE_REQUEST,
});

export const confirmCodeSuccess = () => ({
    type: CONFIRM_CODE_SUCCESS,
});

export const confirmCodeFailure = (error) => ({
    type: CONFIRM_CODE_FAILURE,
    payload: error,
});

export const setCodeConfirmError = (codeConfirmError) => ({
    type: SET_CODE_CONFIRM_ERROR,
    payload: codeConfirmError,
});

export const activateAccountRequest = () => ({
    type: ACTIVATE_ACCOUNT_REQUEST,
});

export const activateAccountSuccess = () => ({
    type: ACTIVATE_ACCOUNT_SUCCESS,
});

export const activateAccountFailure = () => ({
    type: ACTIVATE_ACCOUNT_FAILURE,
});

export const sendNotificationRequest = () => ({
    type: SEND_NOTIFICATION_REQUEST,
});

export const sendNotificationSuccess = () => ({
    type: SEND_NOTIFICATION_SUCCESS,
});

export const sendNotificationFailure = () => ({
    type: SEND_NOTIFICATION_FAILURE,
});

export const setIsNotificationSuccess = (flag) => ({
    type: SET_IS_NOTIFICATION_SUCCESS,
    payload: flag,
});

const fetchContactsRequest = () => ({
    type: FETCH_CONTACTS_REQUEST,
});

const fetchContactsSuccess = () => ({
    type: FETCH_CONTACTS_SUCCESS,
});

const fetchContactsFailure = () => ({
    type: FETCH_CONTACTS_FAILURE,
});

const getCodeRequest = () => ({
    type: GET_CODE_REQUEST,
});

const getCodeSuccess = () => ({
    type: GET_CODE_SUCCESS,
});

const getCodeFailure = () => ({
    type: GET_CODE_FAILURE,
});

const setParams = (params) => ({
    type: SET_PARAMS,
    payload: params,
});

const setOrders = (orders) => ({
    type: SET_ORDERS,
    payload: orders,
});

const setContacts = (contacts) => ({
    type: SET_CONTACTS,
    payload: contacts,
});

const setRefundSum = (refund) => ({
    type: SET_REFUND_SUM,
    payload: refund,
});

const setRefundKind = (refundKind) => ({
    type: SET_REFUND_KIND,
    payload: refundKind,
});

export const setIsOrderTkp = (isOrderTkp) => ({
    type: SET_IS_ORDER_TKP,
    payload: isOrderTkp,
});

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: isLoading,
});

export const setStep = (step) => ({
    type: SET_STEP,
    payload: { step },
});

export const setRloc = (rloc) => ({
    type: SET_RLOC,
    payload: rloc,
});

export const setAttemptId = (attemptId) => ({
    type: SET_ATTEMPT_ID,
    payload: attemptId,
});

export const setSelectedContact = (contact) => ({
    type: SET_SELECTED_CONTACT,
    payload: contact,
});

export const setEmailList = (emailList) => ({
    type: SET_EMAIL_LIST,
    payload: emailList,
});

export const reset = () => ({
    type: RESET,
});

export const fullReset = () => ({
    type: FULL_RESET,
});

export const setErrorCode = (errorCode) => ({
    type: SET_ERROR_CODE,
    payload: errorCode,
});

export const setErrorData = (errorData) => ({
    type: SET_ERROR_DATA,
    payload: errorData,
});

export const setPageSkip = (pageSkip) => ({
    type: SET_PAGE_SKIP,
    payload: pageSkip,
});

export const setOrdersTotalNumber = (totalNumber) => ({
    type: SET_ORDERS_TOTAL_NUMBER,
    payload: totalNumber,
});

export const errorHandler = (error, activityType, errorData) => (dispatch) => {
    const code = get(error, 'data.meta.error_code', commonErrorCodes.unhandledError);
    const data = {
        ...(get(error, 'data.data', {}) || {}),
        ...(activityType ? { _activityType: activityType } : {}),
        ...(errorData || {}),
    };

    dispatch(setErrorCode(code));
    dispatch(setErrorData(data));
    dispatch(setStep(DIALOG_STEP.ERROR));
};

const requestOrders = (locator, lastName, params = {}) =>
    request.get({
        url: API_URLS.ORDERS_V2.LIST_APP,
        query: {
            'filters[locator]': locator,
            'filters[passenger_lastname]': lastName,
            ...params,
        },
        withCaptcha: true,
    });

const requestUserOrders = (params, isUserAuth) =>
    request.get({
        url: API_URLS.ORDERS_V2.LIST,
        query: params,
        isUserAuth,
    });

const mapPassengers = (passengers) =>
    passengers.map((passenger) => ({
        id: passenger.passenger_id,
        firstName: passenger.first_name,
        secondName: passenger.second_name,
        lastName: passenger.last_name,
        birthday: passenger.birthday,
        type: passenger.type,
        docType: passenger.doctype,
        docNumber: passenger.docnumber,
        docCountry: passenger.doccountry,
        docExpiration: passenger.docexpiration,
    }));

const mapSegments = (segments) =>
    segments.map((segment) => ({
        id: segment.segment_id,
        departureCity: segment.departure_city,
        arrivalCity: segment.arrival_city,
        departureAirportCode: segment.departure_airport_code,
        arrivalAirportCode: segment.arrival_airport_code,
        departureDateIso: segment.departure_local_iso,
        ak: segment.ak,
        flightNumber: segment.flight_number,
        status: segment.status,
        statusVisual: segment.status_visual,
    }));

const mapRloc = (rloc) => rloc.split('/')[0];

const getPassengerCounter = (passengers) =>
    passengers.reduce(
        (acc, passenger) => ({
            ...acc,
            [passenger.type]: passenger.type in acc ? acc[passenger.type] + 1 : 1,
        }),
        {}
    );

const mapOrders = (orders) =>
    orders.map((order) => ({
        id: order.order_id,
        uuid: order.order_uuid,
        key: order.order_key,
        availableActions: order.available_actions,
        isInvoluntary: order.is_involuntary,
        isRegistered: order.is_registered,
        rloc: mapRloc(order.rloc),
        status: order.status,
        segments: mapSegments(order.segments),
        passengers: mapPassengers(order.passengers),
        passengerCounters: getPassengerCounter(order.passengers),
        orderActions: order.order_actions,
    }));

const createSyntheticError = (objects, dispatch) => {
    // synthetic error style message
    if (objects && Array.isArray(objects) && objects.length === 0) {
        dispatch(
            errorHandler(
                {
                    data: {
                        meta: {
                            error_code: commonErrorCodes.orderNotFound,
                        },
                    },
                },
                'request-orders'
            )
        );
    }
};

export const findOrders = (locator, lastName) => async (dispatch, getState) => {
    dispatch(findOrdersRequest());

    try {
        dispatch(setParams({ locator, lastName }));
        const [{ objects }] = await Promise.all([requestOrders(locator, lastName), fetchCountries(dispatch, getState)]);
        dispatch(findOrdersSuccess());
        dispatch(setOrders(mapOrders(objects)));

        createSyntheticError(objects, dispatch);
    } catch (error) {
        dispatch(errorHandler(error, 'request-orders'));
        dispatch(findOrdersFailure());
        dispatch(setOrders([]));
    }
};

export const findUserOrders = (locator, lastName) => async (dispatch, getState) => {
    dispatch(findOrdersRequest());
    const state = getState();
    const pageSkip = state.orderManage.pageSkip;
    const currentOrder = state.orderManage.orders;
    const isUserAuth = state.auth.isAuthUser;
    const ordersLimit = 5;
    const hasSearchParams = !!(locator && lastName);

    try {
        const params = {
            'page[skip]': pageSkip,
            'page[limit]': ordersLimit,
        };

        const [{ objects, total }] = await Promise.all([
            hasSearchParams ? requestOrders(locator, lastName, params) : requestUserOrders(params, isUserAuth),
            fetchCountries(dispatch, getState),
        ]);

        if (hasSearchParams) {
            dispatch(setParams({ locator, lastName }));

            createSyntheticError(objects, dispatch);
        }

        dispatch(findOrdersSuccess());
        dispatch(setOrders([...currentOrder, ...mapOrders(objects)]));
        dispatch(setPageSkip(pageSkip + ordersLimit));
        dispatch(setOrdersTotalNumber(total));
    } catch (error) {
        dispatch(errorHandler(error, 'request-orders'));
        dispatch(findOrdersFailure());
        dispatch(setOrders([]));
    }
};

const mapContacts = (contacts) => contacts.map((contact) => contact);

const createMapPartsSum = (partsSum) => partsSum.reduce((acc, item) => ({ ...acc, [item.type]: item.amount }), {});

const mapRefundSum = (refundTypes) => {
    const mappedPartSum = createMapPartsSum(refundTypes[0].parts_sum);

    return {
        miles: mappedPartSum.miles || 0,
        bonus: refundTypes[0].bonus_sum,
        cash: mappedPartSum.cc || 0,
    };
};

const fetchContacts = (rloc, lastName) =>
    request.get(API_URLS.SUPPLEMENTS.CONTACTS_BY_RLOC_AND_LAST_NAME(rloc, lastName));

export const fetchOrderContacts = (rloc, lastName) => async (dispatch) => {
    dispatch(fetchContactsRequest());
    dispatch(setRloc(rloc));
    dispatch(setParams({ locator: rloc, lastName }));

    try {
        const { data } = await fetchContacts(rloc, lastName);
        dispatch(setContacts(mapContacts(data.contacts)));
        dispatch(setRefundSum(mapRefundSum(data.refund_type)));
        dispatch(setRefundKind(data.refund_kind));
        dispatch(setIsOrderTkp(data.is_order_tkp));
        dispatch(setStep(DIALOG_STEP.VOLUNTARY_ATTENTION));
        dispatch(fetchContactsSuccess());
    } catch (error) {
        dispatch(setContacts([]));
        dispatch(setRefundSum({ miles: null, bonus: null, cash: null }));
        dispatch(errorHandler(error));
        dispatch(fetchContactsFailure());
    }
};

export const changeDate = (uuid) => (_, getState) => {
    const ticketExchangeMobWebUrl = `https://www.utair.ru/mobile/order-manage/ticket-exchange/${uuid}`;
    const ticketExchangeWebsiteUrl = `https://www.utair.ru/order-manage/ticket-exchange/${uuid}`;

    const state = getState();

    const { desktop: isDesktop } = state.media;

    if (isDesktop) {
        window.location.href = ticketExchangeWebsiteUrl;
    } else {
        window.location.href = ticketExchangeMobWebUrl;
    }
};

export const cancelOnlineRegistration = () => (dispatch, getState) => {
    const state = getState();
    const { locator, lastName } = state.orderManage.params;
    const { orders } = state.orderManage;
    const firstOrder = orders[0];

    if (!firstOrder) {
        return;
    }

    const flightNumber = firstOrder.segments[0].flightNumber;

    dispatch(openCheckinPage(flightNumber, lastName, locator));
};

const requestCode = (rloc, lastName, contact, sum, refundKind) =>
    request.post(
        API_URLS.SUPPLEMENTS.GET_CODE_BY_RLOC_AND_LAST_NAME(rloc, lastName),
        {
            contact,
            refund_type: 'mile',
            total_sum: sum,
            refund_kind: refundKind,
        },
        false,
        true
    );

export const getCode = (contact) => async (dispatch, getState) => {
    dispatch(getCodeRequest());

    const state = getState();
    const rloc = state.orderManage.rloc;
    const lastName = state.orderManage.params.lastName;
    const refundSum = state.orderManage.refundSum;
    const refundKind = state.orderManage.refundKind || REFUND_KIND.COMPENSATING;

    const sum = Number(refundSum.cash) + Number(refundSum.miles) + Number(refundSum.bonus);

    try {
        const { data } = await requestCode(rloc, lastName, contact, sum, refundKind);

        const attemptId = data.attempt_id;

        dispatch(getCodeSuccess());
        dispatch(setAttemptId(attemptId));
        dispatch(setSelectedContact(contact));

        dispatch(setStep(DIALOG_STEP.ENTER_CODE));
    } catch (error) {
        dispatch(getCodeFailure());
        dispatch(errorHandler(error));
    }
};

const requestConfirmCode = (code, rloc, lastName, attemptId) =>
    request.post(API_URLS.SUPPLEMENTS.CONFIRM_CODE(rloc, lastName), {
        code,
        attempt_id: attemptId,
    });

const STATUS_TYPE = {
    SUCCESS: 'success',
    WAITING_FOR_USER_UPDATE: 'waiting_for_user_update',
};

const getEmailList = (notifications) =>
    notifications.filter((item) => item.message_type === 'email').map((item) => item.message_source);

export const confirmCode = (code) => async (dispatch, getState) => {
    dispatch(confirmCodeRequest());

    const state = getState();

    const rloc = state.orderManage.rloc;
    const lastName = state.orderManage.params.lastName;
    const attemptId = state.orderManage.attemptId;

    try {
        const { data } = await requestConfirmCode(code, rloc, lastName, attemptId);
        const { status, notifications } = data;
        dispatch(confirmCodeSuccess());

        if (status === STATUS_TYPE.WAITING_FOR_USER_UPDATE) {
            dispatch(setStep(DIALOG_STEP.ACTIVATE_ACCOUNT));
        } else {
            dispatch(setStep(DIALOG_STEP.FINISH));
            dispatch(setEmailList(getEmailList(notifications)));
        }
    } catch (error) {
        const errorCode = get(error, 'data.meta.error_code');
        let errorMessage = '';

        if (errorCode === commonErrorCodes.invalidData) {
            errorMessage = 'Неверно введен код';
        } else if (errorCode === commonErrorCodes.orderConfirmAttemptsExceeded) {
            errorMessage = 'Возврат заблокирован';
        } else {
            dispatch(errorHandler(error));
        }

        dispatch(confirmCodeFailure(errorMessage));
    }
};

const requestActivateAccount = (rloc, queryLastName, attemptId, { firstName, lastName, birthday, agreementDate }) =>
    request.post(API_URLS.SUPPLEMENTS.ACTIVATE_ACCOUNT(rloc, queryLastName), {
        birthday,
        attempt_id: attemptId,
        first_name: firstName,
        last_name: lastName,
        gdpr_date: agreementDate,
    });

export const activateAccount = (firstName, lastName, birthday, agreementDate) => async (dispatch, getState) => {
    dispatch(activateAccountRequest());

    const state = getState();
    const rloc = state.orderManage.rloc;
    const queryLastName = state.orderManage.params.lastName;
    const attemptId = state.orderManage.attemptId;

    try {
        await requestActivateAccount(rloc, queryLastName, attemptId, { firstName, lastName, birthday, agreementDate });
        dispatch(activateAccountSuccess());
        dispatch(setStep(DIALOG_STEP.FINISH));
    } catch (error) {
        dispatch(activateAccountFailure());
        dispatch(errorHandler(error));
    }
};

const requestSendNotification = (rloc, queryLastName, email) =>
    request.post(
        API_URLS.SUPPLEMENTS.SEND_NOTIFICATION(rloc, queryLastName),
        {
            email,
        },
        false,
        true
    );

export const sendNotification = (email) => async (dispatch, getState) => {
    dispatch(sendNotificationRequest());

    const state = getState();
    const rloc = state.orderManage.rloc;
    const queryLastName = state.orderManage.params.lastName;

    try {
        const { data } = await requestSendNotification(rloc, queryLastName, email);
        dispatch(sendNotificationSuccess());
        dispatch(setIsNotificationSuccess(true));
        dispatch(setEmailList(getEmailList(data.notifications)));
    } catch (error) {
        dispatch(sendNotificationFailure());
        dispatch(errorHandler(error));
    }
};

export const openSelectContactModal = () => (dispatch) => {
    dispatch(setStep(DIALOG_STEP.SELECT_CONTACT));
    dispatch(setCodeConfirmError(''));
};

export const fetchPrintMKLink = (order) => async (dispatch) => {
    try {
        const lastName = order.passengers && order.passengers.length && order.passengers[0].lastName;

        if (!lastName || !order.uuid) {
            throw new Error('No order uuid or passenger last name set');
        }

        const { download_link } = await request.get(
            API_URLS.ORDERS.ITINERARY_RECEIPT(order.uuid, lastName),
            null,
            false
        );

        if (!download_link) {
            throw new Error('Cant get link');
        }

        return download_link;
    } catch (error) {
        dispatch(setErrorCode(customErrorCodes.fetchPrintMkLink));
        dispatch(setStep(DIALOG_STEP.ERROR));
    }
};

export const deleteOrder = (uuid) => async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    try {
        await request.delete(API_URLS.ORDERS_V2.DELETE(uuid), null, true);

        const state = getState();
        const orders = state.orderManage.orders.filter((order) => order.uuid !== uuid);

        dispatch(setOrders(orders));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setIsLoading(false));
    }
};
