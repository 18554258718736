/* eslint-disable */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { INPUT_DEBOUNCE_TIMEOUT } from 'consts';

import './CityAutocomplete.scss';

export class CityFinder extends React.PureComponent {
    static propTypes = {
        placeholder: PropTypes.string,
        directionClassName: PropTypes.string,
        error: PropTypes.bool,
        selectedCity: PropTypes.shape({
            code: PropTypes.string,
            name: PropTypes.string,
        }),
        cities: PropTypes.arrayOf(PropTypes.shape()),
        defaultCities: PropTypes.arrayOf(PropTypes.shape()),
        onInput: PropTypes.func,
        onChange: PropTypes.func,
        onRef: PropTypes.func,
        onOpenDropdown: PropTypes.func,
        onCloseDropdown: PropTypes.func,
        testid: PropTypes.string.isRequired,
        hasIncorrectTextError: PropTypes.bool,
    };

    static defaultProps = {
        placeholder: '',
        directionClassName: '',
        error: false,
        selectedCity: null,
        cities: [],
        defaultCities: [],
        onInput: () => {},
        onChange: () => {},
        onRef: () => {},
        onOpenDropdown: () => {},
        onCloseDropdown: () => {},
        hasIncorrectTextError: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            optionIdx: 0,
            optionName: '',
            optionValue: '',
            isOpenDropdown: false,
        };

        this.inputEl = null;
        this.debouncedInput = _.debounce(props.onInput, INPUT_DEBOUNCE_TIMEOUT);
    }

    get visibleCities() {
        const { optionName } = this.state;
        const { hasIncorrectTextError, cities, defaultCities } = this.props;

        if (hasIncorrectTextError) {
            return [];
        }

        return optionName ? cities : defaultCities;
    }

    /**
     * set selected city code, name to state
     */
    componentWillMount() {
        const { selectedCity } = this.props;

        this.props.onRef(this);

        if (selectedCity) {
            this.setState({
                optionName: selectedCity.name,
                optionValue: selectedCity.code,
            });
        }
    }

    /**
     * set changed selected city
     * set first city from list if field is typed and cities have been changed
     */
    componentWillReceiveProps(nextProps) {
        const { selectedCity } = this.props;

        if (nextProps.selectedCity && (!selectedCity || nextProps.selectedCity.code !== selectedCity.code)) {
            this.setState({
                optionName: nextProps.selectedCity.name,
                optionValue: nextProps.selectedCity.code,
            });
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    openDropdown = () => {
        this.setState({ isOpenDropdown: true });
        this.props.onOpenDropdown();
    };

    closeDropdown = () => {
        this.setState({ isOpenDropdown: false });
        this.props.onCloseDropdown();
    };

    /**
     * shift option item down (+1) or up (-1)
     * @param {number} offset
     */
    shiftOptionIndex(offset) {
        const optionsLen = this.visibleCities.length;
        let newIndex = this.state.optionIdx + offset;

        if (newIndex < 0) {
            newIndex += optionsLen;
        } else if (newIndex >= optionsLen) {
            newIndex -= optionsLen;
        }

        this.setState({ optionIdx: newIndex });
    }

    selectCity(city) {
        this.setState({
            optionName: city ? city.name : '',
            optionValue: city ? city.code : '',
        });
        this.closeDropdown();
        this.props.onChange(city || null);
    }

    setInputEl = (el) => {
        this.inputEl = el;
    };

    focus = () => {
        this.inputEl && this.inputEl.focus();
    };

    handleChange = (event) => {
        const value = event.target.value;

        this.setState({
            optionName: value,
            optionValue: '',
            optionIdx: 0,
        });
        this.debouncedInput(value);
    };

    handleKeyDown = (event) => {
        const visibleCities = this.visibleCities;
        const hasVisibleCities = visibleCities.length > 0;
        const focusedCity = visibleCities[this.state.optionIdx];

        switch (event.keyCode) {
            case 13: // Enter
                event.preventDefault();
                if (focusedCity) {
                    this.selectCity(focusedCity);
                }
                break;
            case 9: // Tab
                if (focusedCity) {
                    event.preventDefault();
                    this.selectCity(focusedCity);
                } else {
                    this.closeDropdown();
                }
                break;
            case 40: // Down
                event.preventDefault();
                if (hasVisibleCities) {
                    this.setState({ optionValue: '' });
                    this.shiftOptionIndex(1);
                }
                break;
            case 38: // Up
                event.preventDefault();
                if (hasVisibleCities) {
                    this.setState({ optionValue: '' });
                    this.shiftOptionIndex(-1);
                }
                break;
            case 27: // Escape
                event.preventDefault();
                this.closeDropdown();
                break;
            default:
                break;
        }
    };

    /**
     * show first city from list
     * otherwise empty strings
     */
    handleClickOutside = () => {
        const { optionName, isOpenDropdown } = this.state;
        const visibleCities = this.visibleCities;

        this.inputEl.blur();

        if (!isOpenDropdown) {
            return;
        }

        if (!optionName) {
            this.selectCity(null);
        } else if (visibleCities.length) {
            const firstCity = visibleCities[0];
            this.selectCity(firstCity);
        } else {
            this.closeDropdown();
        }
    };

    handleFocus = () => {
        this.openDropdown();
        this.inputEl.select();
    };

    render() {
        const { placeholder, directionClassName, testid } = this.props;
        const { optionName, optionValue, isOpenDropdown } = this.state;
        const visibleCities = this.visibleCities || [];

        // create autocomplete string from name of first city
        const firstCityName = visibleCities.length ? visibleCities[0].name : null;
        const typedChars = optionName.length;
        let autoCompleteString = '';
        if (
            firstCityName &&
            typedChars &&
            optionName.toLowerCase() === firstCityName.substring(0, typedChars).toLowerCase()
        ) {
            autoCompleteString = firstCityName.substring(typedChars);
        }

        return (
            <div className="SearchWidget-box -city">
                <input
                    value={optionName}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.handleFocus}
                    placeholder={placeholder}
                    className={classNames(`SearchWidget-input ${directionClassName}`, { 'is-error': this.props.error })}
                    ref={this.setInputEl}
                    type="text"
                    spellCheck={false}
                    tabIndex="1"
                    data-testid={testid}
                />

                {optionName.length > 0 && isOpenDropdown && (
                    <div className={`SearchWidget-input ${directionClassName} -placeholder`} aria-hidden="true">
                        <span className="SearchWidget-optionName">{optionName}</span>
                        {autoCompleteString}
                    </div>
                )}

                {!!visibleCities.length && (
                    <div className="SearchWidget-dropdown" aria-hidden={!isOpenDropdown}>
                        <div className="CityAutocomplete">
                            <ul className="CityAutocomplete-container">
                                {visibleCities.map((item, key) => this.renderOption(item, key))}
                            </ul>
                        </div>
                    </div>
                )}

                {optionValue && optionName && (
                    <span className={`SearchWidget-abbr ${directionClassName}`}>{optionValue}</span>
                )}
            </div>
        );
    }

    renderOption(city, key) {
        return (
            <li
                className={classNames('CityAutocomplete-box', { 'Select-option-active': key === this.state.optionIdx })}
                key={city.code}
                onClick={() => this.selectCity(city)}
            >
                <b className="CityAutocomplete-name">{city.name}</b>
                <span className="caption block">{city.country}</span>
                <small className="CityAutocomplete-abbr">{city.code}</small>
            </li>
        );
    }
}

export const WrappedCityFinder = onClickOutside(CityFinder);
