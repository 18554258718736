import { RESET_ERROR, SET_ERROR } from './actions';

export const qratorError = (state = { supportId: '' }, { type, payload }) => {
    switch (type) {
        case SET_ERROR:
            return { supportId: payload };

        case RESET_ERROR:
            return { supportId: '' };

        default:
            return state;
    }
};
