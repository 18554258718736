import React from 'react';
import { Modal, Button } from '@public-projects/utair-ui-kit';

export function ModalTemplate({ Image, text, description, buttonText, onClose, onButtonClick, size = 'small' }) {
    return (
        <Modal size={size} onClose={onClose}>
            <Modal.Image>
                <Image width={160} height={160} />
            </Modal.Image>
            <Modal.Title centered data-testid="ModalTemplate-Title">
                {text}
            </Modal.Title>
            {description && (
                <Modal.Description centered data-testid="ModalTemplate-Description">
                    {' '}
                    {description}
                </Modal.Description>
            )}
            {buttonText && (
                <Modal.Buttons>
                    <Button onClick={onButtonClick}>{buttonText}</Button>
                </Modal.Buttons>
            )}
        </Modal>
    );
}
