/* eslint camelcase: [0, {properties: "never"}] */

export const main_page = {
    title: 'Winter travel',
    more_details: '',
    link_title: 'Learn more',
    checkin_promo: {
        title: 'Check in online for flights by Utair',
        header: 'Check in online for free and choose the best seats!',
        promos: [
            'Reserve a space+ seat for your comfort&Online check-in opens ' +
                '36 hours before flight - earlier than check-in at the airport. ' +
                'Reserve best seats in advance when they are still available.',
            'Add checked-in baggage online at best price&Airport fee for excess luggage is up to 40% higher.',
            'Save your time at the airport&Save or print your boarding pass to never ' +
                'stand the queue at the airport. You can proceed straight to security ' +
                'control or luggage drop-off.',
        ],
        attention: 'We recommend arriving at the airport in advance',
        attentionLink: 'Learn more about check-in and boarding',
    },
    meta: {
        description:
            'Book tickets on the official site utair.ru. Tickets without ' +
            'commissions and extra costs. Get miles refunded up to 7%. ' +
            'Check in for free.',
    },
};
