/* eslint-disable max-len */
export const sign = {
    header_sing_up: 'Create an account',
    header_sing_in: 'Log in',
    oauth_login: 'Login via',
    placeholder: {
        login: 'Enter email, phone or Utair Status card',
        registrationLogin: 'Enter email or phone',
        phone: 'Phone',
        email: 'Email',
        card: 'Utair Status card',
        code_full: 'Enter code',
        call_code_full: 'Enter the last 4 digits of the incoming call number',
        code: 'Confirmation code',
    },
    resend: 'Request another code',
    call_again: 'Request call again',
    accept_rules: {
        title: 'Creating an account, I accept',
        link: 'the terms and conditions',
        processing_text: ', allow for',
        processing_link: 'my account data processing',
        processing_rules: `Настоящее согласие предоставляется Авиакомпании на осуществление любых\n
            действий (операций), совершаемых с использованием средств автоматизации или\n
            без использования таких средств с персональными данными, включая сбор,\n
            запись, систематизацию, накопление, хранение, уточнение (обновление,\n
            изменение), извлечение, использование, передачу (распространение,\n
            предоставление, доступ), обезличивание, блокирование, удаление, уничтожение\n
            персональных данных, трансграничную передачу персональных данных, а также\n
            осуществление любых иных действий с моими персональными данными,\n
            предусмотренных действующим законодательством Российской Федерации.`,
        status_text: 'and accept',
        status_link: 'Utair Status program rules',
    },
    phone: 'phone',
    error: {
        api_error: 'Ошибка регистрации пользователя. Попробуйте повторить позднее.',
        incorrect_code: 'Incorrect code',
        social_auth_error: 'Ошибка входа. Попробуйте позднее или заполните обязательные поля',
        code_expired: 'Code expired',
        exceeded_attempts: 'Attempts exceeded. Please try again later',
        send_code: 'Ошибка отправки проверочного кода',
        login_not_found: 'User not found',
        too_many_requests: 'Too many requests. Please try again later',
        user_blocked: 'Your account is blocked. Please contact the Call Centre',
        social_email_exists: 'User already exists. \u00A0 Please log in',
        social_phone_exists: 'User alreay exists.\u00A0 Please log in',
        phone_disabled: 'Пользователь отключил авторизацию через SMS',
        phone_incorrect: 'Невозможно отправить СМС на\u00A0номер в\u00A0вашем профиле',
        send_code_error: 'Error sending code',
        no_contacts: ' not associated with \u00A0any email or phone',
        confirm_attempts_exceeded_text:
            'The request limit has been exceeded. Get a new confirmation code tomorrow or contact',
        confirm_attempts_exceeded_link: 'support',
    },
    countdown_message: 'You can request another code in {{time}} sec',
    countdown_call_message: 'You can request call again in {{time}} sec',
    code_sended_to_email: 'sent to your Email',
    code_sended_to_phone: 'sent to your phone',
    social: {
        yandex: 'Log in with Yandex ID',
    },
};
