/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import type { InjectedRouter } from 'react-router';

import { routeModel } from 'schedule/entities/route';
import { Route } from 'schedule/features/choose-route';
import { Calendar } from 'schedule/features/select-flight-dates';
import { sharedRoutes } from 'schedule/shared/config';
import { generatePath, getEndOfWeek, getIsoDate, getStartOfWeek, useIsMobile } from 'schedule/shared/lib';
import type { City } from 'schedule/shared/model';
import { useAction, useData } from 'schedule/shared/model';
import { Button } from 'schedule/shared/ui/Button';

import styles from './SearchForm.module.scss';

type SearchFormProps = {
    router: InjectedRouter;
};

export function SearchForm({ router }: SearchFormProps) {
    const { departureCity, arrivalCity, departureDateFrom, departureDateTo } = useData();
    const { setData } = useAction();
    const [selectedCities, setSelectedCities] = useState<{ from: City | null; to: City | null }>({
        from: null,
        to: null,
    });
    const [departureDates, setDepartureDates] = useState<{ from: moment.Moment | null; to: moment.Moment | null }>({
        from: null,
        to: null,
    });

    const { data: availableDates } = routeModel.useGetAvailableDates(
        selectedCities.from?.code || null,
        selectedCities.to?.code || null
    );

    useEffect(() => {
        if (!!availableDates && availableDates.length > 0) {
            const currentStartOfWeek = getStartOfWeek();
            const firstAvailableDate = availableDates.find((d) => currentStartOfWeek.isBefore(d, 'day')) || null;
            setData((cur) => ({ ...cur, firstAvailableDate }));
        }
    }, [availableDates, setData]);

    const isMobile = useIsMobile();

    useEffect(() => {
        setDepartureDates((cur) => ({ ...cur, from: departureDateFrom, to: departureDateTo }));
    }, [departureDateFrom, departureDateTo]);

    useEffect(() => {
        setSelectedCities((cur) => ({ ...cur, from: departureCity, to: arrivalCity }));
    }, [departureCity, arrivalCity]);

    const handleFindClick = () => {
        setData((cur) => ({
            ...cur,
            departureCity: selectedCities.from,
            arrivalCity: selectedCities.to,
            departureDateFrom: departureDates.from,
            departureDateTo: departureDates.to,
        }));

        if (!selectedCities.to?.code) {
            router.push(sharedRoutes.PATHS.ROUTES_PAGE);
        } else {
            const dateFrom = getIsoDate(departureDates.from);
            const dateTo = getIsoDate(departureDates.to);

            router.push(
                generatePath(
                    sharedRoutes.PATHS.ROUTE_SCHEDULE_PAGE,
                    {
                        cityFrom: selectedCities.from?.code || null,
                        cityTo: selectedCities.to?.code || null,
                    },
                    {
                        dateFrom,
                        dateTo,
                    }
                )
            );
        }
    };

    return (
        <form className={cn('!pt32', '!pb32', styles.form)}>
            <div className={cn(styles.block, styles.route)}>
                <Route
                    values={{ cityFrom: selectedCities.from, cityTo: selectedCities.to }}
                    onChange={({ cityFrom, cityTo }) => {
                        setSelectedCities({ from: cityFrom, to: cityTo });
                    }}
                    quickFiltersCityTo={
                        <div className={cn(styles.btn, { '!pl16': !isMobile })}>
                            <Button
                                variant="text"
                                size="medium"
                                inverted
                                onClick={() => setSelectedCities((cur) => ({ ...cur, to: null }))}
                            >
                                Куда угодно
                            </Button>
                        </div>
                    }
                />
            </div>

            <div className={cn(styles.block, styles.datepicker)}>
                <Calendar
                    fromDate={departureDates.from}
                    toDate={departureDates.to}
                    lng="ru"
                    availableDates={availableDates || []}
                    isShowOnlyAvailableDates
                    isError={false}
                    onChange={(startDate, endDate) => {
                        setDepartureDates((cur) => ({ ...cur, from: startDate, to: endDate }));
                    }}
                />
                <div className={styles.btn}>
                    <Button
                        variant="text"
                        size="medium"
                        inverted
                        onClick={() =>
                            setDepartureDates((cur) => ({
                                ...cur,
                                from: getStartOfWeek(),
                                to: getEndOfWeek(),
                            }))
                        }
                    >
                        Текущая неделя
                    </Button>
                    <Button
                        variant="text"
                        size="medium"
                        inverted
                        onClick={() =>
                            setDepartureDates((cur) => ({
                                ...cur,
                                from: moment().add(1, 'week').startOf('week'),
                                to: moment().add(1, 'week').endOf('week'),
                            }))
                        }
                    >
                        Следующая неделя
                    </Button>
                    <Button
                        variant="text"
                        size="medium"
                        inverted
                        onClick={() =>
                            setDepartureDates((cur) => ({
                                ...cur,
                                from: null,
                                to: null,
                            }))
                        }
                    >
                        Когда угодно
                    </Button>
                </div>
            </div>

            <Button
                disabled={!selectedCities.from?.code}
                size="large"
                variant="secondary"
                inverted
                fullWidth={isMobile}
                className={styles.submit}
                onClick={handleFindClick}
            >
                Найти
            </Button>
        </form>
    );
}
