/* eslint-disable global-require */
import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import moment from 'moment';

import { languages, getLanguage } from './index';

// After integrate webpack use https://github.com/i18next/i18next-xhr-backend#usage-with-webpack
// prettier-ignore
const i18nInit = (callback) => {
    const lng = getLanguage();
    i18next
        .use(intervalPlural)
        .init({
            lng,
            fallbackLng: languages.ru,
            preload: [languages.ru],
            ns: ['translation'],
            whitelist: Object.values(languages)
        }, () => {
            if (typeof callback === 'function') {
                callback();
            }
        });

    i18next.addResourceBundle('ru', 'translation', require('locales/ru/translation').default, true, true);
    i18next.addResourceBundle('en', 'translation', require('locales/en/translation').default, true, true);
    require('moment/locale/ru');
    moment.locale(lng);
};

export default i18nInit;
