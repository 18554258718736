exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DestinationCity-module__root--2TT-z{display:flex;flex-direction:row;align-items:center;border-radius:6px;background-color:#fff;padding:16px 24px;min-width:342px;box-sizing:border-box;gap:16px}.DestinationCity-module__countryFlag--KjJoN{width:36px;height:36px;border-radius:50%}.DestinationCity-module__route--27-zW{display:flex;flex-direction:column;flex:1}.DestinationCity-module__cityName--3jFPx{color:#111820;font-size:18px;font-style:normal;font-weight:400;line-height:24px;letter-spacing:.1px}@media screen and (max-width: 599px){.DestinationCity-module__cityName--3jFPx{font-size:16px}}.DestinationCity-module__routeType--2u8pj{color:rgba(94,110,135,.8);font-size:14px;font-style:normal;font-weight:400;line-height:20px}.DestinationCity-module__routeType--2u8pj.DestinationCity-module__direct--1F_Ih{color:#008c5f}.DestinationCity-module__icon--TIegA{width:20px;height:20px}.DestinationCity-module__icon--TIegA path{fill:rgba(94,110,135,.8)}@media screen and (max-width: 599px){.DestinationCity-module__root--2TT-z{max-width:unset;width:100%}}", ""]);

// exports
exports.locals = {
	"root": "DestinationCity-module__root--2TT-z",
	"countryFlag": "DestinationCity-module__countryFlag--KjJoN",
	"route": "DestinationCity-module__route--27-zW",
	"cityName": "DestinationCity-module__cityName--3jFPx",
	"routeType": "DestinationCity-module__routeType--2u8pj",
	"direct": "DestinationCity-module__direct--1F_Ih",
	"icon": "DestinationCity-module__icon--TIegA"
};