/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import i18next from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { ISO_DATE_MASK } from 'consts';
import { ReactComponent as ArrowForward } from 'icons/interface/arrow-forward.svg';
import { ReactComponent as ArrowBack } from 'icons/interface/arrow-back.svg';
import { Popover, POPOVER_DIRECTION } from 'components/common/popover';

const SHORT_DATE_FORMAT = 'D MMM';
const FULL_DATE_FORMAT = 'D MMMM';

export class Calendars extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focusedInput: START_DATE,
            isShowCalendar: false,
        };

        this.inputEl = null;
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    setInputEl = (el) => {
        this.inputEl = el;
    };

    focus = () => {
        this.inputEl && this.inputEl.focus();
    };

    handleMouseDown = () => {
        if (document.activeElement !== this.inputEl) {
            return;
        }

        this.toggleShowCalendar();
    };

    closeCalendar = () => {
        this.setState({
            isShowCalendar: false,
            focusedInput: START_DATE,
        });
    };

    toggleShowCalendar = () => {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            isShowCalendar: !this.state.isShowCalendar,
            focusedInput: START_DATE,
        });
    };

    isOutsideRange = (current) => {
        if (!current) return false;

        const today = moment();

        return current.isBefore(today, 'day'); // can not select days before today
    };

    handleDateSelect = (value) => {
        const isReselect = this.state.focusedInput === START_DATE && value.endDate;
        const endDate = isReselect ? null : value.endDate;

        if (!this.props.isTwoWay || endDate) {
            this.closeCalendar();
        }

        this.props.onChange(value.startDate, endDate);
    };

    getDateLabel = () => {
        const { fromDate, backDate, isTwoWay, lng } = this.props;

        if (!fromDate) {
            return '';
        }

        if (!isTwoWay || !backDate) {
            return moment(fromDate).format(FULL_DATE_FORMAT);
        }

        const fromDateLabel = moment(fromDate).locale(lng).format(SHORT_DATE_FORMAT);
        const backDateLabel = moment(backDate).locale(lng).format(SHORT_DATE_FORMAT);

        return `${fromDateLabel} - ${backDateLabel}`;
    };

    handleClickTwoWaysHeader = (e) => {
        e.preventDefault();
        !this.props.isTwoWay && this.toggleIsTwoWay();
    };

    handleClickOneWayHeader = (e) => {
        e.preventDefault();
        this.setState({ focusedInput: START_DATE });
        this.props.isTwoWay && this.toggleIsTwoWay();
    };

    toggleIsTwoWay = () => {
        this.props.onToggleIsTwoWay();
    };

    handleFocus = () => {
        this.toggleShowCalendar();
    };

    handleKeyDown = (e) => {
        switch (e.keyCode) {
            case 9: // Tab
                this.closeCalendar();
                break;
            default:
                break;
        }
    };

    /**
     * @uses react-onclickoutside
     */
    handleClickOutside = () => {
        this.closeCalendar();
    };

    handleFocusChange = (focusedInput) => {
        this.setState({ focusedInput });
    };

    checkHighlightedDay = () => {
        const { fromDate, availableFromDates, availableBackDates, isShowOnlyAvailableDates } = this.props;

        if (!isShowOnlyAvailableDates) {
            return;
        }

        const availableDates =
            this.state.focusedInput === END_DATE
                ? [
                      ...availableFromDates.filter((date) => fromDate?.isAfter(date)),
                      ...availableBackDates.filter((date) => fromDate?.isBefore(date)),
                  ]
                : availableFromDates;

        if (availableDates.length === 0) {
            return () => false;
        }

        return (date) => !availableDates.includes(date.format(ISO_DATE_MASK));
    };

    render() {
        const { isTwoWay, media, fromDate, backDate, isError } = this.props;
        const { focusedInput, isShowCalendar } = this.state;

        const dateLabel = this.getDateLabel();

        return (
            <div className="SearchWidget-box -dates">
                <Popover
                    defaultDirection={POPOVER_DIRECTION.BOTTOM}
                    tooltip={<div>{i18next.t('validator.errors.dates_required')}</div>}
                    isShown={isError && !isShowCalendar}
                    disableHover
                >
                    <input
                        ref={this.setInputEl}
                        onFocus={this.handleFocus}
                        onKeyDown={this.handleKeyDown}
                        onMouseDown={this.handleMouseDown}
                        className={classNames('SearchWidget-input -select', { 'is-error': isError })}
                        type="text"
                        placeholder={dateLabel === '' ? i18next.t('flights_search.dates_placeholder') : null}
                        value={dateLabel}
                        readOnly
                        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                        tabIndex="1"
                    />
                </Popover>

                <div data-testid="SearchWidgetCalendar" className="SearchWidget-dropdown" aria-hidden={!isShowCalendar}>
                    <ul className="Tabs -small Grid-2 -flex -nogutter !mb24" role="tab">
                        <li className="Col" role="presentation">
                            <a
                                className={classNames({ 'is-active': isTwoWay })}
                                onClick={this.handleClickTwoWaysHeader}
                            >
                                {i18next.t('flights_search.two_ways')}
                            </a>
                        </li>
                        <li className="Col" role="presentation">
                            <a
                                data-testid="SearchWidgetCalendarOneWayTab"
                                className={classNames({ 'is-active': !isTwoWay })}
                                onClick={this.handleClickOneWayHeader}
                            >
                                {i18next.t('flights_search.one_way')}
                            </a>
                        </li>
                    </ul>
                    <DayPickerRangeController
                        startDate={fromDate}
                        endDate={isTwoWay ? backDate : null}
                        onDatesChange={this.handleDateSelect}
                        focusedInput={focusedInput}
                        onFocusChange={this.handleFocusChange}
                        isOutsideRange={this.isOutsideRange}
                        isDayHighlighted={this.checkHighlightedDay()}
                        numberOfMonths={media.phone ? 1 : 2}
                        minimumNights={0}
                        firstDayOfWeek={1}
                        navPrev={<ArrowBack width="1.6rem" height="1.6rem" aria-hidden="true" />}
                        navNext={<ArrowForward width="1.6rem" height="1.6rem" aria-hidden="true" />}
                        hideKeyboardShortcutsPanel
                    />
                </div>
            </div>
        );
    }
}

Calendars.propTypes = {
    isError: PropTypes.bool,
    isTwoWay: PropTypes.bool,
    isShowOnlyAvailableDates: PropTypes.bool,
    fromDate: PropTypes.shape(),
    availableFromDates: PropTypes.arrayOf(PropTypes.string),
    backDate: PropTypes.shape(),
    availableBackDates: PropTypes.arrayOf(PropTypes.string),
    media: PropTypes.shape().isRequired,
    lng: PropTypes.string.isRequired,
    onRef: PropTypes.func,
    onToggleIsTwoWay: PropTypes.func,
    onChange: PropTypes.func,
};

Calendars.defaultProps = {
    isError: false,
    isTwoWay: false,
    isShowOnlyAvailableDates: false,
    fromDate: null,
    availableFromDates: [],
    backDate: null,
    availableBackDates: [],
    onRef: () => {},
    onToggleIsTwoWay: () => {},
    onChange: () => {},
};

export const WrappedCalendars = onClickOutside(Calendars);
