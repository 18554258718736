import { CURRENCIES } from 'consts';
import { formatPrice } from 'utils/formatter';

import type {
    Applicability,
    BookedService as BookedServiceType,
    CheckinService,
    DataServices,
    SeatService,
    Segment,
    Service,
    ServiceAvailability,
} from './types';

export interface MappedDataServices extends DataServices {
    bookedServices: BookedServiceType[];
    checkinServices: CheckinService[];
    paymentMethods?: object;
    seatServices: SeatService[];
    segments: Segment[];
    services: Service[];
    serviceAvailability: ServiceAvailability;
    hasAvailableService?: boolean;
    cartId?: string;
}

interface PassengerWithService {
    price: string | null;
    formattedOldPrice: string | null;
    bookedQuantity: number;
    passengerId: number;
    passengerIdAsm: string;
    parentId: number;
    firstName: string;
    lastName: string;
    type: string | null;
}

interface BookedService extends Service {
    segmentIdAsm: string;
    passengerIdAsm: string;
    quantity: number;
}

export function mapDataToModel(data: DataServices): MappedDataServices {
    if (!(data && typeof data === 'object')) {
        return {
            bookedServices: [],
            checkinServices: [],
            paymentMethods: {},
            seatServices: [],
            segments: [],
            services: [],
            serviceAvailability: {},
            hasAvailableService: false,
            cartId: '',
        };
    }

    return {
        ...data,
        hasAvailableService: Object.values(data.serviceAvailability).some((isAvailable) => isAvailable),
    };
}

export function isNeededService(
    service: { segmentIdAsm: string; passengerIdAsm: string },
    segmentId: string,
    passengerId: string
): boolean {
    return service.segmentIdAsm === segmentId && service.passengerIdAsm === passengerId;
}

export function groupBy(items: Service[], prop: keyof Service) {
    return items.reduce((acc, item) => {
        const key = item[prop];

        if (typeof key === 'string') {
            if (acc[key]) {
                acc[key].push(item);
            } else {
                acc[key] = [item];
            }
        }

        return acc;
    }, {} as Record<string, Service[]>);
}

export function findService<T extends { segmentIdAsm: string; passengerIdAsm: string }>(
    services: T[] = [],
    segmentId: string = '',
    passengerId: string = ''
): T | undefined {
    return services.find((service) => service.segmentIdAsm === segmentId && service.passengerIdAsm === passengerId);
}

export function getIsSelected(services: CheckinService[], segmentId: string, passengerId: string) {
    return services.some((service) => isNeededService(service, segmentId, passengerId) && service.quantity);
}

export function changeSelectedServices({
    selected,
    guid,
    segmentIdAsm,
    passengerIdAsm,
    quantity,
}: {
    selected: CheckinService[];
    guid: string;
    segmentIdAsm: string;
    passengerIdAsm: string;
    quantity: number;
}) {
    return [
        ...selected.filter((sel) => !isNeededService(sel, segmentIdAsm, passengerIdAsm)),
        {
            passengerIdAsm,
            segmentIdAsm,
            guid,
            quantity,
        },
    ];
}

export function getTotalPrice(services: CheckinService[], applicability: Applicability[]) {
    return services.reduce((acc, { segmentIdAsm, passengerIdAsm, quantity }) => {
        const service = findService(applicability, segmentIdAsm, passengerIdAsm);

        return acc + (service?.price || 0) * quantity;
    }, 0);
}

export function mapServicesForRemove(services: CheckinService[]) {
    return services.map((service) => ({ ...service, quantity: 0 }));
}

export function mapService(
    services: Service[],
    segments: Segment[],
    originCheckinServices: Service[],
    originBookedServices: Service[],
    labels: Record<string, string>
) {
    if (!(services.length && segments.length)) return { items: [], hasAddedService: false };

    const servicesByCategory = groupBy(services, 'category');
    const checkinServicesByGuid = groupBy(originCheckinServices, 'guid');
    const bookedServicesByGuid = groupBy(originBookedServices, 'guid');

    let hasAddedService = false;

    const items = Object.entries(servicesByCategory).map(([category, children]) => ({
        label: labels[category],
        value: category,
        children: children.map(({ guid, applicability, ...props }) => {
            const bookedServices = bookedServicesByGuid[guid] || [];
            const checkinServices = checkinServicesByGuid[guid] || [];

            if (checkinServices.length && !hasAddedService) {
                hasAddedService = true;
            }

            const segmentsWithServices = segments.reduce((acc: Segment[], segment) => {
                const isAvailableSegment = applicability.some((item) => segment.segmentIdAsm === item.segmentIdAsm);

                if (isAvailableSegment) {
                    const segmentWithServices = {
                        ...segment,
                        passengers: segment.passengers.reduce((accumulator: PassengerWithService[], passenger) => {
                            const availableService = findService(
                                applicability,
                                segment.segmentIdAsm,
                                passenger.passengerIdAsm
                            );

                            if (availableService) {
                                const bookedService = findService(
                                    bookedServices as BookedService[],
                                    segment.segmentIdAsm,
                                    passenger.passengerIdAsm
                                );

                                accumulator.push({
                                    ...passenger,
                                    price: formatPrice(availableService.price, 'RUR', true, true),
                                    formattedOldPrice: formatPrice(
                                        availableService.oldPrice,
                                        CURRENCIES.RUR.value,
                                        false,
                                        false
                                    ),
                                    bookedQuantity: bookedService?.quantity || 0,
                                });
                            }

                            return accumulator;
                        }, []),
                    };

                    acc.push(segmentWithServices);
                }

                return acc;
            }, []);

            return {
                ...props,
                segments: segmentsWithServices,
                id: guid,
                checkinServices,
                applicability,
                hasBookedServices: !!bookedServices.length,
                minPrice: formatPrice(Math.min(...applicability.map(({ price }) => price)), 'RUR', true, true),
            };
        }),
    }));

    return { items, hasAddedService };
}

export function getDiffServices(selectedServices: CheckinService[] = [], prevServices: CheckinService[] = []) {
    return selectedServices.filter((sel) => {
        const prevService = prevServices.find((prev) => isNeededService(sel, prev.segmentIdAsm, prev.passengerIdAsm));

        if ((!prevService && !sel.quantity) || (prevService && sel.quantity === prevService.quantity)) return false;

        return true;
    });
}

export function getHasChangeInBasket(basket: CheckinService[], services: CheckinService[]) {
    if (!basket.length && services.every((service) => !service.quantity)) return false;

    return JSON.stringify(basket) !== JSON.stringify(services);
}
