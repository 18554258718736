/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import i18next from 'i18next';
import _ from 'lodash';
import { utairHttpManager as request } from 'managers/utair';
import downloadjs from 'downloadjs';
import { API_URLS, PRIVATE_CACHE_FLAG, PERSONAL_DATA_FILE_NAME, BOOKING_DOCUMENTS_CACHE_KEYNAME } from 'consts';
import * as TYPES from 'constants/userTypes';
import { cacheManager as cache } from 'utils/cache-manager';
import { normalizeUser, normalizeSpaces } from 'utils/normalize';
import { addressToString, dateToString, excludeKeys, usernameToString } from 'utils/formatter';
import googleTagsManager from 'utils/google-tags-manager';
import { fetchBalance } from 'actions/bonuses';
import { addMessage as sysMessage } from 'actions/sysmessages';
import { getConfirmInitials, getUserData as getUserState } from 'selectors/user';
import { createFSA } from 'utils';
import { setUserSubscriptionsFSA, updateUserSubscriptions } from 'actions/subscriptions';

export const PROFILE_CACHE_KEYNAME = 'profile';
const PROFILE_CACHE_LIFETIME = 360000;

const DOMESTIC_PASSENGERS_CACHE_KEYNAME = 'domesticPassengersCache';
const FOREIGN_PASSENGERS_CACHE_KEYNAME = 'foreignPassengersCache';

export const CacheSettings = {
    name: PROFILE_CACHE_KEYNAME,
    lifeTime: PROFILE_CACHE_LIFETIME,
    flag: PRIVATE_CACHE_FLAG,
};

const sessionsFSA = createFSA(TYPES.SET_SESSIONS);

/**
 * Get user data
 * @return {object} - user fields
 */
export const getUserData = (silent) => (dispatch) => {
    dispatch(fetchBalance(silent));

    return _fetchGetUserData().then((data) => {
        const preparedUserData = _mapUserData(data);

        dispatch(_updateUserFields(preparedUserData));

        googleTagsManager.push({
            utair_customer_id: data.status.cardNo,
        });

        return preparedUserData;
    });
};

const _fetchGetUserData = () => request.get(API_URLS.PROFILE.USER, null, true, CacheSettings);

const _getUserChannelData = (channel) => ({
    verified: !!channel?.verified,
});

const _mapUserData = (data) => {
    const { birthday, block, channels } = data;

    const { email, sms, phone } = channels || {};

    return {
        ...data,
        birthday: typeof birthday === 'number' ? dateToString(birthday) : birthday,
        block: block || null,
        channels: {
            ...(email && {
                email: _getUserChannelData(email),
            }),
            ...(phone && {
                sms: _getUserChannelData(sms),
                phone: _getUserChannelData(phone),
            }),
        },
    };
};

export const downloadPersonalData = () => () => {
    _fetchGetUserData().then((data) => {
        downloadjs(JSON.stringify(data), PERSONAL_DATA_FILE_NAME, 'text/plain');
    });
};

/**
 * Select fields for update
 *
 * @param fields
 * @param check
 * @param isDocumentsChanged
 */
const getFieldsToUpdate = (fields, isDocumentsChanged, check) => (dispatch, getState) => {
    const user = getUserState(getState());

    const updateFields = {};
    const confirm = {};

    if (fields.avatar && fields.avatar !== user.avatar) {
        updateFields.avatar = fields.avatar;
    }

    if (fields.name !== usernameToString(user.initials)) {
        updateFields.name = fields.name;
    }

    if (
        fields.birthday &&
        fields.birthday !== user.birthday &&
        (user.birthdayIsEditable || typeof user.birthdayIsEditable === 'undefined')
    ) {
        updateFields.birthday = fields.birthday;
    }

    if (fields.phone && (fields.phone.match(/[\d\w+]/g).join('') !== user.phone || !check)) {
        const reg = /[0-9]/g;
        const phone = fields.phone.match(reg);
        updateFields.phone = phone.join('');
        confirm.phone = true;
    }

    if (fields.email && (fields.email.toLowerCase() !== (user.email || '').toLowerCase() || !check)) {
        updateFields.email = fields.email.toLowerCase();
        confirm.email = true;
    }

    if (fields.gender && fields.gender !== user.gender) {
        updateFields.gender = fields.gender;
    }

    if (fields.address !== '' && addressToString(fields.address) !== addressToString(user.address)) {
        updateFields.address = fields.address;
    }

    if (isDocumentsChanged) {
        updateFields.documents = fields.documents;
    }

    if (updateFields.documents && Object.keys(updateFields.documents).length === 0) {
        delete updateFields.document;
    }

    if (fields.security && fields.security.phoneLoginAllowed !== (user.security && user.security.phoneLoginAllowed)) {
        updateFields.security = {
            ...(updateFields.security || {}),
            phoneLoginAllowed: fields.security.phoneLoginAllowed,
        };
    }

    if (fields.subscriptions) {
        updateFields.subscriptions = fields.subscriptions;
    }

    if (Number(fields.confirmationGDPRDate) > 0) {
        updateFields.confirmationGDPRDate = Number(fields.confirmationGDPRDate);
    }

    return Promise.resolve({
        fields: updateFields,
        confirm,
    });
};

/**
 * Update user profile method
 * @param {Object} fields - new user fields
 * @param {boolean} isDocumentsChanged - new user fields
 * @param {boolean} check - check channels flag
 * @return {Object} - update result
 */
export const update =
    ({ fields, isDocumentsChanged = false, check = true }) =>
    (dispatch, getState) =>
        dispatch(getFieldsToUpdate(fields, isDocumentsChanged, check)).then((data) => {
            const userData = getUserState(getState());
            const confirmInitials = getConfirmInitials(getState());

            if (!data || !confirmInitials) {
                return false;
            }

            const { name, surname, secondName } = confirmInitials;

            const normalizedSecondName = normalizeSpaces(secondName);

            const initialsOriginalSecondNameData = normalizedSecondName ? { secondName: normalizedSecondName } : {};

            const normalizedUserData = normalizeUser(data.fields);

            // Костыль для UTFRNT-791 Нужен рефакторинг всего личного кабинета
            const confirmationGDPRDateData =
                normalizedUserData.confirmationGDPRDate === undefined
                    ? { confirmationGDPRDate: userData.confirmationGDPRDate }
                    : {};

            if (_.keys(normalizedUserData).includes('subscriptions')) {
                dispatch(updateUserSubscriptions(normalizedUserData.subscriptions));
            }

            const normalizedFields = {
                ...excludeKeys(normalizedUserData, ['subscriptions']),
                initials: {
                    original: {
                        name: normalizeSpaces(name),
                        surname: normalizeSpaces(surname),
                        ...initialsOriginalSecondNameData,
                    },
                },
                ...confirmationGDPRDateData,
            };

            return _fetchUpdate(normalizedFields)
                .then(() => {
                    cache.removeItem(PROFILE_CACHE_KEYNAME);
                    cache.removeItem(BOOKING_DOCUMENTS_CACHE_KEYNAME);

                    return _fetchGetUserData().then((userDataReceived) => {
                        const { birthday, phone, email } = normalizedFields;

                        const { channels: receivedChannels } = userDataReceived;

                        const { phone: receivedPhone, email: receivedEmail } = receivedChannels;

                        const birthdayData = birthday
                            ? {
                                  birthday,
                                  birthdayIsEditable: false,
                              }
                            : {};

                        const phoneData =
                            phone || receivedPhone
                                ? {
                                      channels: {
                                          ...receivedChannels,
                                          phone: phone ? { verified: false } : receivedPhone,
                                      },
                                  }
                                : {};

                        const emailData =
                            email || receivedEmail
                                ? {
                                      channels: {
                                          ...receivedChannels,
                                          email: email ? { verified: false } : receivedEmail,
                                      },
                                  }
                                : {};

                        const preparedReceivedData = {
                            ...normalizedFields,
                            ...birthdayData,
                            ...phoneData,
                            ...emailData,
                        };

                        const userFields = { ...userDataReceived, ...preparedReceivedData };

                        cache.setItem(
                            PROFILE_CACHE_KEYNAME,
                            _.omit(userFields, ['subscriptions']),
                            PROFILE_CACHE_LIFETIME,
                            PRIVATE_CACHE_FLAG
                        );

                        if (!_.isEmpty(userFields.subscriptions)) {
                            dispatch(setUserSubscriptionsFSA(userFields.subscriptions));
                        }

                        dispatch(_updateUserFields(_mapUserData(preparedReceivedData)));

                        cache.removeItem(DOMESTIC_PASSENGERS_CACHE_KEYNAME);
                        cache.removeItem(FOREIGN_PASSENGERS_CACHE_KEYNAME);

                        return {
                            fields: normalizedFields,
                            confirm: data.confirm,
                        };
                    });
                })
                .catch((error) => {
                    throw error;
                });
        });

/**
 * Fetch user
 * @param {object} fields - user fields
 * @return {Promise} -
 */
export const _fetchUpdate = (fields) => {
    const omitFields = ['name', 'confirmInitials'];

    return request.put(API_URLS.PROFILE.USER, _.omit(fields, omitFields), true);
};

/**
 * Set new user fields to store
 * @param {object} data - new user fields
 */
export const _updateUserFields = (data) => ({
    type: TYPES.UPDATE_USER,
    data,
});

/**
 * Confirm channel
 * @param {string} channel
 * @param {code} channel
 * @return {bool} - result
 */
export const confirmChannel = (channel, code) => (dispatch) =>
    request.put(`${API_URLS.PROFILE.USER}${channel}/confirmation/`, { data_type: channel, code }, true).then(() => {
        cache.removeItem(PROFILE_CACHE_KEYNAME);

        return dispatch(getUserData());
    });

/**
 * Reset user data to initial state
 */
export const clearUserData = () => (dispatch) => {
    cache.removeItem(PROFILE_CACHE_KEYNAME);
    dispatch(resetUserData());
};

/**
 * Reset user data to initial state
 */
export const resetUserData = () => (dispatch) => {
    dispatch({
        type: TYPES.RESET_DATA,
    });
};

export const setConfirmInitials = (fields) => (dispatch) =>
    dispatch({
        type: TYPES.SET_CONFIRM_INITIALS,
        fields,
    });

// Action Types

export const CONFIRM_CREDENTIALS = 'utair/actions/user/CONFIRM_CREDENTIALS';

// Action Creators

export const confirmCredentials = (type) => ({
    type: CONFIRM_CREDENTIALS,
    payload: type,
});

/**
 * Получение списка активных сессий пользователя
 * @returns {function(*): (*|Promise<T>)}
 */
export const fetchUserSessions = () => (dispatch) =>
    request
        .get(API_URLS.PROFILE.SESSIONS, null, true)
        .then((data) => dispatch(sessionsFSA(data)))
        .catch((e) => {
            const code = _.get(e, 'data.meta.error_code');
            const message = _.get(e, 'data.meta.error_message', i18next.t('profile.sessions.errors.requestError'));
            const text = i18next.t(`profile.sessions.errors.${code}`, message);

            dispatch(sysMessage(text, 'error'));
        });

/**
 * Удаление сессии пользователя по ID
 * @param id
 * @returns {function(*, *): Promise<T>}
 */
export const removeUserSession = (id) => (dispatch, getState) => {
    const { sessions } = getState().user;

    return request
        .delete(`${API_URLS.PROFILE.SESSIONS}${id}/`, null, true)
        .then(() => {
            dispatch(sessionsFSA(sessions.list.filter((i) => i.id !== id)));
            dispatch(sysMessage(i18next.t('profile.sessions.messages.successRemoveOne'), 'success'));
        })
        .catch((e) => {
            const code = _.get(e, 'data.meta.error_code');
            const message = _.get(e, 'data.meta.error_message', i18next.t('profile.sessions.errors.removeError'));
            const text = i18next.t(`profile.sessions.errors.${code}`, message);

            dispatch(sysMessage(text, 'error'));
        });
};

/**
 * Удаление всех сессии пользователя кроме текущей
 * @returns {function(*, *): Promise<T>}
 */
export const removeAllSessions = () => (dispatch, getState) => {
    const { sessions } = getState().user;

    return request
        .delete(API_URLS.PROFILE.SESSIONS, null, true)
        .then(() => {
            dispatch(sessionsFSA(sessions.list.filter((i) => i.current)));
            dispatch(sysMessage(i18next.t('profile.sessions.messages.successRemoveFew'), 'success'));
        })
        .catch((e) => {
            const code = _.get(e, 'data.meta.error_code');
            const message = _.get(e, 'data.meta.error_message', i18next.t('profile.sessions.errors.removeError'));
            const text = i18next.t(`profile.sessions.errors.${code}`, message);

            dispatch(sysMessage(text, 'error'));
        });
};

export const deleteUser = (openUserDeleteModal) => (dispatch) =>
    request
        .delete(API_URLS.PROFILE.USER, null, true)
        .then((response) => {
            if (response.meta.error_code === 200) {
                openUserDeleteModal();
            }
        })
        .catch((e) => {
            const message = _.get(e, 'data.meta.error_message', i18next.t('profile.messages.profile_delete_error'));

            dispatch(sysMessage(message, 'error'));
        });
