// eslint-disable-next-line func-names
export const isForeignPhone = function (phone) {
    if (typeof phone !== 'string') {
        return false;
    }

    return phone.indexOf('+7') !== 0;
};

// eslint-disable-next-line func-names
export const getPhoneMask = function (phone) {
    return isForeignPhone(phone) ? '+999999999999999' : '+9 (999) 999-99-99';
};
