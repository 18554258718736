/* eslint-disable */
import { CURRENCIES } from 'consts';
import moment from 'moment';

/**
 * create human-readable number string
 * @param {(string|number)} value
 * @return {?string}
 * @example:
 * formatNumber(4700);  // '4 700'
 */
export const formatNumber = (value) => {
    if (value === null || value === undefined) {
        return null;
    }

    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u00A0');
};

/**
 * create human-readable card number string
 * @param {(string|number)} value
 * @return {?string}
 * @example:
 * formatNumber(1000288793);  // '10 0028 8793'
 */
export const formatCardNumber = (value) => {
    if (!value) {
        return null;
    }

    return value.toString().replace(/(\d)(?=(\d{4})+(?!\d))/g, '$1 ');
};

/**
 * formmat 10,13,16 digits card numbers
 */

export const formatMiscCardNumber = (value) => {
    if (!value) return '';

    let format;
    switch (value.length) {
        case 10:
            [, ...format] = value.match(/(.{2})(.{4})(.{4})/);
            return format.join(' ');
        case 13:
            [, ...format] = value.match(/(.{4})(.{4})(.{5})/);
            return format.join(' ');
        case 16:
            [, ...format] = value.match(/(.{4})(.{4})(.{4})(.{4})/);
            return format.join(' ');
        default:
            return '';
    }
};

/**
 * Format price value from kopecks
 * @param {(string|number)} value
 * @return {number}
 */
export const convertFromKopecks = (value) => Number(value) / 100;

/**
 * create human-readable price string
 * @param {(string|number|null)} value
 * @param {string} currency - currency code
 * @param {boolean} isDefaultZero - return 0 by default
 * @param {boolean} formatFromKopecks - needs to be converted from kopecks
 * @return {?string}
 * @example:
 * formatPrice(4700, 'USD');  // '4 700 $'
 */
export const formatPrice = (value, currency, isDefaultZero, formatFromKopecks) => {
    const unformattedValue = formatFromKopecks ? convertFromKopecks(value) : value;
    let valueString = formatNumber(unformattedValue);

    if (!valueString) {
        if (!isDefaultZero) {
            return null;
        }
        valueString = '0';
    }

    return `${valueString}\u00A0${CURRENCIES[currency].label}`;
};

/**
 * parse JSON-string to object
 * if an error return null
 * @param {?string} [value] - JSON-string
 * @return {?Object}
 * @example:
 * parseJson('{"key": "value"}');  // {key: 'value'}
 * parseJson('key value');         // null
 */
export const parseJson = (value) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};
/**
 * format first character to uppercase
 * @param {string} value
 * @return {string}
 * @example
 * ucfirst('hello');  // 'Hello'
 */
export const ucfirst = (value) => value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();

/**
 * return normalized name of user
 * @param {string} value
 * @example:
 * upperCaseName('john DOE');  // 'John Doe'
 */
export const upperCaseName = (value) => value.replace(/[\wа-яА-Я]\S*/g, ucfirst);

/**
 * return user address as string
 * @param {object} addressObject - address object
 * @return {string} - address as string
 */
export const addressToString = (addressObject) => {
    if (!addressObject) return null;
    if (typeof addressObject === 'string') return addressObject;
    const address = [];
    if (addressObject.city) {
        address.push(addressObject.city);
    }
    if (addressObject.address) {
        address.push(addressObject.address);
    }
    return address.join(', ');
};

/**
 * return user name as string
 * @param {object} initials - user name object
 * @param {bool} international - return international user name
 * @return {string} - user name as string
 */
export const usernameToString = (initials, international = false, short = false) => {
    if (!initials) return null;
    const data = !international ? initials.original : initials.international;
    if (typeof data === 'undefined') return '';

    const res = [];
    if (data.name) res.push(data.name);
    if (!short && data.secondName) res.push(data.secondName);
    if (data.surname) res.push(data.surname);

    return res.length ? res.join(' ').trim() : '';
};

/**
 * Returns object without keys from parameter list
 * @param {Object} sourceObject
 * @param {Array<string>} keysToRemove
 * @return {Object}
 */
export const excludeKeys = (sourceObject, keysToRemove) => {
    const keysToRemoveSet = new Set(keysToRemove);

    return Object.entries(sourceObject).reduce((result, [key, item]) => {
        return keysToRemoveSet.has(key) ? result : { ...result, [key]: item };
    }, {});
};

/**
 * Format date into date spring
 * @param {number} date
 * @returns {string}
 */
export const dateToString = (date) => moment.utc(date, 'X').format('DD.MM.YYYY');

/**
 * Format date into unix ms timestamp
 * @param {string} date
 * @returns {string}
 */
export const dateToMsTimestamp = (date) => moment.utc(date, 'DD.MM.YYYY').format('X');

/**
 * Parse milliseconds from date string
 * @param {string} date
 * @returns {number}
 */
export const getMillisecondsFromDate = (date) => parseInt(dateToMsTimestamp(date), 10);

/**
 * Format date to utc
 * @param {string} date
 * @returns {moment.Moment}
 */
export const dateToUTC = (date) => moment.unix(date).utc();
