import _ from 'lodash';
import {call, takeEvery, all} from 'redux-saga/effects';
import {LOG_ERROR} from 'actions/error';
import {CLIENT_ERROR_TYPE} from 'consts';

function* logError({payload}) {
    if (!payload) {
        return;
    }

    const errorOptions = {
        tags: {
            type: CLIENT_ERROR_TYPE
        }
    };

    /* eslint-disable no-undef */
    if (!_.isEmpty(window.Raven)) {
        yield call([Raven, 'captureException'], payload, errorOptions);
    }
}

function* errorSaga() {
    yield all([takeEvery(LOG_ERROR, logError)]);
}

export default errorSaga;
