import React from 'react';

import styles from './Image.module.scss';

interface Props {
    className?: string;
    src: string;
    alt: string;
}

export function Image({ className = '', src, alt }: Props) {
    return (
        <div className={className}>
            <img className={styles.root} src={src} alt={alt} />
        </div>
    );
}
