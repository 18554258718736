import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '@public-projects/utair-ui-kit';
import { resetSocialData } from 'components/Account/social/actions';
import { toggleModal } from 'actions/auth';
import { t } from 'i18next';
import cn from 'classnames';

import Account from '../../Account';

export default function LoginLink({ isIndexRoute }) {
    const dispatch = useDispatch();
    const isModalOpen = useSelector((state) => state.auth.isModalOpen);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="header-user">
            <button
                className={cn('reset-button-style', 'header-user-link login-buton', { '-text-white': isIndexRoute })}
                onClick={() => dispatch(toggleModal(true))}
            >
                {t('header.menu.login')}
            </button>
            {isModalOpen && (
                <Modal
                    disableOverlayClick
                    isLoading={isLoading}
                    size="small"
                    onClose={() => {
                        dispatch(resetSocialData());
                        dispatch(toggleModal());
                    }}
                >
                    <Account setIsLoading={setIsLoading} />
                </Modal>
            )}
        </div>
    );
}
