import React from 'react';
import { Link } from 'react-router';
import cn from 'classnames';
import { trimEnd } from 'lodash';

export default function NavMenuItem({ to, pathname, isExternal, children }) {
    const className = cn('MenuTabs-Item', { 'MenuTabs-Item_active': trimEnd(pathname, '/') === trimEnd(to, '/') });

    if (isExternal) {
        return (
            <a href={to} className={className}>
                {children}
            </a>
        );
    }

    return (
        <Link to={to} className={className}>
            {children}
        </Link>
    );
}
