/* eslint camelcase: [0, {properties: "never"}] */

export const nav_menu_links = {
    buy: 'Book',
    buy_mobile: 'Book',
    check_in: 'Online check-in',
    tickets_mobile: 'Tickets',
    tickets: 'Tickets',
    helicopters: 'Helicopters',
};
