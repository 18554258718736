/* eslint-disable camelcase */
import { about } from './about';
import { boarding_pass } from './boarding-pass';
import { bonuses } from './bonuses';
import { booking } from './booking';
import { check_in } from './check-in';
import { common } from './common';
import { companions } from './companions';
import { errors } from './errors';
import { family } from './family';
import { flights_search } from './flights-search';
import { footer } from './footer';
import { header } from './header';
import { nav_menu_links } from './nav_menu_links';
import { main_page } from './main-page';
import { order_services } from './order-services';
import { orders } from './orders';
import { profile } from './profile';
import { schedule } from './schedule';
import { sign } from './sign';
import { social_auth } from './social-auth';
import { socials } from './socials';
import { validator } from './validator';
import { status_business } from './status-business';
import { subscriptions } from './subscriptions';
import { user_agreements } from './user-agreements';
import { pilot_meals } from './pilot-meals';
import { order_manage } from './order-manage';
import { reset_cache } from './reset-cache';
import { seat_map } from './seat-map';
import { change_personal_data } from './change-personal-data';
import { notifications } from './notifications';
import { onlinePanel } from './online-panel';
import { supportService } from './support-service';

export default {
    about,
    boarding_pass,
    bonuses,
    booking,
    check_in,
    common,
    companions,
    errors,
    family,
    flights_search,
    footer,
    header,
    nav_menu_links,
    main_page,
    order_services,
    orders,
    profile,
    schedule,
    sign,
    social_auth,
    socials,
    validator,
    status_business,
    subscriptions,
    user_agreements,
    pilot_meals,
    order_manage,
    reset_cache,
    seat_map,
    change_personal_data,
    notifications,
    onlinePanel,
    supportService,
};
