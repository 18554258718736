import { useCallback } from 'react';

import { extractError } from 'checkin/shared/api';
import { createContextStore } from 'checkin/shared/lib/createContextStore';

import { getServicesApi } from './api';
import { mapDataToModel } from './lib';
import type { MappedDataServices } from './lib';

export const { useQuery, useData, useAction, withContext } = createContextStore<MappedDataServices>({
    bookedServices: [],
    checkinServices: [],
    paymentMethods: {},
    seatServices: [],
    segments: [],
    services: [],
    serviceAvailability: {},
    hasAvailableService: false,
    cartId: '',
});

export function useGetServices() {
    const { setData, setIsLoading, setError } = useAction();

    function getServices(cartId: string) {
        setIsLoading(true);

        return getServicesApi(cartId)
            .then((response) => {
                setData({ cartId, ...mapDataToModel(response) });
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                setError(extractError(e));
            });
    }

    return useCallback(getServices, [setData, setError, setIsLoading]);
}
