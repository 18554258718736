import { Button } from '@public-projects/utair-ui-kit';
import React from 'react';

import styles from './Button.module.scss';

interface Props {
    onChange: (quantity: number, segmentId: string, passengerId: string) => void;
    segmentId: string;
    passengerId: string;
    quantity?: number;
}

export function CancelButton({ onChange, segmentId, passengerId, quantity = 0 }: Props) {
    return (
        <Button
            className={styles.root}
            size="small"
            variant="secondary"
            onClick={() => onChange(quantity, segmentId, passengerId)}
        >
            Отменить
        </Button>
    );
}
