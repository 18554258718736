import React, { useState } from 'react';

import layout from './Layout.module.scss';
import { Boundaries } from './Boundaries';

export function ServiceLayout({ wrapperRef, cartId, title, Widget, Total, onContinue = () => {} }) {
    const [hasAddedService, setHasAddedService] = useState(false);

    return (
        <>
            <div className={layout.root}>
                <h2 className={layout.title}>{title}</h2>
                <div className={layout.container}>
                    <div className={layout.content}>
                        <Widget wrapperRef={wrapperRef} setHasAddedService={setHasAddedService} cartId={cartId} />
                    </div>
                    <div className={layout.sidebar}>
                        <Total onContinue={onContinue} continueVariant={hasAddedService ? 'primary' : 'secondary'} />
                    </div>
                </div>
            </div>
            <Boundaries />
        </>
    );
}
