import React from 'react';
import { Modal, Button } from '@public-projects/utair-ui-kit';

export function ChooseAirportModal({ titleText, onClose, onButtonClick, size = 'medium' }) {
    return (
        <Modal size={size} onClose={onClose}>
            <Modal.Title centered>{titleText}</Modal.Title>
            <Modal.Content centered>
                Данный перелет можно осуществить двумя видами
                <br />
                воздушного транспорта. Выберите необходимый
            </Modal.Content>

            <Modal.Buttons row centered>
                <Button variant="secondary" onClick={() => onButtonClick(false)}>
                    Самолет
                </Button>
                <Button variant="secondary" onClick={() => onButtonClick(true)}>
                    Вертолет
                </Button>
            </Modal.Buttons>
        </Modal>
    );
}
