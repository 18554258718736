/* eslint-disable max-len */
// eslint-disable-next-line camelcase
export const user_agreements = {
    title: 'Пользовательские соглашения',
    gdpr_warning_modal: {
        text:
            'Мы должны получить ваше согласие на обработку персональных данных для продолжения работы с профилем Utair и участия в программе Status./n' +
            'Если вы откажетесь от обработки персональных данных, то Utair придётся удалить ваш аккаунт. Ваши привилегии, мили, а также ваучеры будут безвозвратно удалены.',
        keep_agreement: 'Я согласен, сохранить профиль',
        delete_account: 'Удалить профиль'
    }
};
