/* eslint-disable */
import { DEFAULT_UID } from 'consts';

class Metrics {
    constructor() {
        this.agentName = 'rstat';
    }

    get agent() {
        return window[this.agentName];
    }

    getUid() {
        const isRstatAvailable = this.agent && this.agent.doCall;
        return isRstatAvailable ? this.agent('getUid') : DEFAULT_UID;
    }

    pushEvent(eventName, eventParams = {}) {
        const isRstatAvailable = !!this.agent;
        if (isRstatAvailable) {
            this.agent('event', eventName, eventParams);
        }
    }
}

export default new Metrics();
