import { get } from 'lodash';
import { API_URLS } from 'consts';
import { utairHttpManager as request } from 'managers/utair';
import { commonErrorCodes, errorHandler } from 'actions/order-manage';
import { mapDataKeys } from 'pages/order-manage/change-personal-data/helpers';
import { UNAVAILABLE_PHONE_ERROR_CODE } from 'constants/unavailable-phone';
import { ConfirmAttemptsExceededError } from 'components/Account/ConfirmAttemptsExceededError';

const ACTIONS_PREFIX = 'actions/change-personal-data/';

export const SET_IS_LOADING = `${ACTIONS_PREFIX}SET_IS_LOADING`;
export const SET_INITIAL_DATA = `${ACTIONS_PREFIX}SET_INITIAL_DATA`;
export const RESET = `${ACTIONS_PREFIX}RESET`;

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: isLoading,
});

export const setInitialData = (initialData) => ({
    type: SET_INITIAL_DATA,
    payload: initialData,
});

export const reset = () => ({
    type: RESET,
});

// export const changePersonalDataErrors = {
//     alreadyChanged: 1201,
//     thirdPartyAirlineTickets: 1202,
//     passengerNotFound: 1203,
//     pointOfSale: 1204,
//     thirdPartyAirlineSegments: 1205,
//     noActiveTickets: 1206,
//     notOFCStatus: 1207,
//     checkedIn: 1208,
//     noActiveTickets2: 1209,
//     noActiveSegments: 1210,
//     hasInsurance: 1211,
//     failedReceiveSirenaData: 1212,
//     onlyTwoFields: 1213,
//     sameData: 1214,
//     unableChangeTypeByBirthday: 1215
// };

export const changePersonalDataErrorMessages = {
    1114: 'Неверно введен код подтверждения.',
    1201: 'Данные пассажира уже были изменены. Повторное изменение невозможно.',
    1202: 'К сожалению, данный заказ оформлен не на сайте ЮТэйр. Для изменения персональных данных необходимо обратиться по месту приобретения билетов.',
    1203: 'Пассажир не найден.',
    1204: 'К сожалению, данный заказ оформлен не на сайте ЮТэйр. Для изменения персональных данных необходимо обратиться по месту приобретения билетов.',
    1205: 'Изменение билетов сторонних авиакомпаний невозможно.',
    1206: 'Не найдены активные билеты. Изменение данных невозможно.',
    1207: 'Возникла проблема с билетом.',
    1208: 'Пассажир прошёл регистрацию. Изменение данных невозможно.',
    1209: 'Не найдены активные билеты. Изменение данных невозможно.',
    1210: 'Не найдены активные сегменты. Изменение данных невозможно.',
    1211: 'У пассажира оформлена страховка. Изменение данных невозможно.',
    1212: 'Изменение более 2-х полей невозможно.',
    1213: 'Введены уже указанные данные.',
    1214: 'Изменение информации о дате рождения невозможно.',
    1216: 'Неверно введен код подтверждения.',
    1217: 'Вы сможете продолжить работу с ним позднее.',
    1218: 'Автоматическое изменение данных по вашему билету невозможно.',
    1220: 'Изменение данных возможно после возврата услуг.',
    10305: 'Заказ не найден.',
    [UNAVAILABLE_PHONE_ERROR_CODE.BY_SMS]: 'Невозможно отправить SMS',
    [UNAVAILABLE_PHONE_ERROR_CODE.BY_CALL]: 'Невозможно отправить звонок',
    default: 'Что-то пошло не так.',
};

export function getErrorMessage(error) {
    const code = get(error, 'data.meta.error_code');

    if (code === commonErrorCodes.orderConfirmAttemptsExceeded) {
        return ConfirmAttemptsExceededError;
    }

    return changePersonalDataErrorMessages[code] || changePersonalDataErrorMessages.default;
}

async function addCountryCodesToSegments(segments) {
    const airportCodesSet = segments.reduce((res, segment) => {
        res.add(segment.departureAirportCode);
        res.add(segment.arrivalAirportCode);

        return res;
    }, new Set());
    const countryCodesByAirportCodes = {};
    const promises = [...airportCodesSet].map((code) =>
        request
            .get({
                url: `${API_URLS.CITIES.ALL}?q=${code}`,
            })
            .then(({ data }) => {
                countryCodesByAirportCodes[code] = data[0].country_code;
            })
    );

    await Promise.all(promises);

    return segments.map((segment) => ({
        ...segment,
        arrival_country: { code: countryCodesByAirportCodes[segment.arrivalAirportCode] },
        departure_country: { code: countryCodesByAirportCodes[segment.departureAirportCode] },
    }));
}

function mapPassenger(passenger) {
    const { document, ...rest } = passenger;
    const data = { ...mapDataKeys(rest), ...mapDataKeys(document) };

    data.docType = data.docType.toLowerCase();

    return data;
}

const mapRestrictions = (restrictions) => {
    if (!restrictions) {
        return {};
    }

    return {
        firstName: !restrictions.can_change_first_name,
        lastName: !restrictions.can_change_last_name,
        secondName: !restrictions.can_change_second_name,
        birthdate: !restrictions.can_change_birthdate,
        documentNumber: !restrictions.can_change_document_number,
        documentType: !restrictions.can_change_document_type,
    };
};

function mapReservation({ passenger, contacts, restrictions, ...rest }) {
    return {
        ...mapDataKeys(rest),
        passenger: mapPassenger(passenger),
        contacts: [...new Set(contacts)],
        restrictions: mapRestrictions(restrictions),
    };
}

export const checkPDChangePossibility =
    (orderUUID, passengerId, rloc, lastName, segments, passengers) => async (dispatch) => {
        dispatch(setIsLoading(true));

        const activeSegments = segments.filter((segment) => segment.statusVisual === 'active');

        try {
            const [reservation, segmentsWithCountryCodes] = await Promise.all([
                request.get({
                    url: API_URLS.ORDERS_V2.PD_CHANGE(orderUUID, passengerId),
                    withCaptcha: true,
                }),
                addCountryCodesToSegments(activeSegments),
            ]);

            dispatch(
                setInitialData({
                    rloc,
                    lastName,
                    reservation: mapReservation(reservation),
                    segments: segmentsWithCountryCodes,
                    otherPassengers: passengers
                        .filter(({ id }) => id !== passengerId)
                        .map(({ id, ...rest }) => ({ ...rest, passengerId: id })),
                })
            );
        } catch (error) {
            dispatch(setInitialData(null));
            dispatch(errorHandler(error, 'change-personal-data', { rloc, lastName }));
        } finally {
            dispatch(setIsLoading(false));
        }
    };
