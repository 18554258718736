export const SERVICE_TYPES = {
    CABIN_BAG: {value: 'cabin_bag', apiKey: 'CABIN_BAG'},
    BAGGAGE: {value: 'baggage', apiKey: 'BAGGAGE'},
    BAG_INF: {value: 'bag_inf', apiKey: 'BAG_INF'},
    BAG_SPORT: {value: 'bag_sport', apiKey: 'BAG_SPORT'},
    MILES: {value: 'miles', apiKey: 'MILES'},
    TKT_CHANGES: {value: 'tkt_changes', apiKey: 'TKT_CHANGES'},
    TKT_RETURN: {value: 'tkt_return', apiKey: 'TKT_RETURN'},
    VIP_LODGE: {value: 'vip_lodge', apiKey: 'VIP_LODGE'},
    PRIOR_REGISTER: {value: 'prior_register', apiKey: 'PRIOR_REGISTER'},
    FOOD: {value: 'food', apiKey: '?'},
    CABIN_BAG_SMALL: {value: 'cabin_bag_small', apiKey: 'CABIN_BAG_SMALL'},
    CABIN_BAG_BIG: {value: 'cabin_bag_big', apiKey: 'CABIN_BAG_BIG'},
    BAG_SMALL: {value: 'bag_small', apiKey: 'BAG_SMALL'},
    BAG_BIG: {value: 'bag_big', apiKey: 'BAG_BIG'},
    BAG_WEAPON: {value: 'bag_weapon', apiKey: 'BAG_WEAPON'},
    BAG_PET: {value: 'bag_pet', apiKey: 'BAG_PET'},
    SEAT: {value: 'seat', apiKey: 'SEAT'},
    SEAT_PLUS: {value: 'seat_plus', apiKey: 'SEAT_PLUS'},
    TKT_CHANGES_NO_SHOW: {value: 'tkt_changes_no_show', apiKey: 'TKT_CHANGES_NO_SHOW'},
    TKT_RETURN_NO_SHOW: {value: 'tkt_return_no_show', apiKey: 'TKT_RETURN_NON_SHOW'}
};

export const SERVICE_TYPES_API_KEYS = {
    PROP_CABIN_BAG: 'CABIN_BAG',
    PROP_BAGGAGE: 'BAGGAGE',
    PROP_BAG_SPORT: 'BAG_SPORT',
    PROP_MILES: 'MILES',
    PROP_TKT_CHANGES: 'TKT_CHANGES',
    PROP_TKT_RETURN: 'TKT_RETURN',
    PROP_VIP_LODGE: 'VIP_LODGE',
    PROP_PRIOR_REGISTER: 'PRIOR_REGISTER',
    PROP_BAG_INF: 'BAG_INF',

    // Дальше идет угадывание значений, сейчас точных нет
    PROP_CABIN_BAG_SMALL: 'CABIN_BAG_SMALL',
    PROP_CABIN_BAG_BIG: 'CABIN_BAG_BIG',
    PROP_BAG_SMALL: 'BAG_SMALL',
    PROP_BAG_BIG: 'BAG_BIG',
    PROP_SEAT: 'SEAT',
    PROP_SEAT_PLUS: 'SEAT_PLUS'
};

export const SERVICE_NAMES = Object.keys(SERVICE_TYPES).reduce(
    (acc, name) => ({
        ...acc,
        [name]: name
    }),
    {}
);
