export function toCamelCase(value) {
    if (!(value && typeof value === 'object')) return value;

    let result;

    if (value instanceof Array) {
        result = value.map(toCamelCase);
    } else {
        result = {};

        Object.keys(value).forEach((key) => {
            const newKey = key.replace(/(_\w)/g, (item) => item[1].toUpperCase());
            result[newKey] = toCamelCase(value[key]);
        });
    }

    return result;
}
