/* eslint-disable */
import { createFSA } from 'utils';
import {utairHttpManager as request} from 'managers/utair';

import * as TYPE from 'constants/subscriptionsTypes';
import {API_URLS} from 'consts';

export const fetchStateFSA = createFSA(TYPE.SET_UNSUBSCRIBE_FETCH_STATE);
export const fetchMessageFSA = createFSA(TYPE.FETCH_UNSUBSCRIBE_MESSAGE_INFO);
export const messageDataFSA = createFSA(TYPE.SET_UNSUBSCRIBE_MESSAGE_DATA);
export const errorFSA = createFSA(TYPE.SET_UNSUBSCRIBE_ERROR);
export const saveFSA = createFSA(TYPE.SAVE_UNSUBSCRIBE_SUBSCRIPTIONS);
export const successFSA = createFSA(TYPE.SET_UNSUBSCRIBE_SUCCESS);
export const updateUserSubscriptions = createFSA(TYPE.UPDATE_USER_SUBSCRIPTIONS);

/**
 * Unsubscribe actions
 */
export const fetchMessageInfo = (id, address) => (dispatch) => dispatch(fetchMessageFSA({id, address}));

export const requestMessage = (id, address) =>
    request.get(`${API_URLS.SYSTEM.SUBSCRIPTIONS}${id}/${address}/`, null, false).then((res) => res.data);

export const saveSubscriptions = (params) => (dispatch) => dispatch(saveFSA(params));

export const requestSaveSubscriptions = (id, address, categories) =>
    request.put(`${API_URLS.SYSTEM.SUBSCRIPTIONS}${id}/${address}/`, {categories});

/**
 * User actions
 */
export const fetchUserFetchFSA = createFSA(TYPE.FETCH_USER_SUBSCRIPTIONS);
export const fetchUserFetchStateFSA = createFSA(TYPE.SET_USER_FETCH_STATE);
export const setUserSubscriptionsFSA = createFSA(TYPE.SET_USER_SUBSCRIPTIONS);
export const userSubscriptionsErrorFSA = createFSA(TYPE.SET_USER_ERROR);

export const fetchUserSubscriptions = () => (dispatch) => dispatch(fetchUserFetchFSA());

export const requestUserSubscriptions = () =>
    request.get(`${API_URLS.PROFILE.SUBSCRIPTIONS}`, null, true).then((res) => res.data);

export const updateUserSubscriptionsRequest = (params) =>
    request.put(`${API_URLS.PROFILE.SUBSCRIPTIONS}`, params, true).then((res) => res.data);
