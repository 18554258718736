import React, { useCallback, useEffect, useState } from 'react';
import { withTransaction } from '@elastic/apm-rum-react';
import cn from 'classnames';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { searchUserOrders, setIsOpenBoardingPassModal } from 'actions/checkin';
import {
    getSegmentsData,
    getIsBoardingPassModalOpen,
    getIsBookingNotFound,
    getIsFlightNotFound,
    getOnlyOfflineRegistrationMessage,
    getIsFailure,
} from 'selectors/checkin';
import { getIsAuthUser } from 'selectors/auth';

import { CheckInForm } from './checkin-form';
import { OrderItem } from './order-item';
import { BoardingPassModal } from './BoardingPassModal';
import { BookingNotFoundModal } from './BookingNotFoundModal';
import { FlightNotFoundModal } from './FlightNotFoundModal';
import { OnlyOfflineRegistrationModal } from './OnlyOfflineRegistrationModal';
import { Failure } from './Failure';
import PromoBlock from './PromoBlock';

function CheckIn({
    location: {
        query: { identifier: idQuery, rloc: rlocQuery, last_name: lastNameQuery },
    },
}) {
    const dispatch = useDispatch();
    const [boardingPassData, setBoardingPassData] = useState(null);
    const orders = useSelector(getSegmentsData, shallowEqual);
    const isBoardingPassModalOpen = useSelector(getIsBoardingPassModalOpen);
    const isBookingNotFound = useSelector(getIsBookingNotFound);
    const isFlightNotFound = useSelector(getIsFlightNotFound);
    const onlyOfflineRegistrationMessage = useSelector(getOnlyOfflineRegistrationMessage);
    const isFailure = useSelector(getIsFailure);

    const isUserAuth = useSelector(getIsAuthUser, shallowEqual);
    useEffect(() => {
        if (isUserAuth) {
            dispatch(searchUserOrders(isUserAuth));
        }
    }, [isUserAuth, dispatch]);

    const handleBoardingPassesModalShow = useCallback(
        (rloc, flightNumber) => {
            setBoardingPassData({ rloc, flightNumber });
            dispatch(setIsOpenBoardingPassModal(true));
        },
        [dispatch]
    );

    return (
        <>
            <Helmet>
                <meta name="description" content={t('check_in.meta.description')} />
                <meta name="keywords" content={t('check_in.meta.keywords')} />
                <title>{t('check_in.meta.title')}</title>
            </Helmet>

            <CheckInForm locatorQuery={rlocQuery || idQuery} lastNameQuery={lastNameQuery} />

            <div className={cn('container-wide', { '!pt64': !orders.length }, { '!pt32': !!orders.length })}>
                {orders &&
                    orders.map((orderItem) => (
                        <OrderItem
                            key={orderItem.rloc}
                            {...orderItem}
                            onShowBoardingPassesModal={handleBoardingPassesModalShow}
                        />
                    ))}

                {!orders.length && <PromoBlock />}
            </div>
            {isBoardingPassModalOpen && <BoardingPassModal isOpen={isBoardingPassModalOpen} {...boardingPassData} />}
            {isFlightNotFound && <FlightNotFoundModal />}
            {onlyOfflineRegistrationMessage && (
                <OnlyOfflineRegistrationModal message={onlyOfflineRegistrationMessage} />
            )}
            {isBookingNotFound && <BookingNotFoundModal />}
            {isFailure && <Failure />}
        </>
    );
}

export default withTransaction('CheckIn', 'page')(CheckIn);
