/* eslint-disable */
import _ from 'lodash';
import { API_URLS } from 'consts';
import { utairHttpManager as request } from 'managers/utair';
import i18next from 'i18next';
import { parseQueryString } from 'utils';

import { logout, toggleModal, authorizeUser, setUserLogin, setUserAuthAttemptId, setIsAuthUser } from 'actions/auth';
import { clearUserData, getUserData } from 'actions/user';
import { resetSocialNetworks } from 'actions/social.networks';
import { resetBonuses } from 'actions/bonuses';

import { getGDPRAgreementDate } from 'selectors/sign';
import { getSocialData, getMergingSocialData } from './selectors';

import * as authServices from './services';

export const YANDEX_APP_ID = authServices['yandex'].APP_ID;
export const SOCIAL_AUTH_STORAGE_KEY = 'SOCIAL_AUTH_QUERY';
export const SUGGEST_SOCIAL_AUTH_STORAGE_KEY = 'SUGGEST_SOCIAL_AUTH_QUERY';

/**
 * Reducer constants
 */
export const SET_SOCIALAUTH_ERROR = 'SET_SOCIALAUTH_ERROR';
export const RESET_SOCIALAUTH = 'RESET_SOCIALAUTH';
export const SET_DIALOG_STATE = 'SET_DIALOG_STATE';
export const SET_SOCIAL_REGISTRATION = 'SET_SOCIAL_REGISTRATION';
export const SET_VISIBLE_SOCIAL = 'SET_VISIBLE_SOCIAL';
export const SET_SOCIAL_DATA = 'SET_SOCIAL_DATA';
export const SET_SOCIAL_AUTH = 'SET_SOCIAL_AUTH';
export const RESET_SOCIAL_DATA = 'RESET_SOCIAL_DATA';

/**
 * Buffers
 */
let currentType = null;
let eventBinded = false;

/**
 * Constants
 */
const VERIFIED_NETWORKS = ['google', 'mailru', 'yandex'];

/**
 * Setters
 */
export const setError = (error) => (dispatch) =>
    dispatch({
        type: SET_SOCIALAUTH_ERROR,
        error,
    });

export const setSocialRegistration =
    (isSocialRegistration = true) =>
    (dispatch) =>
        dispatch({
            type: SET_SOCIAL_REGISTRATION,
            isSocialRegistration,
        });

export const setSocialData = (socialData) => (dispatch) => {
    dispatch({
        type: SET_SOCIAL_DATA,
        socialData,
    });
};

export const setSocialAuth = (isSocialAuth) => (dispatch) => {
    dispatch({
        type: SET_SOCIAL_AUTH,
        isSocialAuth,
    });
};

/**
 * Reset all social data from store
 */
export const reset = () => (dispatch) => {
    currentType = null;
    dispatch({
        type: RESET_SOCIALAUTH,
    });
};

export const resetSocialData = () => (dispatch) => {
    dispatch({
        type: RESET_SOCIAL_DATA,
    });
};

/**
 * Show/hide social auth dialog
 * @param isVisible
 * @param isSocialAuth
 */
export const setDialogState =
    (isVisible, isSocialAuth = false) =>
    (dispatch) => {
        dispatch(setSocialAuth(isSocialAuth));

        dispatch({
            type: SET_DIALOG_STATE,
            isVisible,
        });
    };

/**
 * Show/hide social icons
 * @param isVisible
 */
export const setVisibleSocial =
    (isVisible = true) =>
    (dispatch) => {
        dispatch({
            type: SET_VISIBLE_SOCIAL,
            isVisible,
        });
    };

const _parseData = (storageData) => {
    if (storageData instanceof Object) {
        return storageData;
    }

    return parseQueryString(storageData);
};

/**
 * Make logn with social network data from suggest
 * @param type
 * @param storageData
 * @param callbackFunc
 */
export const suggectSocialAuth = (type, storageData, callbackFunc) => (dispatch) => {
    if (getInstance(type)) {
        if (storageData) {
            currentType = type;

            const parsedData = _parseData(storageData);
            const authData = mapSocialData(parsedData);
            dispatch(setSocialData(authData));

            const params = {
                ...authData,
            };

            return _fetchAuth(params)
                .then((sessionData) =>
                    authorizeUser(`${authData.network}:${authData.auth_data.ident}`, sessionData.session).then(() => {
                        dispatch(clearUserData());
                        dispatch(resetSocialNetworks());
                        dispatch(resetBonuses());
                        dispatch(setUserLogin(null));
                        dispatch(setUserAuthAttemptId(null));
                        dispatch(setIsAuthUser(true));
                        dispatch(getUserData(true));

                        callbackFunc();
                    })
                )
                .catch((error) => {
                    const errorCode = _.get(error, 'data.meta.error_code', 0);
                    switch (errorCode) {
                        case 40073:
                            dispatch(setDialogState(true));
                            break;
                        case 40301:
                            dispatch(setError(i18next.t('sign.error.too_many_requests')));
                            dispatch(resetSocialData());
                            break;
                        case 40302:
                        case 40303:
                            dispatch(setError(i18next.t('sign.error.user_blocked')));
                            dispatch(resetSocialData());
                            break;
                        default:
                            dispatch(setError(i18next.t('common.unknown_error')));
                            dispatch(resetSocialData());
                            break;
                    }
                    dispatch(toggleModal(true));
                });
            return;
        }
    } else {
        throw 'Social service is not defined';
    }
};

/**
 * Make logn with social network data
 * @param type
 * @param callbackFunc
 * @param authData
 */
export const socialAuth = (type, callbackFunc, setIsLoading, authData) => (dispatch) => {
    if (getInstance(type)) {
        currentType = type;
        getInstance().auth(setIsLoading); // OAuth авторизация в соц сети

        if (authData) {
            dispatch(
                setSocialData({
                    auth_data: authData,
                    network: getInstance().NETWORK,
                })
            );
            dispatch(socialAuthFetch(callbackFunc, setIsLoading));
            return;
        }

        if (!eventBinded) {
            eventBinded = true;

            subscribeStorage((event) => {
                listener(event, dispatch, callbackFunc, setIsLoading);
            });
        }
    } else {
        throw 'Social service is not defined';
    }
};

/**
 * Return social network instance
 * @param type
 */
const getInstance = (type = currentType) => authServices[type];

/**
 * Social Auth login
 */
const socialAuthFetch =
    (callbackFunc = () => {}, setIsLoading) =>
    (dispatch, getState) => {
        const socialData = getSocialData(getState());
        const mergingData = getMergingSocialData(getState());

        const params = {
            ...socialData,
        };

        if (mergingData) {
            params.social = mergingData;
        }

        return _fetchAuth(params)
            .then((sessionData) =>
                authorizeUser(`${socialData.network}:${socialData.auth_data.ident}`, sessionData.session).then(() => {
                    dispatch(setUserLogin(null));
                    dispatch(setUserAuthAttemptId(null));
                    dispatch(setIsAuthUser(true));

                    callbackFunc();
                })
            )
            .catch((err) => _errorHandler(err, dispatch, setIsLoading));
    };

/**
 * error handler
 * @param error
 * @param dispatch
 * @param setIsLoading
 * @private
 */
const _errorHandler = (error, dispatch, setIsLoading) => {
    setIsLoading(false);

    const errorCode = _.get(error, 'data.meta.error_code', 0);
    switch (errorCode) {
        case 40073:
            dispatch(setDialogState(true));
            break;
        case 40074:
            dispatch(setError(i18next.t('sign.error.social_email_exists')));
            dispatch(resetSocialData());
            break;
        case 40301:
            dispatch(setError(i18next.t('sign.error.too_many_requests')));
            dispatch(resetSocialData());
            break;
        case 40302:
        case 40303:
            dispatch(setError(i18next.t('sign.error.user_blocked')));
            dispatch(resetSocialData());
            break;
        default:
            dispatch(setError(i18next.t('common.unknown_error')));
            dispatch(resetSocialData());
            break;
    }

    throw error;
};

/**
 * Make request to sign-in method with social login
 * @param data
 * @return {Promise}
 * @private
 */
const _fetchAuth = (data) => {
    const requestParams = {
        ...data,
    };
    return request.post(API_URLS.AUTH.SOCIAL_LOGIN, requestParams, false);
};

/**
 * Make registration user with social network data
 */
export const socialRegistration =
    (callbackFunc, setIsLoading, referralLinkId = null) =>
    (dispatch, getState) => {
        const state = getState();
        const data = getSocialData(state);

        if (VERIFIED_NETWORKS.indexOf(data.network) > -1) {
            const requestParams = {
                ...data,
                referralLinkId,
            };

            if (requestParams.auth_data) {
                requestParams.auth_data.confirmationGDPRDate = getGDPRAgreementDate(state);
            }

            return request
                .post(API_URLS.AUTH.SOCIAL_REGISTRATION, requestParams, false)
                .then((sessionData) => {
                    dispatch(setSocialRegistration());
                    dispatch(socialAuthFetch(callbackFunc, setIsLoading));
                })
                .catch((err) => _errorHandler(err, dispatch, setIsLoading));
        }

        dispatch(setDialogState(false, true));
    };

const mapSocialData = (data) => ({
    network: getInstance().NETWORK,
    auth_data: {
        ident: data.user_id,
        access_token: data.access_token,
        email: data.email,
    },
});

/**
 * OAuth event handler
 * @param event
 * @param dispatch
 * @param callbackFunc
 */
const listener = (event, dispatch, callbackFunc, setIsLoading) => {
    if (event.key === SOCIAL_AUTH_STORAGE_KEY) {
        const storageData = localStorage.getItem(SOCIAL_AUTH_STORAGE_KEY);

        if (storageData) {
            const parsedData = parseQueryString(storageData);

            dispatch(setSocialData(mapSocialData(parsedData)));

            dispatch(socialAuthFetch(callbackFunc, setIsLoading));
        }
    }
};

/**
 * Binding event listener
 * @param {function} listener
 */
const subscribeStorage = (listener) => {
    window.addEventListener('storage', listener, true);
};
