import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from '@public-projects/utair-ui-kit';
import { ReactComponent as FlightNotFound } from 'images/flight-not-found.svg';
import { setOnlyOfflineRegistrationMessage } from 'actions/checkin';

import styles from './OnlyOfflineRegistrationModal.module.scss';

export function OnlyOfflineRegistrationModal({ message }) {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setOnlyOfflineRegistrationMessage(null));
    };

    return (
        <Modal size="medium" onClose={handleClose}>
            <Modal.Image>
                <FlightNotFound width={230} height={230} />
            </Modal.Image>
            <Modal.Title centered>Рейсы с открытой регистрацией не найдены</Modal.Title>
            <Modal.Content centered>
                <span className={styles.modalContentText}>{message}</span>
            </Modal.Content>
            <Modal.Buttons>
                <Button size="large" onClick={handleClose}>
                    Попробовать ещё раз
                </Button>
            </Modal.Buttons>
        </Modal>
    );
}
