/* eslint-disable max-len */
export const sign = {
    header_sing_up: 'Регистрация в Utair Status',
    header_sing_in: 'Вход',
    reg_title: 'Введите данные, чтобы зарегистрироваться',
    login_or_reg_title: 'Введите данные, чтобы войти или зарегистрироваться',
    oauth_login: 'Зарегистрироваться с помощью аккаунтов',
    placeholder: {
        login: 'Email, телефон или карта Status',
        registrationLogin: 'Email или телефон',
        phone: 'Телефон',
        email: 'Email',
        card: 'Карта Status',
        code_full: 'Введите код',
        call_code_full: 'Введите последние 4 цифры номера входящего звонка',
        code: 'Код подтверждения',
        enter_code_from_email: 'Введите код',
        enter_code_from_phone: 'Введите код из СМС',
        code_sent_to_email: 'Мы отправили его на почту {{login}}',
        code_sent_to_phone: 'Мы отправили его на номер {{login}}',
    },
    resend: 'Выслать код повторно',
    call_again: 'Позвонить повторно',
    accept_rules: {
        title: 'Регистрируясь, я принимаю условия',
        link: 'использования персональных данных',
        processing_text: ', даю',
        processing_link: 'согласие на их обработку',
        processing_rules: `Настоящее согласие предоставляется Авиакомпании на осуществление любых\n
            действий (операций), совершаемых с использованием средств автоматизации или\n
            без использования таких средств с персональными данными, включая сбор,\n
            запись, систематизацию, накопление, хранение, уточнение (обновление,\n
            изменение), извлечение, использование, передачу (распространение,\n
            предоставление, доступ), обезличивание, блокирование, удаление, уничтожение\n
            персональных данных, трансграничную передачу персональных данных, а также\n
            осуществление любых иных действий с моими персональными данными,\n
            предусмотренных действующим законодательством Российской Федерации.`,
        status_text: 'и принимаю',
        status_link: 'правила программы Utair Status',
    },
    phone: 'номером телефона',
    error: {
        api_error: 'Ошибка регистрации пользователя. Попробуйте повторить позднее.',
        incorrect_code: 'Проверьте правильность кода подтверждения',
        social_auth_error: 'Ошибка входа. Попробуйте позднее или заполните обязательные поля',
        code_expired: 'Срок действия кода истёк',
        exceeded_attempts: 'Превышено количество попыток. Попробуйте позднее',
        send_code: 'Ошибка отправки проверочного кода',
        login_not_found: 'Пользователь с\u00A0таким логином не найден. ',
        login_not_found_reg: 'Зарегистрироваться?',
        too_many_requests: 'Слишком много попыток. Попробуйте позднее',
        user_blocked: 'Ваша учетная запись заблокирована, обратитесь в\u00A0Службу поддержки',
        social_email_exists: 'Пользователь с\u00A0таким Email уже зарегистрирован.',
        social_phone_exists: 'Пользователь с\u00A0таким телефоном уже зарегистрирован.',
        phone_disabled: 'Пользователь отключил авторизацию через SMS',
        phone_incorrect: 'Невозможно отправить СМС на\u00A0номер в\u00A0вашем профиле',
        send_code_error: 'Ошибка отправки кода',
        no_contacts: ' не привязана к\u00A0Email или номеру телефона',
        confirm_attempts_exceeded_text:
            'Лимит запросов превышен. Получите новый код подтверждения завтра или обратитесь в',
        confirm_attempts_exceeded_link: 'Службу поддержки',
    },
    token_error: {
        auth_error: 'Ошибка авторизации',
        network_restricted: 'Произошла ошибка авторизации из-за ограничений сети или настроек антивируса.',
        to_solve_problem_contact_your_provider:
            'Чтобы устранить ошибку, рекомендуем обратиться в поддержку провайдера. Либо авторизуйтесь с мобильного телефона через сотовую сеть.',
        if_you_have_questions: 'Если у вас остались вопросы, ',
        write_to_us: 'напишите нам.',
    },
    countdown_message: 'Вы сможете повторно отправить код через\u00A0{{time}}\u00A0сек.',
    countdown_call_message: 'Вы сможете повторно запросить звонок через\u00A0{{time}}\u00A0сек.',
    card_status_send: ', привязанный к карте',
    code_sender: 'был\u00A0отправлен код\u00A0доступа',
    call_code_sender: 'был направлен звонок',
    code_sended_to_email: 'На почту',
    code_sended_to_phone: 'На телефон',
    social: {
        title: 'Войдите с помощью соцсетей',
        yandex: 'Войти с Яндекс ID',
    },
};
