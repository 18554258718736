exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tab-module__root--3crza{padding:4px 16px;color:rgba(94,110,135,.8);font-weight:400;font-size:14px;line-height:20px;white-space:nowrap}.Tab-module__active--2U4nq,.Tab-module__root--3crza:hover{color:#111820;background:rgba(0,53,148,.07);border-radius:4px}", ""]);

// exports
exports.locals = {
	"root": "Tab-module__root--3crza",
	"active": "Tab-module__active--2U4nq"
};