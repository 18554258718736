import React, { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { Backdrop } from './Backdrop';
import { Loader } from './Loader';
import { ModalWrapper } from './ModalWrapper';

interface Props {
    onClose: () => void;
    className: string;
    isLoading: boolean;
    disableOverlayClick: boolean;
    children: ReactNode;
}

export function ServiceModal({ onClose, className, isLoading = false, disableOverlayClick = false, children }: Props) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(true);
    }, []);

    const handleCloseModal = () => {
        setIsOpen(false);
        onClose();
    };

    return createPortal(
        <>
            <Backdrop isOpen={isOpen} onClick={handleCloseModal} disableOverlayClick={disableOverlayClick} />
            <ModalWrapper isOpen={isOpen} className={className}>
                {children}
                <Loader isLoading={isLoading} />
            </ModalWrapper>
        </>,
        document.body
    );
}
