import { createSelector } from '@reduxjs/toolkit';
import * as moment from 'moment';

const toRelativeTime = (datetime) => {
    const now = moment.utc();
    const diff = datetime.diff(now);
    const hours = Math.floor(moment.duration(diff).asHours());
    const minutes = moment.duration(diff).minutes();
    const seconds = moment.duration(diff).seconds();

    return { hours, minutes, seconds };
};

const checkinSelector = (state) => state.checkIn;

export const getIsFetching = createSelector(checkinSelector, (data) => data.isFetching);
export const getSearchFormData = createSelector(checkinSelector, (data) => data.searchForm);
export const getIsModalOpen = createSelector(checkinSelector, (data) => data.isModalOpen);
export const getIsBoardingPassModalOpen = createSelector(checkinSelector, (data) => data.isBoardingPassModalOpen);
export const getIsBookingNotFound = createSelector(checkinSelector, (data) => data.isBookingNotFound);
export const getIsFlightNotFound = createSelector(checkinSelector, (data) => data.isFlightNotFound);
export const getOnlyOfflineRegistrationMessage = createSelector(
    checkinSelector,
    (data) => data.onlyOfflineRegistrationMessage
);
export const getIsFailure = createSelector(checkinSelector, (data) => data.isFailure);
export const getOrder = createSelector(checkinSelector, (data) => data.order);
export const getOpenedOrders = createSelector(checkinSelector, (data) => data.openedOrders);
export const getSegmentsData = createSelector(getOpenedOrders, (data) => {
    if (!data || !data.length) {
        return [];
    }

    return data.map((order) => {
        const { rloc, segments } = order;
        const segmentsData = segments.map((segment) => {
            const {
                ak,
                passengers,
                departure_city: departureCity,
                arrival_city: arrivalCity,
                flight_number: flightNumber,
                flight_status: flightStatus,
                departure_local_iso: departureTimeString,
                close_registration_iso: checkinCloseTimeString,
                est_departure_time: estDepartureTime,
                all_passengers_checked: allPassengersChecked,
            } = segment;

            const canDowloadBoardingPass =
                flightStatus === 'sCloseWEBCheckIn' && passengers.some((x) => x.can_download_boarding_pass);
            const passengerLastName = passengers[0].last_name;

            let checkDepartureTime = departureTimeString;

            if (estDepartureTime) {
                try {
                    const check = moment.parseZone(estDepartureTime);

                    if (check.toString() !== 'Invalid date') {
                        checkDepartureTime = estDepartureTime;
                    }
                } catch {} // eslint-disable-line no-empty
            }

            const departureDatetime = moment.parseZone(checkDepartureTime);
            const checkinCloseDatetime = checkinCloseTimeString
                ? moment.parseZone(checkinCloseTimeString)
                : departureDatetime.clone().subtract(45, 'minutes');
            const timeToCheckinClose = toRelativeTime(checkinCloseDatetime);
            const hours =
                timeToCheckinClose.hours > 0
                    ? (timeToCheckinClose.hours === 1 && '1 час') ||
                      moment.duration(timeToCheckinClose.hours, 'hours').humanize({ h: 36 })
                    : '';
            const minutes =
                timeToCheckinClose.minutes > 0
                    ? (timeToCheckinClose.minutes === 1 && '1 минута') ||
                      moment.duration(timeToCheckinClose.minutes, 'minutes').humanize({ m: 90 })
                    : '';
            const departureTime = departureDatetime.format('HH:mm');

            const enableCheckin = passengers.some((x) => !x.disable_checkin);

            return {
                ak,
                rloc,
                flightNumber,
                allPassengersChecked,
                departureDate: departureDatetime.format('DD MMMM, dd'),
                departureTime,
                departureCity,
                arrivalCity,
                isDisableCheckin: !enableCheckin,
                isCheckInOpen: flightStatus === 'sOpenWEBCheckIn',
                timeToCheckinCloseString: `${hours} ${minutes}`.trim(),
                canDowloadBoardingPass,
                passengerLastName,
            };
        });

        return { rloc, segments: segmentsData };
    });
});

export const getHasOrders = createSelector(getSegmentsData, (data) => Boolean(data.length));

// eslint-disable-next-line react/display-name
export const getCheckedInPassengers = (rloc, flightNumber) => (state) => {
    const data = getOpenedOrders(state);
    const order = data.find((x) => x.rloc === rloc);

    if (!order) {
        return null;
    }

    const segment = order.segments.find((x) => x.flight_number === flightNumber);

    if (!segment) {
        return null;
    }

    const passengers = segment.passengers
        .filter((x) => x.can_download_boarding_pass)
        .map((x) => ({
            lastName: x.last_name,
            firstName: x.first_name,
            astraPassengerId: x.astra_pass_id,
        }));

    return passengers;
};
