import { type City } from 'schedule/shared/model';

import type { CityDto } from '../api/types';

export function mapNearestCity(dto: CityDto, lng: string = 'ru'): City | null {
    if (!dto) {
        return null;
    }

    return {
        code: dto.code,
        name: (dto.name_translations && dto.name_translations[lng]) || dto.name,
        countryCode: dto.code,
        countryName: (dto.country_name_translations && dto.country_name_translations[lng]) || dto.country_name,
    };
}
