import {API_URLS} from 'consts';
import {utairHttpManager as request} from 'managers/utair';

const ACTIONS_PREFIX = 'actions/countries/';

export const SET_COUNTRIES = `${ACTIONS_PREFIX}SET_COUNTRIES`;

export const setCountries = (countries) => ({
    type: SET_COUNTRIES,
    payload: countries
});

export const fetchCountries = async (dispatch, getState) => {
    const state = getState();

    if (!state.countries.list.length) {
        const countries = await request.get({
            url: API_URLS.COUNTRIES
        });

        dispatch(setCountries(countries));
    }
};
