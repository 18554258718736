import React from 'react';
import Header from 'components/layout/header';

import { ReactComponent as NotFoundImage } from './images/not-found-img.svg';
import styles from './NotFound.module.scss';

export function NotFound() {
    const linkToSupport = 'https://www.utair.ru/support/11/vyhodit_oshibka_na_sajte_chto_delat';

    return (
        <main className={styles.root}>
            <Header pathname="/" isOnlinePanel />
            <section className={styles.wrapper}>
                <h2 className={styles.errorTitle}>404 Страница не найдена</h2>
                <p className={styles.errorText}>
                    Возможно вы неправильно скопировали ссылку или такой страницы больше не существует. Если вы попали
                    сюда, кликнув на ссылку на нашем сайте, пожалуйста, &nbsp;
                    <a href={linkToSupport} className={styles.link}>
                        сообщите нам об этом.
                    </a>
                    <br />
                    <br />
                </p>
                <p className={styles.errorText}>
                    Вы можете покинуть эту страницу и перейти в один из наших популярных разделов:
                </p>
                <nav>
                    <ul className={styles.links}>
                        <li className={styles.linkItem}>
                            <a href="/" className={styles.link}>
                                Покупка авиабилета
                            </a>
                        </li>
                        <li className={styles.linkItem}>
                            <a href="/check-in-new" className={styles.link}>
                                Регистрация на рейс
                            </a>
                        </li>
                        <li className={styles.linkItem}>
                            <a href="/order-manage" className={styles.link}>
                                Управление билетом
                            </a>
                        </li>
                        <li className={styles.linkItem}>
                            <a href="https://media.utair.ru/status" className={styles.link}>
                                Программа лояльности Utair Status
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className={styles.imageContainer}>
                    <NotFoundImage className={styles.mainImage} />
                </div>
            </section>
        </main>
    );
}
