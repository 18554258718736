import {
    FIND_ORDERS_REQUEST,
    FIND_ORDERS_SUCCESS,
    FIND_ORDERS_FAILURE,
    CONFIRM_CODE_REQUEST,
    CONFIRM_CODE_SUCCESS,
    CONFIRM_CODE_FAILURE,
    SET_CODE_CONFIRM_ERROR,
    FETCH_CONTACTS_REQUEST,
    FETCH_CONTACTS_SUCCESS,
    FETCH_CONTACTS_FAILURE,
    GET_CODE_REQUEST,
    GET_CODE_SUCCESS,
    GET_CODE_FAILURE,
    FETCH_PAYMENT_LINK_REQUEST,
    FETCH_PAYMENT_LINK_SUCCESS,
    FETCH_PAYMENT_LINK_FAILURE,
    SET_PARAMS,
    SET_ORDERS,
    SET_CONTACTS,
    SET_REFUND_SUM,
    SET_RLOC,
    SET_ATTEMPT_ID,
    SET_SELECTED_CONTACT,
    SET_IS_LOADING,
    ACTIVATE_ACCOUNT_REQUEST,
    ACTIVATE_ACCOUNT_SUCCESS,
    ACTIVATE_ACCOUNT_FAILURE,
    SET_STEP,
    SET_ERROR_CODE,
    RESET,
    FULL_RESET,
    SET_EMAIL_LIST,
    SEND_NOTIFICATION_FAILURE,
    SEND_NOTIFICATION_REQUEST,
    SEND_NOTIFICATION_SUCCESS,
    SET_IS_ORDER_TKP,
    SET_ERROR_DATA,
    SET_REFUND_KIND,
    SET_IS_NOTIFICATION_SUCCESS,
    SET_PAGE_SKIP,
    SET_ORDERS_TOTAL_NUMBER,
} from 'actions/order-manage';

const INITIAL_STATE = {
    isLoading: false,
    error: null,
    errorCode: undefined,
    errorData: {},
    orders: [],
    contacts: [],
    emailList: [],
    selectedContact: undefined,
    isOrderTkp: false,
    refundKind: '',
    refundSum: {
        miles: null,
        bonus: null,
        cash: null,
    },
    params: {
        locator: '',
        lastName: '',
    },
    rloc: '',
    attemptId: '',
    step: '',
    code: undefined,
    codeConfirmError: '',
    isNotificationSuccess: false,
    pageSkip: 0,
    totalNumber: 0,
};

const OrderManageReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case FIND_ORDERS_REQUEST:
            return { ...state, isLoading: true };
        case FIND_ORDERS_SUCCESS:
        case FIND_ORDERS_FAILURE:
            return { ...state, isLoading: false };
        case CONFIRM_CODE_REQUEST:
            return { ...state, isLoading: true, code: payload };
        case CONFIRM_CODE_SUCCESS:
            return { ...state, isLoading: false };
        case CONFIRM_CODE_FAILURE:
            return { ...state, isLoading: false, codeConfirmError: payload };
        case SET_CODE_CONFIRM_ERROR:
            return { ...state, codeConfirmError: payload };
        case FETCH_CONTACTS_REQUEST:
            return { ...state, isLoading: true };
        case FETCH_CONTACTS_SUCCESS:
        case FETCH_CONTACTS_FAILURE:
            return { ...state, isLoading: false };
        case GET_CODE_REQUEST:
            return { ...state, isLoading: true };
        case GET_CODE_SUCCESS:
        case GET_CODE_FAILURE:
            return { ...state, isLoading: false };
        case FETCH_PAYMENT_LINK_REQUEST:
            return { ...state, isLoading: true };
        case FETCH_PAYMENT_LINK_SUCCESS:
        case FETCH_PAYMENT_LINK_FAILURE:
            return { ...state, isLoading: false };
        case SET_PARAMS:
            return { ...state, params: { ...payload } };
        case SET_ORDERS:
            return { ...state, orders: [...payload] };
        case SET_CONTACTS:
            return { ...state, contacts: [...payload] };
        case SET_REFUND_SUM:
            return { ...state, refundSum: { ...payload } };
        case SET_REFUND_KIND:
            return { ...state, refundKind: payload };
        case SET_IS_ORDER_TKP:
            return { ...state, isOrderTkp: payload };
        case SET_RLOC:
            return { ...state, rloc: payload };
        case SET_ATTEMPT_ID:
            return { ...state, attemptId: payload };
        case SET_SELECTED_CONTACT:
            return { ...state, selectedContact: payload };
        case SET_EMAIL_LIST:
            return { ...state, emailList: payload };
        case SET_IS_LOADING:
            return { ...state, isLoading: payload };
        case ACTIVATE_ACCOUNT_REQUEST:
            return { ...state, isLoading: true };
        case ACTIVATE_ACCOUNT_SUCCESS:
        case ACTIVATE_ACCOUNT_FAILURE:
            return { ...state, isLoading: false };
        case SEND_NOTIFICATION_REQUEST:
            return { ...state, isLoading: true };
        case SEND_NOTIFICATION_SUCCESS:
        case SEND_NOTIFICATION_FAILURE:
            return { ...state, isLoading: false };
        case SET_IS_NOTIFICATION_SUCCESS:
            return { ...state, isNotificationSuccess: payload };
        case SET_STEP:
            return { ...state, step: payload.step };
        case SET_ERROR_CODE:
            return { ...state, errorCode: payload };
        case SET_ERROR_DATA:
            return { ...state, errorData: payload };
        case SET_PAGE_SKIP:
            return { ...state, pageSkip: payload };
        case SET_ORDERS_TOTAL_NUMBER:
            return { ...state, totalNumber: payload };
        case RESET:
            return {
                ...INITIAL_STATE,
                orders: state.orders,
                params: state.params,
            };
        case FULL_RESET:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

export default OrderManageReducer;
