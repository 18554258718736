// константы используемые в ребукинге
// в дальнейшем нужно избавиться от двойного набора
export const DOC_TYPES = {
    PASSPORT: 'ps',
    FOREIGN_PASSPORT: 'psp',
    FOREIGN_PASSPORT_NON_RF: 'za',
    DIPLOMATIC_PASSPORT: 'dp',
    OFFICIAL_PASSPORT: 'sp',
    NATIONAL_PASSPORT: 'np',
    NAVAL_PASSPORT: 'pm',
    TEMPORARY_ID: 'spu',
    BIRTH_CERTIFICATE: 'sr',
    DEPUTY_ID: 'ud',
    OFFICER_ID: 'udl',
    MILITARY_ID: 'vb',
    RESIDENCE_PERMIT: 'vv',
    CONVICT_TEMPORARY_ID: 'vul',
    UNPRISON_CERTIFICATE: 'spo',
    CIS_RETURN_CERTIFICATE: 'cvv',
};

export const ALL_ADULT_DOC_TYPES = Object.values(DOC_TYPES);

export const FOREIGN_ADULT_DOC_TYPES = [
    DOC_TYPES.FOREIGN_PASSPORT,
    DOC_TYPES.FOREIGN_PASSPORT_NON_RF,
    DOC_TYPES.RESIDENCE_PERMIT,
    DOC_TYPES.MILITARY_ID,
    DOC_TYPES.TEMPORARY_ID,
    DOC_TYPES.DIPLOMATIC_PASSPORT,
    DOC_TYPES.NATIONAL_PASSPORT,
    DOC_TYPES.NAVAL_PASSPORT,
    DOC_TYPES.CIS_RETURN_CERTIFICATE,
    DOC_TYPES.OFFICIAL_PASSPORT,
    DOC_TYPES.UNPRISON_CERTIFICATE,
    DOC_TYPES.CONVICT_TEMPORARY_ID,
    DOC_TYPES.OFFICER_ID,
];

export const FOREIGN_ADULT_DOC_TYPES_WITH_RU_PASSPORT = [...FOREIGN_ADULT_DOC_TYPES, DOC_TYPES.PASSPORT];

export const FOREIGN_ADULT_DOC_TYPES_WITH_RU_DOCS = [
    ...FOREIGN_ADULT_DOC_TYPES,
    DOC_TYPES.PASSPORT,
    DOC_TYPES.BIRTH_CERTIFICATE,
];

export const ALL_CHILD_DOC_TYPES = [
    DOC_TYPES.BIRTH_CERTIFICATE,
    DOC_TYPES.FOREIGN_PASSPORT,
    DOC_TYPES.FOREIGN_PASSPORT_NON_RF,
    DOC_TYPES.NATIONAL_PASSPORT,
    DOC_TYPES.CIS_RETURN_CERTIFICATE,
];

export const RU_DOCUMENTS = [
    DOC_TYPES.PASSPORT,
    DOC_TYPES.FOREIGN_PASSPORT,
    DOC_TYPES.OFFICIAL_PASSPORT,
    DOC_TYPES.DEPUTY_ID,
    DOC_TYPES.CONVICT_TEMPORARY_ID,
    DOC_TYPES.TEMPORARY_ID,
    DOC_TYPES.OFFICER_ID,
];

export const NOT_RU_DOCUMENTS = [DOC_TYPES.NATIONAL_PASSPORT, DOC_TYPES.FOREIGN_PASSPORT_NON_RF];

export const DOCS_FOR_INTERNTAIONAL_INITIALS = [
    DOC_TYPES.FOREIGN_PASSPORT,
    DOC_TYPES.DIPLOMATIC_PASSPORT,
    DOC_TYPES.NATIONAL_PASSPORT,
];
