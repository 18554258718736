import { useState, useEffect } from 'react';
import i18next from 'i18next';
import moment from 'moment';

export const languages = { ru: 'ru', en: 'en' };
export const routePrefixRu = '/';
export const routePrefixEn = '/en';

export function getLanguage() {
    const { pathname } = window.location;

    return pathname.startsWith(routePrefixEn) ? languages.en : languages.ru;
}

export function useLocale() {
    const [language, setLanguage] = useState(getLanguage());

    useEffect(() => {
        const nextLanguage = getLanguage();

        if (language !== nextLanguage) {
            setLanguage(nextLanguage);
        }
    }, []);

    useEffect(() => {
        i18next.changeLanguage(language);
        moment.locale(language);
    }, [language]);

    return {
        language,
        routePrefix: language === languages.en ? routePrefixEn : routePrefixRu,
    };
}
