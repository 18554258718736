/* eslint-disable */
import React from 'react';
import { Link } from 'react-router';

import { ReactComponent as Success } from 'icons/interface/success.svg';
import { ReactComponent as CloseMob } from 'icons/interface/close-mob.svg';
import { useProfileLink } from 'utils/hooks';

const SuccessMessage = ({ profileLink }) => (
    <div className="paymentstatus-success">
        <div className="paymentstatus-logo">
            <Success className="paymentstatus-icon" />
        </div>
        <h1>Карта успешно привязана</h1>
        <p>Нажмите кнопку ниже для продолжения работы с личным кабинетом</p>
        <div className="paymentstatus-close">
            <Link className="Button" to={profileLink}>
                Вернуться в личный кабинет
            </Link>
        </div>
    </div>
);

const FailMessage = ({ profileLink }) => (
    <div className="paymentstatus-fail">
        <div className="paymentstatus-logo">
            <CloseMob className="paymentstatus-icon" />
        </div>
        <h1>Ошибка привязки карты</h1>
        <p>Нажмите кнопку ниже для продолжения работы с личным кабинетом</p>
        <div className="paymentstatus-close">
            <Link className="Button" to={profileLink}>
                Вернуться в личный кабинет
            </Link>
        </div>
    </div>
);

const PaymentStatus = ({ params }) => {
    const profileLink = useProfileLink();

    return (
        <div className="paymentstatus">
            {params.status === 'success' ? (
                <SuccessMessage profileLink={profileLink} />
            ) : (
                <FailMessage profileLink={profileLink} />
            )}
        </div>
    );
};

export default PaymentStatus;
