import { API_BASE_URL } from './apiBaseUrl';
import { createError } from './createError';
import { getHeaders } from './getHeaders';

export async function fileFetcher(url, params = {}) {
    const headers = await getHeaders(params.headers);

    const response = await fetch(`${API_BASE_URL}/${url}`, { ...params, headers });

    if (response.ok) {
        const file = await response.blob();
        const fileName = response.headers.get('content-disposition').substring(21);

        return { file, fileName };
    }

    return createError(response);
}
