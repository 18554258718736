export const subscriptions = {
    title: 'Управление подписками',
    subscription_not_found: 'Подписка не найдена',
    channels: {
        sms: 'SMS',
        email: 'E-mail',
        viber: 'Viber',
        vk: 'VK',
        call: 'Звонок',
        push: 'Push',
        emarsys: 'E-mail',
    },
    categories: {
        advertisement: 'Маркетинговые рассылки',
        information: 'Информационные рассылки',
        security: 'Системные сообщения',
        avia: 'Авиа уведомления',
        other: 'Прочие сообщения',
    },
    save_success: 'Подписки изменены',
    category: 'Категория',
};
