import React from 'react';
import i18next from 'i18next';

import styles from './SignUpAgreement.module.scss';

export const SignUpAgreement = () => (
    <div className={styles.agreementText}>
        {i18next.t('sign.accept_rules.title')}&nbsp;
        <a
            href="https://www.utair.ru/about/politics/#politika-pao-aviakompaniya-yuteyr-v-oblasti-obrabotki-personalnykh-dannykh-i-trebovaniya-k-organizats"
            target="_blank"
            rel="noopener noreferrer"
        >
            {i18next.t('sign.accept_rules.link')}
        </a>
        {i18next.t('sign.accept_rules.processing_text')}
        &nbsp;
        <a
            href="https://www.utair.ru/about/politics/#soglasie-na-obrabotku-personalnykh-dannykh-klienta-pao-aviakompaniya-yuteyr"
            target="_blank"
            rel="noreferrer"
        >
            {i18next.t('sign.accept_rules.processing_link')}
        </a>
        &nbsp;{i18next.t('sign.accept_rules.status_text')}&nbsp;
        <a href="https://media.utair.ru/status" target="_blank" rel="noreferrer">
            {i18next.t('sign.accept_rules.status_link')}
        </a>
    </div>
);
