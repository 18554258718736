exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Backdrop-module__root--1scNZ{position:fixed;top:0;left:0;z-index:90;width:100%;height:100%;background-color:rgba(17,24,32,.25);transition:background-color .3s;display:none}.Backdrop-module__isOpen--2-NYJ{display:block}", ""]);

// exports
exports.locals = {
	"root": "Backdrop-module__root--1scNZ",
	"isOpen": "Backdrop-module__isOpen--2-NYJ"
};