import { useContext } from 'react';

import { ActionContext, DataContext, QueryContext } from './context';

export function useQuery() {
    return useContext(QueryContext);
}

export function useData() {
    return useContext(DataContext);
}

export function useAction() {
    return useContext(ActionContext);
}
