export const validator = {
    errors: {
        phone_format: 'Please enter your phone as +Х (ХХХ) ХХХ-ХХ-ХХ',
        phone_foreign_format: 'Please enter your phone as 5-15 numbers',
        phone_required: 'Please enter your phone',
        phone_operator_code: 'Operator code must begin with 3, 4, 5, 6, 8, 9',
        email_format: 'Please enter a valid email',
        email_required: 'Please enter email',
        lastName_required: 'Please enter last name',
        lastName_length: 'Last name must contain at least 2 characters',
        locator_required: 'Please enter passport number, booking reference or ticket number',
        code_required: 'Please enter validation code',
        code_incorrect: 'Incorrect validation code',
        login_incorrect: 'Please enter a valid login',
        status_card_incorrect: 'Card number must have 10, 11, 13 or 16 digits',
        flightnumber_required: 'Please enter flight number',
        incorrect_city_text: 'Please enter the city using Latin or Russian letters',
        checkinDoc_required: 'Please enter document number, booking reference or ticket number',
        fromCity_required: 'Please enter departure city',
        destCity_required: 'Please enter arrival city',
        dates_required: 'Please select date',
        ticket_type_required: 'Select the ticket type',
        differentCities_required: 'Departure and arrival cities must be different',
    },
};
