/* eslint-disable */
import { API_URLS, PRIVATE_CACHE_FLAG } from 'consts';
import { createFSA } from 'utils';
import { cacheManager } from 'utils/cache-manager';
import { utairHttpManager as request } from 'managers/utair';
import { getUserData } from './user';

export const FAMILY_CACHE_NAME = 'household_members';
const INCOMING_REQUESTS_CACHE_NAME = 'household_incoming_requests';
const FAMILY_CACHE_SETTINGS = {
    lifeTime: 600000,
    flag: PRIVATE_CACHE_FLAG,
};

export const FETCH_MEMBERS = 'FETCH_MEMBERS';
const fetchMembersFSA = createFSA(FETCH_MEMBERS);
const fetchMembers = (isForced) => (dispatch) =>
    request
        .get(
            API_URLS.FAMILY.GET_MEMBERS,
            {},
            true,
            { ...FAMILY_CACHE_SETTINGS, ...{ name: FAMILY_CACHE_NAME } },
            isForced
        )
        .then((data) => dispatch(fetchMembersFSA(data)))
        .catch((e) => dispatch(fetchMembersFSA(new Error(e))));

export const fetchFamily = (isForced) => (dispatch, getState) => {
    const state = getState();
    dispatch(fetchMembersFSA());

    if (!state.user.userData.status.accNo) {
        return dispatch(getUserData('status')).then(() =>
            Promise.all([dispatch(fetchMembers(isForced)), dispatch(fetchInRequests(isForced))])
        );
    }

    return Promise.all([dispatch(fetchMembers(isForced)), dispatch(fetchInRequests(isForced))]);
};

export const FETCH_IN_REQUESTS = 'FETCH_IN_REQUESTS';
export const fetchInRequestsFSA = createFSA(FETCH_IN_REQUESTS);
const fetchInRequests = (isForced) => (dispatch, getState) =>
    request
        .get(
            API_URLS.FAMILY.GET_INCOMING_REQUESTS,
            null,
            true,
            {
                name: INCOMING_REQUESTS_CACHE_NAME,
                ...FAMILY_CACHE_SETTINGS,
            },
            isForced
        )
        .then((data) => dispatch(fetchInRequestsFSA(data)))
        .catch((e) => dispatch(fetchInRequestsFSA(new Error(e))));

export const FETCH_OUT_REQUESTS = 'FETCH_OUT_REQUESTS';
export const fetchOutRequestsFSA = createFSA(FETCH_OUT_REQUESTS);

export const createJoinRequest = (householdId) => () =>
    request.post(API_URLS.FAMILY.REQUEST_JOIN, { householdId }, true);

export const declineInRequest = (userId) => (dispatch) =>
    request.delete(`${API_URLS.FAMILY.DECLINE_JOIN}${userId}/`, null, true).then(() => {
        cacheManager.removeItem(INCOMING_REQUESTS_CACHE_NAME);
        dispatch(fetchInRequests());
    });

export const acceptInRequest = (userId) => (dispatch) =>
    request.put(API_URLS.FAMILY.ACCEPT_JOIN, { userId }, true).then(() => {
        cacheManager.removeItem(INCOMING_REQUESTS_CACHE_NAME);
        dispatch(fetchInRequests());
        dispatch(fetchFamily(true));
    });

export const removeInRequest = (id) => () => request.put(API_URLS.FAMILY.REMOVE_JOIN, { id }, true);

export const inviteUserToFamily = (userId) => () => {
    const params = {
        userId,
        acceptInviteBaseUrl: window.location.origin + '/bonuses/acceptInvite',
    };

    return request.post(API_URLS.FAMILY.INVITE_TO_FAMILY, params, true);
};

export const acceptInviteToFamily = () => (dispatch) => {
    const searchParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const params = {
        userId: searchParams.get('userId'),
        invitedBy: searchParams.get('invitedBy'),
    };

    return request.post(API_URLS.FAMILY.ACCEPT_INVITE, params, true).then(() => dispatch(fetchFamily(true)));
};

export const removeAccountFromHousehold = (householdId, cardNo) => (dispatch) =>
    request
        .delete(`${API_URLS.FAMILY.REMOVE_ACCOUNT}${householdId}/${cardNo}/`, null, true)
        .then(() => dispatch(fetchFamily(true)));

export const SET_CAN_REDEEM = 'SET_CAN_REDEEM';
export const setIsCanRedeem = (cardNo, isCanRedeem) => ({
    type: SET_CAN_REDEEM,
    payload: { cardNo, isCanRedeem },
});
export const changeIsCanRedeem = (householdId, cardNo, isCanRedeem) => (dispatch) => {
    const params = {
        householdId,
        cardNo,
        privileges: {
            canRedeem: isCanRedeem,
        },
    };

    dispatch(setIsCanRedeem(cardNo, isCanRedeem));

    return request
        .put(API_URLS.FAMILY.SET_ACCOUNT_PRIVILEGE, params, true)
        .then(() => {
            cacheManager.removeItem(FAMILY_CACHE_NAME);
        })
        .catch((e) => {
            console.log(new Error(e));
            dispatch(setIsCanRedeem(cardNo, !isCanRedeem));
        });
};

export const setHeadOfFamily = (householdId, cardNo) => (dispatch) =>
    request
        .put(
            API_URLS.FAMILY.SET_ACCOUNT_PRIVILEGE,
            {
                householdId,
                cardNo,
                privileges: {
                    head: true,
                },
            },
            true
        )
        .then(() => {
            cacheManager.removeItem(FAMILY_CACHE_NAME);
            dispatch(fetchFamily(true));
        })
        .catch((e) => console.log(new Error(e)));

export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';
export const SET_MODAL_IS_SHOW = 'SET_MODAL_IS_SHOW';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';

export const setModalType = (type) => ({
    type: SET_MODAL_TYPE,
    payload: { type },
});

export const setModalIsShow = (isShow) => ({
    type: SET_MODAL_IS_SHOW,
    payload: { isShow },
});

export const setModalData = (data) => ({
    type: SET_MODAL_DATA,
    payload: { data },
});

export const showModal = (type, data) => (dispatch, getState) => {
    dispatch(setModalType(type));
    dispatch(setModalData(data));
    dispatch(setModalIsShow(true));
};
