import { useCallback } from 'react';

import { useLocale } from 'locales/index';
import { extractError } from 'schedule/shared/api';
import { useAction } from 'schedule/shared/model';

import { getNearestCitiesApi } from '../api';
import { mapNearestCity } from '../lib';

export function useGetNearestCities() {
    const { setIsLoading, setError, setData } = useAction();

    const { language } = useLocale();

    async function getNearestCity() {
        try {
            setIsLoading(true);
            const { data } = await getNearestCitiesApi();
            const mapped = mapNearestCity(data, language);
            setData((cur) => ({
                ...cur,
                departureCity: mapped,
            }));
        } catch (error) {
            setError(extractError(error));
        } finally {
            setIsLoading(false);
        }
    }

    return useCallback(getNearestCity, [language, setData, setError, setIsLoading]);
}
