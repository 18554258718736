export const header = {
    welcome: 'Welcome',
    to_home: 'Main page',
    links: {
        information: {
            index: 'Информация',
            online_panel: 'Табло рейсов',
            time_table: 'Расписание',
            tickets_documents: 'Билет и документы',
            transports_regulations: 'Правила перевозки',
            rates_conditions: 'Тарифы и условия',
            check_in: 'check-in',
            transfer: 'Трансфер',
            faq: 'Часто задаваемые вопросы',
            feedback: 'Обратная связь',
            contacts: 'Контакты',
            baggage: 'Багаж',
        },
        airport: {
            index: 'В аэропорту',
            business: 'Бизнес-залы',
            help: 'Справка по аэропорту',
            security: 'Транспортная безопасность',
            transfer: 'Для трансферных\n пассажиров',
        },
        business: {
            index: 'Для бизнеса',
            groups: 'Групповые перевозки',
            corporate: 'Корпоративным клиентам',
        },
        board: {
            index: 'На борту',
            logbook: 'Бортовой журнал',
            food: 'Питание на борту',
            business: 'Классы\n обслуживания',
            sky_shop: 'Sky shop',
        },
        service: {
            index: 'Услуги',
            services: 'Дополнительные услуги',
            partner: 'Партнерские услуги',
            check_in: 'Check in',
            comfort: 'Комфорт',
            certificate: 'Справки о перелете',
        },
        about: {
            index: 'О компании',
            today: 'Utair сегодня',
            news: 'Новости',
            aircrafts: 'Флот Utair',
            heli: 'Вертолетные услуги',
            corporate: 'Корпоративная\n информация',
            jobs: 'Вакансии',
            press: 'Пресс-служба Utair',
            partners: 'Авиакомпании-партнёры',
            contacts: 'Контакты',
            politics: 'Политики',
            feedback_staff: 'Сообщение сотрудника',
        },
    },
    menu: {
        where_fly_to: 'Where does Utair fly to?',
        flight_board: 'Flight board',
        flight_schedule: 'Schedule',
        contacts: 'Contacts',
        merch: 'Utair merchandise',
        bonus_program: '«Status» loyalty program',
        assistance: 'Passenger assistance',
        orders: 'My flights',
        information: 'Information',
        miles: 'My miles',
        check_in: 'Check-in',
        buy: 'Book a ticket',
        notifications: 'Notifications',
        spend_miles: 'Spend miles',
        collect_miles: 'Earn miles',
        feedback: 'Help for passengers',
        login: 'Sign in',
        registration: 'Create an account',
        status: 'Status',
        standby: 'Standby',
    },
};
