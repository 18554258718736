/* eslint-disable */
import React from 'react';
import { Router, browserHistory, Route } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { cacheManager } from 'utils/cache-manager';
import { logError } from 'actions/error';
import { getCounterId } from 'managers/getCounterId';

import { NotFound } from './pages/not-found-404';
import AuthHandler from './pages/AuthHandler';

class Root extends React.Component {
    static propTypes = {
        routes: PropTypes.func,
        logError: PropTypes.func.isRequired,
    };

    static defaultProps = {
        routes: () => {},
    };

    render() {
        return (
            <Router history={browserHistory} onUpdate={this.onRouteUpdate}>
                {this.props.routes()}
                <Route path="auth/handler" component={AuthHandler} />
                <Route path="*" component={NotFound} status={404} />
            </Router>
        );
    }

    onRouteUpdate = () => {
        cacheManager.removeExpired();

        if (window.ym) {
            window.ym(getCounterId(), 'hit', window.location.href);
        }

        try {
            rstat('event', 'screen_change', { type: 'History' });
        } catch (e) {
            this.props.logError({ message: e?.message, stack: e?.stack });
        }
    };
}

const mapDispatchToProps = (dispatch) => ({
    logError: (error) => dispatch(logError(error)),
});

export default connect(undefined, mapDispatchToProps)(Root);
