import React from 'react';
import { IndexRoute, Route } from 'react-router';

import DynamicImport from 'components/common/dynamic.import';
import { sharedRoutes } from 'schedule/shared/config';
import { ScheduleLayout } from 'schedule/widgets/main-layout';

type PageProps = JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<DynamicImport> &
    Readonly<unknown> &
    Readonly<{ children?: React.ReactNode }>;

const ScheduleApp = (props: PageProps) => <DynamicImport load={() => import('schedule/app')} {...props} />;
const SearchPage = (props: PageProps) => <DynamicImport load={() => import('./search')} {...props} />;
const WeeklySchedulePage = (props: PageProps) => <DynamicImport load={() => import('./weekly-schedule')} {...props} />;

export const scheduleRoutes = () => (
    <Route component={ScheduleApp}>
        <Route path={sharedRoutes.PATHS.ROUTES_PAGE} component={ScheduleLayout}>
            <IndexRoute component={SearchPage} />
            <Route path={sharedRoutes.PATHS.ROUTE_SCHEDULE_PAGE} component={WeeklySchedulePage} />
        </Route>
    </Route>
);
