/* eslint camelcase: [0, {properties: "never"}] */

export const change_personal_data = {
    payment_success_message: 'Данные успешно отредактированы.',
    payment_failure_message:
        'К сожалению, не удалось завершить редактирование данных пассажира. Пожалуйста, повторите попытку.',
    errors: {
        document_expiration_required: 'Укажите срок действия документа',
        document_expiration_invalid: 'Некорректно указан срок действия',
        document_expired: 'Документ должен быть годен на момент последнего вылета ({{departureDate}})',
        birthday_required: 'Укажите дату рождения',
        birthday_invalid_format: 'Некорректно указана дата рождения',
        birthday_early: 'Дата рождения не может быть раньше 01.01.1900',
        birthday_does_not_exist:
            'Дата рождения должна быть указана и должна быть существующей. Исправьте дату рождения.',
        date_of_birth_not_adult: 'Дата рождения не попадает в категорию Взрослый',
        date_of_birth_not_child: 'Дата рождения не попадает в категорию Ребенок',
        date_of_birth_not_infant: 'Дата рождения не попадает в категорию Младенец',
        date_of_birth_adult_with_infant: 'Пассажир, сопровождающий ребенка, должен быть старше 18 лет на момент вылета',
        document_required: 'Пожалуйста, выберите документ',
        document_number_required: 'Укажите номер документа',
        document_number_invalid_format: 'Неправильный номер документа',
        document_country_required: 'Выберите страну',
        doc_tip_for_sr:
            'Номер свидетельства о рождении должен состоять из латинских или русских букв и цифр, не более 15 символов',
        doc_tip_for_srrf:
            'Номер свидетельства о рождении должен состоять из римской цифры, двух русских букв и шести цифр. Пример: IVЯЮ123456',
        doc_tip_for_np: 'Номер национального паспорта должен содержать символы на латинице и/или цифры.',
        doc_tip_for_za: 'Номер загранпаспорта должен содержать символы на латинице и/или цифры',
        full_name_wrong_lang: 'Укажите имя, фамилию и отчество, используя только латинские буквы',
        first_name_required: 'Укажите имя',
        first_name_invalid_range_min: 'Имя должно быть больше 1 символа',
        first_name_invalid_format: 'Имя может содержать только буквы и дефис',
        first_name_wrong_lang: 'Укажите имя, фамилию и отчество, используя только латинские буквы',
        last_name_required: 'Укажите фамилию',
        last_name_invalid_range_min: 'Фамилия должна быть больше 1 символа',
        last_name_invalid_format: 'Фамилия может содержать только буквы и дефис',
        last_name_wrong_lang: 'Укажите имя, фамилию и отчество, используя только латинские буквы',
        middle_name_invalid_range_min: 'Отчество должно быть больше 1 символа',
        middle_name_invalid_format: 'Отчество может содержать только буквы и дефис',
        middle_name_wrong_lang: 'Укажите имя, фамилию и отчество, используя только латинские буквы',
        full_name_duplicate: 'Укажите отчество только для одного из пассажиров с одинаковыми фамилией и именем',
        date_of_birth_invalid_age_for_document:
            'Возраст пассажира на момент вылета ({{departureDate}}) при выборе документа "{{documentName}}" должен быть меньше {{age}} лет. Проверьте дату рождения или измените тип документа.',
    },
};
