/* eslint-disable */
import { ADD_MESSAGE, REMOVE_MESSAGE, CLOSE_MESSAGE } from 'actions/sysmessages';

const INITIAL_STATE = [];

const sysmessages = (state = INITIAL_STATE, payload) => {
    switch (payload.type) {
        case ADD_MESSAGE: {
            return [...state, payload.message];
        }
        case REMOVE_MESSAGE: {
            return state.filter((item) => item.id !== payload.id);
        }
        case CLOSE_MESSAGE: {
            return state.map((item) => (item.id === payload.id ? {...item, isClosed: true} : item));
        }
        default:
            return state;
    }
};

export default sysmessages;
