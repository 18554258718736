/* eslint camelcase: [0, {properties: "never"}] */

export const boarding_pass = {
    passenger_name: 'Имя пассажира',
    ticket_number: 'Билет',
    ticket_id: 'Документ',
    flight: 'Рейс',
    date: 'Дата',
    direction: 'От-До',
    class: 'Класс',
    seat: 'Место',
    handbag: 'Ручная кладь',
    baggage: 'Багаж',
    ffp_card: 'Карта статус'
};
