export const ADDITIONAL_SERVICE_TYPES = {
    BAGGAGE: { value: 'baggage', apiKey: 'baggage', applicability: 'passenger_way' },
    PET: { value: 'baggage', apiKey: 'pet', applicability: 'passenger_way' },
    MEAL: { value: 'meal', apiKey: 'meal', applicability: 'passenger_segment' },
    BUSINESS_ROOM: { value: 'business_room', apiKey: 'business_room', applicability: 'passenger_segment' },
    SEAT: { value: 'seat', apiKey: 'seat', applicability: 'passenger_segment' },
    INSURANCE: { value: 'insurance', apiKey: 'insurance', applicability: 'all' },
};

export const ADDITIONAL_SERVICE_API_KEYS = {
    baggage: 'BAGGAGE',
    pet: 'PET',
    meal: 'MEAL',
    business_room: 'BUSINESS_ROOM',
    seat: 'SEAT',
    insurance: 'INSURANCE',
};

export const MEAL_TYPES = {
    PROTECTIVE_EQUIPMENT: { value: 'protective_equipment' },
    BREAKFAST: { value: 'breakfast' },
    DINNER: { value: 'dinner' },
    CAUCASIAN: { value: 'caucasian' },
    SANDWICH: { value: 'sandwich' },
    CHILD_FOOD: { value: 'child_food' },
    VEGAN: { value: 'vegan' },
    SPECIAL_MENU: { value: 'special_menu' },
    DESSERTS: { value: 'desserts' },
    DRINKS: { value: 'drinks' },
    ALCOHOL: { value: 'alcohol' },
    ITALIAN: { value: 'italian' },
    BUSINESS: { value: 'business' },
    NEW_YEAR: { value: 'new_year' },
    COMBO_SETS: { value: 'combo_sets' },
};

export const MEAL_API_KEYS = {
    MS: 'PROTECTIVE_EQUIPMENT',
    BF: 'BREAKFAST',
    DL: 'DINNER',
    KL: 'CAUCASIAN',
    SW: 'SANDWICH',
    CH: 'CHILD_FOOD',
    VG: 'VEGAN',
    SM: 'SPECIAL_MENU',
    DS: 'DESSERTS',
    PN: 'DRINKS',
    AL: 'ALCOHOL',
    IT: 'ITALIAN',
    BS: 'BUSINESS',
    NG: 'NEW_YEAR',
    CS: 'COMBO_SETS',
};

export const AVAILABLE_BAGGAGES = {
    '0GP': 'Багаж 23 кг',
    '0M6': 'Багаж 30 кг',
    '08A': 'Ручная кладь 10 кг',
    '0C2': 'Багаж 20 кг',
    '0C5': 'Багаж 30 кг',
    '0IJ': 'Багаж 10 кг',
};
