import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '@public-projects/utair-ui-kit';
import { getFeedbackUrl } from 'consts';
import { ReactComponent as Attention } from 'images/attention.svg';
import { setIsFailure } from 'actions/checkin';

const SupportLink = () => <a href={getFeedbackUrl()}>обратитесь в&nbsp;Службу поддержки</a>;

export function Failure() {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setIsFailure(false));
    };

    return (
        <Modal size="medium" onClose={handleClose}>
            <Modal.Image>
                <Attention width={160} height={160} />
            </Modal.Image>
            <Modal.Title centered>Заказ не найден</Modal.Title>
            <Modal.Content centered>
                К сожалению, мы не смогли найти ваш заказ по техническим причинам. Пожалуйста, повторите попытку или{' '}
                <SupportLink />
            </Modal.Content>
        </Modal>
    );
}
