/* eslint-disable */
/* eslint camelcase: 0 */
import moment from 'moment';
import * as _ from 'lodash/fp';

export const taisUrlConverter = {
    to({departure, arrival, adult_count = 0, child_count = 0, infant_count = 0, from_date, back_date}) {
        const deparr = `/!/${departure}/${arrival}`;
        const dates =
            (from_date ? '/' + moment(from_date).format('DD.MM.YYYY') : '') +
            (back_date ? '+' + moment(back_date).format('DD.MM.YYYY') : '');

        const passengers = `/${adult_count}-${child_count}-${infant_count}`;

        if (!from_date) {
            return deparr + '/';
        }

        return deparr + dates + passengers + '/';
    },

    from(url) {
        const params = _.pipe(_.split('/'), _.drop(2), _.dropRight(0))(url);
        const passengers = (params.find((x) => /^\d+-\d+-\d+$/.test(x)) || '').split('-');
        const dates = (params.find((x) => /^\d\d\.\d\d\.\d\d\d\d/.test(x)) || '').split('+');

        return {
            departure: params[0],
            arrival: params[1],
            adult_count: passengers[0] || 1,
            child_count: passengers[1] || 0,
            infant_count: passengers[2] || 0,
            from_date: dates ? moment(dates[0], 'DD.MM.YYYY').valueOf() : '',
            back_date: !dates || dates.length === 1 ? '' : moment(dates[1], 'DD.MM.YYYY').valueOf(),
            owrt: !dates || dates.length === 1 ? 'ow' : 'rt'
        };
    }
};
