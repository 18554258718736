import { API_BASE_URL } from 'consts';
import { fetcher } from 'schedule/shared/api';

import type { NearestCityDto } from './types';

// https://b.utair.ru/api/docs/cities/GET_cities_nearest/
export function getNearestCitiesApi() {
    const params = new URLSearchParams({
        tag: 'airplane',
    });

    return fetcher<NearestCityDto>(`${API_BASE_URL}/cities/api/v3/cities/nearest?${params.toString()}`, {
        method: 'GET',
    });
}
