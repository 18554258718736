import { SERVICE_NAMES } from 'constants/serviceTypes';

export const SERVICE_VALUES = {
    FREE: 'free',
    UNAVAILABLE: 'unavailable',
    ADDITIONAL_PAY: 'additional_pay',
};

const defaultOptionsOptimum = [
    { service: SERVICE_NAMES.MILES, value: '3-7%' },
    { service: SERVICE_NAMES.CABIN_BAG_SMALL, value: SERVICE_VALUES.FREE },
    { service: SERVICE_NAMES.CABIN_BAG_BIG, value: '+2 000 ₽', interlineValue: '-' },
    { service: SERVICE_NAMES.BAG_SMALL, value: '₽' },
    { service: SERVICE_NAMES.BAG_BIG, value: SERVICE_VALUES.FREE },
    { service: SERVICE_NAMES.BAG_SPORT, value: '₽' },
    { service: SERVICE_NAMES.BAG_WEAPON, value: '₽' },
    { service: SERVICE_NAMES.BAG_PET, value: '₽' },
    { service: SERVICE_NAMES.SEAT, value: '+300 ₽', interlineValue: '-' },
    { service: SERVICE_NAMES.SEAT_PLUS, value: '1499₽' },
    { service: SERVICE_NAMES.TKT_CHANGES, value: '+2 000 ₽', interlineValue: '-' },
    { service: SERVICE_NAMES.TKT_CHANGES_NO_SHOW, value: '6000₽' },
    { service: SERVICE_NAMES.TKT_RETURN, value: '-' },
    { service: SERVICE_NAMES.TKT_RETURN_NO_SHOW, value: '-' },
    { service: SERVICE_NAMES.BAG_INF, value: '5 кг' },
];

export const TICKET_CLASS_TYPES = {
    BUSINESS_NEW: {
        value: 'businessNew',
        code: 'BUSINESS_NEW',
        shortCode: 'BUSINESS_NEW',
        cartCode: 'BUSINESS_NEW',
        economical: false,
        defaultOptions: [
            { service: SERVICE_NAMES.MILES, value: '3-7%' },
            { service: SERVICE_NAMES.CABIN_BAG_SMALL, value: SERVICE_VALUES.FREE },
            { service: SERVICE_NAMES.CABIN_BAG_BIG, value: '2 × 10 кг', interlineValue: '-' },
            { service: SERVICE_NAMES.BAG_SMALL, value: '₽' },
            { service: SERVICE_NAMES.BAG_BIG, value: '2 × 30 кг', interlineValue: '30 кг' },
            { service: SERVICE_NAMES.BAG_SPORT, value: '•' },
            { service: SERVICE_NAMES.BAG_WEAPON, value: '•' },
            { service: SERVICE_NAMES.BAG_PET, value: '₽' },
            { service: SERVICE_NAMES.SEAT, value: SERVICE_VALUES.FREE },
            { service: SERVICE_NAMES.SEAT_PLUS, value: '-' },
            { service: SERVICE_NAMES.TKT_CHANGES, value: '+2 000 ₽', interlineValue: '-' },
            { service: SERVICE_NAMES.TKT_CHANGES_NO_SHOW, value: '6000₽' },
            { service: SERVICE_NAMES.TKT_RETURN, value: '6000₽' },
            { service: SERVICE_NAMES.TKT_RETURN_NO_SHOW, value: '6000₽' },
            { service: SERVICE_NAMES.BAG_INF, value: '5 кг' },
            { service: SERVICE_NAMES.PRIOR_REGISTER, value: SERVICE_VALUES.FREE, hideShort: true },
            { service: SERVICE_NAMES.VIP_LODGE, value: SERVICE_VALUES.FREE, hideShort: true },
            // может быть value, cartValue, interlineValue, interlineCartValue
        ],
        priority: 0,
    },
    PREMIUM_NEW: {
        value: 'premiumNew',
        code: 'PREMIUM_NEW',
        shortCode: 'PREMIUM_NEW',
        cartCode: 'PREMIUM_NEW',
        economical: false,
        defaultOptions: [
            { service: SERVICE_NAMES.MILES, value: '3-7%' },
            { service: SERVICE_NAMES.CABIN_BAG_SMALL, value: SERVICE_VALUES.FREE },
            { service: SERVICE_NAMES.CABIN_BAG_BIG, value: SERVICE_VALUES.FREE, interlineValue: '-' },
            { service: SERVICE_NAMES.BAG_SMALL, value: '₽' },
            { service: SERVICE_NAMES.BAG_BIG, value: SERVICE_VALUES.FREE },
            { service: SERVICE_NAMES.BAG_SPORT, value: '•' },
            { service: SERVICE_NAMES.BAG_WEAPON, value: '•' },
            { service: SERVICE_NAMES.BAG_PET, value: '₽' },
            { service: SERVICE_NAMES.SEAT, value: SERVICE_VALUES.FREE },
            { service: SERVICE_NAMES.SEAT_PLUS, value: '1499₽' },
            { service: SERVICE_NAMES.TKT_CHANGES, value: '+2 000 ₽', interlineValue: '-' },
            { service: SERVICE_NAMES.TKT_CHANGES_NO_SHOW, value: '6000₽' },
            { service: SERVICE_NAMES.TKT_RETURN, value: '6000₽' },
            { service: SERVICE_NAMES.TKT_RETURN_NO_SHOW, value: '-' },
            { service: SERVICE_NAMES.BAG_INF, value: '5 кг' },
        ],
        priority: 2,
    },
    OPTIMUM_NEW: {
        value: 'optimumNew',
        code: 'OPTIMUM_NEW',
        shortCode: 'OPTIMUM_NEW',
        cartCode: 'OPTIMUM_NEW',
        economical: false,
        defaultOptions: defaultOptionsOptimum,
        priority: 3,
    },
    MINIMUM_NEW: {
        value: 'minimumNew',
        code: 'MINIMUM_NEW',
        shortCode: 'MINIMUM_NEW',
        cartCode: 'MINIMUM_NEW',
        economical: false,
        defaultOptions: [
            { service: SERVICE_NAMES.MILES, value: '3-7%' },
            { service: SERVICE_NAMES.CABIN_BAG_SMALL, value: SERVICE_VALUES.FREE },
            { service: SERVICE_NAMES.CABIN_BAG_BIG, value: '+2 000 ₽', interlineValue: '-' },
            { service: SERVICE_NAMES.BAG_SMALL, value: '₽' },
            { service: SERVICE_NAMES.BAG_BIG, value: '+3 000 ₽', interlineValue: '-' },
            { service: SERVICE_NAMES.BAG_SPORT, value: '₽' },
            { service: SERVICE_NAMES.BAG_WEAPON, value: '₽' },
            { service: SERVICE_NAMES.BAG_PET, value: '₽' },
            { service: SERVICE_NAMES.SEAT, value: '+300 ₽', interlineValue: '-' },
            { service: SERVICE_NAMES.SEAT_PLUS, value: '1499₽' },
            { service: SERVICE_NAMES.TKT_CHANGES, value: '+6 000 ₽', interlineValue: '-' },
            { service: SERVICE_NAMES.TKT_CHANGES_NO_SHOW, value: '6000₽', interlineValue: '-' },
            { service: SERVICE_NAMES.TKT_RETURN, value: '-' },
            { service: SERVICE_NAMES.TKT_RETURN_NO_SHOW, value: '-' },
            { service: SERVICE_NAMES.BAG_INF, value: 'Только в салон' },
        ],
        priority: 5,
    },

    BUSINESS: {
        value: 'business',
        code: 'BUSINESS',
        shortCode: 'FLEX',
        defaultOptions: [
            { service: 'MILES', value: '3-7%' },
            { service: 'TKT_CHANGES', value: '' },
            { service: 'TKT_RETURN', value: '' },
            { service: 'CABIN_BAG', value: '2x10кг' },
            { service: 'BAGGAGE', value: '2x32кг' },
            { service: 'BAG_INF', value: '10кг' },
            { service: 'BAG_SPORT', value: '23кг' },
            { service: 'VIP_LODGE', value: true },
            { service: 'PRIOR_REGISTER', value: true },
            { service: 'FOOD', value: true },
        ],
        priority: 0,
    },
    FLEX: {
        value: 'flex',
        code: 'FLEX',
        shortCode: 'FLEX',
        defaultOptions: [
            { service: 'MILES', value: '3-7%' },
            { service: 'TKT_CHANGES', value: '' },
            { service: 'TKT_RETURN', value: '' },
            { service: 'CABIN_BAG', value: '10кг' },
            { service: 'BAGGAGE', value: '23кг' },
            { service: 'BAG_INF', value: '10кг' },
            { service: 'BAG_SPORT', value: '23кг' },
        ],
        priority: 2,
    },
    STANDARD: {
        value: 'standard',
        code: 'STANDARD',
        shortCode: 'STD',
        defaultOptions: [
            { service: 'MILES', value: '3-7%' },
            { service: 'TKT_CHANGES', value: '' },
            { service: 'CABIN_BAG', value: '10кг' },
            { service: 'BAGGAGE', value: '23кг' },
            { service: 'BAG_INF', value: '10кг' },
            { service: 'BAG_SPORT', value: '23кг' },
        ],
        priority: 3,
    },
    STANDBY: {
        value: 'standby',
        code: 'STANDBY',
        shortCode: 'STANDBY',
        defaultOptions: defaultOptionsOptimum,
        priority: 7,
    },
    LIGHT: {
        value: 'light',
        code: 'LIGHT',
        shortCode: 'LT',
        defaultOptions: [
            { service: 'MILES', value: '3-7%' },
            { service: 'CABIN_BAG', value: '10кг' },
            { service: 'BAGGAGE', value: '' },
            { service: 'BAG_INF', value: '10кг' },
            { service: 'BAG_SPORT', value: '' },
        ],
        priority: 5,
    },
    COMFORT: {
        value: 'comfort',
        code: 'FLEX',
        shortCode: 'FLEX',
        defaultOptions: [
            { service: 'MILES', value: '3-7%' },
            { service: 'TKT_CHANGES', value: '' },
            { service: 'TKT_RETURN', value: '' },
            { service: 'CABIN_BAG', value: '2x10кг' },
            { service: 'BAGGAGE', value: '2x32кг' },
            { service: 'BAG_INF', value: '10кг' },
            { service: 'BAG_SPORT', value: '23кг' },
            { service: 'VIP_LODGE', value: '' },
            { service: 'PRIOR_REGISTER', value: '' },
        ],
        priority: 1,
    },
    PREFERENTIAL: {
        value: 'preferential',
        code: '?',
        shortCode: 'LT',
        defaultOptions: [
            { service: 'MILES', value: '3-7%' },
            { service: 'CABIN_BAG', value: '2x10кг' },
            { service: 'BAGGAGE', value: '' },
            { service: 'BAG_INF', value: '10кг' },
            { service: 'BAG_SPORT', value: '23кг' },
        ],
        priority: 4,
    },
};

export const DEFAULT_TICKET_CLASS = TICKET_CLASS_TYPES.STANDARD;
