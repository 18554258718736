exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Passenger-module__counterWrapper--tk7ln{display:flex;flex-wrap:nowrap}.Passenger-module__passenger--3jbll{display:flex;align-items:center;justify-content:space-between;padding:16px 20px 0 16px}.Passenger-module__passenger--3jbll+.Passenger-module__passenger--3jbll{padding-top:12px}.Passenger-module__passenger--3jbll:last-child{padding-bottom:16px}.Passenger-module__prices--2NY5C{display:inline-block;margin-right:12px}.Passenger-module__name--1VjCy,.Passenger-module__price--1AA3E,.Passenger-module__oldPrice--2LYfI{font-weight:400;font-size:14px;line-height:20px}.Passenger-module__subText--1GZMk{font-size:12px;line-height:16px;color:#008c5f}.Passenger-module__button--1g11Z{min-width:94px}.Passenger-module__oldPrice--2LYfI{color:rgba(94,110,135,.5);text-decoration:line-through;margin-right:8px}.Passenger-module__activeDiscount--554Go{color:#008c5f}.Passenger-module__selectedPrice--Lj62d{color:rgba(94,110,135,.8)}.Passenger-module__selectedOldPrice--2umMR{color:rgba(94,110,135,.5)}", ""]);

// exports
exports.locals = {
	"counterWrapper": "Passenger-module__counterWrapper--tk7ln",
	"passenger": "Passenger-module__passenger--3jbll",
	"prices": "Passenger-module__prices--2NY5C",
	"name": "Passenger-module__name--1VjCy",
	"price": "Passenger-module__price--1AA3E",
	"oldPrice": "Passenger-module__oldPrice--2LYfI",
	"subText": "Passenger-module__subText--1GZMk",
	"button": "Passenger-module__button--1g11Z",
	"activeDiscount": "Passenger-module__activeDiscount--554Go",
	"selectedPrice": "Passenger-module__selectedPrice--Lj62d",
	"selectedOldPrice": "Passenger-module__selectedOldPrice--2umMR"
};