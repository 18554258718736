/* eslint-disable max-len */
/* eslint camelcase: [0, {properties: "never"}] */

export const order_services = {
    add_service: 'Выбрать',
    remove_service: 'Отказаться',
    change_service: 'Изменить',
    close: 'Свернуть',
    applicability: {
        passenger_way: 'за пассажира в одну сторону',
        passenger_segment: 'завтрак или обед от шеф-повара',
        all: 'за всех по всем направлениям',
    },
    passengers_interval: '(0){ };(1){за 1 пассажира};(2-inf){за {{count}} пассажиров};',
    bought_meal_interval:
        '(0){ };(1){Была оплачена 1 порция};(2-4){Было оплачено {{count}} порции};(5-inf){Было оплачено {{count}} порций};',
    description: {
        baggage: 'Забронируйте свой дополнительный багаж сейчас. Это сохранит вам деньги и время.',
        meal: 'Вы можете выбрать из разнообразного меню то, что вам по вкусу.',
        business_room: 'Отдельная комфортная зона ожидания, индивидуальное обслуживание и приоритетная регистрация.',
        sets: '',
        insurance: '«Безоблачный перелет» — защита от несчастного случая.',
    },
    notice: {
        baggage: '',
        meal: 'Услуга доступна на рейсы из Московской области не менее, чем за 12ч до вылета, на рейсы из регионов - не менее 48ч до вылета.',
        business_room: 'Услуга доступна только для аэропорта Внуково.',
        sets: '',
        insurance: '',
    },
    meal_types: {
        protective_equipment: 'Средства защиты',
        breakfast: 'Завтраки',
        halal: 'Халяль',
        salad: 'Салаты',
        dinner: 'Обеды',
        vegan: 'Вегетарианское меню',
        lenten: 'Постное меню',
        child_food: 'Детское меню',
        sandwich: 'Сэндвичи',
        milan: 'Миланское меню',
        drinks: 'Напитки',
        kosher: 'Кошер',
        caucasian: 'Кавказская кухня',
        alcohol: 'Алкогольные напитки',
        special_menu: 'Специальное меню',
        desserts: 'Десерты',
        italian: 'Итальянское меню',
        business: 'Бизнес меню',
        new_year: 'Новогоднее меню',
        combo_sets: 'Комбо наборы',
    },
    offer_condition: 'Условия публичной оферты',
    insurance_condition: 'Условия страхования авиапассажиров',
    key_information_document: 'Ключевой информационный документ',
    included_option: 'В тариф включено',
    included_bag: '1 место ({{value}}) включено в тариф',
    included_bag_0: 'Багаж не предусмотрен тарифом',
    pet_modal: {
        weight_confirmation: 'Я подтверждаю, что вес переноски вместе с животным(и) не превышает 10 кг',
        size_confirmation: 'Я подтверждаю, что размер переноски не превышает 55x40x25 см',
    },
};
