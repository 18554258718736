import { all, call } from 'redux-saga/effects';

import bonusesSaga from './bonuses';
import linkAuthSaga from '../pages/authorize/link-auth-saga';
import prefillBasketWatcherSaga from './orders/prefill-basket';
import pollingDopsSaga from './orders/polling-dops';
import { userSaga } from './user';
import errorSaga from './error-saga';
import subscriptionsSaga from './subscriptions';
import fetchSelectedOrderWatcher from './orders/selected-order-saga';
import paymentSelectedOrderWatcher from './orders/payment-order-saga';
import resetCacheSaga from './reset-cache';
import { checkinWatchers } from './checkin';
import onlinePanelWatchers from './online-panel';

// rootSaga
export default function* rootSaga() {
    yield all([
        call(bonusesSaga),
        call(prefillBasketWatcherSaga),
        call(pollingDopsSaga),
        call(userSaga),
        call(linkAuthSaga),
        call(errorSaga),
        call(subscriptionsSaga),
        call(fetchSelectedOrderWatcher),
        call(paymentSelectedOrderWatcher),
        call(resetCacheSaga),
        call(checkinWatchers),
        call(onlinePanelWatchers),
    ]);
}
