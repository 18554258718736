export const footer = {
    phone_label: 'Paid call',
    phone_label_ru: 'Toll free for Utair Status members',
    appstore: 'Available on the App Store',
    googleplay: 'Available on Google Play',
    links: {
        view: 'View',
        status: 'Utair Status',
        orders: 'My flights',
        private_data: 'My account'
    },
    rights: '© 2018 Utair'
};
