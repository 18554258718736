import metrics from 'managers/metrics';

const pushMetricsEvent = (eventName, eventData) => () => {
    metrics.pushEvent(eventName, {
        channel: 'desktop',
        ...eventData,
    });
};

export default pushMetricsEvent;
