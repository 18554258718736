/* eslint-disable */
import _ from 'lodash';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import * as TYPE from 'constants/subscriptionsTypes';

import {
    fetchStateFSA,
    successFSA,
    messageDataFSA,
    errorFSA,
    requestMessage,
    requestSaveSubscriptions
} from 'actions/subscriptions';

export function* fetchMessageSaga({payload}) {
    yield put(fetchStateFSA(true));
    yield put(errorFSA(null));
    try {
        const data = yield call(() => requestMessage(payload.id, payload.address));
        yield put(
            messageDataFSA({
                ...payload,
                ...data
            })
        );
    } catch (e) {
        yield put(fetchStateFSA(false));
        yield put(errorFSA(_.get(e, 'data.meta')));
    }
}

export function* saveSaga({payload}) {
    yield put(fetchStateFSA(true));
    yield put(successFSA(false));
    const messageInfo = yield select((state) => state.subscriptions.unsubscribe.messageInfo);
    try {
        yield call(() => requestSaveSubscriptions(messageInfo.id, messageInfo.address, payload));
        yield put(successFSA(true));
        yield put(
            messageDataFSA({
                ...messageInfo,
                blockedCategories: payload
            })
        );
    } catch (e) {
        yield put(fetchStateFSA(false));
        yield put(errorFSA(_.get(e, 'data.meta')));
    }
    yield put(fetchStateFSA(false));
}

export default function* () {
    yield all([
        takeEvery(TYPE.FETCH_UNSUBSCRIBE_MESSAGE_INFO, fetchMessageSaga),
        takeEvery(TYPE.SAVE_UNSUBSCRIBE_SUBSCRIPTIONS, saveSaga)
    ]);
}
