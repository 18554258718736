export const METRICS_EVENT_NAMES = {
    SEARCH_FORM_OPENED: 'search_form_opened',
    SEARCH_PARAM_PRECHOSEN: 'search_param_prechosen',
    DEP_POINT_CHOSEN: 'dep_point_chosen',
    ARR_POINT_CHOSEN: 'arr_point_chosen',
    DEP_DATE_CHOSEN: 'dep_date_chosen',
    ONE_WAY_CHOSEN: 'one_way_chosen',
    BACK_DATE_CHOSEN: 'back_date_chosen',
    ADT_COUNT_CHOSEN: 'adt_count_chosen',
    CHD_COUNT_CHOSEN: 'chd_count_chosen',
    INF_COUNT_CHOSEN: 'inf_count_chosen',
    FIND_BUTTON_CLICKED: 'find_button_clicked',
    UPGRADE_AVAILABLE_SHOWN: 'upgrade_available_shown',
    REDIRECT_TO_UPGRADE: 'redirect_to_upgrade',
    DISCARD_UPGRADE: 'discard_upgrade'
};

export const METRICS_PARAM_VALUES = {
    FILL_METHOD: {
        CACHE: 'cache',
        GEOLOCATION: 'geolocation',
        NO_FILL: 'no_fill'
    }
};
