import moment from 'moment';
import { DATE_MASK } from 'consts';

import { languages } from '../../../locales';
import VALIDATION_ERRORS from './validationErrors';
import { DOC_TYPES } from './bookingDocTypes';
import { RU_COUNTRY_CODE } from './bookingConsts';

const { REQUIRED, INVALID_FORMAT, INVALID_RANGE_MIN, INVALID_RANGE_MAX, WRONG_LANG } = VALIDATION_ERRORS;
const { PASSPORT, FOREIGN_PASSPORT, BIRTH_CERTIFICATE, FOREIGN_PASSPORT_NON_RF, NATIONAL_PASSPORT } = DOC_TYPES;
const languagesCharCodesRanges = {
    EN: {
        min: 97,
        max: 122,
    },
    RU: {
        min: 1072,
        max: 1105,
    },
};

export function getCharLanguage(char) {
    const charCode = char.charCodeAt(0);

    if (charCode >= languagesCharCodesRanges.EN.min && charCode <= languagesCharCodesRanges.EN.max) {
        return languages.en;
    }

    if (charCode >= languagesCharCodesRanges.RU.min && charCode <= languagesCharCodesRanges.RU.max) {
        return languages.ru;
    }

    return null;
}

function languageUniformity(value) {
    const testedValue = value.toLowerCase().replace(/\s/g, '').replace(/[0-9]/g, '');

    let currentLanguage = null;

    for (let i = 0; i < testedValue.length; i += 1) {
        const charLanguage = getCharLanguage(testedValue.charAt(i));

        if (currentLanguage && charLanguage && currentLanguage !== charLanguage) {
            return WRONG_LANG;
        }

        currentLanguage = charLanguage;
    }

    return null;
}

const validator = {
    docNumber(value, isRequired, docType, docCountry) {
        if (!value) {
            return isRequired ? REQUIRED : null;
        }

        const { length } = value;

        if (length > 15) {
            return INVALID_FORMAT;
        }

        if (docType === FOREIGN_PASSPORT_NON_RF || docType === NATIONAL_PASSPORT) {
            return /^[0-9a-z]*$/i.test(value) ? null : INVALID_FORMAT;
        }

        if (docType === PASSPORT) {
            return /^[0-9]{10}$/.test(value) ? null : INVALID_FORMAT;
        }

        if (docType === FOREIGN_PASSPORT) {
            return /^[0-9]{9}$/i.test(value) ? null : INVALID_FORMAT;
        }

        if (docCountry === RU_COUNTRY_CODE && docType === BIRTH_CERTIFICATE) {
            return /^[IVXivx]{1,7}[а-яА-Я]{2}[0-9]{6}$/.test(value) ? null : INVALID_FORMAT;
        }

        return !/^[а-яА-ЯA-Za-z0-9]+$/.test(value) || languageUniformity(value) ? INVALID_FORMAT : null;
    },

    languageUniformity(value) {
        return languageUniformity(value);
    },

    date(value, isRequired, min, max) {
        if (!value) {
            return isRequired ? REQUIRED : null;
        }

        const momentDate = moment(value, DATE_MASK);

        if (!momentDate.isValid()) {
            return INVALID_FORMAT;
        }

        const valueTime = momentDate.valueOf();

        if (min && valueTime < moment(min, DATE_MASK).valueOf()) {
            return INVALID_RANGE_MIN;
        }

        if (max && valueTime > moment(max, DATE_MASK).valueOf()) {
            return INVALID_RANGE_MAX;
        }

        return null;
    },

    userName(value, isRequired, lang) {
        if (!value) {
            return isRequired ? REQUIRED : null;
        }

        if (!/^[a-zа-яё-]*$/i.test(value)) {
            return INVALID_FORMAT;
        }

        const { length } = value;

        if (length < 2) {
            return INVALID_RANGE_MIN;
        }

        if (lang) {
            const isValidRuLang = /^[а-яё-]*$/i.test(value);
            const isValidEnLang = /^[a-z-]*$/i.test(value);

            if (
                (!isValidRuLang && !isValidEnLang) ||
                (lang === languages.ru && !isValidRuLang) ||
                (lang === languages.en && !isValidEnLang)
            ) {
                return WRONG_LANG;
            }
        }

        return null;
    },
};

export default validator;
