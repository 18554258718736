import cn from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import styles from './ModalWrapper.module.scss';

interface Props {
    className: string;
    isOpen: boolean;
    children: ReactNode;
}

export function ModalWrapper({ isOpen = true, className, children }: Props) {
    return <div className={cn(styles.root, isOpen && styles.isOpen, className)}>{children}</div>;
}
