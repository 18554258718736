export const ERROR_TYPES = {
    INTERNAL_APP_ERROR: 'internal_app_error',
    CONNECTION_REFUSED: 'connection_refused',
    BAD_REQUEST: 'bad_request',
    UNAUTHORIZED: 'unauthorized',
    FORBIDDEN: 'forbidden',
    NOT_FOUND: 'not_found',
    CONFLICT: 'conflict',
    INTERNAL_SERVER_ERROR: 'internal_server_error',
    UNKNOWN: 'unknown',
};

export const APP_ERROR_CODES = {
    EMPTY_USER_NAME: 90001,
    PROMO_IS_NOT_APPLICABLE: 40001,
};
