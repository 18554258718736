/* eslint-disable react/require-default-props */
import classNames from 'classnames';
import React from 'react';

export interface GeneratorProps {
    suffixCls?: string;
    tagName: 'header' | 'footer' | 'main' | 'section';
    displayName: string;
}

export interface BasicProps extends React.HTMLAttributes<HTMLDivElement> {
    prefixCls?: string;
    suffixCls?: string;
    rootClassName?: string;
}

interface BasicPropsWithTagName extends BasicProps {
    tagName: 'header' | 'footer' | 'main' | 'section';
}

function generator({ tagName, displayName, ...restGeneratorProps }: GeneratorProps) {
    return (BasicComponent: React.ElementType) => {
        const Adapter = React.forwardRef<HTMLElement, BasicProps>((props, ref) => (
            <BasicComponent ref={ref} tagName={tagName} {...restGeneratorProps} {...props} />
        ));

        Adapter.displayName = displayName;

        return Adapter;
    };
}

const Basic = React.forwardRef<HTMLElement, BasicPropsWithTagName>((props, ref) => {
    const { suffixCls, className, tagName: TagName, ...others } = props;

    const prefixWithSuffixCls = suffixCls ? `layout-${suffixCls}` : 'layout';

    return (
        <TagName className={classNames(prefixWithSuffixCls, className)} ref={ref} {...others}>
            {props.children}
        </TagName>
    );
});

Basic.displayName = 'Basic';

const BasicLayout = React.forwardRef<HTMLElement, BasicPropsWithTagName>((props, ref) => {
    const { className, rootClassName, children, tagName: Tag, style, ...others } = props;

    const classString = classNames('layout', className, rootClassName);

    return (
        <Tag ref={ref} className={classString} style={style} {...others}>
            {children}
        </Tag>
    );
});

BasicLayout.displayName = 'BasicLayout';

const Layout = generator({
    tagName: 'section',
    displayName: 'Layout',
})(BasicLayout);

const Header = generator({
    suffixCls: 'header',
    tagName: 'header',
    displayName: 'Header',
})(Basic);

const Footer = generator({
    suffixCls: 'footer',
    tagName: 'footer',
    displayName: 'Footer',
})(Basic);

const Content = generator({
    suffixCls: 'content',
    tagName: 'main',
    displayName: 'Content',
})(Basic);

export { Content, Footer, Header };

export default Layout;
