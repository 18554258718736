/* eslint-disable */
import { API_URLS, PRIVATE_CACHE_FLAG } from 'consts';
import { createFSA } from 'utils';
import { utairHttpManager as request } from 'managers/utair';
import { cacheManager } from 'utils/cache-manager';

export const FETCH_SOCIAL_NETWORKS = 'FETCH_SOCIAL_NETWORKS';

export const SOCIAL_CACHE_NAME = 'social';

const SOCIAL_CACHE_SETTINGS = {
    name: SOCIAL_CACHE_NAME,
    lifeTime: 60000,
    flag: PRIVATE_CACHE_FLAG,
};

const fetchSocialNetworksFSA = createFSA(FETCH_SOCIAL_NETWORKS);

export const fetchSocialNetworks = (forced) => (dispatch, getState) =>
    request
        .get(API_URLS.AUTH.SOCIAL_REGISTRATION, null, true, SOCIAL_CACHE_SETTINGS, forced)
        .then((networks) => dispatch(fetchSocialNetworksFSA(networks)))
        .catch((e) => dispatch(fetchSocialNetworksFSA(new Error(e))));

export const detachSocialNetwork = (network, ident) => (dispatch, getState) =>
    request.delete(API_URLS.AUTH.SOCIAL_REGISTRATION + `${network}/${ident}/`, null, true);

export const resetSocialNetworks = () => (dispatch) => {
    cacheManager.removeItem(SOCIAL_CACHE_NAME);
    dispatch(fetchSocialNetworksFSA());
};
