/* eslint-disable */
import { utairHttpManager as request } from 'managers/utair';
import { cacheManager as cache } from 'utils/cache-manager';
import { normalizeName } from 'utils/normalize';
import { API_URLS, PRIVATE_CACHE_FLAG, BOOKING_DOCUMENTS_CACHE_KEYNAME } from 'consts';
import moment from 'moment';
import { getMillisecondsFromDate, usernameToString } from 'utils/formatter';
import { nonNumbersRegExp } from '../utils/reg-exp';

export const SET_COMPANIONS = 'SET_COMPANIONS';
export const ADD_COMPANION = 'ADD_COMPANION';
export const EDIT_COMPANION = 'EDIT_COMPANION';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const DELETE_COMPANION = 'DELETE_COMPANION';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_FETCH_MODE = 'SET_FETCH_MODE';

const COMPANIOS_CACHE_KEYNAME = 'companions';
const COMPANIOS_CACHE_LIFETIME = 360000;

const CacheSettings = {
    name: COMPANIOS_CACHE_KEYNAME,
    lifeTime: COMPANIOS_CACHE_LIFETIME,
    flag: PRIVATE_CACHE_FLAG,
};

/**
 * Prepare companion fields to fetch
 * @param {object} fields - fields to prepare
 * @return {object} - prepared fields
 */
const _prepareFields = (fields) => {
    const { name, initials, birthday, documents, gender, status } = fields;

    const { cardNo } = status || {};

    const statusCardNoData = cardNo ? { status: { cardNo: cardNo.replace(nonNumbersRegExp, '') } } : {};

    const fetchData = {
        birthday: getMillisecondsFromDate(birthday),
        documents: documents,
        gender: gender,
        initials: {
            original: {
                name: '',
                secondName: '',
                surname: '',
            },
        },
        ...statusCardNoData,
    };

    try {
        return {
            ...fetchData,
            initials: {
                original: normalizeName(name, initials),
            },
        };
    } catch (error) {
        return {
            ...fetchData,
            ..._getPreparedCompanionInitials(name, initials),
        };
    }
};

/**
 * Get user initials or parse them from name
 * @param {string} name
 * @param {Object} initials
 * @returns {Object}
 */
const _getPreparedCompanionInitials = (name, initials) => {
    if (initials) {
        return initials;
    }

    const parsedInitials = name.split(' ');

    const hasSecondName = parsedInitials.length > 2;

    return {
        name: parsedInitials[0],
        surname: hasSecondName ? parsedInitials[2] : parsedInitials[1],
        secondName: hasSecondName ? parsedInitials[1] : undefined,
    };
};

const _mapCompanions = (items) =>
    items.map((item) => {
        item.name = item.name || usernameToString(item.initials);
        item.birthday = moment.utc(item.birthday, 'X').format('DD.MM.YYYY');
        return item;
    });

/**
 * Fetch companions list
 * @return {array} - companions array
 */
export const getCompanions = (clearCache) => (dispatch) => {
    if (clearCache) {
        cache.removeItem(CacheSettings.name);
    }

    return _fetchGetCompanions().then((data) => {
        dispatch(_setCompanions(data));
        dispatch(setFetch(false));

        return true;
    });
};

const _fetchGetCompanions = () => request.get(API_URLS.PROFILE.COMPANIONS, null, true, CacheSettings);

const _setCompanions = (companions) => (dispatch) =>
    dispatch({
        type: SET_COMPANIONS,
        companions: _mapCompanions(companions),
    });

/**
 * Add new companion
 * @param {object} data - new companion fields
 * @return {bool} - fetch result
 */
export const addCompanion = (data) => (dispatch) => {
    dispatch(setFetch(true));

    const fields = _prepareFields(data);

    return dispatch(_fetchAddCompanion(fields));
};

/**
 * Fetch add request
 * @param {object} fields - prepared field to request
 * @return {type} - description
 */
export const _fetchAddCompanion = (fields) => (dispatch) =>
    request.post(API_URLS.PROFILE.COMPANIONS, fields, true).then(() => {
        cache.removeItem(COMPANIOS_CACHE_KEYNAME);
        cache.removeItem(BOOKING_DOCUMENTS_CACHE_KEYNAME);

        return dispatch(getCompanions()).then(() => {
            dispatch(setFetch(false));
            return Promise.resolve();
        });
    });

/**
 * Update companion
 * @param {object} data - companion fieds to update
 * @return {bool} - update result
 */
export const editCompanion = (data) => (dispatch) => {
    dispatch(setFetch(true));
    cache.removeItem(BOOKING_DOCUMENTS_CACHE_KEYNAME);

    const fields = _prepareFields(data);

    return dispatch(_fetchEditCompanion(data.id, fields));
};

/**
 * Fetch edit
 * @param {string} id - companion id
 * @param {object} fields - companion id
 * @return {bool} - request result
 */
export const _fetchEditCompanion = (id, fields) => (dispatch, getState) => {
    dispatch(setFetch(true));

    return request.put(API_URLS.PROFILE.COMPANIONS + `${id}/`, fields, true).then(() => {
        cache.removeItem(COMPANIOS_CACHE_KEYNAME);

        return _fetchGetCompanions();
    });
};

/*
 * Update entity in local storage
 * @param {object} companions -
 */
const _setStoreCompanions = (companions) => {
    cache.setItem(COMPANIOS_CACHE_KEYNAME, companions, COMPANIOS_CACHE_LIFETIME, PRIVATE_CACHE_FLAG);
};

/**
 * Delete companion
 * @param {string} id - companion ID
 * @return {bool} - delete result
 */
export const deleteCompanion = (id) => (dispatch) => {
    dispatch(setFetch(true));

    return request.delete(API_URLS.PROFILE.COMPANIONS + `${id}/`, null, true).then(() => {
        dispatch(_fetchGetCompanions).then((companions) => {
            const updatedList = companions.filter((item) => item.id !== id);
            _setStoreCompanions(updatedList);
            dispatch(setFetch(false));
        });

        dispatch({
            type: DELETE_COMPANION,
            id,
        });
    });
};

/**
 * Update companion field `isEdit` in store to render edit form
 * @param {string} id - companion ID
 */
export const setEditMode = (id) => ({
    type: SET_EDIT_MODE,
    id,
});

/**
 * Set save errors
 * @param {object} errors - errors
 */
export const setErrors = (errors) => ({
    type: SET_ERRORS,
    errors,
});

/**
 * Set save errors
 * @param {object} errors - errors
 */
export const setFetch = (fetching) => (dispatch) =>
    dispatch({
        type: SET_FETCH_MODE,
        fetching,
    });
