exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Agreement-module__item--3ggpq{display:flex}.Agreement-module__agreementText--Qi5yR{font-size:12px;line-height:16px}", ""]);

// exports
exports.locals = {
	"item": "Agreement-module__item--3ggpq",
	"agreementText": "Agreement-module__agreementText--Qi5yR"
};