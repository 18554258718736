import React from 'react';
import { Checkbox, Tooltip } from '@public-projects/utair-ui-kit';
import { ReactComponent as QuestionIcon } from 'checkin/shared/assets/icons/icon-question.svg';
import { t } from 'i18next';

import styles from './UnaccompaniedMinorCheckbox.module.scss';

export const UnaccompaniedMinorCheckbox = ({ isChecked, onChange }) => {
    const checkedHandler = () => {
        onChange(isChecked);
    };

    return (
        <div className={styles.root}>
            <Checkbox
                labelClassName={styles.checkbox}
                label={t('booking.unaccompaniedMinorCheckbox')}
                onChange={checkedHandler}
                checked={isChecked}
                variant="contained"
            />
            <Tooltip
                interactive
                placement="bottom"
                className={styles.tooltip}
                tooltip={
                    <div>
                        <p>Без родителей или совершенно-летнего сопровождающего.</p>
                        <a
                            href="https://www.utair.ru/support/8/ob_usluge_soprovozhdenie_detej_ot_5_do_16_let"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.tooltipLink}
                        >
                            Подробнее
                        </a>
                    </div>
                }
            >
                <div>
                    <QuestionIcon className={styles.icon} />
                </div>
            </Tooltip>
        </div>
    );
};
