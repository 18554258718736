import React, { createContext, useContext, useMemo, useState } from 'react';
import type { ComponentType, Dispatch, FC, ReactNode, SetStateAction } from 'react';

interface ExtractErrorType {
    type: string;
    detail: string;
}

interface QueryContextType {
    isLoading: boolean;
    error: ExtractErrorType | null;
}

interface ActionContextType<T> {
    setData: Dispatch<SetStateAction<T>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setError: Dispatch<SetStateAction<ExtractErrorType | null>>;
}

interface ContextProviderProps {
    children: ReactNode;
}

export function createContextStore<T>(initialState: T) {
    const ActionContext = createContext<ActionContextType<T> | null>(null);
    const DataContext = createContext<T>(initialState);
    const QueryContext = createContext<QueryContextType | null>(null);

    function useQuery(): QueryContextType | null {
        return useContext(QueryContext);
    }

    function useData(): T {
        return useContext(DataContext);
    }

    function useActionContext(): ActionContextType<T> {
        const context = useContext(ActionContext);

        if (!context) {
            throw new Error("Don't use default value");
        }

        return context;
    }

    const ContextProvider: FC<ContextProviderProps> = ({ children }) => {
        const [data, setData] = useState<T>(initialState);
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [error, setError] = useState<ExtractErrorType | null>(null);

        const action = useMemo(() => ({ setData, setIsLoading, setError }), [setData, setIsLoading, setError]);

        return (
            <ActionContext.Provider value={action}>
                <QueryContext.Provider value={{ isLoading, error }}>
                    <DataContext.Provider value={data}>{children}</DataContext.Provider>
                </QueryContext.Provider>
            </ActionContext.Provider>
        );
    };

    function withContext<U extends object>(Component: ComponentType<U>) {
        return function Context(props: U) {
            return (
                <ContextProvider>
                    <Component {...props} />
                </ContextProvider>
            );
        };
    }

    return { useQuery, useData, useAction: useActionContext, ContextProvider, withContext };
}
