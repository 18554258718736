/* eslint-disable */
import {
    SET_STEP,
    SET_LOGIN,
    SET_LOGIN_ERROR,
    SET_CODE,
    SET_CODE_ERROR,
    GETTING_TOKEN_FAILED,
    SET_SIGN_BUSY,
    SET_CHECK_TYPE,
    CLEAR_SIGN_FORM,
    SET_CODE_MESSAGE,
    SET_GDPR_AGREEMENT_DATE,
    SET_CONFIRMATION_TYPE,
} from 'actions/sign';
import { TOGGLE_MODAL_MODE } from '../actions/auth';

const INITIALSTATE = {
    step: 0,
    checkType: '',
    inputValue: '',
    codeValue: '',
    inputError: '',
    codeError: '',
    hasTokenError: false,
    isBusy: false,
    social_auth: false,
    social_data: null,
    merging_social_data: null,
    code_message: null,
    GDPRAgreementDate: 0,
    confirmationType: '',
};

const sign = (
    state = INITIALSTATE,
    { type, step, login, code, error, isBusy, checkType, socialData, isSocialAuth, codeMessage, payload }
) => {
    switch (type) {
        case SET_STEP: {
            return {
                ...state,
                hasTokenError: false,
                step,
            };
        }

        case SET_CHECK_TYPE: {
            return {
                ...state,
                checkType,
            };
        }

        case SET_LOGIN: {
            return {
                ...state,
                inputValue: login,
            };
        }

        case SET_LOGIN_ERROR: {
            return {
                ...state,
                inputError: error,
            };
        }

        case SET_CODE: {
            return {
                ...state,
                codeValue: code,
            };
        }

        case SET_CODE_ERROR: {
            return {
                ...state,
                codeError: error,
            };
        }

        case GETTING_TOKEN_FAILED: {
            return {
                ...state,
                hasTokenError: true,
            };
        }

        case SET_SIGN_BUSY: {
            return {
                ...state,
                isBusy,
            };
        }

        case SET_CODE_MESSAGE: {
            return {
                ...state,
                code_message: codeMessage,
            };
        }

        case CLEAR_SIGN_FORM: {
            return INITIALSTATE;
        }

        case TOGGLE_MODAL_MODE:
            return { ...state, inputError: '', codeError: '' };

        case SET_GDPR_AGREEMENT_DATE:
            return { ...state, GDPRAgreementDate: payload };

        case SET_CONFIRMATION_TYPE:
            return { ...state, confirmationType: payload };

        default: {
            return state;
        }
    }
};

export default sign;
