import React from 'react';
import { ReactComponent as ErrorImage } from 'checkin/shared/assets/images/error.svg';
import { ERRORS } from 'checkin/shared/consts';

import { ModalTemplate } from '../ModalTemplate';

export function ErrorModal({
    text,
    buttonText = '',
    reloadOnClose = true,
    onClose,
    onRetry = () => {},
    size = 'small',
}) {
    const isUnknown = text === ERRORS.UNKNOWN_ERROR;

    const handleRetryButtonClick = () => {
        onClose();
        onRetry();

        if (reloadOnClose) {
            window.location.reload();
        }
    };

    return (
        <ModalTemplate
            size={size}
            Image={ErrorImage}
            text={text}
            description={isUnknown && 'В работе сайта возникла ошибка, попробуйте еще раз'}
            buttonText={buttonText || (isUnknown && 'Попробовать еще раз')}
            onButtonClick={handleRetryButtonClick}
            onClose={onClose}
        />
    );
}
