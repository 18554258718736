exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Row-module__root--2cqum{display:flex;background-color:#f6f8fc;border-radius:6px;padding:16px}.Row-module__action--2LBju{margin-right:6px;height:24px}", ""]);

// exports
exports.locals = {
	"root": "Row-module__root--2cqum",
	"action": "Row-module__action--2LBju"
};