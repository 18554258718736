import { createSelector } from '@reduxjs/toolkit';

const getSocialAuthState = (state) => state.socialAuth;

export const getError = createSelector(getSocialAuthState, (state) => state.error);

export const getDialogIsVisibleState = createSelector(getSocialAuthState, (state) => state.isVisibleDisalog);

export const getBusyState = createSelector(getSocialAuthState, (state) => state.isBusy);

export const isSocialRegistration = createSelector(getSocialAuthState, (state) => state.isSocialRegistration);

export const getSocialIsVisibleState = createSelector(getSocialAuthState, (state) => state.isVisibleSocial);

export const getSocialData = createSelector(getSocialAuthState, (store) => store.social_data);

export const getMergingSocialData = createSelector(getSocialAuthState, (store) => store.merging_social_data);
