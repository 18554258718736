import { type City } from 'schedule/shared/model';

import type { CityDto } from '../api/types';

export function mapCities(dto: CityDto[], lng: string = 'ru'): City[] {
    const cities = dto.map(
        (city): City => ({
            code: city.code,
            name: city.name_translations[lng] || city.name,
            countryCode: city.country_code,
            countryName: city.country_name_translations[lng] || city.country_name,
        })
    );

    return cities;
}
