import { getClientAccessToken, getUserAccessToken } from 'actions/auth';

export async function getToken() {
    let token;

    try {
        token = await getUserAccessToken();
    } catch {
        token = await getClientAccessToken();
    }

    return token;
}
