/* eslint-disable */
import _ from 'lodash';
import {FETCH_SCHEDULE, FETCH_SCHEDULE_CLAIM, FETCH_SCHEDULE_CLEAR} from 'actions/schedule';

const INITIAL_DATA = {
    schedule: {
        isFetching: false,
        items: [],
        start: 0,
        limit: 10,
        total: 0,
        error: ''
    }
};

const schedule = (state = INITIAL_DATA, {type, payload, error}) => {
    switch (type) {
        case FETCH_SCHEDULE:
            if (error) {
                return {
                    ...state,
                    schedule: {
                        ...INITIAL_DATA.schedule,
                        error: payload.message
                    }
                };
            } else if (payload) {
                return {
                    ...state,
                    schedule: {
                        ...INITIAL_DATA.schedule,
                        start: payload.start,
                        limit: payload.limit,
                        total: payload.total,
                        items: payload.start > 0 ? _.concat(state.schedule.items, payload.items) : payload.items
                    }
                };
            }
            return {
                ...state,
                schedule: {
                    ...INITIAL_DATA.schedule,
                    isFetching: true
                }
            };

        case FETCH_SCHEDULE_CLAIM:
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    isFetching: true
                }
            };

        case FETCH_SCHEDULE_CLEAR:
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    items: []
                }
            };
        default:
            return state;
    }
};

export default schedule;
