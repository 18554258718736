exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".List-module__list--22Ked{background-color:#fff;border-radius:6px}.List-module__listHeaderWrapper--2kDJt{position:sticky;top:0;padding-top:16px;z-index:1;background-color:#ebeef3}.List-module__listHeader--3gmy6{width:816px;background-color:#fff;box-shadow:0px 3px 7px rgba(0,37,102,.1);border-top-left-radius:6px;border-top-right-radius:6px}.List-module__listDivider--1koaz{display:flex;align-items:center;margin:16px 16px 0;font-weight:400;font-size:14px;line-height:20px;color:rgba(94,110,135,.8)}.List-module__anchor--1L0Wm{position:relative;top:-56px}.List-module__tab--6k-sR{display:inline-block;margin:14px 1px}.List-module__tab--6k-sR:first-child{margin-left:16px}.List-module__tab--6k-sR:last-child{margin-right:16px}", ""]);

// exports
exports.locals = {
	"list": "List-module__list--22Ked",
	"listHeaderWrapper": "List-module__listHeaderWrapper--2kDJt",
	"listHeader": "List-module__listHeader--3gmy6",
	"listDivider": "List-module__listDivider--1koaz",
	"anchor": "List-module__anchor--1L0Wm",
	"tab": "List-module__tab--6k-sR"
};