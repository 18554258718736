import { pushPlausibleEvent } from 'checkin/shared/lib/plausible';

const SERVICE_PAGE_NAMES = {
    MEAL: 'meal',
    BAGGAGE: 'baggage',
};

export function sendServicePageErrorEvent(pathname = '', params) {
    const page = pathname.includes(SERVICE_PAGE_NAMES.BAGGAGE) ? SERVICE_PAGE_NAMES.BAGGAGE : SERVICE_PAGE_NAMES.MEAL;

    pushPlausibleEvent(`checkin_${page}_screen_error`, params);
}
