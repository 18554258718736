/* eslint-disable */
import React from 'react';
import classNames from 'classnames';

import {ReactComponent as Plus} from 'icons/interface/plus.svg';
import {ReactComponent as Minus} from 'icons/interface/minus.svg';

const buttonStyle = {
    width: '1.6rem',
    height: '1.6rem'
};

class NumberInput extends React.Component {
    static defaultProps = {
        onChange: () => {},
        value: 0,
        text: ''
    };

    constructor(props) {
        super(props);

        const {minValue, maxValue} = props;
        let value = props.value;

        if (Number.isInteger(minValue) && value < minValue) {
            value = minValue;
        } else if (Number.isInteger(maxValue) && value > maxValue) {
            value = maxValue;
        }

        this.state = {value};
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.maxValue < this.props.value) {
            this.changeValue(nextProps.maxValue);
        }
    }

    changeValue(newValue) {
        const {minValue, maxValue} = this.props;

        if (
            (Number.isInteger(minValue) && newValue < minValue) ||
            (Number.isInteger(maxValue) && newValue > maxValue)
        ) {
            return;
        }

        this.setState({value: newValue});
        this.props.onChange(newValue);
    }

    handleIncButtonClick = () => {
        this.changeValue(this.state.value + 1);
    };

    handleDecButtonClick = () => {
        this.changeValue(this.state.value - 1);
    };

    handleInputChange = (event) => {
        const newValue = +event.target.value;

        if (Number.isInteger(newValue)) {
            this.changeValue(newValue);
        }
    };

    render() {
        const {minValue, maxValue, className} = this.props;
        const {value} = this.state;

        return (
            <div className={classNames('Counter', className)}>
                <button
                    className="Button-square"
                    type="button"
                    aria-label="Отнять"
                    disabled={minValue >= value}
                    onClick={this.handleDecButtonClick}
                    data-testid="number-input-minus"
                >
                    <Minus style={buttonStyle} />
                    <span className="aria-hidden">-</span>
                </button>

                <span className="Counter-value">
                    <input
                        type="number"
                        value={value}
                        className="Counter-input"
                        onChange={this.handleInputChange}
                        onInput={this.handleInputChange}
                    />
                </span>

                <button
                    disabled={maxValue <= value}
                    onClick={this.handleIncButtonClick}
                    className="Button-square"
                    type="button"
                    aria-label="Прибавить"
                    data-testid="number-input-plus"
                >
                    <Plus style={buttonStyle} />
                    <span className="aria-hidden">+</span>
                </button>
            </div>
        );
    }
}

export default NumberInput;
